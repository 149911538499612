import { Backdrop, Button } from "@deligoo/ui"
import dayjs from "dayjs"
import { Dispatch, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useAppSelector } from "@/App"
import { CloseButton } from "@/components/CloseButton"
import { DeliveryMethod } from "@/components/DeliveryMethod"
import { PaymentForm } from "@/components/PaymentForm"
import { Price } from "@/components/Price"
import { selectOrderById } from "@/store/orders"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { Order } from "@/types"
import { formatHour, useIsMounted } from "@/utils"

import { DriverActionsBar } from "./actions/DriverActionsBar"
import cls from "./OrderDetailsModal.module.scss"
import { OrderTimeline } from "./OrderTimeline"
import { SignatureModal } from "./SignatureModal"

type OrderDetailsModalProps = {
  orderId: number
  handleClose: () => void
}

const OrderDetailsModal = ({
  orderId,
  handleClose,
}: OrderDetailsModalProps) => {
  const order = useAppSelector(selectOrderById(orderId))

  const interfaceType = useSelector(
    selectCurrentRestaurantKey("interface_type")
  )

  const [isSignatureShown, setIsSignatureShown] = useState(false)

  const { t } = useTranslation("orders")

  const isMounted = useIsMounted()

  if (!order) return null

  const creationDate = dayjs(order.created_at)

  return (
    <Backdrop>
      <div className={cls.orderDetails}>
        <header className={cls.header}>
          <span className={cls.left}>
            <h2 className={cls.title}>
              {t("details_modal.order_uid", { orderUid: order.uid })}
            </h2>
            <span className={cls.address}>{order.delivery?.address}</span>
          </span>

          <CloseButton onClick={handleClose} />
        </header>

        <DriverActionsBar
          orderId={orderId}
          handleModalClose={handleClose}
          isParentMounted={isMounted}
        />

        <div className={cls.content}>
          <div className={cls.systemData}>
            <h4 className={cls.subtitle}>{t("details_modal.system_data")}</h4>
            <dl className={cls.details}>
              <dt>{t("details_modal.created_at")}</dt>
              <dd>
                <span className={cls.spaced}>
                  {creationDate.format("DD.MM.YYYY")}
                </span>
                <span className={cls.spaced}>{formatHour(creationDate)}</span>
              </dd>
              <dt>{t("details_modal.estimated_time")}</dt>
              <dd>
                <span className={cls.spaced}>
                  <DeliveryMethod
                    deliveryMethod={order.delivery_method}
                    deliveryAt={order.delivery_at}
                  />
                </span>
                {order.pickup_at && (
                  <span className={cls.spaced}>
                    {formatHour(order.pickup_at)}
                  </span>
                )}
              </dd>
              <dt>{t("details_modal.payment")}</dt>
              <dd>
                <span className={cls.spaced}>
                  <PaymentForm
                    paymentForm={order.payment_form}
                    iconSide="right"
                  />
                </span>
                <span className={cls.spaced}>
                  <Price subunit>{order.full_price_subunit}</Price>
                </span>
              </dd>
              <dt>{t("details_modal.count")}</dt>
              <dd>
                {order.amount} {t("unit", { count: order.amount })}
              </dd>
              {interfaceType === "hebe" && (
                <>
                  <dt>{t("details_modal.weight")}</dt>
                  <dd>{order.weight} kg</dd>
                </>
              )}
              <dt>{t("details_modal.size")}</dt>
              <dd>
                {Object.entries(order.packages).map(([type, amount]) => (
                  <div key={type}>
                    {t(`package_type.${type}`)} (x
                    {amount})
                  </div>
                ))}
              </dd>
              <dt>{t("details_modal.drinks")}</dt>
              <dd>
                {order.has_drinks
                  ? t("details_modal.yes")
                  : t("details_modal.no")}
              </dd>
              <dt>{t("details_modal.phone")}</dt>
              <dd>{order.delivery?.phone || order.pickup?.phone}</dd>
              {order.delivery?.address && (
                <>
                  <dt>{t("details_modal.address")}</dt>
                  <dd>{order.delivery?.address}</dd>
                </>
              )}
              <dt>{t("details_modal.distance")}</dt>
              <dd>{order.distance} km</dd>
              <dt>{t("details_modal.order_comment")}</dt>
              <dd>{order.order_comment || t("details_modal.none")}</dd>
              <dt>{t("details_modal.client_comment")}</dt>
              <dd>{order.client_comment || t("details_modal.none")}</dd>
              <dt>{t("details_modal.customer_comment")}</dt>
              <dd>{order.customer_comment || t("details_modal.none")}</dd>

              {order.external_id && (
                <>
                  <dt>Źródłowy numer zamówienia</dt>
                  <dd>#{order.external_id.slice(-8)}</dd>
                </>
              )}

              {order.cancellation_reason && (
                <>
                  <dt>{t("details_modal.cancellation_reason")}</dt>
                  <dd>
                    {order.cancellation_reason || t("details_modal.none")}
                  </dd>
                </>
              )}
              {order.invoice && order.provider !== "marketplace" && (
                <>
                  <dt>{t("details_modal.invoice")}</dt>
                  <dd className={cls.invoice}>
                    <span>{order.invoice.company}</span>
                    <span>
                      {`${order.invoice.street}, ${order.invoice.postal_code} ${order.invoice.city}`}
                    </span>
                    <span>
                      {t("details_modal.nip")}: {order.invoice.nip}
                    </span>
                  </dd>
                </>
              )}
              {order.delivery?.authorization_code && (
                <>
                  <dt>{t("details_modal.authorization_code")}</dt>
                  <dd>{order.delivery.authorization_code}</dd>
                </>
              )}
              {order.delivery?.authorization_signature && (
                <>
                  <dt>{t("details_modal.signature")}</dt>
                  <dd>
                    <Button
                      as="a"
                      variation="text"
                      size="large"
                      onClick={() => setIsSignatureShown(true)}
                      className={cls.signature}
                    >
                      {t("details_modal.show_signature")}
                    </Button>
                  </dd>
                </>
              )}
            </dl>

            {isSignatureShown && (
              <SignatureModal
                signature={order.delivery?.authorization_signature}
                handleClose={() => setIsSignatureShown(false)}
              />
            )}
          </div>

          <section className={cls.timeline}>
            <h4 className={cls.subtitle}>{t("details_modal.timeline")}</h4>
            {order.id && (
              <OrderTimeline
                orderId={order.id}
                dependencies={[
                  order.state,
                  order.driver_id,
                  order.order_is_ready,
                ]}
                className={cls.orderTimeline}
              />
            )}
          </section>
        </div>
      </div>
    </Backdrop>
  )
}

function useDetailsModal(): [
  Order["id"] | null,
  Dispatch<SetStateAction<Order["id"] | null>>
] {
  const [currentOrderDetailsId, setCurrentOrderDetailsId] = useState<
    Order["id"] | null
  >(null)

  return [currentOrderDetailsId, setCurrentOrderDetailsId]
}

export { OrderDetailsModal, useDetailsModal }
