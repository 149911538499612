const complaint = {
  Complaints: "Complaints",
  "Fill a complaint form": "Fill a complaint form",
  "Drag here or": "Drag & drop here",
  "choose files": "select files",
  city: "City",
  client: "Location",
  email: "E-mail Address",
  order_uid: "Order number",
  date: "Date",
  description: "Description",
  location: "Location",
  complaint_reason: "Complaint reason",
  attachments: "Attachments",
  Send: "Send",
  "Form has been sent": "Complaint sent",
  "Failed to send a form": "Complaint error",
  "Field is required": "Field is required",
  order_uid_pattern:
    "Enter the 7-digit order number, e.g.: #1234567 or 1234567",
  "Select complaint reason": "Choose the reason for the complaint",
  courier_late: "the courier is more than 15 minutes late",
  order_cancelled: "order cancelled by courier",
  parcel_damaged: "parcel damaged",
  pickup_time_changed: "pickup time changed",
  parcel_lost: "lost parcel",
  rude_courier: "bad behavior of the courier",
  other: "other",
}

export default complaint
