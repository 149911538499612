import { Switch } from "@deligoo/ui"
import { useState } from "react"
import { useSelector } from "react-redux"

import { useAppDispatch } from "@/App"
import { requestUpdateSettings, selectSettingsKey } from "@/store/client"

const AutomaticPrintingSwitch = () => {
  const settings = useSelector(selectSettingsKey("settings_attributes"))

  const { automatic_receipt_printing = false } = settings || {}

  const [isRequestingUpdate, setIsRequestingUpdate] = useState(false)

  const dispatch = useAppDispatch()

  async function togglePrinting() {
    setIsRequestingUpdate(true)

    await dispatch(
      requestUpdateSettings({
        settings_attributes: {
          automatic_receipt_printing: !automatic_receipt_printing,
        },
      })
    )

    setIsRequestingUpdate(false)
  }

  return (
    <Switch
      on={automatic_receipt_printing}
      disabled={isRequestingUpdate}
      onChange={togglePrinting}
    />
  )
}

export { AutomaticPrintingSwitch }
