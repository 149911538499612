const payments = {
  headers: {
    unsettled_payments: "Nierozliczone płatności",
    order_uid: "Numer zamówienia i adres",
    pickup_date: "Data odbioru",
    driver: "Kurier",
    amount: "Kwota",
    event: "Zdarzenie",
    status: "Status",
    amount_to_settle: "Kwota do rozliczenia",
  },

  summary: {
    unsettled_amount: "Do rozliczenia",
    awaiting_confirmation_amount: "Do potwierdzenia",
    total: "Suma",
  },

  settlement_type_label: {
    waiting_for_client: "Potwierdź",
    waiting_for_driver: "Oczekuje",
    waiting: "Rozlicz",
    unsettled: "Rozlicz",
  },

  refund_for: {
    for: "dla",
    client: "Lokalu",
    driver: "Kuriera",
  },
  payment_form: {
    card: "Karta",
    cash: "Gotówka",
    paid: "Zapłacono",
  },
  payment_status: {
    completed: "Potwierdzono",
    in_progress: "W trakcie",
    failed: "Niepowodzenie",
    chargeback: "Zwrot",
  },
  sales_document: {
    invoice: "Faktura",
    receipt: "Paragon",
  },
  modal: {
    header: {
      waiting_for_client: "Potwierdź płatność #{{uid}}",
      waiting_for_driver: "Płatność #{{uid}} oczekuje",
      unsettled: "Rozlicz płatność #{{uid}}",
    },
    description: {
      waiting_for_client:
        "Ta transakcja została rozliczona przez kuriera, jeśli potwierdzasz zdarzenie, zatwierdź rozliczenie.",
      waiting_for_driver:
        "Płatność zostanie rozliczona w momencie potwierdzenia przez kuriera.",
      unsettled:
        "Jeśli potwierdzisz rozliczenie, ta transakcja będzie musiała zostać potwierdzona przez kuriera.",
    },
    buttonText: {
      waiting_for_client: "Potwierdzam rozliczenie",
      waiting_for_driver: "Rozlicz płatność",
      unsettled: "Rozlicz płatność",
    },
  },
}

export default payments
