import clsx from "clsx"
import { useEffect } from "react"

import { Backdrop } from "../Backdrop"
import { Icon } from "../Icon"
import { Spinner } from "../Spinner"
import cls from "./Modal.module.scss"

export type ModalProps = {
  children: React.ReactNode
  light?: boolean
  loading?: boolean
  className?: string
  zIndex?: number
  closeBtnPosition?: "inside" | "outside"
  onClick?: (e?: React.SyntheticEvent) => void
  handleClose: (e?: React.SyntheticEvent) => void
}

export const Modal = ({
  loading = false,
  closeBtnPosition = "outside",
  handleClose,
  light,
  zIndex,
  onClick,
  className,
  children,
  ...props
}: ModalProps) => {
  function handleClick(event: React.SyntheticEvent) {
    event.stopPropagation() // Prevent event bubbling to Backdrop
    onClick?.()
  }

  function onBackdropClick(e: React.SyntheticEvent) {
    e.stopPropagation() // Prevent event bubbling to wrapper components
    handleClose()
  }

  useCloseOnEscape(handleClose)

  return (
    <Backdrop light={light} onClick={onBackdropClick} style={{ zIndex }}>
      <div
        className={clsx(cls.modal, loading && cls.loading, className)}
        onClick={handleClick}
        role="dialog"
        {...props}
      >
        <div className={clsx(cls.close, closeBtnPosition === "inside" && cls.inside)}>
          <button className={cls.closeBtn} onClick={handleClose}>
            <Icon name="close" size="small" />
          </button>
        </div>

        {loading ? <Spinner className={cls.spinner} /> : children}
      </div>
    </Backdrop>
  )
}

export type ModalChildrenProps = {
  children: React.ReactNode
  className?: string
}

const ModalTitle = ({ children, className, ...props }: ModalChildrenProps) => {
  return (
    <div className={clsx(cls.title, className)} {...props}>
      {children}
    </div>
  )
}

const ModalHeader = ({ children, className, ...props }: ModalChildrenProps) => {
  return (
    <div className={clsx(cls.header, className)} {...props}>
      {children}
    </div>
  )
}

const ModalContent = ({ children, className, ...props }: ModalChildrenProps) => {
  return (
    <div className={clsx(cls.content, className)} {...props}>
      {children}
    </div>
  )
}

const ModalFooter = ({ children, className, ...props }: ModalChildrenProps) => {
  return (
    <div className={clsx(cls.footer, className)} {...props}>
      {children}
    </div>
  )
}

Modal.Title = ModalTitle
Modal.Header = ModalHeader
Modal.Footer = ModalFooter
Modal.Content = ModalContent

function useCloseOnEscape(handleClose: () => void) {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Escape") handleClose()
    }

    document.addEventListener("keydown", listener)

    return () => {
      document.removeEventListener("keydown", listener)
    }
  }, [handleClose])
}
