import { Button, Modal } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

import { useAppDispatch } from "@/App"
import { requestCancelOrder } from "@/store/orders"
import { useIsMounted } from "@/utils"

import { ActionProps } from "."
import cls from "./actions.module.scss"

const TEMPLATES = ["delivery_time_exceeded", "canceled_by_client", "other"]

const CancelOrder = ({
  orderId,
  handleClose,
  isParentMounted,
  isActionInProgress,
  setIsActionInProgress,
}: ActionProps) => {
  const [cancellationReason, setCancellationReason] = useState<string>()

  const dispatch = useAppDispatch()
  const isMounted = useIsMounted()

  const { t } = useTranslation(["orders", "common"])

  function handleCancelOrder() {
    if (!orderId) return

    if (!cancellationReason) {
      toast.error(t("cancel_order_modal.select_cancelation_reason_error"), {
        toastId: "noCancellationReasonError",
      })

      return
    }

    setIsActionInProgress(true)

    toast.dismiss("noCancellationReasonError")

    dispatch(
      requestCancelOrder({
        orderId,
        cancellationReason: t(`cancelation_reasons.${cancellationReason}`, {
          lng: "pl",
        }),
      })
    )
      .then(unwrapResult)
      .then(({ ok }) => {
        if (!ok) return
        toast.success(t("cancel_order_modal.order_canceled"))
        isMounted() && handleClose()
      })
      .finally(() => {
        isParentMounted() && setIsActionInProgress(false)
      })
  }

  if (!orderId) return null

  return (
    <Modal
      handleClose={handleClose}
      className={clsx(cls.actionModal, cls.confirm)}
      light
    >
      <Modal.Title>{t("cancel_order_modal.title")}</Modal.Title>
      <Modal.Content>
        <div className={cls.content}>
          {t("cancel_order_modal.cancelation_reason")}:
          {TEMPLATES.map((template, index) => (
            <Button
              className="mt-2"
              onClick={() => setCancellationReason(template)}
              color={cancellationReason === template ? "primary" : undefined}
              size="extra-large"
              key={index}
              fullWidth
            >
              {t(`cancelation_reasons.${template}`)}
            </Button>
          ))}
        </div>

        <div className={cls.actions}>
          <Button
            onClick={handleClose}
            className={cls.action}
            color="alert"
            size="extra-large"
          >
            {t("common:buttons.no")}
          </Button>

          <Button
            onClick={handleCancelOrder}
            className={cls.action}
            color="success"
            size="extra-large"
            loading={isActionInProgress}
          >
            {t("common:buttons.yes")}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export { CancelOrder }
