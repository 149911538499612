import { Icon } from "@deligoo/ui"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Card } from "@/components/Card"
import { RestaurantStatus } from "@/components/RestaurantStatus"
import {
  selectCurrentRestaurant,
  selectCurrentRestaurantStatus,
} from "@/store/restaurant"

import cls from "../Dashboard.module.scss"

const PricingPolicy = () => {
  const restaurant = useSelector(selectCurrentRestaurant)
  const restaurantStatus = useSelector(selectCurrentRestaurantStatus)

  const { t } = useTranslation("dashboard")

  const isActive = restaurant?.pricing_policy === "active"

  const helpModal = {
    title: "Delivery pricing policy",
    content: (
      <>
        {t("privacy_policy_desc")}
        <div className="text-small mt-2">{t("privacy_policy_small")}</div>
      </>
    ),
  }

  return (
    <Card
      help={t("Learn more")}
      helpModal={helpModal}
      loading={restaurantStatus === "loading"}
    >
      <Card.Header>
        <Card.Title>
          <Icon
            name={isActive ? "check" : "close"}
            color={isActive ? "success" : "alert"}
          />
        </Card.Title>
      </Card.Header>
      <div className={cls.pricingPolicyStatus}>
        {t("Delivery pricing policy")}
        <RestaurantStatus status={isActive} />
      </div>
    </Card>
  )
}

export { PricingPolicy }
