import appUpdate from "./appUpdate"
import common from "./common"
import complaint from "./complaint"
import createOrder from "./createOrder"
import dashboard from "./dashboard"
import getApps from "./getApps"
import integrations from "./integrations"
import messages from "./messages"
import orders from "./orders"
import payments from "./payments"
import print from "./print"
import restaurant from "./restaurant"
import settings from "./settings"
import signIn from "./signIn"
import statistics from "./statistics"

const pl = {
  dashboard,
  payments,
  orders,
  restaurant,
  appUpdate,
  print,
  complaint,
  common,
  settings,
  statistics,
  getApps,
  signIn,
  integrations,
  messages,
  createOrder,
}

export default pl
