import { Provider } from "@deligoo/shared"
import { Button } from "@deligoo/ui"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useAppSelector } from "@/App"
import { selectOrderById } from "@/store/orders"
import {
  selectCanRestaurantCreateOrders,
  selectCurrentRestaurantKey,
} from "@/store/restaurant"
import { Order } from "@/types"
import { printOrderDetails } from "@/utils"
import {
  canBeCanceled,
  canBeReentered,
  canBeRefunded,
  canHavePaymentChanged,
  downloadOrderLabel,
  hasFinished,
  isReceivable,
} from "@/utils/order"

import {
  CancelOrder,
  NotifyDriver,
  OrderBasket,
  OrderNotes,
  PaymentRefund,
  ReenterOrder,
  UpdateOrderPayment,
} from "../actions"
import cls from "../OrderDetailsModal.module.scss"

type OrderDetailsAction =
  | "cancel"
  | "reenter"
  | "updatePayment"
  | "paymentRefund"
  | "orderBasket"
  | "notes"

const CAN_REENTER_ORDERS_PROVIDERS: Provider[] = [
  "deligoo",
  "shop",
  "marketplace",
  "glodny",
]

type DriverActionsBarProps = {
  orderId: Order["id"]
  isParentMounted: () => boolean
  handleModalClose: () => void
}

export const DriverActionsBar = ({
  orderId,
  isParentMounted,
  handleModalClose,
}: DriverActionsBarProps) => {
  const order = useAppSelector(selectOrderById(orderId))

  const interfaceType = useSelector(
    selectCurrentRestaurantKey("interface_type")
  )
  const canRestaurantCreateOrders = useSelector(selectCanRestaurantCreateOrders)

  const canCancelOrders = useSelector(
    selectCurrentRestaurantKey("can_cancel_orders")
  )
  const canCreateAgainOrders = useSelector(
    selectCurrentRestaurantKey("can_create_again_orders")
  )
  const [isActionInProgress, setIsActionInProgress] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [currentAction, setCurrentAction] =
    useState<OrderDetailsAction | null>()

  const { t } = useTranslation("orders")

  const closeCurrentAction = () => setCurrentAction(null)

  if (!order) return null

  return (
    <div className={cls.actions}>
      {canCancelOrders && (
        <>
          <Button
            className={cls.actionBtn}
            color="muted"
            iconProps={{ name: "close-circle" }}
            onClick={() => setCurrentAction("cancel")}
            disabled={!canBeCanceled(order)}
          >
            {t("order_actions.cancel")}
          </Button>
          {currentAction === "cancel" && canBeCanceled(order) && (
            <CancelOrder
              orderId={order.id}
              handleClose={closeCurrentAction}
              isParentMounted={isParentMounted}
              isActionInProgress={isActionInProgress}
              setIsActionInProgress={setIsActionInProgress}
            />
          )}
        </>
      )}

      {canRestaurantCreateOrders && canCreateAgainOrders && (
        <Button
          className={cls.actionBtn}
          color="muted"
          iconProps={{ name: "edit" }}
          onClick={() => setCurrentAction("reenter")}
          disabled={
            !canBeReentered(order) ||
            !CAN_REENTER_ORDERS_PROVIDERS.includes(order.provider)
          }
        >
          {t("order_actions.reenter")}
        </Button>
      )}
      {currentAction === "reenter" && canBeReentered(order) && (
        <ReenterOrder
          orderId={order.id}
          handleClose={closeCurrentAction}
          handleModalClose={handleModalClose}
          isParentMounted={isParentMounted}
          isActionInProgress={isActionInProgress}
          setIsActionInProgress={setIsActionInProgress}
        />
      )}

      {(interfaceType === "deligoo" || interfaceType === "soliddelivery") && (
        <>
          <Button
            className={cls.actionBtn}
            color="muted"
            iconProps={{ name: "cash" }}
            style={{ minWidth: "180px" }}
            disabled={hasFinished(order.state) || !canHavePaymentChanged(order)}
            onClick={() => setCurrentAction("updatePayment")}
          >
            {t("order_actions.update_payment")}
          </Button>
          {currentAction === "updatePayment" && (
            <UpdateOrderPayment
              orderId={order.id}
              handleClose={closeCurrentAction}
              isParentMounted={isParentMounted}
              isActionInProgress={isActionInProgress}
              setIsActionInProgress={setIsActionInProgress}
            />
          )}

          <Button
            className={cls.actionBtn}
            color="muted"
            iconProps={{ name: "cash-return" }}
            onClick={() => setCurrentAction("paymentRefund")}
            disabled={
              order.canceled_by === "admin" ? false : !canBeRefunded(order)
            }
          >
            {t("order_actions.refund")}
          </Button>
          {currentAction === "paymentRefund" && (
            <PaymentRefund
              orderId={order.id}
              handleClose={closeCurrentAction}
              isParentMounted={isParentMounted}
              isActionInProgress={isActionInProgress}
              setIsActionInProgress={setIsActionInProgress}
            />
          )}
        </>
      )}

      {Boolean(Object.entries(order.details).length) && (
        <>
          <Button
            className={cls.actionBtn}
            color="muted"
            iconProps={{ name: "tasks" }}
            onClick={() => setCurrentAction("orderBasket")}
          >
            {t("order_actions.content")}
          </Button>
          {currentAction === "orderBasket" && (
            <OrderBasket orderId={order.id} handleClose={closeCurrentAction} />
          )}
        </>
      )}

      <Button
        className={cls.actionBtn}
        color="muted"
        iconProps={{ name: "comments" }}
        onClick={() => setCurrentAction("notes")}
        disabled={hasFinished(order.state)}
      >
        {t("order_actions.notes")}
      </Button>
      {currentAction === "notes" && (
        <OrderNotes
          orderId={order.id}
          handleClose={closeCurrentAction}
          isParentMounted={isParentMounted}
          isActionInProgress={isActionInProgress}
          setIsActionInProgress={setIsActionInProgress}
        />
      )}

      <NotifyDriver orderId={order.id} isParentMounted={isParentMounted} />

      {(interfaceType === "inpost" || order?.has_label) && (
        <Button
          className={cls.actionBtn}
          color="muted"
          iconProps={{ name: "pdf" }}
          onClick={() => downloadOrderLabel(order.id)}
          disabled={!order.has_label}
        >
          {t("order_actions.download_label")}
        </Button>
      )}

      {window.KBBluetooth && isReceivable(order) && (
        <Button
          className={cls.actionBtn}
          color="muted"
          iconProps={{ name: "bills" }}
          onClick={async () => {
            if (isPrinting) return
            setIsPrinting(true)

            await printOrderDetails(order.id)
            setIsPrinting(false)
          }}
          loading={isPrinting}
        >
          {t("order_actions.print")}
        </Button>
      )}
    </div>
  )
}
