import { Button } from "@deligoo/ui"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useSelector } from "react-redux"

import { selectCurrentOrderFormOrderId } from "@/store/orderForm"
import { PaymentAndPackage, TransportType } from "@/types"
import { fetchAvailableTransportTypes } from "@/utils"

import cls from "../PaymentAndPackage.module.scss"

const TransportTypes = () => {
  const { setValue } = useFormContext<PaymentAndPackage>()
  const orderId = useSelector(selectCurrentOrderFormOrderId)

  const packages = useWatch<PaymentAndPackage["packages"]>({
    name: "packages",
  })! // Can't be undefined as defaultValue is provided in useForm
  const packagesCount = useMemo(() => getPackagesCount(packages), [packages])

  const transport_types = useWatch<PaymentAndPackage["transport_types"]>({
    name: "transport_types",
  })! // Can't be undefined as defaultValue is provided in useForm

  const [availableTransportTypes, setAvailableTransportTypes] = useState<
    TransportType[]
  >(["car"])

  const isTransportTypeAvailable = useCallback(
    (transportType: TransportType) =>
      availableTransportTypes.some(
        (availableType) => availableType === transportType
      ),
    [availableTransportTypes]
  )

  useEffect(() => {
    async function getAvailableTransportTypes() {
      if (!orderId) return

      const { json } = await fetchAvailableTransportTypes(orderId, {
        "big-pizza": Number(packages["big-pizza"]) || 0,
        "standard-dish": Number(packages["standard-dish"]) || 0,
        "own-packaging": Number(packages["own-packaging"]) || 0,
      })

      setAvailableTransportTypes(() => {
        return json?.data?.available_transport_types || ["car"]
      })
    }

    getAvailableTransportTypes()
    // Only count of packages is important
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, packagesCount])

  useEffect(() => {
    const valueToSet = transport_types.filter(isTransportTypeAvailable)

    setValue("transport_types", valueToSet)
    // Only length of transportTypes is important. Also, adding the array itself
    // to deps would cause an infinite rerender loop because of setValue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transport_types.length,
    availableTransportTypes,
    isTransportTypeAvailable,
    setValue,
  ])

  return (
    <div className={cls.transportTypes}>
      <Button
        className={cls.transportType}
        iconProps={{ name: "bike", size: "large" }}
        color="primary"
        size="extra-large"
        disabled={!isTransportTypeAvailable("bicycle")}
      />

      <Button
        className={cls.transportType}
        iconProps={{ name: "scooter", size: "large" }}
        color="primary"
        size="extra-large"
        disabled={!isTransportTypeAvailable("scooter")}
      />

      <Button
        className={cls.transportType}
        style={{ cursor: "default" }}
        iconProps={{ name: "private-car", size: "large" }}
        color="primary"
        size="extra-large"
      />
    </div>
  )
}

function getPackagesCount(packages: PaymentAndPackage["packages"] | undefined) {
  return packages
    ? Object.values(packages).reduce(
        (prev, curr) => Number(prev) + Number(curr),
        0
      )
    : 0
}

export { TransportTypes }
