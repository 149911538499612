import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Card } from "@/components/Card"
import {
  selectCurrentRestaurant,
  selectCurrentRestaurantStatus,
} from "@/store/restaurant"

const DistanceLimit = () => {
  const restaurant = useSelector(selectCurrentRestaurant)
  const restaurantStatus = useSelector(selectCurrentRestaurantStatus)

  const { t } = useTranslation("dashboard")

  const helpModal = {
    title: "Distance limit",
    content: t("distance_limit_desc"),
  }

  return (
    <Card
      help={t("Learn more")}
      helpModal={helpModal}
      loading={restaurantStatus === "loading"}
    >
      <Card.Header>
        <Card.Title color="warning">
          {restaurant?.deligoo_max_distance_limit} km
        </Card.Title>
      </Card.Header>
      {t("Distance limit")}
    </Card>
  )
}

export { DistanceLimit }
