import { Icon, Spinner, ThemeColor } from "@deligoo/ui"
import clsx from "clsx"
import { Children, CSSProperties, HTMLAttributes } from "react"
import { useTranslation } from "react-i18next"

import cls from "./Table.module.scss"

type TableProps = HTMLAttributes<HTMLDivElement> & {
  columns?: string
  minWidth?: string
}

const Table = ({
  columns,
  minWidth = "1000px",
  style,
  children,
  className,
  ...props
}: TableProps) => {
  const columnsStyles = columns
    ? ({ "--columns": columns } as CSSProperties)
    : {}

  const minWidthStyles = { "--minWidth": minWidth } as CSSProperties

  return (
    <div className={cls.wrapper} style={minWidthStyles}>
      <div
        className={clsx(cls.table, className)}
        style={{ ...columnsStyles, ...style }}
        {...props}
      >
        {children}
      </div>
    </div>
  )
}

const Thead = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={clsx(cls.thead, className)} {...props}>
      {children}
    </div>
  )
}

type TbodyProps = HTMLAttributes<HTMLDivElement> & {
  loading?: boolean
}

const Tbody = ({ children, className, loading, ...props }: TbodyProps) => {
  const { t } = useTranslation()

  if (loading) {
    return (
      <div className={clsx(cls.tbody, className)} {...props}>
        <div className={cls.status}>
          <Spinner className={cls.spinner} size="extra-large" />
        </div>
      </div>
    )
  }

  return (
    <div className={clsx(cls.tbody, className)} {...props}>
      {Children.count(children) ? (
        children
      ) : (
        <div className={cls.status}>
          <Icon name="box" className={cls.icon} />
          {t("alerts.empty")}
        </div>
      )}
    </div>
  )
}

type TrProps = HTMLAttributes<HTMLDivElement> & {
  indicatorColor?: ThemeColor
}

const Tr = ({
  indicatorColor,
  children,
  className,
  onClick,
  ...props
}: TrProps) => {
  return (
    <div
      className={clsx(
        cls.tr,
        indicatorColor && [cls.indicator, cls[indicatorColor]],
        onClick && cls.action,
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  )
}

const Th = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={clsx(cls.th, className)} {...props}>
      {children}
    </div>
  )
}

type TdProps = HTMLAttributes<HTMLDivElement> & {
  indicatorLabel?: boolean
}

const Td = ({ indicatorLabel, children, className, ...props }: TdProps) => {
  return (
    <div
      className={clsx(cls.td, indicatorLabel && cls.indicatorLabel, className)}
      {...props}
    >
      {children}
    </div>
  )
}

Table.Thead = Thead
Table.Tbody = Tbody
Table.Th = Th
Table.Tr = Tr
Table.Td = Td

export { Table }
