export enum ThemeColors {
  primary = "primary",
  secondary = "secondary",
  success = "success",
  warning = "warning",
  alert = "alert",
  danger = "danger",
  info = "info",
  accent = "accent",
  muted = "muted",
  light = "light",
  dark = "dark",
}

export enum ControlSizes {
  small = "small",
  medium = "medium",
  large = "large",
  "extra-large" = "extra-large",
}

export type ThemeColor = keyof typeof ThemeColors
export type ControlSize = keyof typeof ControlSizes
