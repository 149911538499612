import { useTranslation } from "react-i18next"

import { Card } from "@/components/Card"
import { Gauge } from "@/components/Gauge"
import { Statistics } from "@/types"

import cls from "../Statistics.module.scss"

type AverageDailyOrdersProps = {
  loading: boolean
  value?: Statistics["average_daily_orders_count"]
  className?: string
}

const AverageDailyOrders = ({
  loading,
  value,
  className,
}: AverageDailyOrdersProps) => {
  const { t } = useTranslation("statistics")

  return (
    <Card loading={loading} className={className} sections inline>
      <Card.Section>
        <Gauge fraction={0.9} iconName="box" className={cls.chart} />
      </Card.Section>
      <Card.Section>
        <Card.Title color="success">{value} szt.</Card.Title>
        {t("average_daily_orders_count")}
      </Card.Section>
    </Card>
  )
}

export { AverageDailyOrders }
