import dayjs, { Dayjs } from "dayjs"

import {
  Delivery,
  Order,
  OrderPoint,
  OrderPointState,
  OrderState,
  Pickup,
} from "@/types"
import { downloadBase64File, fetchDownloadOrderLabel } from "@/utils"

export function isPickup(point: OrderPoint): point is Pickup {
  return point.type === "Order::Pickup"
}

export function isDelivery(point: OrderPoint): point is Delivery {
  return point.type === "Order::Delivery"
}

export function isWithoutLogistics(order: Order) {
  const { delivery_method, management_kind } = order

  return (
    delivery_method === "own_delivery" ||
    delivery_method === "pickup_now" ||
    delivery_method === "pickup_later" ||
    management_kind === "shop_only"
  )
}

export function isPickupOnly(order: Order) {
  return Boolean(order.pickup && !order.delivery)
}

export function isOrderProcessing(orderState: OrderState) {
  return ["pending", "assigned", "omitted"].includes(orderState)
}

export function hasOrderBeenPublished(orderState: OrderState) {
  return ["published", "started", "finished", "failed"].includes(orderState)
}

export function hasFinished(orderState: OrderState | OrderPointState) {
  const finishedStates = ["finished", "failed", "canceled", "expired"]
  return finishedStates.includes(orderState)
}

export function hasFailed(orderState: OrderState | OrderPointState) {
  const failedStates = ["failed", "canceled", "expired"]
  return failedStates.includes(orderState)
}

export function getCurrentPoint(order: Order): OrderPoint {
  const isPickupOnly = order.pickup && !order.delivery
  const isDeliveryOnly = order.delivery && !order.pickup

  if (isPickupOnly) return order.pickup!
  if (isDeliveryOnly) return order.delivery!

  return order.pickup && hasFinished(order.pickup.state)
    ? order.delivery!
    : order.pickup!
}

export function getPointTime(orderPoint: OrderPoint) {
  if (orderPoint.state === "finished" && orderPoint.arrived_at) {
    return orderPoint.arrived_at // arrived_at sic!
  }

  if (
    orderPoint.state === "in_progress" &&
    isPickup(orderPoint) &&
    orderPoint.estimated_arrived_at
  ) {
    return orderPoint.estimated_arrived_at
  }

  return orderPoint.estimated_time || orderPoint.created_at
}

export function getOrderTime(order: Order) {
  if (order.state === "pending" && order.pickup_at) return order.pickup_at
  return getPointTime(getCurrentPoint(order))
}

export function getOrderTimeWithLate(order: Order) {
  const currentPoint = getCurrentPoint(order)
  return dayjs(getOrderTime(order))
    .add(currentPoint.late || 0, "minutes")
    .toISOString()
}

export function sortByPriority(a: Order, b: Order) {
  const difference = getSortPriority(a) - getSortPriority(b)

  if (difference === 0) {
    if (a.state === "finished" && b.state === "finished") {
      // "Finished" later first
      return getOrderTimeWithLate(a) > getOrderTimeWithLate(b) ? -1 : 1
    }

    // Time sooner first
    return getOrderTimeWithLate(a) < getOrderTimeWithLate(b) ? -1 : 1
  }

  return difference
}

function getSortPriority(order: Order) {
  // This function is awkward because it mirrors OrderProgress@getLabel
  if (order.pickup) {
    switch (order.pickup.state) {
      case "published":
        return 4
      case "started":
        return 2
      case "in_progress":
        return 3
    }
  }

  if (order.delivery) {
    switch (order.delivery.state) {
      case "published":
        return 9
      case "started":
        return 7
      case "in_progress":
        return 8
    }
  }

  switch (order.state) {
    case "pending": {
      return isAfterPerformAt(order) ? 1 : 6
    }
    case "assigned":
      return 5
    case "finished":
      return 10
    case "failed":
      return 12
    case "canceled":
      return 11
    case "expired":
      return 13
  }

  return 6 // Just put it somewhere in the middle
}

export const isFromDate = (date: Dayjs) => (order: Order) =>
  dayjs(
    order.pickup_at || order.delivery_at || order.perform_at || order.created_at
  ).isSame(date, "day")

export function getDelayColor(delay: number) {
  return delay >= 10 ? "alert" : delay > 0 ? "warning" : "success"
}

export function isAfterPerformAt(order: Order) {
  return Boolean(order.perform_at && dayjs().isAfter(dayjs(order.perform_at)))
}

export function isReceivable(order: Order) {
  return order.provider !== "deligoo"
}

export function isToReceive(order: Order) {
  return (
    isReceivable(order) &&
    (order.state === "draft" || order.state === "waiting")
  )
}

export function wasUnsuccessfullyReceived(order: Order) {
  return (
    isReceivable(order) &&
    (order.state === "canceled" ||
      order.state === "failed" ||
      order.state === "expired")
  )
}

export function canBeConfirmed(order: Order) {
  return (
    isReceivable(order) &&
    order.client_allowed_receive_actions.includes("accept")
  )
}

export function canBeConfirmedWithOwnDelivery(order: Order) {
  return (
    isReceivable(order) &&
    order.client_allowed_receive_actions.includes("own_delivery")
  )
}

export function canContinueReceive(order: Order) {
  return (
    isReceivable(order) &&
    order.client_allowed_receive_actions.includes("continue")
  )
}

export function canBeRejected(order: Order) {
  return (
    isReceivable(order) &&
    order.client_allowed_receive_actions.includes("reject")
  )
}

export function canBeCanceled(order: Order) {
  if (hasFinished(order.state)) return false
  if (order.pickup && hasFinished(order.pickup.state)) return false

  return true
}

export function canBeReentered(order: Order) {
  if (
    order.state === "expired" &&
    order.provider === "shop" &&
    order.payment_form === "paid"
  ) {
    return false
  }

  return canBeCanceled(order) || hasFailed(order.state)
}

export function canBeRefunded(order: Order) {
  return (
    order.online_payment &&
    hasFinished(order.state) &&
    order.payment_status === "completed"
  )
}

export function canOrderBeConfirmedReady(order: Order) {
  const isPickupUnderway =
    order.pickup && ["started", "in_progress"].includes(order.pickup.state)

  return !order.order_is_ready && !hasFinished(order.state) && isPickupUnderway
}

export function canHavePaymentChanged(order: Order) {
  if (!order.pickup || hasFinished(order.pickup.state)) return false
  if (isReceivable(order) && order.payment_form === "paid") return false
  return true
}

export function canBeTracked(order: Order) {
  const currentPoint = getCurrentPoint(order)
  if (!isPickup(currentPoint)) return false

  const trackedStates: OrderPointState[] = ["started", "in_progress"]
  return Boolean(order.pickup) && trackedStates.includes(order.pickup!.state)
}

export async function downloadOrderLabel(orderId: Order["id"]) {
  const {
    json: { data, filename },
  } = await fetchDownloadOrderLabel(orderId)
  if (!data || !filename) return

  downloadBase64File({ data, filename })
}

export function isOrderFromFuture(order?: Order | null) {
  const datetime = order?.pickup_at || order?.delivery_at

  return datetime ? dayjs(datetime).isAfter(dayjs(), "day") : false
}

export function isDeliveryMethodPickup(order: Order) {
  return ["pickup_now", "pickup_later"].includes(order.delivery_method)
}
