import { Button } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

import { useAppDispatch } from "@/App"
import { requestConfirmWithOwnDelivery } from "@/store/orders"

import cls from "../ReceiveOrderModal.module.scss"
import { ActionButtonProps } from "."

const ConfirmWithOwnDeliveryButton = ({
  orderId,
  loading,
  isParentMounted,
  handleModalClose,
  setIsActionInProgress,
}: ActionButtonProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation("orders")

  function handleConfirmWithOwnDelivery() {
    if (!orderId) return
    isParentMounted() && setIsActionInProgress(true)

    dispatch(requestConfirmWithOwnDelivery(orderId))
      .then(unwrapResult)
      .then(() => {
        handleModalClose()
      })
      .finally(() => {
        isParentMounted() && setIsActionInProgress(false)
      })
  }

  return (
    <Button
      className={clsx(cls.button, cls.ownDeliveryBtn)}
      color="warning"
      size="extra-large"
      fullWidth
      iconProps={{ name: "own-delivery", size: "small" }}
      iconSide="right"
      onClick={handleConfirmWithOwnDelivery}
      loading={loading}
    >
      {t("orders_to_receive.actions.own_delivery")}
    </Button>
  )
}

export { ConfirmWithOwnDeliveryButton }
