import { toast } from "react-toastify"

import i18n from "@/i18n"
import { Order } from "@/types"
import { fetchPrintOrderDetails, sleep } from "@/utils"

declare global {
  // Extending Window object is possible only with an interface
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    KBBluetooth?: {
      isConnecting: () => boolean
      isConnected: () => boolean
      connect: (macAddress?: string) => void
      disconnect: () => void
      sendBytes: (bytes: string) => boolean
    }
  }
}

function printData(data: string | undefined) {
  try {
    // Check if running in kiosk and with bluetooth printer support
    if (!window.KBBluetooth) {
      throw new Error("Bluetooth printer interface not available")
    }

    if (!data) throw new Error("Print data not available")

    const printSuccess = window.KBBluetooth.sendBytes(data)

    if (!printSuccess) {
      console.warn("Printer not available")
      toast.error(i18n.t("print:Printer not available"))
    }
  } catch (error) {
    console.warn(error)
  }
}

export async function printOrderData(orderPrintData: string | undefined) {
  let retryCount = 0

  async function attemptPrint(data: string | undefined) {
    if (window.KBBluetooth?.isConnected()) {
      await sleep(2000)

      printData(data)

      window.KBBluetooth.disconnect()
    } else {
      if (retryCount > 5) {
        console.warn("Could not connect to printer")
        toast.error(i18n.t("print:Could not connect to printer"))
        return
      }

      retryCount++

      await sleep(3000)
      await attemptPrint(data)
    }
  }

  try {
    // Check if running in kiosk and with bluetooth printer support
    if (!window.KBBluetooth) {
      throw new Error("Bluetooth printer interface not available")
    }

    window.KBBluetooth.connect()

    // Wait 3 seconds to make sure order details are ready and printer is connected
    await sleep(3000)

    await attemptPrint(orderPrintData)
  } catch (error) {
    console.warn(error)
  }
}

export async function printOrderDetails(orderId: Order["id"]) {
  try {
    const {
      json: { data },
    } = await fetchPrintOrderDetails(orderId)

    await printOrderData(data?.print_data)
  } catch (error) {
    console.warn(error)
  }
}
