import { ThemeColor } from "@deligoo/ui"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Card } from "@/components/Card"
import { DeliveryDetailsModal } from "@/components/DeliveryDetailsModal"
import { Gauge } from "@/components/Gauge"
import {
  selectCurrentRestaurantKey,
  selectCurrentRestaurantStatus,
} from "@/store/restaurant"

import cls from "../Dashboard.module.scss"

function getFraction(value: number) {
  const fraction = value / 80
  return fraction < 1 ? fraction : 1
}

function getColor(value: number): ThemeColor {
  if (value < 0.625) return "success"
  if (value <= 0.99) return "warning"
  return "alert"
}

export function getPickupDurationMeta(pickupDuration = 0) {
  const fraction = getFraction(pickupDuration)

  return {
    fraction,
    color: getColor(fraction),
  }
}

const PickupTime = () => {
  const restaurantStatus = useSelector(selectCurrentRestaurantStatus)

  const pickupDuration = useSelector(
    selectCurrentRestaurantKey("pickup_duration")
  )

  const [showDeliveryDetailsModal, setShowDeliveryDetailsModal] =
    useState(false)

  const { color, fraction } = getPickupDurationMeta(pickupDuration)

  const { t } = useTranslation("dashboard")

  const helpModal = {
    title: "Estimated pickup time",
    content: <>{t("estimated_pickup_time_desc")}</>,
  }

  return (
    <>
      <button
        onClick={() => setShowDeliveryDetailsModal(true)}
        className={cls.tall}
        type="button"
      >
        <Card
          link
          sections
          help={t("Learn more")}
          helpModal={helpModal}
          loading={restaurantStatus === "loading"}
        >
          <Card.Section>
            <Gauge
              color={color}
              fraction={fraction}
              iconName="delivery-time"
              className={cls.chart}
            />
          </Card.Section>
          <Card.Section>
            <Card.Title size="large" color={color}>
              {pickupDuration} min.
            </Card.Title>
            {t("Estimated pickup time")}
          </Card.Section>
        </Card>
      </button>

      {showDeliveryDetailsModal && (
        <DeliveryDetailsModal
          handleClose={() => setShowDeliveryDetailsModal(false)}
        />
      )}
    </>
  )
}

export { PickupTime }
