import { Marketplace, Provider } from "@deligoo/shared"
import clsx from "clsx"
import { HTMLAttributes } from "react"

import { humanizedMarketplaceName, humanizedProviderName } from "./humanized"
import {
  deli1,
  deligoo,
  fastcat,
  furgonetka,
  glodny,
  glovo,
  jedzcochcesz,
  makro,
  menupl,
  pyszne,
  restaumatic,
  ubereats,
  upmenu,
  zioapp,
} from "./logotypes"
import cls from "./ProviderLogo.module.scss"

export { humanizedMarketplaceName, humanizedProviderName }

const PROVIDERS_ASSETS = {
  deli1,
  deligoo,
  shop: deligoo,
  api: deligoo,
  marketplace: null,
  jedzcochcesz,
  glodny,
  pyszne,
  ubereats,
  glovo,
  makro,
  upmenu,
  menupl,
  fastcat,
  zioapp,
  furgonetka,
  restaumatic,
} as const

type ProviderLogoProps = {
  provider: Provider
  marketplace: Marketplace | null
  light?: boolean
  small?: boolean
  className?: string
}

export const ProviderLogo = ({ provider, marketplace, light, small, className }: ProviderLogoProps) => {
  const providerAsset = getProviderAsset()

  function getProviderAsset() {
    const providerAsset = PROVIDERS_ASSETS[marketplace || provider]

    if (!providerAsset) return null

    if (light && small && providerAsset.smallLight) {
      return providerAsset.smallLight
    }

    if (light && providerAsset.light) {
      return providerAsset.light
    }

    if (small && providerAsset.small) {
      return providerAsset.small
    }

    return providerAsset.default
  }

  const label = marketplace ? humanizedMarketplaceName[marketplace] : humanizedProviderName[provider]

  if (!providerAsset) {
    return <div className={clsx(cls.provider, className)}>{label}</div>
  }

  return (
    <img src={providerAsset} className={clsx(cls.provider, small && cls.small, className)} title={label} alt={label} />
  )
}

const List = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={clsx(cls.providersList, className)} {...props}>
      {children}
    </div>
  )
}

ProviderLogo.List = List
