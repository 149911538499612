const restaurants = {
  lock_reason: {
    clients_orders_limit_reached: "Wykorzystano dzienny limit dostaw",
    clients_unsettled_payments:
      "Z powodu nierozliczonych płatności lokalizacja została zablokowana, aby aktywować lokalizację rozlicz płatności.",
    clients_locked_by_admin:
      "Lokalizacja została zablokowana przez administratora, aby aktywować lokalizację skontaktuj się z supportem DeliGoo.",
    clients_inactive:
      "Z powodu braku aktywności przez dłuższy czas lokalizacja została zablokowana, aby aktywować lokalizację skontaktuj się z supportem DeliGoo.",
    clients_deactivated_by_admin:
      "Lokalizacja została zablokowana przez administratora, aby aktywować lokalizację skontaktuj się z supportem DeliGoo.",
    partners_deactivated_by_admin:
      "Konto zostało zablokowane przez administratora, aby aktywować konto skontaktuj się z supportem DeliGoo.",
  },
}

export default restaurants
