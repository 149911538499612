import { Button, Switch } from "@deligoo/ui"
import clsx from "clsx"
import { MouseEvent, ReactNode } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useAppSelector } from "@/App"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { PackageType, PaymentAndPackage } from "@/types"
import { isTruthyOrZero } from "@/utils"

import cls from "../PaymentAndPackage.module.scss"
import { PACKAGES_ASSETS } from "./packagesAssets"
import { TransportTypes } from "./TransportTypes"
import { WeightInput } from "./WeightInput"

type PackageProps = {
  name: ReactNode
  desc: string
  inputName: PackageType
  disabled?: boolean
}

const Package = ({ name, desc, inputName, disabled }: PackageProps) => {
  const clientAcceptedPackages = useAppSelector(
    selectCurrentRestaurantKey("packages")
  )

  const { register, setValue } = useFormContext()

  const { packages, packages: { [inputName]: count } = { [inputName]: 0 } } =
    useWatch<PaymentAndPackage>({})

  function setCount(value: string | number) {
    setValue(`packages`, { ...packages, [inputName]: value })
  }

  function incrementCount() {
    setCount(Number(count) + 1)
  }

  function decrementCount() {
    if (Number(count) !== 0) setCount(Number(count) - 1)
  }

  if (!clientAcceptedPackages?.includes(inputName)) return null

  return (
    <div
      className={clsx(
        cls.package,
        Number(count) > 0 && cls.outlined,
        disabled && cls.disabled
      )}
      onClick={() => {
        if (disabled) return
        if (!count) setCount(1)
        if (isTruthyOrZero(Number(count))) incrementCount()
      }}
    >
      <div className={cls.name}>{name}</div>
      <div className={cls.desc}>{desc}</div>
      <img
        className={cls.packageImg}
        src={PACKAGES_ASSETS[inputName]}
        alt={desc}
      />

      <div className={clsx(cls.controls, Number(count) > 0 && cls.active)}>
        <Button
          className={cls.control}
          size="small"
          color="primary"
          variation="contrast"
          onClick={(e: MouseEvent) => {
            e.stopPropagation()
            decrementCount()
          }}
          disabled={disabled}
        >
          -
        </Button>
        <div className={cls.value}>{count}</div>
        <Button
          className={cls.control}
          size="small"
          color="primary"
          variation="contrast"
          onClick={(e: MouseEvent) => {
            e.stopPropagation()
            incrementCount()
          }}
          disabled={disabled}
        >
          +
        </Button>
      </div>

      <input
        type="hidden"
        defaultValue={packages?.[inputName] ? Number(packages?.[inputName]) : 0}
        ref={register}
        name={`packages.${inputName}`}
      />
    </div>
  )
}

const DeligooPackages = () => {
  const { register, errors } = useFormContext()

  const { t } = useTranslation("createOrder")

  return (
    <section className={clsx(cls.packageType, cls.default)}>
      <div className={cls.about}>
        <h2
          className={clsx(
            "h400 mb-5",
            errors?.["packages"] && "text-alert",
            cls.header
          )}
        >
          {t("headers.order_kind")}
        </h2>

        <div className="text-large mt-4">{t("descriptions.order_kind")}</div>

        <TransportTypes />

        <Switch
          name="has_drinks"
          ref={register}
          className="mt-8"
          labelClassName="h400"
        >
          {t("headers.drinks")}
        </Switch>
      </div>

      <Package
        name={t("package_types.big-pizza")}
        desc={t("package_types_desc.big-pizza")}
        inputName="big-pizza"
      />

      <Package
        name={t("package_types.standard-dish")}
        desc={t("package_types_desc.standard-dish")}
        inputName="standard-dish"
      />

      <Package
        name={t("package_types.own-packaging")}
        desc={t("package_types_desc.own-packaging")}
        inputName="own-packaging"
      />

      {errors?.packages && (
        <div className={clsx("text-error", cls.error)}>
          {errors.packages.message}
        </div>
      )}
    </section>
  )
}

const HEBE_PACKAGES = [
  "16,5 cm x 13,5 cm x 9 cm",
  "28 cm x 17 cm x 9,5 cm",
  "37 cm x 25 cm x 9,5 cm",
  "37 cm x 25 cm x 19 cm",
  "44 cm x 32 cm x 2,5 cm",
]

const HebePackages = () => {
  const { errors } = useFormContext()

  const { t } = useTranslation("createOrder")

  return (
    <section className={clsx(cls.packageType, cls.hebe)}>
      <div className={cls.about}>
        <h2
          className={clsx(
            "h400",
            errors?.["packages"] && "text-alert",
            cls.header
          )}
        >
          {t("headers.select_package_and_weight")}
        </h2>

        <WeightInput />
      </div>

      {HEBE_PACKAGES.map((name, index) => (
        <Package
          name={name}
          desc={t(`package_types.hebe-box-${index + 1}`)}
          inputName={`hebe-box-${index + 1}` as PackageType}
          key={index}
        />
      ))}

      {errors?.packages && (
        <div className={clsx("text-error", cls.error)}>
          {errors.packages.message}
        </div>
      )}
    </section>
  )
}

const InpostPackages = () => {
  const { errors } = useFormContext()

  const { t } = useTranslation("createOrder")

  return (
    <section className={clsx(cls.packageType, cls.ecommerce)}>
      <div className={cls.about}>
        <h2
          className={clsx(
            "h400",
            errors?.["packages"] && "text-alert",
            cls.header
          )}
        >
          {t("headers.select_package_type")}
        </h2>
      </div>

      <Package
        name={
          <>
            {t("package_types_desc.max")} <span>8 cm x 38 cm x 64 cm</span>
          </>
        }
        desc={t("package_types.inpost-size-a")}
        inputName="inpost-size-a"
      />
      <Package
        name={
          <>
            {t("package_types_desc.max")} <span>19 cm x 38 cm x 64 cm</span>
          </>
        }
        desc={t("package_types.inpost-size-b")}
        inputName="inpost-size-b"
      />
      <Package
        name={
          <>
            {t("package_types_desc.max")} <span>41 cm x 38 cm x 64 cm</span>
          </>
        }
        desc={t("package_types.inpost-size-c")}
        inputName="inpost-size-c"
      />
      {errors?.packages && (
        <div className={clsx("text-error", cls.error)}>
          {errors.packages.message}
        </div>
      )}
    </section>
  )
}

const EcommercePackages = () => {
  const { errors } = useFormContext()

  const { t } = useTranslation("createOrder")

  return (
    <section className={clsx(cls.packageType, cls.ecommerce)}>
      <div className={cls.about}>
        <h2
          className={clsx(
            "h400",
            errors?.["packages"] && "text-alert",
            cls.header
          )}
        >
          {t("headers.select_package_type")}
        </h2>
      </div>

      <Package
        name={
          <>
            {t("package_types_desc.max")} <span>8 cm x 38 cm x 64 cm</span>
          </>
        }
        desc={t("package_types.ecommerce-size-a")}
        inputName="ecommerce-size-a"
      />
      <Package
        name={
          <>
            {t("package_types_desc.max")} <span>19 cm x 38 cm x 64 cm</span>
          </>
        }
        desc={t("package_types.ecommerce-size-b")}
        inputName="ecommerce-size-b"
      />
      <Package
        name={
          <>
            {t("package_types_desc.max")} <span>41 cm x 38 cm x 64 cm</span>
          </>
        }
        desc={t("package_types.ecommerce-size-c")}
        inputName="ecommerce-size-c"
      />

      {errors?.packages && (
        <div className={clsx("text-error", cls.error)}>
          {errors.packages.message}
        </div>
      )}
    </section>
  )
}

export { DeligooPackages, EcommercePackages, HebePackages, InpostPackages }
