import "./Toast.scss"

import {
  ToastContainer as Container,
  ToastContainerProps,
} from "react-toastify"

const ToastContainer = (props: ToastContainerProps) => <Container {...props} />

export { ToastContainer }
