import { Backdrop, Icon } from "@deligoo/ui"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { CloseButton } from "@/components/CloseButton"
import i18n from "@/i18n"
import { selectCurrentOrderFormStep, setOrderFormStep } from "@/store/orderForm"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { IconName, OrderFormStepKey } from "@/types"

import { ContactDetails } from "./ContactDetails"
import { Notes } from "./Notes"
import cls from "./OrderForm.module.scss"
import { PaymentAndPackage } from "./PaymentAndPackage"
import { ProcessingTime } from "./ProcessingTime"
import { Summary } from "./Summary"

type Step = {
  name: string
  icon: IconName
  key: OrderFormStepKey
  Content: (props: unknown) => JSX.Element
}

const OrderForm = () => {
  const currentStepKey = useSelector(selectCurrentOrderFormStep)

  const interfaceType = useSelector(
    selectCurrentRestaurantKey("interface_type")
  )

  const hasPayments =
    interfaceType === "deligoo" || interfaceType === "soliddelivery"

  const steps: Step[] = [
    {
      name: i18n.t("createOrder:steps.contact_detail"),
      icon: "business-client",
      key: "contact_detail",
      Content: ContactDetails,
    },
    {
      name: hasPayments
        ? i18n.t("createOrder:steps.payment_form")
        : i18n.t("createOrder:steps.package_type"),
      icon: hasPayments ? "credit-card" : "box",
      key: "payment_and_packages",
      Content: PaymentAndPackage,
    },
    {
      name: i18n.t("createOrder:steps.processing_time"),
      icon: "clock",
      key: "processing_time",
      Content: ProcessingTime,
    },
    {
      name: i18n.t("createOrder:steps.notes"),
      icon: "comments",
      key: "notes",
      Content: Notes,
    },
    {
      name: i18n.t("createOrder:steps.summary"),
      icon: "check-circle",
      key: "summary",
      Content: Summary,
    },
  ]

  const currentStepIndex = steps.findIndex(
    (step) => currentStepKey === step.key
  )

  const currentStep = steps.find((step) => step.key === currentStepKey)

  const dispatch = useDispatch()

  const { t } = useTranslation("createOrder")

  return (
    <Backdrop>
      <div className={cls.createOrder}>
        <header className={cls.header}>
          <h1 className={cls.title}>{t("headers.create_order")}</h1>

          <ol className={cls.steps}>
            {steps.map((step) => {
              const isCurrent = currentStepKey === step.key

              const isCompleted =
                steps.findIndex((s) => s.name === step.name) < currentStepIndex

              const isSummary = currentStepKey === "summary"

              return (
                <li
                  key={step.key}
                  className={clsx(
                    cls.step,
                    isCompleted && cls.completed,
                    !isCompleted && !isCurrent && cls.pending
                  )}
                >
                  <button
                    onClick={() => dispatch(setOrderFormStep(step.key))}
                    className={cls.link}
                    disabled={!isCompleted || isSummary}
                    type="button"
                  >
                    <Icon name={step.icon} className={cls.icon} />
                    <span className={cls.label}>{step.name}</span>
                  </button>
                </li>
              )
            })}
          </ol>

          <CloseButton
            onClick={() => dispatch(setOrderFormStep(null))}
            className="ml-auto"
          />
        </header>

        <div className={cls.content}>
          {currentStep && <currentStep.Content />}
        </div>
      </div>
    </Backdrop>
  )
}

export { OrderForm }
