import clsx from "clsx"
import {
  ChangeEvent,
  ComponentProps,
  Dispatch,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  SetStateAction,
  useState,
} from "react"

import { Field } from "../Field"
import cls from "./AutocompleteField.module.scss"
import { ReactComponent as LoadingPlaceholder } from "./placeholder.svg"

export type Autocomplete = {
  values: Array<unknown>
  status: "loading" | "fetched" | "updating" | "error" | null
  onValueSelect: (value: unknown) => void
  valueToString: (value: unknown) => string
  valueToSubtitle?: (value: unknown) => string
  translations?: {
    empty?: string
    error?: string
  }
}

type AutocompleteFieldProps = ComponentProps<typeof Field> & {
  autocomplete: Autocomplete
  currentValue: string
  setCurrentValue: Dispatch<SetStateAction<string>>
}

const AutocompleteField = forwardRef<HTMLInputElement, AutocompleteFieldProps>(
  ({ currentValue, setCurrentValue, autocomplete, inputProps, className, ...props }, ref) => {
    const [areSuggestionsShown, showSuggestions] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
      setSelectedIndex(0)
      setCurrentValue(e.currentTarget.value)
      inputProps?.onChange?.(e)
    }

    function handleInputFocus(e: FocusEvent<HTMLInputElement>) {
      showSuggestions(true)
      inputProps?.onFocus?.(e)
    }

    function handleInputBlur(e: FocusEvent<HTMLInputElement>) {
      const value = autocomplete.values[selectedIndex]

      if (value) autocomplete.onValueSelect(value)

      showSuggestions(false)

      inputProps?.onBlur?.(e)
    }

    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
      const suggestionsCount = autocomplete.values.length

      const nextIndex = selectedIndex + 1 < suggestionsCount ? selectedIndex + 1 : selectedIndex
      const prevIndex = selectedIndex > 0 ? selectedIndex - 1 : selectedIndex

      switch (e.key) {
        case "ArrowDown":
          e.preventDefault()
          setSelectedIndex(nextIndex)
          return
        case "ArrowUp":
          e.preventDefault()
          setSelectedIndex(prevIndex)
          return
        case "Enter":
          e.preventDefault()
          e.currentTarget.blur()
      }

      inputProps?.onKeyDown?.(e)
    }

    return (
      <div className={clsx(cls.autocomplete, className)}>
        <Field
          ref={ref}
          inputProps={{
            ...inputProps,
            value: currentValue,
            onFocus: handleInputFocus,
            onBlur: handleInputBlur,
            onKeyDown: handleKeyDown,
            onChange: handleInputChange,
          }}
          {...props}
        />

        {areSuggestionsShown && autocomplete.status && (
          <div className={cls.suggestions}>
            <ul className={cls.list}>
              {autocomplete.values?.map((value, index) => (
                <li key={index}>
                  <button
                    type="button"
                    onClick={() => autocomplete.onValueSelect(value)}
                    onMouseEnter={() => setSelectedIndex(index)}
                    className={clsx(cls.suggestion, selectedIndex === index && cls.selected)}
                  >
                    <HighlightedSuggestionName
                      inputValue={currentValue}
                      suggestion={autocomplete.valueToString(value)}
                    />

                    {autocomplete.valueToSubtitle?.(value)}
                  </button>
                </li>
              ))}
            </ul>

            {["loading", "updating"].includes(autocomplete.status) && <LoadingPlaceholder className={cls.loading} />}

            {autocomplete.status === "fetched" && !autocomplete.values.length && (
              <div className="text-small text-muted">{autocomplete.translations?.empty || "Brak wyników"}</div>
            )}

            {autocomplete.status === "error" && (
              <div className="text-small text-alert">
                {autocomplete.translations?.error || "Nie udało się pobrać listy"}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
)

function escapeRegex(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

function highlightSuggestion(inputValue: string, suggestion: string) {
  const re = new RegExp(escapeRegex(inputValue), "ig")
  const match = suggestion.match(re)
  const highlight = match?.length ? match[0] : ""

  return {
    __html: suggestion.replace(highlight, `<span>${highlight}</span>`),
  }
}

const HighlightedSuggestionName = ({ inputValue, suggestion }: { inputValue: string; suggestion: string }) => {
  return <div className={cls.name} dangerouslySetInnerHTML={highlightSuggestion(inputValue, suggestion)} />
}

export { AutocompleteField }
