import clsx from "clsx"
import dayjs from "dayjs"
import { useMediaQuery } from "react-responsive"

import { Message } from "@/types"

import cls from "../Messages.module.scss"

type MessageItemProps = {
  message: Message
  active: boolean
  selectMessage: (message: Message) => void
}

const MessageItem = ({ message, active, selectMessage }: MessageItemProps) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" })

  return (
    <li
      key={message.id}
      className={clsx(
        cls.messageItem,
        !message.read_at && cls.unread,
        active && cls.active,
        active && isMobile && cls.autoHeight
      )}
      onClick={() => selectMessage(message)}
    >
      <span className={clsx(cls.datetime, "text-muted")}>
        {dayjs(message.created_at).format("DD.MM.YYYY, HH:mm")}
      </span>
      <span className={cls.messageTitle}>{message.title}</span>
      {active && isMobile && (
        <span className={cls.expandedMessage}>{message.content}</span>
      )}
    </li>
  )
}

export { MessageItem }
