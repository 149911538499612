import { Icon, Spinner } from "@deligoo/ui"
import clsx from "clsx"
import dayjs from "dayjs"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { Price } from "@/components/Price"
import { SummaryList } from "@/components/SummaryList"
import { Table } from "@/components/Table"
import {
  getOrders,
  selectFilteredOrdersIds,
  selectFilteredPaidAmount,
  selectOrderById,
  selectOrdersStatus,
} from "@/store/orders"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { Order } from "@/types"
import { canBeRefunded, hasFailed } from "@/utils/order"

import cls from "../Orders.module.scss"
import { OrderDetailsModal, useDetailsModal } from "./OrderDetailsModal"

type OrdersPaymentsProps = {
  date: string
  filters: Array<(order: Order) => boolean>
}

const PAYMENTS_LIST_HIDDEN_STATES = [
  "waiting",
  "draft",
  "unpaid",
  "expired",
  "canceled",
]
const filterOrdersHiddenForPaymentsTab = (order: Order) =>
  !PAYMENTS_LIST_HIDDEN_STATES.includes(order.state)

const filterPaid = (order: Order) =>
  order.payment_status === "completed" && order.payment_form === "paid"
const filterPaidWithCash = (order: Order) =>
  order.payment_status === "completed" && order.payment_form === "cash"
const filterPaidWithCard = (order: Order) =>
  order.payment_status === "completed" && order.payment_form === "card"

const OrdersPayments = ({ date, filters }: OrdersPaymentsProps) => {
  const paymentsFilters = useMemo(
    () => [...filters, filterOrdersHiddenForPaymentsTab],
    [filters]
  )

  const ordersStatus = useSelector(selectOrdersStatus)

  const paidAmount = useSelector(
    selectFilteredPaidAmount(...paymentsFilters, filterPaid)
  )
  const paidByCashAmount = useSelector(
    selectFilteredPaidAmount(...paymentsFilters, filterPaidWithCash)
  )
  const paidByCardAmount = useSelector(
    selectFilteredPaidAmount(...paymentsFilters, filterPaidWithCard)
  )

  const currentRestaurantId = useSelector(selectCurrentRestaurantKey("id"))
  const [currentOrderDetailsId, setCurrentOrderDetailsId] = useDetailsModal()
  const paymentsIds = useSelector(selectFilteredOrdersIds(...paymentsFilters))

  const dispatch = useDispatch()

  const { t } = useTranslation("orders")

  useEffect(() => {
    if (currentRestaurantId) dispatch(getOrders(dayjs(date)))
  }, [dispatch, currentRestaurantId, date])

  return (
    <>
      <header className={cls.header}>
        <h1 className="h300">{t("payments_summary.today_summary")}</h1>

        <SummaryList>
          <SummaryList.Item
            title={t("payments_summary.paid_amount")}
            desc={
              ordersStatus === "fetched" ? (
                <Price subunit>{paidAmount}</Price>
              ) : (
                <Spinner size="small" />
              )
            }
            color="warning"
            iconName="check-circle"
          />
          <SummaryList.Item
            title={t("payments_summary.paid_by_cash_amount")}
            desc={
              ordersStatus === "fetched" ? (
                <Price subunit>{paidByCashAmount}</Price>
              ) : (
                <Spinner size="small" />
              )
            }
            color="success"
            iconName="cash"
          />
          <SummaryList.Item
            title={t("payments_summary.paid_by_card_amount")}
            desc={
              ordersStatus === "fetched" ? (
                <Price subunit>{paidByCardAmount}</Price>
              ) : (
                <Spinner size="small" />
              )
            }
            color="info"
            iconName="credit-card"
          />
          <SummaryList.Item
            title={t("payments_summary.total")}
            desc={
              ordersStatus === "fetched" ? (
                <Price subunit>
                  {paidAmount + paidByCashAmount + paidByCardAmount}
                </Price>
              ) : (
                <Spinner size="small" />
              )
            }
            color="primary"
            iconName="money"
          />
        </SummaryList>
      </header>

      <Table columns={"100px 180px 1fr 190px 150px 200px 120px"}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t("headers.order_uid")}</Table.Th>
            <Table.Th>{t("headers.time")}</Table.Th>
            <Table.Th>{t("headers.address")}</Table.Th>
            <Table.Th>{t("headers.payment_form")}</Table.Th>
            <Table.Th className="align-right">{t("headers.amount")}</Table.Th>
            <Table.Th>{t("headers.payment_status")}</Table.Th>
            <Table.Th>{t("headers.status")}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody loading={ordersStatus === "loading"}>
          {(ordersStatus === "fetched" || ordersStatus === "updating") &&
            paymentsIds &&
            paymentsIds.map((paymentId) => (
              <PaymentRow
                key={paymentId}
                paymentId={paymentId}
                setCurrentOrderDetailsId={setCurrentOrderDetailsId}
              />
            ))}
        </Table.Tbody>
      </Table>

      {(ordersStatus === "fetched" || ordersStatus === "updating") &&
        paymentsIds &&
        currentOrderDetailsId && (
          <OrderDetailsModal
            orderId={currentOrderDetailsId}
            handleClose={() => setCurrentOrderDetailsId(null)}
          />
        )}
    </>
  )
}

type PaymentRowProps = {
  paymentId: Order["id"]
  setCurrentOrderDetailsId: (orderId: Order["id"]) => void
}

const PaymentRow = ({
  paymentId,
  setCurrentOrderDetailsId,
}: PaymentRowProps) => {
  const order = useSelector(selectOrderById(paymentId))

  const { t } = useTranslation("payments")

  if (!order) return null

  const isRefundRequired = hasFailed(order.state) && canBeRefunded(order)

  const isPaymentFinished =
    order.payment_status === "completed" || order.payment_status === "failed"

  return (
    <Table.Tr
      key={order.uid}
      onClick={() => setCurrentOrderDetailsId(order.id)}
      indicatorColor={isRefundRequired ? "alert" : undefined}
    >
      <Table.Td indicatorLabel>
        #{order.uid}
        {order.ordinal_number ? (
          <span className="ml-1">({order.ordinal_number})</span>
        ) : (
          <Spinner className="ml-1" size="small" inline />
        )}
      </Table.Td>
      <Table.Td>
        {order.pickup_at && dayjs(order.pickup_at).format("HH:mm")}
      </Table.Td>
      <Table.Td>{order.delivery?.address || order.pickup?.address}</Table.Td>
      <Table.Td className={cls.driver}>
        {t(`payment_form.${order.payment_form}`)}
      </Table.Td>
      <Table.Td className="align-right">
        <Price subunit>{order.full_price_subunit}</Price>
      </Table.Td>
      <Table.Td
        className={clsx(
          "h300",
          order.payment_status === "failed" && "text-alert",
          order.payment_status === "completed" && "text-success"
        )}
      >
        {order.payment_status && t(`payment_status.${order.payment_status}`)}
      </Table.Td>
      <Table.Td>
        {isRefundRequired ? (
          <div className={cls.paymentRefund}>
            <Icon
              name="block"
              color="alert"
              className={cls.paymentRefundIcon}
            />
            <span className="text-tag">
              {t("headers.payment_refund_required")}
            </span>
          </div>
        ) : isPaymentFinished ? (
          <Icon name="check-circle" color="success" />
        ) : (
          <Spinner size="large" />
        )}
      </Table.Td>
    </Table.Tr>
  )
}

export { OrdersPayments }
