import clsx from "clsx"
import { useTranslation } from "react-i18next"

import { Price } from "@/components/Price"
import { Order } from "@/types"

import cls from "./Basket.module.scss"

type BasketProps = {
  details: Order["details"]
}

const Basket = ({ details }: BasketProps) => {
  const { t } = useTranslation()

  if (!details.dishes && !details.notes) return null

  return (
    <div className={cls.basket}>
      <div className={cls.dishes}>
        {details?.dishes?.map((dish) => (
          <div key={dish.title} className={cls.dish}>
            <span className={cls.dishInfo}>
              <span>
                <span
                  className={clsx(cls.quantity, `quantity-${dish.quantity}`)}
                >
                  {dish.quantity}x
                </span>
                <span>{dish.title}</span>
              </span>
              <Price subunit className="text-muted">
                {dish.price_subunit}
              </Price>
            </span>
            {Boolean(dish.comment) && (
              <div>
                <span className={clsx(cls.quantity, "text-muted")} />
                <span>{dish.comment}</span>
              </div>
            )}
            {Boolean(dish.packaging_cost_amount_subunit) && (
              <span className={clsx(cls.packagingCost, "text-muted")}>
                <span>{t("dish.packaging")}</span>
                <Price subunit>{dish.packaging_cost_amount_subunit}</Price>
              </span>
            )}
            {dish.toppings && (
              <div className={clsx(cls.toppings, "text-muted ml-5")}>
                {dish.toppings.map((topping) => (
                  <div
                    key={topping.title}
                    className={clsx(
                      cls.topping,
                      topping.quantity === 0 && cls.strike
                    )}
                  >
                    <span>
                      <span className={clsx(cls.quantity, `quantity-${topping.quantity}`)}>{topping.quantity}x</span>
                      <span>{topping.title}</span>
                    </span>
                    <Price subunit>
                      {Number(topping.price_subunit) * Number(topping.quantity)}
                    </Price>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      {details.notes && (
        <div className={clsx(cls.notes, "text-muted")}>{details.notes}</div>
      )}
    </div>
  )
}

export { Basket }
