import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { DataStatus, Message, RootState } from "@/types"
import {
  fetchMessages,
  fetchReadAllUnreadMessages,
  fetchReadMessage,
  handleStandardError,
  updateCollectionItemById,
} from "@/utils"

import { clearDataOnLogout } from "./auth"
import { setCurrentRestaurant } from "./restaurant"

type MessagesState = {
  data: Message[] | null
  status: DataStatus | null
}

const initialState: MessagesState = {
  data: null,
  status: null,
}

export const getMessages = createAsyncThunk(
  "messages/getMessages",
  async () => {
    const { response, json } = await fetchMessages()

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
    }
  }
)

export const requestReadAllUnreadMessages = createAsyncThunk(
  "messages/readAllUnread",
  async () => {
    const { response, json } = await fetchReadAllUnreadMessages()

    if (!response.ok) {
      handleStandardError(response, json)
    }

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
    }
  }
)

export const requestReadMessage = createAsyncThunk(
  "messages/readMessage",
  async (messageId: Message["id"]) => {
    const { response, json } = await fetchReadMessage(messageId)

    if (!response.ok) {
      handleStandardError(response, json)
    }

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
    }
  }
)

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    updateMessage(state, action: PayloadAction<Message>) {
      const updatedMessage = action.payload

      if (state.data?.some((message) => message.id === updatedMessage.id)) {
        updateCollectionItemById(state.data, updatedMessage)
      } else {
        state.data?.unshift(updatedMessage)
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMessages.pending, (state) => {
      if (state.data) {
        state.status = "updating"
      } else {
        state.status = "loading"
      }
    })

    builder.addCase(getMessages.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        state.data = payload.data
        state.status = "fetched"
      } else {
        state.status = "error"
      }
    })

    builder.addCase(
      requestReadAllUnreadMessages.fulfilled,
      (state, { payload }) => {
        if (payload.ok && payload.data) {
          payload.data.forEach((updatedMessage) => {
            if (!state.data) return
            updateCollectionItemById(state.data, updatedMessage)
          })
        }
      }
    )

    builder.addCase(requestReadMessage.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        if (!state.data) return
        const updatedMessage = payload.data
        updateCollectionItemById(state.data, updatedMessage)
      }
    })

    builder.addCase(setCurrentRestaurant.pending, (state) => {
      state.data = null
      state.status = null
    })

    builder.addCase(clearDataOnLogout, () => initialState)
  },
})

export const selectMessages = (state: RootState) => state.messages.data

export const selectUnreadMessagesCount = (state: RootState) =>
  state.messages.data?.filter((message) => !message.read_at).length

export const selectIsAnyUnreadImportantMessage = (state: RootState) =>
  state.messages.data?.some(
    (message) => !message.read_at && message.important && message.important > 1
  )

export const { updateMessage } = messagesSlice.actions
export const messagesReducer = messagesSlice.reducer
