const common = {
  pl: "Polish",
  en: "English",

  notifications: {
    header: "Notifications",
    clear: "Clear all",
  },

  headers: {
    orders_panel: "Order panel",
    check_distance: "Calculate distance",
    distance: "Distance",
    delivery_cost: "Delivery cost",
    delivery_duration: "Estimated delivery time",
  },

  buttons: {
    create_order: "Add order",
    dashboard: "Dashboard",
    save: "Save",
    change_language: "Change language",
    close: "Close",
    ok: "Ok",
    sync: "Synchronize",
    go_to_payments: "Go to payments",
    confirm: "Confirm",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
  },

  alerts: {
    holiday_mode_enabled: "Holiday mode enabled",
    maintenance_mode_enabled: "Maintenance mode enabled",
    creating_orders_disabled: "Adding orders is temporarily blocked",
    empty: "No data to display",
    data_sync_required: "Data synchronization required",
    connection_established:
      "Connection to the server has been re-established. Please sync application data to continue.",
    reconnected: "Connection to the server has been recovered",
    connection_lost: "Connection to the server was lost",
  },

  errors: {
    is_required: "Required",
    min_length_0: "Enter at least {{count}} character",
    min_length_1: "Enter at least {{count}} characters",
    min_length_2: "Enter at least {{count}} characters",
    select_city: "Select a city from the list",
    select_street: "Select a street from the list",
    db_street_not_found: "The street was not found in the database",
    phone_number_format: "Please enter a valid phone number",
    postal_code_format: "Please enter a valid postal code",
    distance_limit: "Exceeded the distance limit – {{distanceLimit}} km",
    session_expired: "You have been logged out because of an expired session",
    network_error: "Connection error with the server or no Internet access",
  },

  labels: {
    city: "City",
    street: "Street (without house number)",
    street_with_house_number: "Street with house number",
    first_name: "First name",
    last_name: "Last name",
    company_name: "Company name",
    company_name_or_person: "Full name / Company name",
    phone: "Phone number",
    email: "E-mail address",
    humanized_full_address: "Address",
    house_number: "House number",
    flat_number: "Flat number",
    flat: "Flat",
    floor: "Floor",
    staircase: "Staircase",
    access_code: "Access code",
    postal_code: "Postal code",
    language: "Language",
  },

  nav: {
    dashboard: "Dashboard",
    orders: "Orders",
    payments: "Payments",
    orders_receiveing: "Receiving orders",
    statistics: "Statistics",
    settings: "Settings",
  },

  autocomplete: {
    no_results: "No results",
    fetch_error: "The list could not be retrieved",
  },

  dish: {
    packaging: "Packaging",
  },

  today: "Today",
}

export default common
