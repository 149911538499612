import { Button, Dropdown, Icon, Input } from "@deligoo/ui"
import { ChangeEvent, FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, useHistory } from "react-router-dom"

import { Site, SITES } from "@/sites"
import {
  requestClientLogin,
  selectAuthError,
  selectIsAuthenticated,
  selectIsAuthenticating,
} from "@/store/auth"
import { getDefaultSite, isProductionEnvironment } from "@/utils"

import cls from "./SignIn.module.scss"

const SignIn = () => {
  const [currentSite, setCurrentSite] = useState(getDefaultSite())

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const isAuthenticating = useSelector(selectIsAuthenticating)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const error = useSelector(selectAuthError)

  const dispatch = useDispatch()
  const history = useHistory()

  const isSolidDeliver = window.location.hostname === "panel.solid.delivery"

  const { t } = useTranslation("signIn")

  function setSite(site: Site) {
    localStorage.setItem("site", JSON.stringify(site))
    setCurrentSite(site)
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    dispatch(requestClientLogin({ username, password }))
  }

  if (isAuthenticated) return <Redirect to="/" />

  return (
    <>
      <form className={cls.form} onSubmit={handleSubmit}>
        {!isProductionEnvironment() && (
          <span>
            <Dropdown
              trigger={
                <span>
                  <Button variation="outlined" color="primary" type="button">
                    {currentSite?.name}
                  </Button>
                </span>
              }
            >
              {SITES.map((site) => (
                <Dropdown.MenuItem
                  key={site.id}
                  handleClick={() => setSite(site)}
                >
                  {site.name}
                </Dropdown.MenuItem>
              ))}
            </Dropdown>
          </span>
        )}

        <div className={cls.formInputs}>
          {error && <div className="text-alert">{error.text}</div>}

          <Input
            placeholder={t("login")}
            autoCapitalize="none"
            size="extra-large"
            className={cls.input}
            selectOnFocus
            autoFocus
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setUsername(e.target.value)
            }
          />

          <Input
            placeholder={t("password")}
            autoCapitalize="none"
            className={cls.input}
            inputMode="numeric"
            type="password"
            selectOnFocus
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />

          <Button
            size="extra-large"
            color="primary"
            type="submit"
            className={cls.action}
            fullWidth
            loading={isAuthenticating}
          >
            {t("sign_in")}
          </Button>

          {!isSolidDeliver && (
            <Button
              iconSide="left"
              size="large"
              color="primary"
              variation="outlined"
              className={cls.getApps}
              onClick={() => history.push("/pobierz")}
            >
              {t("get_app")}
              <Icon name="chevron-right" color="primary" className="ml-4" />
            </Button>
          )}
        </div>
      </form>
      {!isSolidDeliver && (
        <img
          src={
            isSolidDeliver
              ? require("./soliddeliver.svg").default
              : require("./deligoo.svg").default
          }
          className={cls.figure}
          alt=""
        />
      )}
    </>
  )
}

export { SignIn }
