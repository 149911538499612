import { useCallback, useEffect, useRef } from "react"

export function useIsMounted() {
  const isMountedRef = useRef(true)

  useEffect(
    () => () => {
      isMountedRef.current = false
    },
    []
  )

  const isMounted = useCallback(() => isMountedRef.current, [isMountedRef])

  return isMounted
}
