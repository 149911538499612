import { Button, ProviderLogo, Spinner } from "@deligoo/ui"
import clsx from "clsx"
import dayjs from "dayjs"
import deepEqual from "lodash.isequal"
import { memo, MouseEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { DriverInfo } from "@/components/DriverInfo"
import { OrderProgress } from "@/components/OrderProgress"
import { Table } from "@/components/Table"
import {
  getOrders,
  selectFilteredOrdersIdsWithLinking,
  selectLastOrderInLink,
  selectOrderById,
  selectOrdersStatus,
} from "@/store/orders"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { Order } from "@/types"
import { canBeTracked, hasOrderBeenPublished } from "@/utils"

import cls from "../Orders.module.scss"
import { OrderDetailsModal, useDetailsModal } from "./OrderDetailsModal"
import { OrderTracking } from "./tracking/OrderTracking"

type OrdersListProps = {
  date: string
  filters: Array<(order: Order) => boolean>
}

const OrdersList = ({ date, filters }: OrdersListProps) => {
  const ordersIdsWithLinking = useSelector(
    selectFilteredOrdersIdsWithLinking(...filters),
    deepEqual
  )

  const ordersStatus = useSelector(selectOrdersStatus)
  const currentRestaurantId = useSelector(selectCurrentRestaurantKey("id"))

  const [currentOrderDetailsId, setCurrentOrderDetailsId] = useDetailsModal()
  const [trackingId, setTrackingId] = useState<Order["id"] | null>()

  const dispatch = useDispatch()

  const { t } = useTranslation("orders")

  useEffect(() => {
    if (currentRestaurantId) dispatch(getOrders(dayjs(date)))
  }, [dispatch, currentRestaurantId, date])

  useEffect(() => {
    // Quick and dirty because ASAP; consider react-router as a solution
    const searchParams = new URLSearchParams(window.location.search)
    const orderId = searchParams.get("order_id")

    if (ordersStatus === "fetched" && orderId) {
      setCurrentOrderDetailsId(Number(orderId))
      window.history.pushState({}, document.title, "/zlecenia")
    }
  }, [ordersStatus, setCurrentOrderDetailsId])

  return (
    <Table columns={"minmax(150px, 1fr) 380px 250px 80px"}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>{t("headers.order_uid_and_address")}</Table.Th>
          <Table.Th>{t("headers.status")}</Table.Th>
          <Table.Th>{t("headers.driver")}</Table.Th>
          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody loading={ordersStatus === "loading"}>
        {(ordersStatus === "fetched" || ordersStatus === "updating") &&
          ordersIdsWithLinking &&
          ordersIdsWithLinking.map((ordersIds) => (
            <GroupedOrdersRow
              key={ordersIds[0]}
              ordersIds={ordersIds}
              setCurrentOrderDetailsId={setCurrentOrderDetailsId}
              setTrackingId={setTrackingId}
            />
          ))}
      </Table.Tbody>

      {(ordersStatus === "fetched" || ordersStatus === "updating") &&
        ordersIdsWithLinking &&
        currentOrderDetailsId && (
          <OrderDetailsModal
            orderId={currentOrderDetailsId}
            handleClose={() => setCurrentOrderDetailsId(null)}
          />
        )}

      {(ordersStatus === "fetched" || ordersStatus === "updating") &&
        ordersIdsWithLinking &&
        trackingId && (
          <OrderTracking
            orderId={trackingId}
            handleClose={() => setTrackingId(null)}
          />
        )}
    </Table>
  )
}

type GroupedOrdersRowProps = {
  ordersIds: Array<Order["id"]>
  setCurrentOrderDetailsId: (orderId: Order["id"]) => void
  setTrackingId: (orderId: Order["id"]) => void
}

export const GroupedOrdersRow = memo(
  ({
    ordersIds,
    setCurrentOrderDetailsId,
    setTrackingId,
  }: GroupedOrdersRowProps) => (
    <div className={cls.groupedOrdersRow}>
      {ordersIds.map((orderId, index) => (
        <OrderRow
          key={orderId}
          orderId={orderId}
          isFirstOrderInGroup={index === 0}
          setCurrentOrderDetailsId={setCurrentOrderDetailsId}
          setTrackingId={setTrackingId}
        />
      ))}
    </div>
  ),
  (prevProps, nextProps) => deepEqual(prevProps.ordersIds, nextProps.ordersIds)
)

type OrderRowProps = {
  orderId: Order["id"]
  isFirstOrderInGroup: boolean
  setCurrentOrderDetailsId: (orderId: Order["id"]) => void
  setTrackingId: (orderId: Order["id"]) => void
}

const OrderRow = ({
  orderId,
  isFirstOrderInGroup,
  setCurrentOrderDetailsId,
  setTrackingId,
}: OrderRowProps) => {
  const order = useSelector(selectOrderById(orderId))
  const lastOrderInLink = useSelector(selectLastOrderInLink(orderId))

  const { t } = useTranslation("orders")

  if (!order) return null

  return (
    <Table.Tr
      className={cls.orderRow}
      key={order.id}
      onClick={() => setCurrentOrderDetailsId(order.id)}
    >
      <Table.Td>
        <div className="h300">
          {order.delivery?.address ||
            order.delivery?.phone ||
            order.pickup?.phone}
        </div>
        <div className="h200 text-primary d-flex align-center mt-2">
          #{order.uid}
          {order.ordinal_number ? (
            <span className="ml-1">({order.ordinal_number})</span>
          ) : (
            <Spinner className="ml-1" size="small" inline />
          )}
          {order.provider !== "deligoo" && (
            <div className={cls.provider}>
              <ProviderLogo
                provider={order.provider}
                marketplace={order.marketplace_kind}
              />
              <div className="floatRight text-muted">#{order.external_id && order.external_id.slice(-8)}</div>
            </div>
          )}
        </div>
      </Table.Td>
      <Table.Td>
        <div className={clsx(cls.centered, cls.orderProgressWrapper)}>
          {isFirstOrderInGroup && (
            <OrderProgress order={order} lastOrderInLink={lastOrderInLink} />
          )}
        </div>
      </Table.Td>
      <Table.Td className={cls.driver}>
        {isFirstOrderInGroup ? (
          hasOrderBeenPublished(order.state) && order.driver ? (
            <div className={clsx(cls.centered, cls.driverWrapper)}>
              <DriverInfo driver={order.driver} />
            </div>
          ) : (
            <span className={clsx(cls.centered, cls.driverWrapper)}>–</span>
          )
        ) : null}
      </Table.Td>
      <Table.Td>
        {isFirstOrderInGroup && (
          <div className={cls.centered}>
            <Button
              title={t("headers.track_driver")}
              iconProps={{ name: "crosshair" }}
              color={order.state !== "started" ? "muted" : "success"}
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                setTrackingId(orderId)
              }}
              disabled={!canBeTracked(order)}
            />
          </div>
        )}
      </Table.Td>
    </Table.Tr>
  )
}

export { OrdersList }
