import { useCallback, useEffect, useRef } from "react"
import { toast } from "react-toastify"

export function useIsMounted() {
  const isMountedRef = useRef(true)

  useEffect(
    () => () => {
      isMountedRef.current = false
    },
    []
  )

  const isMounted = useCallback(() => isMountedRef.current, [isMountedRef])

  return isMounted
}

export function useRemoveToastOnMount(toastId: string | number | null) {
  useEffect(() => {
    if (toastId !== null) toast.dismiss(toastId)
  }, [toastId])
}

export function usePersistLocale(locale: string) {
  useEffect(() => {
    localStorage.setItem("user_locale", locale)
  }, [locale])
}
