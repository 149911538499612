import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Card } from "@/components/Card"
import {
  selectCurrentRestaurant,
  selectCurrentRestaurantStatus,
} from "@/store/restaurant"

const Mode = () => {
  const restaurant = useSelector(selectCurrentRestaurant)
  const restaurantStatus = useSelector(selectCurrentRestaurantStatus)

  const { t } = useTranslation("dashboard")

  const helpModal = {
    title: "Restaurant status",
    content: (
      <>
        <dl>
          <dt className="h300">{t("status_standard")}</dt>
          <dd>{t("status_standard_desc")}</dd>

          <dt className="h300 mt-4">{t("status_standby")}</dt>
          <dd>{t("status_standby_desc")}</dd>
        </dl>

        <div className="text-small mt-4">{t("status_desc")}</div>
      </>
    ),
  }

  return (
    <Card
      help={t("Learn more")}
      helpModal={helpModal}
      loading={restaurantStatus === "loading"}
    >
      <Card.Header>
        <Card.Title className="capitalize">{restaurant?.mode}</Card.Title>
      </Card.Header>
      {t("Mode")}
    </Card>
  )
}

export { Mode }
