import { Backdrop, Icon } from "@deligoo/ui"
import clsx from "clsx"
import { ComponentProps, HTMLAttributes, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { shallowEqual, useSelector } from "react-redux"
import { NavLink, useLocation } from "react-router-dom"

import { Indicator } from "@/components/Indicator"
import { selectOrdersToReceiveIds } from "@/store/orders"
import {
  selectCanRestaurantReceiveOrders,
  selectCurrentRestaurantKey,
} from "@/store/restaurant"

import cls from "./Navbar.module.scss"

const Navbar = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  const canReceiveOrders = useSelector(selectCanRestaurantReceiveOrders)

  const ordersToReceiveIds = useSelector(
    selectOrdersToReceiveIds(),
    shallowEqual
  )

  const ordersToReceiveCount = ordersToReceiveIds.length

  const [isExpanded, setExpanded] = useState(false)

  const location = useLocation()

  const { t } = useTranslation()

  function toggleNavbar() {
    setExpanded((isExpanded) => !isExpanded)
  }

  useEffect(() => setExpanded(false), [location])

  return (
    <header className={clsx(cls.navbarWrapper, className)}>
      {isExpanded && (
        <Backdrop className={cls.backdrop} onClick={toggleNavbar} />
      )}

      <div className={clsx(cls.navbar, isExpanded && cls.expanded)}>
        <Logo isExpanded={isExpanded} />

        <nav className={cls.nav}>
          <NavLink
            exact
            to="/"
            className={cls.navLink}
            activeClassName={cls.isActive}
          >
            <LargeIcon name="dashboard" />
            <span className={cls.label}>{t("nav.dashboard")}</span>
          </NavLink>

          <NavLink
            to="/zlecenia"
            className={cls.navLink}
            activeClassName={cls.isActive}
          >
            <LargeIcon name="tasks" />
            <span className={cls.label}>{t("nav.orders")}</span>
          </NavLink>

          <NavLink
            to="/platnosci"
            className={cls.navLink}
            activeClassName={cls.isActive}
          >
            <LargeIcon name="cash" />
            <span className={cls.label}>{t("nav.payments")}</span>
          </NavLink>

          {canReceiveOrders && (
            <NavLink
              to="/odbieranie-zlecen"
              className={cls.navLink}
              activeClassName={cls.isActive}
            >
              <Indicator count={ordersToReceiveCount}>
                <LargeIcon name="box-pickup" />
              </Indicator>
              <span className={cls.label}>{t("nav.orders_receiveing")}</span>
            </NavLink>
          )}

          <NavLink
            to="/statystyki"
            className={cls.navLink}
            activeClassName={cls.isActive}
          >
            <LargeIcon name="statistics" />
            <span className={cls.label}>{t("nav.statistics")}</span>
          </NavLink>

          <NavLink
            to="/ustawienia"
            className={cls.navLink}
            activeClassName={cls.isActive}
          >
            <LargeIcon name="settings" />
            <span className={cls.label}>{t("nav.settings")}</span>
          </NavLink>
        </nav>

        <div className={cls.footer}>
          <button
            className={cls.toggleBtn}
            onClick={toggleNavbar}
            type="button"
          >
            <Icon name="short-arrow-tiny-right" color="primary" />
          </button>
        </div>
      </div>
    </header>
  )
}

const LargeIcon = ({ name, ...props }: ComponentProps<typeof Icon>) => (
  <Icon className={cls.icon} name={name} size="large" {...props} />
)

const Logo = ({ isExpanded }: { isExpanded: boolean }) => {
  const interfaceType = useSelector(
    selectCurrentRestaurantKey("interface_type")
  )

  function getInterfaceLogoUrl() {
    switch (interfaceType) {
      case "inpost":
        return require("@/assets/logotypes/logo-inpost.png").default
      case "soliddelivery":
        return require("@/assets/logotypes/logo-soliddelivery.svg").default
      default:
        return require(`@/assets/logotypes/logo-deligoo-${
          isExpanded ? "gradient" : "sygnet"
        }.svg`).default
    }
  }

  return (
    <NavLink
      to="/"
      className={clsx(cls.logotype, interfaceType && cls[interfaceType])}
    >
      <img alt={interfaceType || "Deligoo"} src={getInterfaceLogoUrl()} />
    </NavLink>
  )
}

export { Navbar }
