import { TimelineStep } from "@deligoo/shared"
import clsx from "clsx"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import useAsyncEffect from "use-async-effect"

import i18n from "@/i18n"
import { Order, OrderFull } from "@/types"
import { fetchOrder } from "@/utils"

import cls from "./OrderTimeline.module.scss"

type OrderTimelineProps = {
  orderId: Parameters<typeof useOrderTimeline>[0]
  /**
   * @param dependencies - an array of Order attributes that trigger a timeline refetch
   */
  dependencies: Parameters<typeof useOrderTimeline>[1]
  className?: string
}

const OrderTimeline = ({
  orderId,
  dependencies,
  className,
}: OrderTimelineProps) => {
  const { timeline, state } = useOrderTimeline(orderId, dependencies)

  const { t } = useTranslation("orders")

  return (
    <div className={clsx(cls.timeline, className)}>
      {state === "loading" && t("timeline.loading")}
      {state === "error" && t("timeline.fetch_error")}
      {state === "fetched" &&
        timeline?.map((event) => (
          <TimelineStep
            key={`${event.created_at}${event.key}`}
            rootSize="1.125rem"
            event={event}
            textKey={(event) => event.description.pos_text}
            parameters={(event, paramCls) => {
              if (!event.description.pos_parameters) return null

              return Object.entries(event.description.pos_parameters).map(
                ([key, value]) => (
                  <div
                    key={key}
                    className={clsx(
                      paramCls,
                      ["delivery_late", "pickup_late"].includes(key) &&
                        "text-alert"
                    )}
                  >
                    {value}
                  </div>
                )
              )
            }}
          />
        ))}
    </div>
  )
}

function useOrderTimeline<T extends keyof Order>(
  orderId: Order["id"],
  dependencies: Array<Order[T]>
) {
  const [timeline, setTimeline] = useState<OrderFull["events"] | null>(null)
  const [state, setState] = useState<"loading" | "error" | "fetched" | null>(
    null
  )

  useAsyncEffect(
    async (isSubscribed) => {
      try {
        setState("loading")
        const { response, json } = await fetchOrder(orderId)
        if (!response.ok) throw response
        if (isSubscribed() && json.data) {
          setTimeline(json.data.events)
          setState("fetched")
        }
      } catch (error) {
        console.warn(error)
        toast.error(i18n.t("orders:timeline.fetch_error"))
        if (isSubscribed()) setState("error")
      }
    },
    [orderId, ...dependencies]
  )

  return { timeline, state }
}

export { OrderTimeline }
