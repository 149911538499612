import { Button, Dropdown } from "@deligoo/ui"
import clsx from "clsx"
import dayjs from "dayjs"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { DatePicker } from "@/components/DatePicker"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { Order } from "@/types"
import { isFromDate } from "@/utils/order"

import cls from "./Orders.module.scss"
import {
  OrdersDetails,
  OrdersList,
  OrdersPayments,
  OrdersTabs,
} from "./partials"

const ORDER_LIST_HIDDEN_STATES = ["waiting", "draft", "unpaid"]

const Orders = () => {
  const { t } = useTranslation("orders")
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"))
  const [selectedDeliveryType, setSelectedDeliveryType] = useState("all")

  function handleDateChange(date: Date | null) {
    date && setDate(dayjs(date).format("YYYY-MM-DD"))
  }

  const interfaceType = useSelector(
    selectCurrentRestaurantKey("interface_type")
  )

  const location = useLocation()

  const filters = useMemo(
    () => [
      (order: Order) =>
        isFromDate(dayjs(date))(order) &&
        !ORDER_LIST_HIDDEN_STATES.includes(order.state),
      (order: Order) => {
        if (selectedDeliveryType === "pickup_only")
          return Boolean(order.pickup && !order.delivery)
        if (selectedDeliveryType === "deliveries")
          return Boolean(order.delivery)
        return true
      },
    ],
    [date, selectedDeliveryType]
  )

  function renderContent(pathname: string) {
    switch (pathname) {
      case "/zlecenia":
        return <OrdersList date={date} filters={filters} />
      case "/zlecenia/szczegoly":
        return <OrdersDetails date={date} filters={filters} />
      case "/zlecenia/platnosci":
        return <OrdersPayments date={date} filters={filters} />
    }
  }

  return (
    <div className={clsx("page-content", cls.orders)}>
      <header className={cls.header}>
        <OrdersTabs />
        <div className="d-flex">
          {(interfaceType === "deligoo" ||
            interfaceType === "soliddelivery") && (
            <div className="mr-6">
              <Dropdown
                theme="light"
                trigger={
                  <span className="text-muted">
                    <Button
                      color="muted"
                      iconSide="right"
                      size="extra-large"
                      variation="outlined"
                      iconProps={{
                        name: "chevron-tiny-down",
                        color: "muted",
                      }}
                    >
                      {t("filters.show")}{" "}
                      <span className="h300">
                        {t(`filters.${selectedDeliveryType}`)}
                      </span>
                    </Button>
                  </span>
                }
              >
                <Dropdown.MenuItem
                  handleClick={() => setSelectedDeliveryType("deliveries")}
                >
                  {t("filters.deliveries")}
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  handleClick={() => setSelectedDeliveryType("pickup_only")}
                >
                  {t("filters.pickup_only")}
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  handleClick={() => setSelectedDeliveryType("all")}
                >
                  {t("filters.all")}
                </Dropdown.MenuItem>
              </Dropdown>
            </div>
          )}
          <DatePicker
            date={dayjs(date).toDate()}
            handleDateChange={handleDateChange}
          />
        </div>
      </header>
      {renderContent(location.pathname)}
    </div>
  )
}

export { Orders }
