import clsx from "clsx"

import { IconName } from "../../icons/build"
import { ThemeColor } from "../../types"
import cls from "./Icon.module.scss"

export type IconProps = React.SVGAttributes<SVGElement> & {
  children?: never
  name: IconName
  size?: "tiny" | "small" | "medium" | "large" | "extra-large"
  color?: ThemeColor
  className?: string
  gutter?: "left" | "right"
}

export const Icon = ({ name, size = "medium", color, className, gutter, ...props }: IconProps) => (
  <svg
    className={clsx(
      cls.icon,
      cls[size],
      color && cls[color],
      gutter === "left" && cls.gutterLeft,
      gutter === "right" && cls.gutterRight,
      className
    )}
    aria-hidden
    {...props}
  >
    <use href={`#dgi-${name}`} />
  </svg>
)
