const restaurants = {
  lock_reason: {
    clients_orders_limit_reached: "Daily delivery limit has been reached",
    clients_unsettled_payments:
      "Due to unsettled payments, your location has been locked. Please settle your payment to activate account.",
    clients_locked_by_admin:
      "The location has been blocked by the administrator. Please contact DeliGoo support.",
    clients_inactive:
      "Due to inactivity for a long time, the location has been blocked, to activate the location please contact DeliGoo support.",
    clients_deactivated_by_admin:
      "The location has been blocked by the administrator, to activate the location please contact DeliGoo support.",
    partners_deactivated_by_admin:
      "The account has been blocked by the administrator, to activate the account contact DeliGoo support.",
  },
}

export default restaurants
