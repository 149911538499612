import { Button, Modal } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

import { useAppDispatch } from "@/App"
import { requestPaymentRefund, selectOrderById } from "@/store/orders"
import { useIsMounted } from "@/utils"

import { ActionProps } from "."
import cls from "./actions.module.scss"

const PaymentRefund = ({
  orderId,
  handleClose,
  isParentMounted,
  isActionInProgress,
  setIsActionInProgress,
}: ActionProps) => {
  const order = useSelector(selectOrderById(orderId))

  const dispatch = useAppDispatch()

  const isMounted = useIsMounted()

  const { t } = useTranslation(["orders", "common"])

  function handlePaymentRefund() {
    if (!orderId) return

    setIsActionInProgress(true)

    dispatch(requestPaymentRefund(orderId))
      .then(unwrapResult)
      .then(({ ok }) => {
        if (!ok) return
        toast.success(t("payment_refund_modal.payment_refunded"))
        isMounted() && handleClose()
      })
      .finally(() => {
        isParentMounted() && setIsActionInProgress(false)
      })
  }

  if (!orderId && !order) return null

  if (order?.canceled_by === "admin") {
    return (
      <Modal
        handleClose={handleClose}
        className={clsx(cls.actionModal, cls.confirm)}
        light
      >
        <Modal.Title>
          {t("payment_refund_modal.cannot_be_refunded", { orderId })}
        </Modal.Title>
        <Modal.Content>
          <Button
            onClick={handleClose}
            className={cls.action}
            size="extra-large"
            color="success"
          >
            {t("common:buttons.ok")}
          </Button>
        </Modal.Content>
      </Modal>
    )
  }

  return (
    <Modal
      handleClose={handleClose}
      className={clsx(cls.actionModal, cls.confirm)}
      light
    >
      <Modal.Title>{t("payment_refund_modal.title")}</Modal.Title>
      <Modal.Content>
        <div className={cls.actions}>
          <Button
            onClick={handleClose}
            className={cls.action}
            color="alert"
            size="extra-large"
            loading={isActionInProgress}
          >
            {t("common:buttons.no")}
          </Button>

          <Button
            onClick={handlePaymentRefund}
            className={cls.action}
            color="success"
            size="extra-large"
            loading={isActionInProgress}
          >
            {t("common:buttons.yes")}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export { PaymentRefund }
