import clsx from "clsx"
import { CSSProperties } from "react"

import { isTruthyOrZero } from "@/utils"

import cls from "./SvgProgress.module.scss"

type SvgProgressProps = {
  progress?: number
}

const SvgProgress = ({ progress }: SvgProgressProps) => {
  const radius = 45
  const circumference = Math.PI * radius * 2

  const hasProgress = isTruthyOrZero(progress)
  const scopedProgress = hasProgress && progress! < 100 ? progress : 100

  const offset = hasProgress
    ? ((scopedProgress! - 100) / 100) * circumference * -1
    : null

  const styles = hasProgress ? ({ "--offset": offset } as CSSProperties) : {}

  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(cls.svgProgress, !hasProgress && cls.loading)}
    >
      <circle cx="50" cy="50" r={radius} className={cls.bg} />
      <circle cx="50" cy="50" r={radius} className={cls.value} style={styles} />
    </svg>
  )
}

export { SvgProgress }
