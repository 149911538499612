import { ThemeColor } from "@deligoo/ui"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Card } from "@/components/Card"
import { Gauge } from "@/components/Gauge"
import { selectClientKey } from "@/store/client"
import {
  selectStatisticsData,
  selectStatisticsStatus,
} from "@/store/statistics"

import cls from "../Dashboard.module.scss"

function getFraction(value: number) {
  const fraction = value / 3.5
  return fraction
}

function getColor(value: number): ThemeColor {
  if (value <= 0.6) return "success"
  if (value <= 0.85) return "warning"
  if (value <= 0.99) return "alert"
  return "danger"
}

export function getTrafficLoadMeta(value = 0) {
  const fraction = getFraction(value)
  const percentage = Math.round(fraction * 100)

  return {
    fraction,
    percentage,
    color: getColor(fraction),
  }
}

const TrafficLoad = () => {
  const statistics = useSelector(selectStatisticsData)
  const statisticsStatus = useSelector(selectStatisticsStatus)
  const trafficLoadEnabled = useSelector(
    selectClientKey("traffic_load_enabled")
  )

  const loadValue = statistics?.load_value

  const { fraction, percentage, color } = getTrafficLoadMeta(loadValue)

  const { t } = useTranslation("dashboard")

  const helpModal = {
    title: "Traffic load",
    content: (
      <>
        {t("traffic_load_desc")}
        <dl className="mt-2">
          <dt className="h300 text-success mt-2">{t("traffic_load_green")}</dt>
          <dd>{t("traffic_load_green_desc")}</dd>

          <dt className="h300 text-warning mt-2">{t("traffic_load_orange")}</dt>
          <dd>{t("traffic_load_orange_desc")}</dd>

          <dt className="h300 text-alert mt-2">{t("traffic_load_red")}</dt>
          <dd>{t("traffic_load_red_desc")}</dd>
        </dl>
      </>
    ),
  }

  if (!trafficLoadEnabled) return null

  return (
    <Card
      inline
      sections
      help={t("Learn more")}
      helpModal={helpModal}
      className={cls.wide}
      loading={!statisticsStatus || statisticsStatus === "loading"}
    >
      <Card.Section>
        <Gauge
          color={color}
          fraction={fraction}
          iconName="boxes"
          className={cls.chart}
        />
      </Card.Section>
      <Card.Section>
        <Card.Title size="large" color={color}>
          {percentage}%
        </Card.Title>
        {t("Traffic load")}
      </Card.Section>
    </Card>
  )
}

export { TrafficLoad }
