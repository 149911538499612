import { combineReducers } from "redux"

import { authReducer } from "./auth"
import { clientReducer } from "./client"
import { globalReducer } from "./global"
import { integrationsReducer } from "./integrations"
import { invalidOrdersReducer } from "./invalidOrders"
import { messagesReducer } from "./messages"
import { notificationsReducer } from "./notifications"
import { orderFormReducer } from "./orderForm"
import { ordersReducer } from "./orders"
import { restaurantReducer } from "./restaurant"
import { statisticsReducer } from "./statistics"
import { unsettledPaymentsReducer } from "./unsettledPayments"

export const rootReducer = combineReducers({
  auth: authReducer,
  client: clientReducer,
  integrations: integrationsReducer,
  orderForm: orderFormReducer,
  restaurant: restaurantReducer,
  statistics: statisticsReducer,
  orders: ordersReducer,
  invalidOrders: invalidOrdersReducer,
  unsettledPayments: unsettledPaymentsReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  global: globalReducer,
})

export type RootState = ReturnType<typeof rootReducer>
