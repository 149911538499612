import { Button, Icon, Modal } from "@deligoo/ui"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  selectCurrentRestaurantKey,
  setLockedAccountModalState,
} from "@/store/restaurant"

import cls from "./LockedAccountModal.module.scss"

const LockedAccountModal = () => {
  const lockReason = useSelector(selectCurrentRestaurantKey("lock_reason"))

  const { t } = useTranslation(["restaurant", "common"])
  const history = useHistory()
  const dispatch = useDispatch()

  function handleClose() {
    dispatch(setLockedAccountModalState(false))
  }

  return (
    <Modal handleClose={handleClose} className={cls.modal}>
      <Icon name="warnings" color="alert" className={cls.icon} />
      <Modal.Title>Konto zablokowane</Modal.Title>

      {lockReason && (
        <Modal.Content>{t(`lock_reason.${lockReason}`)}</Modal.Content>
      )}

      {lockReason === "clients_unsettled_payments" ? (
        <Button
          color="primary"
          size="extra-large"
          onClick={() => {
            handleClose()
            history.push("/platnosci")
          }}
        >
          {t("common:buttons.go_to_payments")}
        </Button>
      ) : (
        <Button color="primary" size="extra-large" onClick={handleClose}>
          {t("common:buttons.ok")}
        </Button>
      )}
    </Modal>
  )
}

export { LockedAccountModal }
