import { ThemeColor } from "@deligoo/ui"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Card } from "@/components/Card"
import { Gauge } from "@/components/Gauge"
import {
  selectStatisticsData,
  selectStatisticsStatus,
} from "@/store/statistics"

import cls from "../Dashboard.module.scss"

function getFraction(value: number) {
  const fraction = value / 100
  return fraction < 1 ? fraction : 1
}

function getColor(value: number): ThemeColor {
  if (value === null) return "muted"
  if (value <= 70) return "success"
  if (value <= 100) return "warning"
  return "alert"
}

export function getTrafficRateMeta(value: number) {
  const fraction = getFraction(value)

  return {
    fraction,
    color: getColor(value),
  }
}

const TrafficRate = () => {
  const statistics = useSelector(selectStatisticsData)
  const statisticsStatus = useSelector(selectStatisticsStatus)

  const trafficRate = statistics?.traffic_value || 0

  const { fraction, color } = getTrafficRateMeta(trafficRate)

  const { t } = useTranslation("dashboard")

  const helpModal = {
    title: "Traffic rate",
    content: (
      <>
        <p>{t("traffic_rate")}</p>
        <p>{t("traffic_rate_desc")}</p>
        <div className="text-small mt-2">{t("traffic_rate_small")}</div>
      </>
    ),
  }

  return (
    <Card
      sections
      inline
      help={t("Learn more")}
      helpModal={helpModal}
      className={cls.wide}
      loading={!statisticsStatus || statisticsStatus === "loading"}
    >
      <Card.Section>
        <Gauge
          color={color}
          fraction={fraction}
          iconName="traffics"
          className={cls.chart}
        />
      </Card.Section>
      <Card.Section>
        <Card.Title size="large" color={color}>
          {trafficRate}%
        </Card.Title>
        {t("Traffic rate")}
      </Card.Section>
    </Card>
  )
}

export { TrafficRate }
