const dashboard = {
  "Learn more": "Dowiedz się więcej",

  "Alert message": "Komunikat alarmowy",

  "Receiving orders": "Odbieranie zamówień",

  "Remaining deliveries": "Pozostała ilość dostaw",

  "Expected delivery time": "Przewidywany czas dostawy",
  "Expected pickup time": "Przewidywany czas odbioru",
  "Estimated pickup time": "Szacowany czas odbioru",
  "Available soon": "Dostępne wkrótce",

  "Distance limit": "Zasięg dostawy",

  Integrations: "Aktywność na portalach",

  Mode: "Tryb realizacji",

  "Payments unsettled or awaiting confirmation":
    "Płatności nierozliczone i do potwierdzenia",
  "No unsettled payments": "Brak płatności do rozliczenia",
  "{{count}} payments_0": "{{count}} płatność",
  "{{count}} payments_1": "{{count}} płatności",
  "{{count}} payments_2": "{{count}} płatności",

  "Delivery pricing policy": "Polityka cenowa dostaw",
  Active: "Aktywna",
  Inactive: "Nieaktywna",

  "You have {{count}} new orders to receive_0":
    "Masz {{count}} nowe zamówienie do odebrania",
  "You have {{count}} new orders to receive_1":
    "Masz {{count}} nowe zamówienia do odebrania",
  "You have {{count}} new orders to receive_2":
    "Masz {{count}} nowych zamówień do odebrania",

  "You have {{count}} new invalid orders_0":
    "Masz {{count}} nowe nieprawidłowe zamówienie",
  "You have {{count}} new invalid orders_1":
    "Masz {{count}} nowe nieprawidłowe zamówienia",
  "You have {{count}} new invalid orders_2":
    "Masz {{count}} nowych nieprawidłowych zamówień",

  "See orders_0": "Zobacz zamówienie",
  "See orders_1": "Zobacz zamówienia",
  "See orders_2": "Zobacz zamówienia",

  "No new orders to receive": "Nie masz żadnych nowych zamówień do odebrania",

  "Pending deliveries": "Oczekujące dostawy",
  "Deliveries in progress": "Dostawy w realizacji",
  "Orders received by clients": "Zamówienia odebrane",
  "Total deliveries": "Dostaw łącznie",

  "Traffic load": "Obłożenie",

  "Traffic rate": "Natężenie ruchu drogowego",

  "Restaurant status": "Status lokalu",

  remaining_deliveries_desc:
    "Liczba pozostałych, możliwych do zrealizowania dostaw danego dnia.",
  remaining_deliveries_small:
    "* dotyczy zakontraktowanych partnerów z maksymalnym limitem dostaw.",

  distance_limit_desc:
    "Maksymalna odległość, na jaką możesz wysłać swoje zamówienie. Dystans mierzymy po najkrótszej trasie, którą fizycznie musi pokonać kierowca.",

  integrations_desc:
    "Włącz lub wyłącz aktywność lokalu na stronie internetowej lub w serwisie glodny.pl",
  integrations_count_0: "przyjmowanie zamówień nieaktywne",
  integrations_count_1: "jesteś aktywny na jednym z dwóch portali",
  integrations_count_2: "jesteś aktywny na wszystkich podpiętych portalach",

  status_standard: "Standard",
  status_standard_desc:
    "Pokazujemy czas przybycia kuriera maksymalnie na 30 minut wcześniej.",
  status_standby: "Awaryjny",
  status_standby_desc:
    "Godzinę odbioru pokażemy po wystartowaniu zlecenia do Twojego punktu.",
  status_desc:
    "* po dodaniu zlecenia, system wyznacza kierowcę, który możesz odebrać zamówienie najszybciej. Jeśli czas przygotowania zamówienia jest mniejszy niż 30 minut, wyszukujemy go od razu. Przy większym czasie przygotowania, precyzyjną godzinę odbioru pokażemy nieco później.",

  estimated_pickup_time_desc:
    "Uśredniony czas odbioru, w oparciu o aktualne obłożenie i natężenie ruchu drogowego. Po kliknięciu i wpisaniu adresu, można sprawdzić szacowany czas całkowitej realizacji zamówienia.",

  privacy_policy_desc:
    "Po wpisaniu adresu, system podpowie jaką kwotę należy doliczyć klientowi do rachunku.",
  privacy_policy_small:
    "* informacja wyłącznie dla obsługi restauracji. Należy najpiew ustawić cennik dostaw na stronie app.deligoo.pl/partner/",

  receiving_orders_desc:
    "Tutaj zobaczysz zamówienia do odebrania z Twojej strony internetowej lub serwisu glodny.pl - kliknij w pulsujący komunikat.",

  traffic_load_desc: "Procentowy wskaźnik aktualnego obciążenia zamówieniami.",
  traffic_load_green: "Zielony",
  traffic_load_green_desc: "wysoka wydajność, duża ilość dostępnych kierowców.",
  traffic_load_orange: "Pomarańczowy",
  traffic_load_orange_desc:
    "wzrost ilości zamówień, nieco dłuższy czas oczekiwania na odbiór.",
  traffic_load_red: "Czerwony",
  traffic_load_red_desc:
    "bardzo duża ilość zleceń, wydłużony czas oczekiwania.",

  traffic_rate: "Aktualne natężenie ruchu drogowego (korki).",
  traffic_rate_desc:
    "System w czasie rzeczywistym mierzy aktualny czas przejazdu na kilku głównych ulicach miasta. Wskaźnik powyżej 50% oznacza godziny szczytu. Wraz ze wskaźnikiem obciążenia ruchu, pokazujemy naszą aktualną przepustowość.",
  traffic_rate_small:
    "* wysoki wskaźnik oznacza wydłużony czas realizacji zlecenia.",

  working_hours_desc:
    "Godziny pracy kurierów Deligoo. Możesz dodać zamówienie wcześniej, jednak odbiór nastąpi w podanym zakresie.",
  working_hours_small:
    "* ostatnie zlecenie możesz dodać maksymalnie na 30 min. przed końcem naszej pracy",
}

export default dashboard
