import clsx from "clsx"
import { Box,PolymorphicComponentProps } from "react-polymorphic-box"

import { ControlSize, ThemeColor } from "../../types"
import { Icon } from "../Icon"
import { Spinner } from "../Spinner"
import cls from "./Button.module.scss"

export type ButtonOwnProps = {
  size?: ControlSize
  variation?: "solid" | "outlined" | "contrast" | "accented" | "text"
  iconSide?: "left" | "right"
  iconProps?: React.ComponentProps<typeof Icon>
  color?: ThemeColor
  raised?: boolean
  pill?: boolean
  fullWidth?: boolean
  loading?: boolean
  disabled?: boolean
  className?: string
  children?: React.ReactNode
}

export type ButtonProps<E extends React.ElementType> = PolymorphicComponentProps<E, ButtonOwnProps>

const defaultElement = "button"

export const Button = <E extends React.ElementType = typeof defaultElement>({
  size = "medium",
  variation = "solid",
  iconSide = "left",
  iconProps,
  color,
  raised,
  pill,
  fullWidth,
  loading,
  disabled,
  className,
  children,
  ...props
}: ButtonProps<E>) => {
  return (
    <Box
      as={defaultElement}
      className={clsx(
        cls.button,
        cls[variation],
        !children && cls.iconOnly,
        iconProps && cls[iconSide],
        fullWidth && cls.fullWidth,
        size && cls[size],
        color && cls[color],
        pill && cls.pill,
        loading && cls.loading,
        disabled && cls.disabled,
        !disabled && raised && cls.raised,
        !children && cls.iconOnly,
        className
      )}
      disabled={disabled}
      type={!props.as && !props.type ? "button" : undefined}
      {...props}
    >
      {iconProps && (
        <span className={cls.icon}>
          <Icon {...iconProps} />
        </span>
      )}

      {children && <span className={cls.label}>{children}</span>}
      {loading && <Spinner size="large" className={cls.spinner} />}
    </Box>
  )
}
