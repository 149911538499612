import { Button, Modal } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { ChangeEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

import { useAppDispatch } from "@/App"
import { requestUpdateClientComment, selectOrderById } from "@/store/orders"
import { useIsMounted } from "@/utils"

import { ActionProps } from "."
import cls from "./actions.module.scss"

const OrderNotes = ({
  orderId,
  handleClose,
  isParentMounted,
  isActionInProgress,
  setIsActionInProgress,
}: ActionProps) => {
  const order = useSelector(selectOrderById(orderId))

  const [comment, setComment] = useState(order?.client_comment || "")

  const dispatch = useAppDispatch()

  const isMounted = useIsMounted()

  const { t } = useTranslation(["orders", "common"])

  function handleUpdateClientComment() {
    if (!orderId) return

    if (!comment && !order?.client_comment) {
      toast.error(t("order_notes_modal.cannot_be_empty"), {
        toastId: "emptyCommentError",
      })
      return
    }

    setIsActionInProgress(true)

    dispatch(requestUpdateClientComment({ orderId, comment }))
      .then(unwrapResult)
      .then(({ ok }) => {
        if (!ok) return
        toast.success(t("order_notes_modal.notes_updated"))
        isMounted() && handleClose()
      })
      .finally(() => {
        isParentMounted() && setIsActionInProgress(false)
      })
  }

  function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setComment(e.target.value)
  }

  if (!orderId || !order) return null

  return (
    <Modal
      handleClose={handleClose}
      className={clsx(cls.actionModal, cls.notes)}
      light
    >
      <Modal.Title>{t("order_notes_modal.title")}</Modal.Title>
      <Modal.Content>
        <div className={cls.content}>
          <textarea
            placeholder={t("order_notes_modal.notes_content_placeholder")}
            value={comment}
            onChange={handleChange}
          />
        </div>

        <div className={cls.actions}>
          <Button
            onClick={handleClose}
            className={cls.action}
            color="alert"
            size="extra-large"
          >
            {t("common:buttons.cancel")}
          </Button>

          <Button
            className={cls.action}
            color="success"
            size="extra-large"
            loading={isActionInProgress}
            onClick={handleUpdateClientComment}
          >
            {t("common:buttons.confirm")}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export { OrderNotes }
