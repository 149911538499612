import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { DistanceBar } from "@/components/DistanceBar"
import { Price } from "@/components/Price"
import { selectClientKey } from "@/store/client"
import {
  selectCurrentRestaurantKey,
  selectIsPricingPolicyActive,
} from "@/store/restaurant"
import { selectStatisticsData } from "@/store/statistics"
import { isTruthyOrZero } from "@/utils"
import {
  getPickupDurationMeta,
  getTrafficLoadMeta,
  getTrafficRateMeta,
} from "@/views/Dashboard/partials"

import cls from "../ContactDetails.module.scss"

const Summary = () => {
  const { errors, register } = useFormContext()

  const { distance, delivery_price_subunit } = useWatch({})

  const distanceLimit =
    useSelector(selectCurrentRestaurantKey("deligoo_max_distance_limit")) || 0

  const isPricingPolicyActive = useSelector(selectIsPricingPolicyActive)

  const trafficLoadEnabled = useSelector(
    selectClientKey("traffic_load_enabled")
  )

  const statistics = useSelector(selectStatisticsData)

  const pickupDuration = useSelector(
    selectCurrentRestaurantKey("pickup_duration")
  )

  const trafficLoadMeta = getTrafficLoadMeta(statistics?.load_value || 0)
  const trafficRateMeta = getTrafficRateMeta(statistics?.traffic_value || 0)
  const pickupDurationMeta = getPickupDurationMeta(pickupDuration)

  const distanceError =
    errors?.delivery_zone?.message ||
    errors?.delivery_range?.message ||
    errors?.distance_for_car?.message ||
    errors?.distance_for_bicycle?.message ||
    errors?.distance_for_scooter?.message ||
    errors?.distance?.message

  const { t } = useTranslation(["dashboard", "common"])

  return (
    <div className={cls.summary}>
      <div className={cls.item}>
        <h3 className="h300">{t("common:headers.distance")}</h3>
        <div className={cls.itemContent}>
          {isTruthyOrZero(distance) ? (
            <DistanceBar distance={distance} limit={distanceLimit} />
          ) : (
            <span className="h600 text-primary">–</span>
          )}

          {distanceError && <div className="text-error">{distanceError}</div>}
        </div>

        <input
          name="distance"
          type="hidden"
          ref={register({
            required: `${t("common:errors.is_required")}`,
            max: {
              value: distanceLimit,
              message: `${t("common:errors.distance_limit", {
                distanceLimit,
              })}`,
            },
          })}
        />
      </div>

      {isPricingPolicyActive && (
        <div className={cls.item}>
          <h3 className="h300">{t("common:headers.delivery_cost")}</h3>
          <div className={cls.itemContent}>
            <span className="h600 text-primary">
              {isTruthyOrZero(delivery_price_subunit) ? (
                <Price subunit>{delivery_price_subunit}</Price>
              ) : (
                "–"
              )}
            </span>
          </div>

          <input name="delivery_price_subunit" type="hidden" ref={register()} />
        </div>
      )}

      {trafficLoadEnabled && (
        <div className={cls.item}>
          <h3 className="h300">{t("Traffic load")}</h3>
          <div className={cls.itemContent}>
            <span className={`h600 text-${trafficLoadMeta.color}`}>
              {trafficLoadMeta.percentage}%
            </span>
          </div>
        </div>
      )}

      <div className={cls.item}>
        <h3 className="h300">{t("Traffic rate")}</h3>
        <div className={cls.itemContent}>
          <span className={`h600 text-${trafficRateMeta.color}`}>
            {statistics?.traffic_value || 0}%
          </span>
        </div>
      </div>

      <div className={cls.item}>
        <h3 className="h300">{t("Expected pickup time")}</h3>
        <div className={cls.itemContent}>
          <span className={`h600 text-${pickupDurationMeta.color}`}>
            {pickupDuration} min.
          </span>
        </div>
      </div>
    </div>
  )
}

export { Summary }
