import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"

import { DataStatus, Restaurant, RootState } from "@/types"
import { fetchSetCurrentRestaurant, handleStandardError } from "@/utils"
import {
  canCreateOrders,
  canReceiveOrders,
  hasAvailableOrders,
} from "@/utils/restaurant"

import { clearDataOnLogout, requestClient } from "./auth"

type RestaurantState = {
  current: Restaurant | null
  status: DataStatus | null
  isLockedAccountModalActive: boolean
  isAppAlertHidden: boolean
}

const initialState: RestaurantState = {
  current: null,
  status: null,
  isLockedAccountModalActive: false,
  isAppAlertHidden: true,
}

export const setCurrentRestaurant = createAsyncThunk(
  "restaurant/setCurrent",
  async (clientId: number) => {
    const { response, json } = await fetchSetCurrentRestaurant(clientId)

    if (!response.ok) {
      handleStandardError(response, json)
    }

    return { ok: response.ok, status: response.status, data: json.data }
  }
)

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    updateRestaurant(state, action: PayloadAction<Restaurant>) {
      const updatedRestaurant = action.payload
      state.current = updatedRestaurant
    },
    setLockedAccountModalState(state, action: PayloadAction<boolean>) {
      state.isLockedAccountModalActive = action.payload
    },
    setIsAppAlertHidden(state, action: PayloadAction<boolean>) {
      state.isAppAlertHidden = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestClient.pending, (state) => {
      if (state.current) {
        state.status = "updating"
      } else {
        state.status = "loading"
      }
    })

    builder.addCase(requestClient.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        state.current = payload.data.current_restaurant
        state.isLockedAccountModalActive = Boolean(
          payload.data.current_restaurant.lock_reason
        )
        state.status = "fetched"
      } else {
        state.status = "error"
      }
    })

    builder.addCase(setCurrentRestaurant.pending, (state) => {
      state.current = null
      state.status = "loading"
    })

    builder.addCase(setCurrentRestaurant.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        state.current = payload.data.current_restaurant
        state.status = "fetched"
      } else {
        state.status = "error"
      }
    })

    builder.addCase(clearDataOnLogout, () => initialState)
  },
})

export const selectCurrentRestaurant = (state: RootState) =>
  state.restaurant.current

export const selectCurrentRestaurantKey =
  <T extends keyof Restaurant>(key: T) =>
  (state: RootState) =>
    state.restaurant.current?.[key]

export const selectCurrentRestaurantStatus = (state: RootState) =>
  state.restaurant.status

export const selectCanRestaurantReceiveOrders = createSelector(
  selectCurrentRestaurantKey("order_management_kind"),
  selectCurrentRestaurantKey("interface_type"),
  (orderManagementKind, interfaceType) =>
    orderManagementKind && interfaceType
      ? canReceiveOrders(orderManagementKind, interfaceType)
      : false
)

export const selectCanRestaurantCreateOrders = createSelector(
  selectCurrentRestaurantKey("order_management_kind"),
  (managementKind) => managementKind && canCreateOrders(managementKind)
)

export const selectIsPricingPolicyActive = (state: RootState) =>
  state.restaurant.current?.pricing_policy === "active"

export const selectIsBlockedFromCreatingOrders = createSelector(
  selectCurrentRestaurantKey("lock_reason"),
  selectCurrentRestaurantKey("today_available_orders"),
  (lockReason, availableOrders) =>
    Boolean(lockReason || !hasAvailableOrders(availableOrders))
)

export const selectLockedAccountModalState = (state: RootState) =>
  state.restaurant.isLockedAccountModalActive

export const selectIsAppAlertHidden = (state: RootState) =>
  state.restaurant.isAppAlertHidden

export const selectIsHolidayOrMaintenanceModeEnabled = (state: RootState) =>
  state.restaurant.current?.team_holiday_mode ||
  state.restaurant.current?.maintenance

export const {
  updateRestaurant,
  setLockedAccountModalState,
  setIsAppAlertHidden,
} = restaurantSlice.actions
export const restaurantReducer = restaurantSlice.reducer
