import { Backdrop, Button, Icon, ProviderLogo } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

import { useAppDispatch } from "@/App"
import { Basket } from "@/components/Basket"
import { CloseButton } from "@/components/CloseButton"
import { DeliveryMethod } from "@/components/DeliveryMethod"
import { Price } from "@/components/Price"
import {
  rejectInvalidOrder,
  selectInvalidOrderById,
} from "@/store/invalidOrders"
import { ContactDetail, Order } from "@/types"
import { InvalidOrder } from "@/types/InvalidOrder"
import { useIsMounted, useRemoveToastOnMount } from "@/utils"

import {
  EditOrderAddressModal,
  useEditOrderAddressModal,
} from "./EditOrderAddressModal"
import cls from "./ReceiveOrderModal.module.scss"
import { CustomerContactDetailsSection } from "./sections/CustomerContactDetailsSection"
import { PaymentSection } from "./sections/PaymentSection"

type ReceiveOrderModalProps = {
  orderId: Order["id"]
  handleClose: () => void
}

const ReceiveInvalidOrderModal = ({
  orderId,
  handleClose,
}: ReceiveOrderModalProps) => {
  const invalidOrder = useSelector(selectInvalidOrderById(orderId))

  const [isActionInProgress, setIsActionInProgress] = useState(false)

  const [orderToAddressEditId, setOrderToAddressEditId] =
    useEditOrderAddressModal()

  const isMounted = useIsMounted()

  const dispatch = useAppDispatch()

  const { t } = useTranslation("orders")

  useRemoveToastOnMount("newOrderToReceive")

  if (!invalidOrder) return null

  const order = invalidOrder.data

  async function rejectOrder() {
    setIsActionInProgress(true)

    const { response } = await dispatch(rejectInvalidOrder(orderId)).then(
      unwrapResult
    )

    if (isMounted()) setIsActionInProgress(false)

    if (response.ok) {
      toast.success(t("invalid_orders_modal.toasts.order_rejected"))
      handleClose()
    }
  }

  return (
    <Backdrop>
      {orderToAddressEditId ? (
        <EditOrderAddressModal
          orderId={invalidOrder.id}
          onReturnClick={() => setOrderToAddressEditId(null)}
          handleClose={handleClose}
        />
      ) : (
        <div className={cls.receiveOrder}>
          <header className={cls.header}>
            <div className={cls.left}>
              <h2 className={cls.title}>
                {t("invalid_orders_modal.headers.confirm_invalid_order")}
              </h2>
              <span className={cls.address}>
                {humanizeAddress(order.customer_contact_detail_attributes)}
                <span className="text-alert d-flex align-center ml-5">
                  <Icon name="alarm" gutter="right" />{" "}
                  {t("invalid_orders_modal.headers.order_address_is_invalid")}
                </span>
              </span>
            </div>

            <div className={cls.right}>
              {order.provider && (
                <ProviderLogo
                  provider={order.provider}
                  marketplace={order.marketplace_kind}
                />
              )}

              <CloseButton
                onClick={handleClose}
                size="extra-large"
                className="ml-8"
              />
            </div>
          </header>

          <div className={cls.content}>
            <div className={cls.left}>
              <Basket details={order.details} />
            </div>

            <div className={cls.right}>
              <div className={cls.summary}>
                <CustomerContactDetailsSection
                  details={order.customer_contact_detail_attributes}
                />

                <div className={clsx(cls.section, cls.deliveryInfo)}>
                  <div className={cls.deliveryMethod}>
                    <DeliveryMethod
                      deliveryMethod={order.delivery_method}
                      deliveryAt={order.delivery_at}
                      pickupAt={order.pickup_at}
                    />
                    {t("receive_order_modal.headers.delivery_method")}
                  </div>

                  {order.order_comment_attributes && (
                    <div className={cls.orderComment}>
                      {order.order_comment_attributes.description}
                    </div>
                  )}
                </div>

                <PaymentSection
                  provider={order.provider}
                  marketplace={order.marketplace_kind}
                  paymentForm={order.payment_form}
                  invoice={order.invoice}
                />

                <div className={clsx(cls.section, cls.pricing)}>
                  <div className={cls.item}>
                    <span className={cls.label}>
                      {t("receive_order_modal.headers.order_total_price")}:
                    </span>
                    {order.price_subunit !== null ? (
                      <Price className={cls.value} subunit>
                        {order.price_subunit}
                      </Price>
                    ) : (
                      "-"
                    )}
                  </div>

                  <div className={cls.item}>
                    <span className={cls.label}>
                      {t("receive_order_modal.headers.order_delivery_price")}:
                    </span>
                    {order.price_delivery_subunit !== null ? (
                      <Price className={cls.value} subunit>
                        {order.price_delivery_subunit}
                      </Price>
                    ) : (
                      "-"
                    )}
                  </div>
                  {order.price_packaging_subunit !== null &&
                  order.price_packaging_subunit !== undefined &&
                  order.price_packaging_subunit > 0 && (
                    <div className={cls.item}>
                      <span className={cls.label}>
                        {t("receive_order_modal.headers.order_price_packaging")}:
                      </span>
                      <Price className={cls.value} subunit>
                        {order.price_packaging_subunit}
                      </Price>
                    </div>
                  )}
                  {order.discount_code_value_subunit !== null &&
                    order.discount_code_value_subunit > 0 && (
                      <div className={cls.item}>
                        <span className={cls.label}>
                          {t("receive_order_modal.headers.order_discount")}:
                        </span>

                        <Price className={cls.value} subunit>
                          {order.discount_code_value_subunit}
                        </Price>
                      </div>
                    )}

                  <div className={cls.item}>
                    <span className={cls.label}>
                      {t("receive_order_modal.headers.order_total_amount")}:
                    </span>
                    <Price subunit>
                      {(order.price_subunit || 0) +
                        (order.price_delivery_subunit || 0)}
                    </Price>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className={cls.actions}>
            <Button
              className={cls.button}
              color="alert"
              size="extra-large"
              fullWidth
              iconProps={{ name: "close", size: "small" }}
              iconSide="right"
              onClick={rejectOrder}
              loading={isActionInProgress}
            >
              {t("orders_to_receive.actions.reject")}
            </Button>

            <Button
              className={cls.button}
              color="primary"
              size="extra-large"
              fullWidth
              iconProps={{ name: "edit", size: "small" }}
              iconSide="right"
              onClick={() => setOrderToAddressEditId(invalidOrder.id)}
              loading={isActionInProgress}
            >
              {t("orders_to_receive.actions.edit_address")}
            </Button>
          </footer>
        </div>
      )}
    </Backdrop>
  )
}

function useReceiveInvalidOrderModal(): [
  InvalidOrder["id"] | null,
  (id: InvalidOrder["id"] | null) => void
] {
  const [orderToReceiveId, setOrderToReceiveId] = useState<
    InvalidOrder["id"] | null
  >(null)

  return [orderToReceiveId, setOrderToReceiveId]
}

function humanizeAddress(data: Partial<ContactDetail>) {
  const flatNumber = data.flat_number ? ` / ${data.flat_number}` : ""

  return `${data.street} ${data.house_number}${flatNumber}, ${data.postal_code} ${data.city}`
}

export { ReceiveInvalidOrderModal, useReceiveInvalidOrderModal }
