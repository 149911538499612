import clsx from "clsx"
import dayjs from "dayjs"

import { Message } from "@/types"

import cls from "../Messages.module.scss"

const MessageContent = ({ message }: { message: Message | null }) => {
  return (
    <div className={cls.messageContent}>
      {message && (
        <div>
          <div className={clsx(cls.datetime, "text-muted")}>
            {dayjs(message.created_at).format("DD.MM.YYYY, HH:mm")}
          </div>
          <div className={cls.title}>{message.title}</div>
          <div className={clsx(cls.message, "text-muted")}>
            {message.content}
          </div>
        </div>
      )}
    </div>
  )
}

export { MessageContent }
