import { FullCity } from "@deligoo/shared"
import { Autocomplete, AutocompleteField, Field } from "@deligoo/ui"
import { debounce } from "lodash"
import { ComponentProps, useEffect, useMemo, useRef, useState } from "react"

import { fetchStreetSuggestions, useIsMounted } from "../../utils"

type StreetFieldProps = {
  value: string
  cityValue: FullCity
  setValue: (value: string) => void
  onSuggestionsChange?: (suggestions: string[]) => void
  translations?: Autocomplete["translations"]
} & ComponentProps<typeof Field>

export const StreetField = ({
  value,
  cityValue,
  setValue,
  onSuggestionsChange,
  translations,
  ...props
}: StreetFieldProps) => {
  const [streetSuggestions, setStreetSuggestions] = useState<string[]>([])
  const [suggestionStatus, setSuggestionStatus] = useState<Autocomplete["status"]>(null)

  const [currentStreetValue, setCurrentStreetValue] = useState(value)

  const isMounted = useIsMounted()

  const partialAddress = useMemo(() => ({ ...cityValue, street: currentStreetValue }), [cityValue, currentStreetValue])

  function filterDuplicatedSuggestions(suggestions: string[]) {
    return suggestions.filter(
      (suggestionStreet, index, self) => self.findIndex((street) => street === suggestionStreet) === index
    )
  }

  async function getStreets(partialAddress: FullCity & { street: string }, fromDatabase = true) {
    if (!partialAddress.street || partialAddress.street.length < 2) {
      setSuggestionStatus(null)
      setStreetSuggestions([])
      return
    }

    if (!Object.values(partialAddress).every(Boolean)) return

    if (document.activeElement?.id !== "street" && !fromDatabase) {
      debouncedGetStreets.cancel()
      debouncedGetDbStreets.cancel()
      return
    }

    setSuggestionStatus("loading")

    const { response, json } = await fetchStreetSuggestions(partialAddress, fromDatabase)

    if (!isMounted()) return

    if (!response.ok) {
      setSuggestionStatus("error")
      return
    }

    const filteredSuggestions = filterDuplicatedSuggestions(json.data?.map((dataItem) => dataItem.street) || [])

    if (!fromDatabase) {
      setStreetSuggestions((currentValue) => filterDuplicatedSuggestions([...currentValue, ...filteredSuggestions]))
    } else {
      setStreetSuggestions(filteredSuggestions)
    }

    if (!fromDatabase) setSuggestionStatus("fetched")
  }

  const debouncedGetStreets = useRef(debounce(getStreets, 500)).current
  const debouncedGetDbStreets = useRef(debounce(getStreets, 3000)).current

  useEffect(() => {
    debouncedGetStreets.cancel()
    debouncedGetDbStreets.cancel()

    debouncedGetStreets(partialAddress)
    debouncedGetDbStreets(partialAddress, false)

    return () => {
      debouncedGetStreets.cancel()
      debouncedGetDbStreets.cancel()
    }
  }, [debouncedGetDbStreets, debouncedGetStreets, partialAddress])

  useEffect(() => {
    if (onSuggestionsChange) onSuggestionsChange(streetSuggestions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streetSuggestions])

  return (
    <AutocompleteField
      required
      label="Ulica (bez numeru budynku)"
      name="street"
      currentValue={currentStreetValue}
      setCurrentValue={setCurrentStreetValue}
      inputProps={{
        id: "street",
        onFocus: () => debouncedGetDbStreets(partialAddress, false),
        ...props.inputProps,
      }}
      autocomplete={{
        status: suggestionStatus,
        values: streetSuggestions,
        onValueSelect: (value) => {
          setValue(value as string)
          setCurrentStreetValue(value as string)
        },
        valueToString: (value) => value as string,
        translations,
      }}
      {...props}
    />
  )
}
