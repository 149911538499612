import { ProviderLogo } from "@deligoo/ui"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { Card } from "@/components/Card"
import {
  selectInvalidOrdersIds,
  selectInvalidOrdersProviders,
  selectInvalidOrdersStatus,
} from "@/store/invalidOrders"
import {
  selectOrdersStatus,
  selectOrdersToReceiveIds,
  selectOrdersToReceiveProviders,
} from "@/store/orders"
import { Order } from "@/types"
import { InvalidOrder } from "@/types/InvalidOrder"
import {
  ReceiveInvalidOrderModal,
  ReceiveOrderModal,
  useReceiveInvalidOrderModal,
  useReceiveOrderModal,
} from "@/views/OrdersToReceive/partials"

import cls from "../Dashboard.module.scss"
import pizza from "../pizza.svg"
import pizzaBox from "../pizza-box.svg"

const cardClassNames = [cls.orders, cls.wide, cls.tall]

export const ReceivedOrders = () => {
  const ordersToReceiveIds = useSelector(selectOrdersToReceiveIds())
  const invalidOrdersIds = useSelector(selectInvalidOrdersIds())

  const ordersStatus = useSelector(selectOrdersStatus)
  const invalidOrdersStatus = useSelector(selectInvalidOrdersStatus)

  if (ordersStatus === "loading" || invalidOrdersStatus === "loading") {
    return <Card className={clsx(...cardClassNames)} loading />
  }

  if (ordersToReceiveIds.length) {
    return <NewOrdersToReceive ordersIds={ordersToReceiveIds} />
  }

  if (invalidOrdersIds.length) {
    return <NewInvalidOrdersToReceive ordersIds={invalidOrdersIds} />
  }

  return <NoNewOrdersToReceive />
}

const NewOrdersToReceive = ({
  ordersIds,
}: {
  ordersIds: Array<Order["id"]>
}) => {
  const providers = useSelector(selectOrdersToReceiveProviders())

  const [orderToReceiveId, setOrderToReceiveId] = useReceiveOrderModal()

  const ordersCount = ordersIds.length || 0

  const color =
    providers?.length === 1
      ? providers[0].marketplace || providers[0].provider
      : "primary"

  const history = useHistory()

  const { t } = useTranslation("dashboard")

  function handleClick() {
    if (ordersCount === 1) {
      setOrderToReceiveId(ordersIds[0])
    } else {
      history.push("/odbieranie-zlecen")
    }
  }

  const helpModal = {
    title: "Receiving orders",
    content: t("receiving_orders_desc"),
  }

  return (
    <>
      <Card color={color} className={clsx(...cardClassNames, cls.pulsating)}>
        <Card.Header className={cls.providers}>
          <ProviderLogo.List>
            {providers.map(({ provider, marketplace }) => (
              <ProviderLogo
                provider={provider}
                marketplace={marketplace}
                small={ordersCount > 1}
                key={provider + marketplace}
                light
              />
            ))}
          </ProviderLogo.List>

          <Card.Help text={t("Learn more")} modal={helpModal} />
        </Card.Header>

        <Card.Title>
          {t("You have {{count}} new orders to receive", {
            count: ordersCount,
          })}
        </Card.Title>

        <Card.Footer>
          <Card.Action onClick={handleClick}>
            {t("See orders", { count: ordersCount })}
          </Card.Action>
        </Card.Footer>
      </Card>

      {orderToReceiveId && (
        <ReceiveOrderModal
          orderId={orderToReceiveId}
          handleClose={() => setOrderToReceiveId(null)}
        />
      )}
    </>
  )
}

const NewInvalidOrdersToReceive = ({
  ordersIds,
}: {
  ordersIds: Array<InvalidOrder["id"]>
}) => {
  const providers = useSelector(selectInvalidOrdersProviders())

  const [invalidOrderId, setInvalidOrderId] = useReceiveInvalidOrderModal()

  const ordersCount = ordersIds.length || 0

  const color = providers.length === 1 ? providers[0] : "primary"

  const history = useHistory()

  const { t } = useTranslation("dashboard")

  function handleClick() {
    if (ordersCount === 1) {
      setInvalidOrderId(ordersIds[0])
    } else {
      history.push("/odbieranie-zlecen/nieprawidlowe")
    }
  }

  const helpModal = {
    title: "Receiving orders",
    content: t("receiving_orders_desc"),
  }

  return (
    <>
      <Card color={color} className={clsx(...cardClassNames, cls.pulsating)}>
        <Card.Header className={cls.providers}>
          <ProviderLogo.List>
            {providers.map((provider) => (
              <ProviderLogo
                provider={provider}
                marketplace={null}
                small={ordersCount > 1}
                key={provider}
                light
              />
            ))}
          </ProviderLogo.List>

          <Card.Help text={t("Learn more")} modal={helpModal} />
        </Card.Header>

        <Card.Title>
          {t("You have {{count}} new invalid orders", {
            count: ordersCount,
          })}
        </Card.Title>

        <Card.Footer>
          <Card.Action onClick={handleClick}>
            {t("See orders", { count: ordersCount })}
          </Card.Action>
        </Card.Footer>
      </Card>

      {invalidOrderId && (
        <ReceiveInvalidOrderModal
          orderId={invalidOrderId}
          handleClose={() => setInvalidOrderId(null)}
        />
      )}
    </>
  )
}

const NoNewOrdersToReceive = () => {
  const { t } = useTranslation("dashboard")

  const helpModal = {
    title: "Receiving orders",
    content: t("receiving_orders_desc"),
  }

  return (
    <Card color="dark" className={clsx(...cardClassNames)}>
      <Card.Header className={cls.providers}>
        <ProviderLogo provider="deligoo" marketplace={null} light />
        <Card.Help text={t("Learn more")} modal={helpModal} />
      </Card.Header>

      <Card.Title>{t("No new orders to receive")}</Card.Title>

      <img src={pizza} className={cls.pizza} alt="" />
      <img src={pizzaBox} className={cls.box} alt="" />
    </Card>
  )
}
