import { Button } from "@deligoo/ui"
import dayjs from "dayjs"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { DatePickerModal } from "./DatePickerModal"

type DatePickerProps = {
  date: Date
  handleDateChange: (date: Date | null) => void
  range?: boolean
}

const DatePicker = ({ date, handleDateChange, range }: DatePickerProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const formattedToday = dayjs().format("YYYY-MM-DD")
  const formattedDate = dayjs(date).format("YYYY-MM-DD")

  const { t } = useTranslation()

  return (
    <>
      {isModalOpen && (
        <DatePickerModal
          datePickerCalendarProps={{
            date,
            onDateChange: (newDate) => {
              handleDateChange(newDate)
              setModalOpen(false)
            },
          }}
          handleClose={() => setModalOpen(false)}
        />
      )}

      <Button
        className="text-dark"
        color="muted"
        variation="outlined"
        size="extra-large"
        iconProps={{ name: "calendar", color: "primary" }}
        onClick={() => setModalOpen(true)}
      >
        {formattedDate === formattedToday ? t("today") : formattedDate}
      </Button>
    </>
  )
}

export { DatePicker }
