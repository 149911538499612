import { Button, Modal } from "@deligoo/ui"
import { useTranslation } from "react-i18next"

import { OrderPoint } from "@/types"
import { downloadBase64File } from "@/utils"

import cls from "./OrderDetailsModal.module.scss"

type SignatureModalProps = {
  signature: OrderPoint["authorization_signature"] | undefined
  handleClose: () => void
}

export const SignatureModal = ({
  signature,
  handleClose,
}: SignatureModalProps) => {
  const { t } = useTranslation("orders")

  if (!signature) return null

  return (
    <Modal className={cls.signatureModal} handleClose={handleClose}>
      <Modal.Title>{t("signature_modal.customer_signature")}</Modal.Title>

      <Modal.Content>
        <img
          src={signature.data}
          alt={signature.filename || ""}
          className={cls.signatureImage}
        />
      </Modal.Content>

      <Modal.Footer className={cls.signatureDownload}>
        <Button
          fullWidth
          color="primary"
          onClick={() => downloadBase64File(signature)}
        >
          {t("signature_modal.download")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
