const statistics = {
  today: "Today",
  yesterday: "Yesterday",
  this_week: "This week",
  last_week: "Last week",
  this_month: "This month",
  last_month: "Last month",
  date_range: "Date range",
  completion_under_60: "Orders up to 60 minutes",
  completion_over_60: "Orders above 60 minutes",
  total_orders: "Total orders ",
  orders_with_delivery_failed: "Failed deliveries",
  average_preparation_time_duration: "Average preparation time",
  average_daily_orders_count: "Average daily orders count",
  average_estimated_delivery_time_duration: "Average delivery time",
}

export default statistics
