import { Button, Spinner } from "@deligoo/ui"
import clsx from "clsx"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { batch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { useAppDispatch } from "@/App"
import {
  selectCurrentOrderFormOrder,
  setOrderFormStep,
} from "@/store/orderForm"
import {
  selectCanRestaurantCreateOrders,
  selectCurrentRestaurantKey,
  selectIsBlockedFromCreatingOrders,
  selectIsHolidayOrMaintenanceModeEnabled,
  setLockedAccountModalState,
} from "@/store/restaurant"
import { selectStatisticsData } from "@/store/statistics"
import { canBeCanceled, downloadOrderLabel, useIsMounted } from "@/utils"
import { CancelOrder } from "@/views/Orders/partials/actions" // TODO: Move to components?

import formCls from "../OrderForm.module.scss"
import cls from "./Summary.module.scss"
import { SummaryState } from "./SummaryState"

const Summary = () => {
  const dispatch = useAppDispatch()

  const canRestaurantCreateOrders = useSelector(selectCanRestaurantCreateOrders)

  const isBlockedFromCreatingOrders = useSelector(
    selectIsBlockedFromCreatingOrders
  )

  const interfaceType = useSelector(
    selectCurrentRestaurantKey("interface_type")
  )

  const order = useSelector(selectCurrentOrderFormOrder)
  const statistics = useSelector(selectStatisticsData)
  const totalOrders = statistics?.total_orders_count

  const canCancelOrders = useSelector(
    selectCurrentRestaurantKey("can_cancel_orders")
  )

  const isHolidayOrMaintenanceModeEnabled = useSelector(
    selectIsHolidayOrMaintenanceModeEnabled
  )

  const [isCancelModalOpen, setCancelModalOpen] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)

  const history = useHistory()

  const isMounted = useIsMounted()

  const { t } = useTranslation("createOrder")

  function createNewOrder() {
    if (isBlockedFromCreatingOrders) {
      dispatch(setLockedAccountModalState(true))
    } else {
      batch(() => {
        dispatch(setOrderFormStep(null))
        dispatch(setOrderFormStep("contact_detail"))
      })
    }
  }

  function goToOrdersList() {
    dispatch(setOrderFormStep(null))
    history.push("/zlecenia")
  }

  if (!order) {
    return (
      <div className={clsx(formCls.step, formCls.main)}>
        <Spinner size="extra-large" className={formCls.spinner} />
      </div>
    )
  }

  return (
    <div className={formCls.step}>
      <div className={cls.summary}>
        <SummaryState order={order} totalOrders={totalOrders} />
      </div>

      <div className={formCls.actions}>
        {canCancelOrders && canBeCanceled(order) && (
          <>
            <Button
              onClick={() => setCancelModalOpen(true)}
              color="alert"
              size="extra-large"
              type="button"
              fullWidth
            >
              {t("buttons.cancel_order")}
            </Button>

            {isCancelModalOpen && (
              <CancelOrder
                orderId={order.id}
                handleClose={() => setCancelModalOpen(false)}
                isParentMounted={isMounted}
                isActionInProgress={isCancelling}
                setIsActionInProgress={setIsCancelling}
              />
            )}
          </>
        )}

        {(interfaceType === "inpost" || order?.has_label) && (
          <Button
            onClick={() => order && downloadOrderLabel(order.id)}
            className={cls.downloadLabel}
            disabled={!order?.has_label}
            variation="accented"
            size="extra-large"
            color="primary"
            type="button"
            fullWidth
          >
            {t("buttons.download_label")}
          </Button>
        )}

        <Button
          onClick={goToOrdersList}
          color="primary"
          variation="accented"
          size="extra-large"
          type="button"
          fullWidth
        >
          {t("buttons.return_to_orders")}
        </Button>

        {!isHolidayOrMaintenanceModeEnabled && canRestaurantCreateOrders && (
          <Button
            onClick={createNewOrder}
            color="success"
            size="extra-large"
            type="button"
            fullWidth
          >
            {t("buttons.create_order")}
          </Button>
        )}
      </div>
    </div>
  )
}

export { Summary }
