import { Icon } from "@deligoo/ui"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { Card } from "@/components/Card"
import {
  selectStatisticsData,
  selectStatisticsStatus,
} from "@/store/statistics"

import cls from "../Dashboard.module.scss"

function getColor(count = 0) {
  return count < 3 ? "success" : count < 7 ? "warning" : "alert"
}

const PaymentsCard = () => {
  const statistics = useSelector(selectStatisticsData)
  const statisticsStatus = useSelector(selectStatisticsStatus)

  const { t } = useTranslation("dashboard")

  const paymentsCount = statistics?.orders_unsettled_count
  const className = clsx(cls.payments, cls.wide)

  const history = useHistory()

  if (paymentsCount) {
    return (
      <Card
        color={getColor(paymentsCount)}
        loading={paymentsCount === null}
        className={className}
        inline
      >
        <Card.Header>
          <Card.Title>
            {t("{{count}} payments", { count: paymentsCount })}
          </Card.Title>
        </Card.Header>
        {t("Payments unsettled or awaiting confirmation")}
        <Card.Footer>
          <Card.Action iconOnly onClick={() => history.push("/platnosci")}>
            <Icon name="cash" />
          </Card.Action>
        </Card.Footer>
      </Card>
    )
  }

  return (
    <Card
      color="dark"
      className={className}
      loading={!statisticsStatus || statisticsStatus === "loading"}
    >
      <Card.Title className={cls.title}>
        {t("No unsettled payments")}
      </Card.Title>

      <img
        src={require("../credit-card.svg").default}
        alt=""
        className={cls.card}
      />
    </Card>
  )
}

export { PaymentsCard }
