import { Icon } from "@deligoo/ui"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Card } from "@/components/Card"
import { selectCanRestaurantReceiveOrders } from "@/store/restaurant"
import {
  selectStatisticsData,
  selectStatisticsStatus,
} from "@/store/statistics"

import cls from "../Dashboard.module.scss"

const Summary = () => {
  const statistics = useSelector(selectStatisticsData)
  const statisticsStatus = useSelector(selectStatisticsStatus)
  const canReceiveOrders = useSelector(selectCanRestaurantReceiveOrders)

  const { t } = useTranslation("dashboard")

  return (
    <Card
      sections
      className={clsx(cls.wide, canReceiveOrders && cls.tall)}
      loading={!statisticsStatus || statisticsStatus === "loading"}
    >
      <Card.Section className={cls.summaryItem}>
        <Icon name="overdues" color="primary" className={cls.icon} />
        {t("Pending deliveries")}
        <span>{statistics?.orders_with_delivery_pending_count}</span>
      </Card.Section>
      <Card.Section className={cls.summaryItem}>
        <Icon name="box-config" color="primary" className={cls.icon} />
        {t("Deliveries in progress")}
        <span>{statistics?.orders_with_delivery_in_progress_count}</span>
      </Card.Section>

      {canReceiveOrders && (
        <>
          <Card.Section className={cls.summaryItem}>
            <Icon name="box-check" color="primary" className={cls.icon} />
            {t("Orders received by clients")}
            <span>{statistics?.orders_received_by_client_count}</span>
          </Card.Section>
          <Card.Section className={cls.summaryItem}>
            <Icon name="boxes" color="primary" className={cls.icon} />
            {t("Total deliveries")}
            <span>{statistics?.orders_with_delivery_count}</span>
          </Card.Section>
        </>
      )}
    </Card>
  )
}

export { Summary }
