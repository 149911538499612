import { Icon, Spinner } from "@deligoo/ui"
import dayjs from "dayjs"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { Table } from "@/components/Table"
import {
  getOrders,
  selectFilteredOrdersIds,
  selectOrderById,
  selectOrdersStatus,
} from "@/store/orders"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { Order, OrderState } from "@/types"
import { formatHour } from "@/utils"
import {
  hasFinished,
  hasOrderBeenPublished,
  isWithoutLogistics,
} from "@/utils/order"

import cls from "../Orders.module.scss"
import { OrderDetailsModal, useDetailsModal } from "./OrderDetailsModal"

type OrdersDetailsProps = {
  date: string
  filters: Array<(order: Order) => boolean>
}

const OrdersDetails = ({ date, filters }: OrdersDetailsProps) => {
  const ordersStatus = useSelector(selectOrdersStatus)
  const currentRestaurantId = useSelector(selectCurrentRestaurantKey("id"))

  const [currentOrderDetailsId, setCurrentOrderDetailsId] = useDetailsModal()

  const ordersIds = useSelector(selectFilteredOrdersIds(...filters))

  const dispatch = useDispatch()

  const { t } = useTranslation("orders")

  useEffect(() => {
    if (currentRestaurantId) dispatch(getOrders(dayjs(date)))
  }, [dispatch, currentRestaurantId, date])

  return (
    <Table columns={"1fr 1fr 1fr 1fr 1fr 0.5fr"}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>{t("headers.order_uid")}</Table.Th>
          <Table.Th>{t("headers.created_at")}</Table.Th>
          <Table.Th>{t("headers.pickup_at")}</Table.Th>
          <Table.Th>{t("headers.phone")}</Table.Th>
          <Table.Th>{t("headers.distance")}</Table.Th>
          <Table.Th>{t("headers.status")}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody loading={ordersStatus === "loading"}>
        {(ordersStatus === "fetched" || ordersStatus === "updating") &&
          ordersIds &&
          ordersIds.map((orderId) => (
            <OrderRow
              key={orderId}
              orderId={orderId}
              setCurrentOrderDetailsId={setCurrentOrderDetailsId}
            />
          ))}
      </Table.Tbody>

      {(ordersStatus === "fetched" || ordersStatus === "updating") &&
        ordersIds &&
        currentOrderDetailsId && (
          <OrderDetailsModal
            orderId={currentOrderDetailsId}
            handleClose={() => setCurrentOrderDetailsId(null)}
          />
        )}
    </Table>
  )
}

type OrderRowProps = {
  orderId: Order["id"]
  setCurrentOrderDetailsId: (orderId: Order["id"]) => void
}

const OrderRow = ({ orderId, setCurrentOrderDetailsId }: OrderRowProps) => {
  const order = useSelector(selectOrderById(orderId))

  if (!order) return null

  return (
    <Table.Tr key={order.id} onClick={() => setCurrentOrderDetailsId(order.id)}>
      <Table.Td>
        #{order.uid}
        {order.ordinal_number ? (
          <span className="ml-1">({order.ordinal_number})</span>
        ) : (
          <Spinner className="ml-1" size="small" inline />
        )}
      </Table.Td>
      <Table.Td>{formatHour(order.created_at)}</Table.Td>
      <Table.Td>
        <PickupTime order={order} />
      </Table.Td>
      <Table.Td className={cls.driver}>
        {order.delivery?.phone || order.pickup?.phone}
      </Table.Td>
      <Table.Td>{order.distance} km</Table.Td>
      <Table.Td>
        <OrderStateIcon state={order.state} />
      </Table.Td>
    </Table.Tr>
  )
}

function getPickupTime(order: Order) {
  if (isWithoutLogistics(order)) {
    return order.pickup_at
  }

  if (hasOrderBeenPublished(order.state)) {
    return order.pickup?.estimated_time
  }

  return false
}

const PickupTime = ({ order }: { order: Order }) => {
  const pickupTime = getPickupTime(order)

  return pickupTime ? (
    <>{formatHour(pickupTime)}</>
  ) : !hasFinished(order.state) ? (
    <Spinner size="large" inline />
  ) : null
}

const OrderStateIcon = ({ state }: { state: OrderState }) => {
  switch (state) {
    case "finished":
      return <Icon name="check-circle" color="success" />
    case "canceled":
    case "failed":
      return <Icon name="block" color="alert" />
    default:
      return <Spinner size="large" />
  }
}

export { OrdersDetails }
