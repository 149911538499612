import clsx from "clsx"
import { useEffect } from "react"
import { createPortal } from "react-dom"

import cls from "./Backdrop.module.scss"

export type BackdropProps = React.HTMLAttributes<HTMLDivElement> & {
  transparent?: boolean
  light?: boolean
}

export const Backdrop = ({
  transparent,
  light,
  className,
  children,
  ...props
}: BackdropProps & JSX.IntrinsicElements["div"]): React.ReactPortal => {
  useEffect(() => {
    document.body.style.overflowY = "hidden"

    return () => {
      document.body.style.overflowY = ""
    }
  }, [])

  return createPortal(
    <div className={clsx(cls.backdrop, transparent && cls.transparent, light && cls.light, className)} {...props}>
      {children}
    </div>,
    document.body
  )
}
