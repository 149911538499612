const getApps = {
  pos_app: "Aplikacja DeliGoo POS",

  step: "Krok {{count}}",

  step_1_title: "Obejrzyj wideo instruktażowe",
  step_1_description:
    "Sprawdź krok po kroku jak nadać uprawnienia i zainstalować aplikację.",
  step_1_cta: "Obejrzyj",

  step_2_title: "Pobierz aplikacje DeliGoo POS",
  step_2_description:
    "Aplikacja panelu zleceń przeznaczona na urządzenia z systemem Android.",
  step_2_cta: "Pobierz",

  step_3_title: "AnyDesk zdalne wsparcie",
  step_3_description:
    "Aplikacja do zdalnego zarządzania urządzeniem na wypadek potrzeby kontaktu z supportem.",
  step_3_cta: "Przejdź",
}

export default getApps
