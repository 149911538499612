import { Button, Icon } from "@deligoo/ui"
import Tippy from "@tippyjs/react"
import clsx from "clsx"
import dayjs, { Dayjs } from "dayjs"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Instance as TippyInstance } from "tippy.js"
import useAsyncEffect from "use-async-effect"

import { Card } from "@/components/Card"
import {
  selectCurrentRestaurantKey,
  selectIsAppAlertHidden,
} from "@/store/restaurant"
import { DataStatus, Statistics as IStatistics } from "@/types"
import { fetchStatistics } from "@/utils"

import cls from "./Statistics.module.scss"
import { StatisticsCards } from "./StatisticsCards"

type Range = {
  label: string
  icon:
    | "today"
    | "yesterday"
    | "this-week"
    | "last-week"
    | "this-month"
    | "last-month"
  start: Dayjs
  end: Dayjs
}

const Statistics = () => {
  const today = dayjs()

  const { t } = useTranslation("statistics")

  const ranges: Range[] = useMemo(
    () => [
      { label: t("today"), icon: "today", start: today, end: today },
      {
        label: t("yesterday"),
        icon: "yesterday",
        start: today.subtract(1, "day"),
        end: today.subtract(1, "day"),
      },
      {
        label: t("this_week"),
        icon: "this-week",
        start: today.startOf("week"),
        end: today,
      },
      {
        label: t("last_week"),
        icon: "last-week",
        start: today.subtract(1, "week").startOf("week"),
        end: today.subtract(1, "week").endOf("week"),
      },
      {
        label: t("this_month"),
        icon: "this-month",
        start: today.startOf("month"),
        end: today,
      },
      {
        label: t("last_month"),
        icon: "last-month",
        start: today.subtract(1, "month").startOf("month"),
        end: today.subtract(1, "month").endOf("month"),
      },
    ],
    [t, today]
  )

  const currentRestaurantId = useSelector(selectCurrentRestaurantKey("id"))

  const isAlertRead = useSelector(selectIsAppAlertHidden)

  const [statistics, setStatistics] = useState<IStatistics | null>(null)
  const [statisticsStatus, setStatisticsStatus] = useState<DataStatus | null>(
    null
  )
  const [currentRange, setCurrentRange] = useState(ranges[0])

  const [tippyInstance, setTippyInstance] = useState<TippyInstance | null>(null)

  useAsyncEffect(
    async (isMounted) => {
      if (statistics) {
        setStatisticsStatus("updating")
      } else {
        setStatisticsStatus("loading")
      }

      const { response, json } = await fetchStatistics(
        currentRange.start,
        currentRange.end
      )

      if (!isMounted()) return

      if (response.ok && json.data) {
        setStatistics(json.data)
        setStatisticsStatus("fetched")
      } else {
        setStatisticsStatus("error")
      }
    },
    [currentRange, currentRestaurantId]
  )

  return (
    <div className="page-content">
      <div className={clsx(cls.tiles, !isAlertRead && cls.dense)}>
        <div className={cls.nav}>
          <Tippy
            className={cls.navDropdown}
            theme="light"
            trigger="click"
            placement="bottom"
            interactive
            onCreate={(instance) => setTippyInstance(instance)}
            content={
              <div className={cls.navButtons}>
                {ranges.map((range) => (
                  <Button
                    className={cls.navButton}
                    iconProps={{ name: range.icon }}
                    onClick={() => {
                      setCurrentRange(range)
                      tippyInstance?.hide()
                    }}
                    key={range.label}
                    color={
                      currentRange.label === range.label ? "primary" : undefined
                    }
                  >
                    {range.label}
                  </Button>
                ))}
              </div>
            }
          >
            <button type="button">
              <Card color="dark" className={cls.navCard}>
                <Card.Header>
                  <Card.Title>
                    <Icon
                      name="calendar"
                      color="primary"
                      className={cls.icon}
                    />
                    {currentRange.label}
                    <Icon
                      name={"chevron-tiny-down"}
                      color="muted"
                      size={"tiny"}
                      gutter="left"
                    />
                  </Card.Title>
                </Card.Header>
                {t("date_range")}
              </Card>
            </button>
          </Tippy>
        </div>

        <StatisticsCards
          isLoading={
            statisticsStatus === "loading" || statisticsStatus === "updating"
          }
          statistics={statistics}
        />
      </div>
    </div>
  )
}

export { Statistics }
