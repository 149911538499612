export const SITES = [
  {
    id: 1,
    name: "Production",
    protocol: "https",
    appHost: "app.deligoo.pl",
    appPort: null,
    apiHost: "api.deligoo.pl",
    apiPort: null,
    wsHost: "websocket2.deligoo.pl",
    wsPort: null,
  },
  {
    id: 2,
    name: "UAT",
    protocol: "https",
    appHost: "uat-app.deligoo.pl",
    appPort: null,
    apiHost: "uat-api.deligoo.pl",
    apiPort: null,
    wsHost: "uat-websocket.deligoo.pl",
    wsPort: null,
  },
  {
    id: 3,
    name: "Staging",
    protocol: "https",
    appHost: "staging-app.deligoo.pl",
    appPort: null,
    apiHost: "staging-api.deligoo.pl",
    apiPort: null,
    wsHost: "staging-websocket.deligoo.pl",
    wsPort: null,
  },
  {
    id: 4,
    name: "Localhost",
    protocol: "http",
    appHost: "localhost",
    appPort: 3000,
    apiHost: "localhost",
    apiPort: 3001,
    wsHost: "localhost",
    wsPort: 8080,
  },
  {
    id: 5,
    name: "Preproduction",
    protocol: "https",
    appHost: "preproduction-app.deligoo.pl",
    appPort: null,
    apiHost: "preproduction-api.deligoo.pl",
    apiPort: null,
    wsHost: "preproduction-websocket.deligoo.pl",
    wsPort: null,
  },
  {
    id: 6,
    name: "Sandbox",
    protocol: "https",
    appHost: "sandbox-app.deligoo.pl",
    appPort: null,
    apiHost: "sandbox-api.deligoo.pl",
    apiPort: null,
    wsHost: "sandbox-websocket.deligoo.pl",
    wsPort: null,
  },
] as const

export type Site = typeof SITES[number]
