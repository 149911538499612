import { Badge, ProviderLogo } from "@deligoo/ui"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { DeliveryMethod } from "@/components/DeliveryMethod"
import { PaymentForm } from "@/components/PaymentForm"
import { Price } from "@/components/Price"
import { Table } from "@/components/Table"
import {
  selectInvalidOrderById,
  selectInvalidOrdersStatus,
} from "@/store/invalidOrders"
import { Order } from "@/types"

import cls from "./OrdersToReceiveList.module.scss"
import {
  ReceiveInvalidOrderModal,
  useReceiveInvalidOrderModal,
} from "./ReceiveInvalidOrderModal"

type InvalidOrdersListProps = {
  ordersIds: Array<Order["id"]>
}

export const InvalidOrdersList = ({ ordersIds }: InvalidOrdersListProps) => {
  const ordersStatus = useSelector(selectInvalidOrdersStatus)

  const [invalidOrderId, setInvalidOrderId] = useReceiveInvalidOrderModal()

  const { t } = useTranslation("orders")

  return (
    <div className={cls.ordersToReceiveList}>
      <header className={cls.header}>
        <h1 className={cls.title}>{t("headers.invalid_orders")}</h1>
      </header>

      <Table columns={"minmax(250px, 1fr) 200px 250px 250px 250px"}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t("headers.order_date")}</Table.Th>
            <Table.Th>{t("headers.provider")}</Table.Th>
            <Table.Th>{t("headers.delivery_method")}</Table.Th>
            <Table.Th>{t("headers.payment_method")}</Table.Th>
            <Table.Th>{t("headers.status")}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody loading={ordersStatus === "loading"}>
          {(ordersStatus === "fetched" || ordersStatus === "updating") &&
            ordersIds &&
            ordersIds.map((orderId) => (
              <OrderRow
                key={orderId}
                orderId={orderId}
                setActiveInvalidOrderId={setInvalidOrderId}
              />
            ))}
        </Table.Tbody>
      </Table>

      {invalidOrderId && (
        <ReceiveInvalidOrderModal
          orderId={invalidOrderId}
          handleClose={() => setInvalidOrderId(null)}
        />
      )}
    </div>
  )
}

type OrderRowProps = {
  orderId: Order["id"]
  setActiveInvalidOrderId: (orderId: Order["id"]) => void
}

const OrderRow = ({ orderId, setActiveInvalidOrderId }: OrderRowProps) => {
  const order = useSelector(selectInvalidOrderById(orderId))

  const { t } = useTranslation("orders")

  if (!order) return null

  return (
    <Table.Tr key={order.id} onClick={() => setActiveInvalidOrderId(order.id)}>
      <Table.Td>
        <div className="h300 mb-2">#{order.id}</div>
        <div className="text-medium text-primary">
          {dayjs(order.created_at).format("DD.MM.YY HH:mm")}
        </div>
      </Table.Td>
      <Table.Td>
        <ProviderLogo provider={order.data.provider} marketplace={null} />
      </Table.Td>
      <Table.Td>
        <DeliveryMethod
          deliveryMethod={order.data.delivery_method}
          deliveryAt={order.data.delivery_at}
          pickupAt={order.data.pickup_at}
        />
      </Table.Td>
      <Table.Td>
        <div className="mb-2">
          <Price subunit>{order.data.price_subunit}</Price>
        </div>
        <PaymentForm paymentForm={order.data.payment_form} iconSide="left" />
      </Table.Td>
      <Table.Td>
        <Badge color="alert">{t("headers.invalid")}</Badge>
      </Table.Td>
    </Table.Tr>
  )
}
