import { useTranslation } from "react-i18next"

import { Card } from "@/components/Card"
import { Statistics } from "@/types"

type TotalOrdersProps = {
  loading: boolean
  value?: Statistics["total_orders_count"]
  className?: string
}

const TotalOrders = ({ loading, value, className }: TotalOrdersProps) => {
  const { t } = useTranslation("statistics")

  return (
    <Card loading={loading} className={className}>
      <Card.Header>
        <Card.Title color="success">{value}</Card.Title>
      </Card.Header>
      {t("total_orders")}
    </Card>
  )
}

export { TotalOrders }
