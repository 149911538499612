import dayjs, { Dayjs } from "dayjs"
import { FocusEvent } from "react"
import { shallowEqual } from "react-redux"

import { Site, SITES } from "@/sites"
import { TimeDuration } from "@/types"

export * from "./errors"
export * from "./fetch"
export * from "./hooks"
export * from "./masks"
export * from "./order"
export * from "./print"
export * from "./restaurant"
export * from "./sounds"

export function formatTimeDuration(time: TimeDuration) {
  let formattedTime = ""

  if (time.hours) formattedTime += `${time.hours} godz. `
  if (time.minutes) formattedTime += `${time.minutes} min `
  if (time.seconds || !formattedTime) formattedTime += `${time.seconds} sek.`

  return formattedTime.trim()
}

export function formatHour(timestamp: string | Dayjs) {
  return dayjs(timestamp).format("HH:mm")
}

export function updateCollectionItemById<T extends { id: number }>(
  collection: T[],
  updatedItem: T
) {
  const index = collection.findIndex((el) => el.id === updatedItem.id)
  if (index !== -1) collection[index] = updatedItem
}

export function deleteCollectionItemById<T extends { id: number }>(
  collection: T[],
  itemToDeleteId: T["id"]
) {
  return collection.filter((el) => el.id !== itemToDeleteId)
}

export function isToday(date: Dayjs | Date | string) {
  return dayjs(date).isToday()
}

export function roundToTwoDecimals(num: number) {
  return Number(num.toFixed(2))
}

export function formatPrice(
  price?: number | string | null,
  options?: { subunit?: boolean }
) {
  if (!isTruthyOrZero(price)) return ""

  return options?.subunit
    ? (Number(price) / 100).toFixed(2)
    : Number(price).toFixed(2)
}

export function sumFullPrices<
  T extends Array<{ full_price_subunit: number }> | null
>(collection: T) {
  if (!collection) return 0

  return collection.reduce((prev, curr) => prev + curr.full_price_subunit, 0)
}

// Two decimal places, "," or "." as a separator
export const validDecimalRegex = /^\d+(\.|,)?\d?\d?$/

export function handlePriceInputBlur(e: FocusEvent<HTMLInputElement>) {
  const safeValue = e.currentTarget.value.replace(/[^0-9.,]/g, "")
  e.currentTarget.value = formatPrice(safeValue)
}

export function isTruthyOrZero(value: unknown) {
  return Boolean(value || value === 0)
}

export function debounce(
  callback: (...args: unknown[]) => unknown,
  timeoutInMs: number
) {
  let timerId: number

  return () => {
    window.clearTimeout(timerId)
    timerId = window.setTimeout(callback, timeoutInMs)
  }
}

export function getDateFromTime(time: string, date?: string) {
  const [h, m] = time.split(":")

  return dayjs(date).set("h", Number(h)).set("m", Number(m))
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function isProductionEnvironment() {
  return process.env.REACT_APP_ENVIRONMENT === "production"
}

export function isSiteValid(siteToCheck: unknown): siteToCheck is Site {
  return SITES.some((site) => shallowEqual(site, siteToCheck))
}

export function getDefaultSite() {
  const defaultSiteId = isProductionEnvironment() ? 1 : 3 // production or staging depending on env

  const lsSite = JSON.parse(localStorage.getItem("site")!)
  if (lsSite && isSiteValid(lsSite)) return lsSite

  return SITES.find((site) => site.id === defaultSiteId)
}

export function escapeRegex(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

export function sortByCreationTime(
  a: { created_at: string },
  b: { created_at: string }
) {
  return a.created_at < b.created_at ? -1 : 1
}

export async function convertFileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onloadend = () => {
      if (!reader.result) return
      const base64string = reader.result.toString()
      const base64 = base64string.substr(base64string.indexOf(",") + 1)
      resolve(base64)
    }

    reader.onerror = (err) => {
      reject(err)
    }
  })
}

export function downloadBase64File(file: { data: string; filename: string }) {
  const a = document.createElement("a")
  a.href = file.data
  a.download = file.filename
  a.click()
}

export function priceToSubunit(price: string | number) {
  return Math.round(Number(price) * 100)
}

export const ORDER_UID_REGEX = /^#?\d{7}$/
