import { Button, Icon, Modal } from "@deligoo/ui"
import { useTranslation } from "react-i18next"

import { useAppSelector } from "@/App"

export const HolidayOrMaintenanceModeModal = ({
  handleClose,
}: {
  handleClose: () => void
}) => {
  const isHolidayModeEnabled = useAppSelector(
    (state) => state.restaurant.current?.team_holiday_mode
  )
  const isMaintenanceModeEnabled = useAppSelector(
    (state) => state.restaurant.current?.maintenance
  )

  const { t } = useTranslation()

  return (
    <Modal handleClose={handleClose} className="text-center">
      <Modal.Content>
        <Icon name="warnings" color="alert" size="extra-large" />
        {isHolidayModeEnabled && (
          <div className="h400 mt-4 mb-2">
            {t("alerts.holiday_mode_enabled")}
          </div>
        )}
        {isMaintenanceModeEnabled && (
          <div className="h400 mt-4 mb-2">
            {t("alerts.maintenance_mode_enabled")}
          </div>
        )}
        <div className="mb-10">{t("alerts.creating_orders_disabled")}</div>

        <Button color="primary" size="extra-large" onClick={handleClose}>
          {t("buttons.ok")}
        </Button>
      </Modal.Content>
    </Modal>
  )
}
