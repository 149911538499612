import clsx from "clsx"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  selectCanRestaurantReceiveOrders,
  selectCurrentRestaurantKey,
} from "@/store/restaurant"
import { getStatistics } from "@/store/statistics"
import { useRemoveToastOnMount } from "@/utils"

import cls from "./Dashboard.module.scss"
import {
  DeliveriesValue,
  DistanceLimit,
  Integrations,
  Mode,
  PaymentsCard,
  PickupTime,
  PricingPolicy,
  ReceivedOrders,
  Summary,
  TotalOrders,
  TrafficLoad,
  TrafficRate,
  WorkingHours,
} from "./partials"

const SHOP_ONLY_CARDS = [
  Summary,
  PaymentsCard,
  WorkingHours,
  TotalOrders,
  Mode,
  PickupTime,
  DeliveriesValue,
  TrafficLoad,
  PricingPolicy,
  DistanceLimit,
  TrafficRate,
]

const CAN_RECEIVE_ORDERS_CARDS = [
  ReceivedOrders,
  PaymentsCard,
  WorkingHours,
  Integrations,
  PricingPolicy,
  DistanceLimit,
  Mode,
  TrafficLoad,
  PickupTime,
  DeliveriesValue,
  Summary,
  TrafficRate,
]

const Dashboard = () => {
  const alert = useSelector(selectCurrentRestaurantKey("alert"))
  const currentRestaurantId = useSelector(selectCurrentRestaurantKey("id"))
  const canReceiveOrders = useSelector(selectCanRestaurantReceiveOrders)

  const cards = canReceiveOrders ? CAN_RECEIVE_ORDERS_CARDS : SHOP_ONLY_CARDS
  const dispatch = useDispatch()

  useRemoveToastOnMount("newOrderToReceive")

  useEffect(() => {
    if (currentRestaurantId) dispatch(getStatistics())
  }, [dispatch, currentRestaurantId])

  return (
    <div className="page-content">
      <div className={clsx(cls.tiles, alert && cls.dense)}>
        {cards.filter(Boolean).map((Card, index) => (
          <Card key={index} />
        ))}
      </div>
    </div>
  )
}

export { Dashboard }
