import clsx from "clsx"

import cls from "./RestaurantStatus.module.scss"

type RestaurantStatusProps = {
  status?: boolean
  locked?: boolean
}

const RestaurantStatus = ({
  status = false,
  locked = false,
}: RestaurantStatusProps) => {
  return (
    <span
      className={clsx(
        "ml-3",
        cls.status,
        locked && cls.locked,
        !locked && status && cls.online
      )}
    />
  )
}

export { RestaurantStatus }
