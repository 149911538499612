const complaint = {
  Complaints: "Reklamacje",
  "Fill a complaint form": "Wypełnij formularz reklamacyjny",
  "Drag here or": "Przeciągnij tutaj lub",
  "choose files": "wybierz pliki",
  city: "Miasto",
  client: "Nazwa lokalizacji",
  email: "Adres e-mail",
  order_uid: "Numer zlecenia",
  date: "Data",
  description: "Opis zdarzenia",
  location: "Nazwa lokalizacji",
  complaint_reason: "Powód reklamacji",
  attachments: "Załączniki",
  Send: "Wyślij",
  "Form has been sent": "Formularz został wysłany",
  "Failed to send a form": "Nie udało się wysłać formularza",
  "Field is required": "Pole jest wymagane",
  order_uid_pattern:
    "Wprowadź 7-cyfrowy numer zlecenia np: #1234567 lub 1234567",
  "Select complaint reason": "Wybierz powód reklamacji",
  courier_late: "spóźnienie kuriera powyżej 15 minut",
  order_cancelled: "anulowanie zlecenia przez kuriera",
  parcel_damaged: "zniszczenie przesyłki",
  pickup_time_changed: "zmiana czasu odbioru przesyłki",
  parcel_lost: "zagubiona przesyłka",
  rude_courier: "złe zachowanie kuriera",
  other: "inne",
}

export default complaint
