import { Address, ContactDetail } from "@deligoo/shared"
import {
  Button,
  CityField,
  fetchFindPostalCode,
  Field,
  Icon,
  StreetField,
} from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import { debounce } from "lodash"
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Rifm } from "rifm"

import { useAppDispatch } from "@/App"
import { CloseButton } from "@/components/CloseButton"
import {
  acceptInvalidOrder,
  selectInvalidOrderById,
} from "@/store/invalidOrders"
import { Order } from "@/types"
import { InvalidOrder } from "@/types/InvalidOrder"
import {
  formatPhone,
  formatPostalCode,
  handleStandardError,
  useIsMounted,
} from "@/utils"

import cls from "./EditOrderAddressModal.module.scss"

function getFormatedContactDetails(details?: Partial<ContactDetail>) {
  if (!details) return {}

  return {
    ...details,
    phone: formatPhone(details.phone || ""),
    postal_code: formatPostalCode(details.postal_code || ""),
  }
}

type InvalidOrderModalProps = {
  orderId: Order["id"]
  onReturnClick: () => void
  handleClose: () => void
}

export const EditOrderAddressModal = ({
  orderId,
  onReturnClick,
  handleClose,
}: InvalidOrderModalProps) => {
  const order = useSelector(selectInvalidOrderById(orderId))

  const formMethods = useForm<InvalidOrder["data"]>({
    defaultValues: {
      ...order?.data,
      customer_contact_detail_attributes: getFormatedContactDetails(
        order?.data.customer_contact_detail_attributes
      ),
      client_contact_detail_attributes: getFormatedContactDetails(
        order?.data.client_contact_detail_attributes
      ),
    },
    mode: "all",
  })

  const { handleSubmit } = formMethods

  const [currentTab, setCurrentTab] = useState<"form" | "json">("form")
  const [isActionInProgress, setIsActionInProgress] = useState(false)

  const dispatch = useAppDispatch()
  const isMounted = useIsMounted()
  const history = useHistory()

  const { t } = useTranslation("orders")

  if (!order) return null

  async function acceptOrder(formData: InvalidOrder["data"]) {
    const body = {
      ...order?.data,
      ...formData,
    }

    setIsActionInProgress(true)

    const { data: orderToReceive } = await dispatch(
      acceptInvalidOrder({ id: orderId, body })
    ).then(unwrapResult)

    if (isMounted() && orderToReceive) {
      setIsActionInProgress(false)

      history.push({
        pathname: "/odbieranie-zlecen",
        state: {
          orderId: orderToReceive.id,
        },
      })
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(acceptOrder)} className={cls.receiveOrder}>
        <header className={cls.header}>
          <h1 className={cls.title}>
            {t("invalid_orders_modal.headers.update_address")}
          </h1>

          <Button
            onClick={() => setCurrentTab("form")}
            color={currentTab === "form" ? "light" : "dark"}
            className="mr-3 ml-10"
          >
            {t("invalid_orders_modal.headers.form")}
          </Button>

          <Button
            onClick={() => setCurrentTab("json")}
            color={currentTab === "json" ? "light" : "dark"}
          >
            {t("invalid_orders_modal.headers.source_data")}
          </Button>

          <CloseButton onClick={handleClose} className="ml-auto" />
        </header>

        <div className={cls.content}>
          <div className={cls.main}>
            {currentTab === "json" && (
              <pre className={cls.json}>
                {JSON.stringify(order.data, null, 2)}
              </pre>
            )}

            {currentTab === "form" && (
              <>
                {order.data.client_contact_detail_attributes && (
                  <fieldset>
                    <h3 className="text-dark">
                      {t("invalid_orders_modal.headers.pickup_data")}
                    </h3>
                    <ContactDetailsForm
                      details="client_contact_detail_attributes"
                      defaultValue={order.data.client_contact_detail_attributes}
                    />
                  </fieldset>
                )}

                {order.data.customer_contact_detail_attributes && (
                  <fieldset>
                    <h3 className="text-dark">
                      {t("invalid_orders_modal.headers.delivery_data")}
                    </h3>
                    <ContactDetailsForm
                      details="customer_contact_detail_attributes"
                      defaultValue={
                        order.data.customer_contact_detail_attributes
                      }
                    />
                  </fieldset>
                )}
              </>
            )}
          </div>

          <footer className={cls.actions}>
            <Button
              onClick={onReturnClick}
              color="primary"
              variation="accented"
              size="extra-large"
            >
              <Icon name="short-arrow-tiny-left" gutter="right" />
              {t("invalid_orders_modal.actions.return")}
            </Button>

            <Button
              disabled={isActionInProgress}
              color="primary"
              size="extra-large"
              type="submit"
            >
              {t("invalid_orders_modal.actions.confirm")}
              <Icon name="short-arrow-tiny-right" gutter="left" />
            </Button>
          </footer>
        </div>
      </form>
    </FormProvider>
  )
}

type ContactDetailsFormProps = {
  details:
    | "client_contact_detail_attributes"
    | "customer_contact_detail_attributes"
  defaultValue: Partial<ContactDetail>
}

const ContactDetailsForm = ({
  details,
  defaultValue,
}: ContactDetailsFormProps) => {
  const { setValue, register, errors } = useFormContext()

  const detailsErrors: typeof errors = errors[details]

  const { t } = useTranslation()

  const values = useWatch({
    name: details,
    defaultValue,
  })

  const address = useMemo(
    () => ({
      city: values.city || "",
      state: values.state || "",
      county: values.county || "",
      municipality: values.municipality || "",
      street: values.street || "",
      house_number: values.house_number || "",
      postal_code: values.postal_code || "",
    }),
    [values]
  )

  const setCityValue = useCallback(
    (value) => {
      Object.entries(value).forEach(([key, value]) => {
        setValue(`${details}.${key}`, value)
      })

      setValue(`${details}.postal_code`, "")
    },
    [details, setValue]
  )

  const onCitySuggestionsChange = useCallback(
    (suggestions) => {
      if (suggestions.length) {
        Object.entries(suggestions[0]).forEach(([key, value]) => {
          setValue(`${details}.${key}`, value)
        })
      }
    },
    [details, setValue]
  )

  const setStreetValue = useCallback(
    (value) => {
      setValue(`${details}.street`, value)
      setValue(`${details}.postal_code`, "")
    },
    [details, setValue]
  )

  const onStreetSuggestionsChange = useCallback(
    (suggestions) => {
      if (suggestions.length && suggestions[0] !== address.street) {
        setValue(`${details}.street`, suggestions[0])
      }
    },
    [address.street, setValue, details]
  )

  async function updatePostalCode({ postal_code, ...partialAddress }: Address) {
    const isValid = Object.values(partialAddress).every(Boolean)

    if (!isValid || postal_code) {
      debouncedUpdatePostalCode.cancel()
      return
    }

    const { response, json } = await fetchFindPostalCode(partialAddress)

    if (json.errors) {
      handleStandardError(response, json)
      return
    }

    if (response.ok && json.data) {
      setValue(`${details}.postal_code`, json.data.postal_code)
    }
  }

  const debouncedUpdatePostalCode = useRef(
    debounce(updatePostalCode, 1000)
  ).current

  useEffect(() => {
    debouncedUpdatePostalCode(address)

    return () => {
      debouncedUpdatePostalCode.cancel()
    }
  }, [address, debouncedUpdatePostalCode])

  return (
    <div className={cls.contactDetailsForm}>
      <PhoneField
        name={`${details}.phone`}
        error={detailsErrors?.phone?.message}
      />

      <CityField
        label={t("labels.city")}
        value={address.city}
        error={
          (detailsErrors?.city ||
            detailsErrors?.state ||
            detailsErrors?.county ||
            detailsErrors?.municipality) &&
          `${t("errors.select_city")}`
        }
        setValue={setCityValue}
        onSuggestionsChange={onCitySuggestionsChange}
        translations={{
          empty: `${t("autocomplete.no_results")}`,
          error: `${t("autocomplete.fetch_error")}`,
        }}
        help={
          address.state && address.municipality
            ? `${address.state}, ${address.municipality}`
            : undefined
        }
      />

      <input
        name={`${details}.city`}
        ref={register({ required: true })}
        type="hidden"
      />

      <input
        name={`${details}.state`}
        ref={register({ required: true })}
        type="hidden"
      />

      <input
        name={`${details}.county`}
        ref={register({ required: true })}
        type="hidden"
      />

      <input
        name={`${details}.municipality`}
        ref={register({ required: true })}
        type="hidden"
      />

      <StreetField
        label={t("labels.street")}
        key={address.city}
        value={address.street}
        cityValue={{
          city: address.city,
          state: address.state,
          county: address.county,
          municipality: address.municipality,
        }}
        setValue={setStreetValue}
        onSuggestionsChange={onStreetSuggestionsChange}
        error={detailsErrors?.street?.message}
        translations={{
          empty: `${t("autocomplete.no_results")}`,
          error: `${t("autocomplete.fetch_error")}`,
        }}
        required
      />

      <input
        name={`${details}.street`}
        ref={register({ required: `${t("errors.select_street")}` })}
        type="hidden"
      />

      <Field
        label={t("labels.floor")}
        name={`${details}.floor`}
        inputProps={{ type: "number" }}
        ref={register()}
      />

      <Field
        label={t("labels.staircase")}
        name={`${details}.staircase`}
        ref={register()}
      />

      <Field
        label={t("labels.house_number")}
        name={`${details}.house_number`}
        error={detailsErrors?.house_number?.message}
        ref={register({ required: "Pole jest wymagane" })}
        inputProps={{ onBlur: () => setValue(`${details}.postal_code`, "") }}
        required
      />

      <Field
        label={t("labels.flat_number")}
        name={`${details}.flat_number`}
        ref={register()}
      />

      <Field
        label={t("labels.access_code")}
        name={`${details}.access_code`}
        ref={register()}
      />

      <PostalCodeField
        name={`${details}.postal_code`}
        error={detailsErrors?.postal_code?.message}
      />
    </div>
  )
}

type PhoneFieldProps = {
  name?: string
} & ComponentProps<typeof Field>

const PhoneField = ({ name = "phone", ...props }: PhoneFieldProps) => {
  const { register, setValue } = useFormContext()

  const maskedPhone = useWatch({
    name,
    defaultValue: "",
  })

  const { t } = useTranslation()

  return (
    <Rifm
      value={maskedPhone}
      onChange={(value) => setValue(name, value)}
      format={formatPhone}
    >
      {({ value, onChange }) => (
        <Field
          required
          name={name}
          label={t("labels.phone")}
          className={cls.phone}
          ref={register({
            required: `${t("errors.is_required")}`,
            pattern: {
              value: /[0-9]{3} [0-9]{3} [0-9]{3}/,
              message: `${t("errors.phone_number_format")}`,
            },
          })}
          {...props}
          inputProps={{
            type: "tel",
            maxLength: 11,
            readOnly: true,
            ...props.inputProps,
            value,
            onChange,
          }}
        />
      )}
    </Rifm>
  )
}

type PostalCodeFieldProps = {
  name?: string
} & ComponentProps<typeof Field>

const PostalCodeField = ({
  name = "postal_code",
  ...props
}: PostalCodeFieldProps) => {
  const { register, setValue } = useFormContext()

  const maskedPostalCode = useWatch({
    name,
    defaultValue: "",
  })

  const { t } = useTranslation()

  return (
    <Rifm
      value={maskedPostalCode}
      onChange={(value) => setValue(name, value)}
      format={formatPostalCode}
    >
      {({ value, onChange }) => (
        <Field
          label={t("labels.postal_code")}
          name={name}
          inputProps={{
            tabIndex: -1,
            ...props.inputProps,
            value,
            onChange,
          }}
          {...props}
          required
          ref={register({
            required: `${t("errors.is_required")}`,
            pattern: {
              value: /[0-9]{2}-[0-9]{3}/,
              message: `${t("errors.postal_code_format")}`,
            },
          })}
        />
      )}
    </Rifm>
  )
}

export function useEditOrderAddressModal(): [
  Order["id"] | null,
  (id: Order["id"] | null) => void
] {
  const [orderToEditAddressId, setOrderToEditAddressId] = useState<
    InvalidOrder["id"] | null
  >(null)

  return [orderToEditAddressId, setOrderToEditAddressId]
}
