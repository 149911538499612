const statistics = {
  today: "Dzisiaj",
  yesterday: "Wczoraj",
  this_week: "Ten tydzień",
  last_week: "Zeszły tydzień",
  this_month: "Ten miesiąc",
  last_month: "Zeszły miesiąc",
  date_range: "Zakres dat dla pokazywanych statystyk",
  completion_under_60: "Realizacje do 60 minut",
  completion_over_60: "Realizacje ponad 60 minut",
  total_orders: "Ilość zamówień łącznie",
  orders_with_delivery_failed: "Zamówienia nieudane",
  average_preparation_time_duration: "Średni czas przygotowania",
  average_daily_orders_count: "Średnia dzienna ilość zamówień",
  average_estimated_delivery_time_duration: "Średni czas doręczenia",
}

export default statistics
