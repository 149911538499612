import { ErrorOption } from "react-hook-form"
import { toast } from "react-toastify"

import i18n from "@/i18n"
import { ErrorCodeAndText, Errors } from "@/types"

export function formatErrorMessage(error: { name: string } & ErrorCodeAndText) {
  return error.text
}

export function getFirstError(errors: Errors) {
  const entries = Object.entries(errors)
  if (!entries.length) return null

  const [errorName, errorBody] = entries[0]

  return { name: errorName, code: errorBody[0]?.code, text: errorBody[0]?.text }
}

export function handleStandardError(
  response: { status: number | null },
  json?: { errors?: Errors }
) {
  if (response.status === null) {
    // Has to be a network error
    toast.error(i18n.t("common:errors.network_error"), {
      toastId: "networkError",
      updateId: "networkError",
    })
    return
  }

  if (!json?.errors) {
    showUnknownErrorToast()
    return
  }

  const firstError = getFirstError(json.errors)

  if (!firstError) {
    showUnknownErrorToast()
    return
  }

  toast.error(formatErrorMessage(firstError))
}

export function setFormErrors<FieldName extends string>(
  errors: Errors,
  setError: (name: FieldName, error: ErrorOption) => void
) {
  Object.entries(errors).forEach((entry) => {
    const [key, value] = entry

    setError(key as FieldName, {
      message: value[0].text,
    })
  })
}

export function showUnknownErrorToast() {
  toast.error("Wystąpił nieznany błąd", {
    toastId: "unknownError",
    updateId: "unknownError",
  })
}
