import { Restaurant } from "@/types"

const CAN_RECEIVE_ORDERS_KINDS: Array<Restaurant["order_management_kind"]> = [
  "receive_orders",
  "create_and_receive_orders",
  "shop_only",
]

export function canReceiveOrders(
  order_management_kind: Restaurant["order_management_kind"],
  interface_type: Restaurant["interface_type"]
) {
  if (interface_type !== "deligoo" && interface_type !== "soliddelivery")
    return false
  return CAN_RECEIVE_ORDERS_KINDS.includes(order_management_kind)
}

const CAN_CREATE_ORDERS_KINDS: Array<Restaurant["order_management_kind"]> = [
  "create_and_receive_orders",
  "create_orders",
]

export function canCreateOrders(
  order_management_kind: Restaurant["order_management_kind"]
) {
  return CAN_CREATE_ORDERS_KINDS.includes(order_management_kind)
}

export function hasAvailableOrders(
  availableOrders?: Restaurant["today_available_orders"]
) {
  return availableOrders === null || availableOrders
}
