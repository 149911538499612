import { Button, Dropdown } from "@deligoo/ui"
import clsx from "clsx"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import useAsyncEffect from "use-async-effect"

import { LOCALES } from "@/i18n"
import { LayoutProps } from "@/layouts"
import { fetchInfo, formatPhone, usePersistLocale } from "@/utils"

import cls from "./BasicLayout.module.scss"

const BasicLayout = ({ children, className }: LayoutProps) => {
  const [contactNumber, setContactNumber] = useState<string>()

  const { t, i18n } = useTranslation()
  const isSolidDeliver = window.location.hostname === "panel.solid.delivery"

  usePersistLocale(i18n.language)

  useAsyncEffect(async (isMounted) => {
    const { response, json } = await fetchInfo()

    if (isMounted() && response.ok) {
      setContactNumber(formatPhone(json.contact_number || ""))
    }
  }, [])

  return (
    <div className={clsx(cls.basic, className)}>
      <header className={cls.header}>
        <NavLink to="/" className={cls.logotype}>
          <img
            src={
              isSolidDeliver
                ? require("@/assets/logotypes/soliddelivery.svg").default
                : require("@/assets/logotypes/logo-deligoo-gradient.svg")
                    .default
            }
            alt="Deligoo"
          />
        </NavLink>

        <div className={cls.buttons}>
          {contactNumber && (
            <Button
              size="large"
              variation="text"
              color="dark"
              iconProps={{
                name: "phone",
                color: "primary",
                className: cls.buttonIcon,
              }}
              className={cls.button}
            >
              {contactNumber}
            </Button>
          )}

          <Dropdown
            trigger={
              <span>
                <Button
                  size="large"
                  variation="text"
                  color="dark"
                  iconProps={{
                    name: "users",
                    color: "primary",
                    className: cls.buttonIcon,
                  }}
                  className={cls.button}
                >
                  {t("buttons.change_language")}
                </Button>
              </span>
            }
          >
            {LOCALES.map((locale) => (
              <Dropdown.MenuItem
                style={{ minWidth: "15rem" }}
                key={locale}
                handleClick={() => i18n.changeLanguage(locale)}
              >
                {t(locale)}
              </Dropdown.MenuItem>
            ))}
          </Dropdown>
        </div>

        <h1 className={cls.siteName}>{t("headers.orders_panel")}</h1>
      </header>

      <main className={cls.main}>{children}</main>
    </div>
  )
}

export { BasicLayout }
