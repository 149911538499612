import L, { LatLngTuple } from "leaflet"
import { Marker } from "react-leaflet"

type RestaurantMarkerProps = {
  position: LatLngTuple
}

const RestaurantMarker = ({ position }: RestaurantMarkerProps) => {
  const icon = getMarkerIcon()

  return <Marker icon={icon} position={position} />
}

function getMarkerIcon() {
  return L.divIcon({
    className: `RestaurantMarker`,
    iconAnchor: [10, 10],
    iconSize: [40, 40],
  })
}

export { RestaurantMarker }
