import { Badge, ProviderLogo } from "@deligoo/ui"
import clsx from "clsx"
import dayjs from "dayjs"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { DeliveryMethod } from "@/components/DeliveryMethod"
import { PaymentForm } from "@/components/PaymentForm"
import { Price } from "@/components/Price"
import { Table } from "@/components/Table"
import { getOrders, selectOrderById, selectOrdersStatus } from "@/store/orders"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { Order, OrderPointState, OrderState } from "@/types"
import { formatHour, useRemoveToastOnMount } from "@/utils"
import { isToReceive } from "@/utils/order"

import cls from "./OrdersToReceiveList.module.scss"
import { ReceiveOrderModal, useReceiveOrderModal } from "./ReceiveOrderModal"

type OrdersToReceiveListProps = {
  type: "ordersToReceive" | "ordersReceived" | "ordersUnsuccessfullyReceived"
  date: string
  ordersIds: Array<Order["id"]>
}

const OrdersToReceiveList = ({
  type,
  date,
  ordersIds,
}: OrdersToReceiveListProps) => {
  const ordersStatus = useSelector(selectOrdersStatus)
  const currentRestaurantId = useSelector(selectCurrentRestaurantKey("id"))

  const dispatch = useDispatch()

  const location = useLocation<{ orderId?: number } | null>()

  const [orderToReceiveId, setOrderToReceiveId] = useReceiveOrderModal()

  const { t } = useTranslation("orders")

  useRemoveToastOnMount(type === "ordersToReceive" ? "newOrderToReceive" : null)

  useEffect(() => {
    if (currentRestaurantId) dispatch(getOrders(dayjs(date)))
  }, [dispatch, currentRestaurantId, date])

  useEffect(() => {
    if (type === "ordersToReceive" && location.state?.orderId) {
      setOrderToReceiveId(location.state.orderId)
      return
    }

    if (type === "ordersToReceive" && ordersIds.length === 1) {
      setOrderToReceiveId(ordersIds[0])
    }
    // Only run on first render and on tab change
    //eslint-disable-next-line
  }, [type])

  return (
    <div className={cls.ordersToReceiveList}>
      <header className={cls.header}>
        <h1 className={cls.title}>{t("headers.orders_to_receive")}</h1>
      </header>

      <Table columns={"minmax(250px, 1fr) 200px 250px 250px 250px"}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t("headers.order_date")}</Table.Th>
            <Table.Th>{t("headers.provider")}</Table.Th>
            <Table.Th>{t("headers.delivery_method")}</Table.Th>
            <Table.Th>{t("headers.payment_method")}</Table.Th>
            <Table.Th>{t("headers.status")}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody loading={ordersStatus === "loading"}>
          {(ordersStatus === "fetched" || ordersStatus === "updating") &&
            ordersIds &&
            ordersIds.map((orderId) => (
              <OrderRow
                key={orderId}
                orderId={orderId}
                setOrderToReceiveId={setOrderToReceiveId}
              />
            ))}
        </Table.Tbody>
      </Table>

      {(ordersStatus === "fetched" || ordersStatus === "updating") &&
        orderToReceiveId &&
        orderToReceiveId && (
          <ReceiveOrderModal
            orderId={orderToReceiveId}
            handleClose={() => setOrderToReceiveId(null)}
          />
        )}
    </div>
  )
}

type OrderRowProps = {
  orderId: Order["id"]
  setOrderToReceiveId: (orderId: Order["id"]) => void
}

const OrderRow = ({ orderId, setOrderToReceiveId }: OrderRowProps) => {
  const order = useSelector(selectOrderById(orderId))

  const { t } = useTranslation("orders")

  if (!order) return null

  return (
    <Table.Tr
      key={order.id}
      className={clsx(isToReceive(order) && cls.pulsating)}
      onClick={() => setOrderToReceiveId(order.id)}
    >
      <Table.Td>
        <div className="h300 mb-2">#{order.uid}</div>
        <div className="text-medium text-primary">
          {formatHour(order.created_at)}
        </div>
      </Table.Td>
      <Table.Td>
        <ProviderLogo
          provider={order.provider}
          marketplace={order.marketplace_kind}
          />
          <div>#{order.external_id && order.external_id.slice(-8)}</div>
      </Table.Td>
      <Table.Td>
        <DeliveryMethod
          deliveryMethod={order.delivery_method}
          deliveryAt={order.delivery_at}
          pickupAt={order.pickup_at}
        />
      </Table.Td>
      <Table.Td>
        <div className="mb-2">
          <Price subunit>{order.full_price_subunit}</Price>
        </div>
        <PaymentForm paymentForm={order.payment_form} iconSide="left" />
      </Table.Td>
      <Table.Td>
        <Badge
          color={getStateColor(order.state)}
          variation={getStateBadgeVariation(order.state)}
        >
          {t(`state.${order.state}`)}
        </Badge>
      </Table.Td>
    </Table.Tr>
  )
}

function getStateColor(state: OrderState | OrderPointState) {
  switch (state) {
    case "assigned":
    case "published":
      return "warning"
    case "started":
      return "info"
    case "in_progress":
      return "accent"
    case "finished":
      return "success"
    case "canceled":
    case "failed":
      return "alert"
    default:
      return "muted"
  }
}

function getStateBadgeVariation(state: OrderState | OrderPointState) {
  switch (state) {
    case "assigned":
    case "canceled":
    case "expired":
      return "accented"
    case "published":
    case "started":
    case "in_progress":
    case "finished":
    case "failed":
      return "solid"
    default:
      return "outlined"
  }
}

export { OrdersToReceiveList }
