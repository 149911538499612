import { Backdrop, Button } from "@deligoo/ui"
import clsx from "clsx"
import dayjs from "dayjs"
import { RefObject, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useAppDispatch } from "@/App"
import { CloseButton } from "@/components/CloseButton"
import {
  requestHideAllNotifications,
  requestReadAllNotifications,
  selectNotifications,
  selectUnreadNotificationsCount,
} from "@/store/notifications"

import { NotificationItem } from "./NotificationItem"
import cls from "./Notifications.module.scss"

type NotificationsProps = {
  handleClose: () => void
}

const Notifications = ({ handleClose }: NotificationsProps) => {
  const notifications = useSelector(selectNotifications)
  const unreadNotificationsCount = useSelector(selectUnreadNotificationsCount)

  const dispatch = useAppDispatch()

  const [mountTimestamp, setMountTimestamp] = useState<string | null>(null)
  const listRef = useRef<HTMLDivElement>(null)

  const { canBeScrolledDown, canBeScrolledUp } = useCanBeScrolled(listRef)

  const { t } = useTranslation()

  useEffect(() => {
    setMountTimestamp(dayjs().toISOString())
  }, [])

  useEffect(() => {
    if (unreadNotificationsCount) dispatch(requestReadAllNotifications())
  }, [dispatch, unreadNotificationsCount])

  return (
    <Backdrop className={cls.backdrop} transparent onClick={handleClose}>
      <div
        className={clsx(
          cls.notifications,
          canBeScrolledDown && !canBeScrolledUp && cls.gradientBottom,
          canBeScrolledUp && !canBeScrolledDown && cls.gradientTop,
          canBeScrolledDown && canBeScrolledUp && cls.gradientBoth
        )}
        onClick={(e) => e.stopPropagation()} // Prevent bubbling to Backdrop
      >
        <header className={cls.header}>
          <h2 className="h500">{t("notifications.header")}</h2>
          <CloseButton onClick={handleClose} size="extra-large" />
        </header>
        <div ref={listRef} className={cls.list}>
          {notifications?.map((notification) => (
            <NotificationItem
              key={notification.id}
              className={cls.item}
              notification={notification}
              wasPreviouslyUnread={
                !notification.read_at ||
                (mountTimestamp
                  ? dayjs(notification.read_at).isAfter(dayjs(mountTimestamp))
                  : false)
              }
            />
          ))}
        </div>
        <Button
          className={cls.clearAllBtn}
          color="primary"
          size="extra-large"
          fullWidth
          onClick={() =>
            dispatch(requestHideAllNotifications()).then(() => handleClose())
          }
        >
          {t("notifications.clear")}
        </Button>
      </div>
    </Backdrop>
  )
}

function useCanBeScrolled<T extends HTMLElement>(ref: RefObject<T>) {
  const [canBeScrolledDown, setCanBeScrolledDown] = useState(false)
  const [canBeScrolledUp, setCanBeScrolledUp] = useState(false)

  useLayoutEffect(() => {
    if (!ref.current) return

    const refCurrent = ref.current

    function updateStates() {
      if (refCurrent) {
        const { scrollHeight, scrollTop, clientHeight } = refCurrent

        setCanBeScrolledDown(scrollHeight - scrollTop > clientHeight)
        setCanBeScrolledUp(scrollTop > 0)
      }
    }

    updateStates()

    refCurrent.addEventListener("scroll", updateStates)

    return () => refCurrent.removeEventListener("scroll", updateStates)
  }, [ref])

  return { canBeScrolledDown, canBeScrolledUp }
}

export { Notifications }
