import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import en from "@/locales/en"
import pl from "@/locales/pl"

export const LOCALES = ["pl", "en"]

const userLocale = localStorage.getItem("user_locale")

const resources = {
  pl,
  en,
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: userLocale || "pl",
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
