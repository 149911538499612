import { Button, Icon } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { batch, useSelector } from "react-redux"

import { useAppDispatch } from "@/App"
import { Price } from "@/components/Price"
import {
  selectCurrentOrderFormOrder,
  selectOrderFormCurrentStepStatus,
  selectOrderFormStepData,
  setOrderFormStep,
  setOrderFormStepData,
  submitOrderFormStep,
} from "@/store/orderForm"
import {
  selectCurrentRestaurantKey,
  selectIsPricingPolicyActive,
} from "@/store/restaurant"
import { PaymentAndPackage as PaymentAndPackageFormData } from "@/types"
import {
  fetchAvailableTransportTypes,
  handleStandardError,
  isTruthyOrZero,
  setFormErrors,
} from "@/utils"
import { canHavePaymentChanged, isReceivable } from "@/utils/order"

import formCls from "../OrderForm.module.scss"
import {
  DeligooPackages,
  DeliveryCost,
  EcommercePackages,
  HebePackages,
  InpostPackages,
  PaymentOptions,
} from "./partials"
import cls from "./PaymentAndPackage.module.scss"

const stepKey = "payment_and_packages"

const PaymentAndPackage = () => {
  const dispatch = useAppDispatch()

  const order = useSelector(selectCurrentOrderFormOrder)
  const interfaceType = useSelector(
    selectCurrentRestaurantKey("interface_type")
  )
  const restaurantTransportTypes = useSelector(
    selectCurrentRestaurantKey("transport_types")
  )
  const isPricingPolicyActive = useSelector(selectIsPricingPolicyActive)
  const currentStepStatus = useSelector(selectOrderFormCurrentStepStatus)
  const formStepData = useSelector(selectOrderFormStepData(stepKey))

  const contactDetailData = useSelector(
    selectOrderFormStepData("contact_detail")
  )

  const isOrderReceive = order && isReceivable(order)

  const formMethods = useForm<PaymentAndPackageFormData>({
    defaultValues: {
      ...formStepData,
      price_subunit:
        interfaceType === "deligoo" || interfaceType === "soliddelivery"
          ? formStepData?.price_subunit
          : 0,
      price_delivery_subunit:
        interfaceType === "deligoo" || interfaceType === "soliddelivery"
          ? formStepData?.price_delivery_subunit ||
            contactDetailData?.delivery_price_subunit ||
            0
          : 0,
      packages: formStepData?.packages || {},
      transport_types: formStepData?.transport_types ||
        restaurantTransportTypes || ["car"],
    },
    mode: "onChange",
  })

  const { getValues, handleSubmit, setError, register, control } = formMethods

  const { price_subunit, price_delivery_subunit } =
    useWatch<PaymentAndPackageFormData>({
      control,
    })

  const totalPrice =
    isTruthyOrZero(price_subunit) && isTruthyOrZero(price_delivery_subunit)
      ? Number(price_subunit) + Number(price_delivery_subunit)
      : null

  const { t } = useTranslation("createOrder")

  async function onSubmit(stepData: PaymentAndPackageFormData) {
    dispatch(
      setOrderFormStepData({
        stepKey,
        stepData,
      })
    )

    let availableTransportTypes

    if (interfaceType !== "deligoo" && interfaceType !== "soliddelivery") {
      if (!order?.id) return

      const { response, json } = await fetchAvailableTransportTypes(
        order.id,
        stepData.packages
      )

      if (response.ok && json.data?.available_transport_types) {
        availableTransportTypes = json.data?.available_transport_types
      } else {
        handleStandardError(response, json)
      }
    }

    const { ok: wasSubmittedSuccessfully, errors } = await dispatch(
      submitOrderFormStep({
        stepKey,
        stepData: {
          ...stepData,
          transport_types: availableTransportTypes || stepData.transport_types,
        },
        orderId: order?.id,
      })
    ).then(unwrapResult)

    if (wasSubmittedSuccessfully) {
      dispatch(setOrderFormStep("processing_time"))
    } else if (errors) {
      setFormErrors(errors, setError)
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form className={formCls.step} onSubmit={handleSubmit(onSubmit)}>
        <div className={formCls.main}>
          {interfaceType === "deligoo" || interfaceType === "soliddelivery" ? (
            <section className={cls.prices}>
              <section className={clsx(cls.section, cls.price)}>
                <h2 className="h400">{t("headers.payment_form")}</h2>
                <div className={cls.content}>
                  <PaymentOptions
                    readOnly={order ? !canHavePaymentChanged(order) : false}
                  />
                </div>
              </section>

              {(isOrderReceive || isPricingPolicyActive) && (
                <>
                  <section className={cls.section}>
                    <h2 className="h400">{t("headers.delivery_price")}</h2>
                    <div className={cls.content}>
                      <DeliveryCost provider={order?.provider} />
                    </div>
                  </section>

                  <section className={clsx(cls.section, cls.totalPrice)}>
                    <h2 className="h400">{t("headers.total")}</h2>
                    <div className={clsx("text-dark", cls.content)}>
                      {isTruthyOrZero(totalPrice) ? (
                        <Price subunit>{totalPrice}</Price>
                      ) : (
                        "–"
                      )}
                    </div>
                  </section>
                </>
              )}
            </section>
          ) : (
            <>
              <input
                type="hidden"
                ref={register}
                name="payment_form"
                value="paid"
              />
              <input
                type="hidden"
                ref={register}
                name="price_delivery_subunit"
              />
            </>
          )}

          <input
            name="price_subunit"
            type="hidden"
            ref={register({
              required: `${t("errors.price_value_required")}`,
              validate: (value) => {
                const isPaidOptionSelected =
                  getValues("payment_form") === "paid"

                return (
                  isPaidOptionSelected ||
                  value > 0 ||
                  `${t("errors.price_value_too_low")}`
                )
              },
            })}
          />

          {interfaceType === "deligoo" && <DeligooPackages />}
          {interfaceType === "soliddelivery" && <DeligooPackages />}
          {interfaceType === "hebe" && <HebePackages />}
          {interfaceType === "inpost" && <InpostPackages />}
          {interfaceType === "ecommerce" && <EcommercePackages />}

          <Controller
            name="packages"
            render={() => <>{null}</>}
            rules={{
              validate: (packages) =>
                Object.values(packages).some((amount) =>
                  Boolean(Number(amount))
                ) || `${t("errors.select_package")}`,
            }}
          />

          <Controller name="transport_types" render={() => <>{null}</>} />
        </div>

        <div className={formCls.actions}>
          <Button
            onClick={() => {
              batch(() => {
                dispatch(
                  setOrderFormStepData({
                    stepKey,
                    stepData: getValues(),
                  })
                )
                dispatch(setOrderFormStep("contact_detail"))
              })
            }}
            color="primary"
            variation="accented"
            size="extra-large"
            type="button"
            fullWidth
          >
            <Icon name="short-arrow-tiny-left" />
            {t("buttons.return")}
          </Button>

          <Button
            loading={currentStepStatus === "loading"}
            color="primary"
            size="extra-large"
            type="submit"
            fullWidth
          >
            {t("buttons.continue")}
            <Icon name="short-arrow-tiny-right" />
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export { PaymentAndPackage }
