import { useTranslation } from "react-i18next"

import { Card } from "@/components/Card"
import { Gauge } from "@/components/Gauge"
import colors from "@/scss/colors.module.scss"
import { Statistics } from "@/types"

import cls from "../Statistics.module.scss"

type CompletionOver60MinProps = {
  loading: boolean
  value?: Statistics["completion_over_60_minutes_in_percent"]
  className?: string
}

const CompletionOver60Min = ({
  loading,
  value,
  className,
}: CompletionOver60MinProps) => {
  const { t } = useTranslation("statistics")

  return (
    <Card loading={loading} className={className} sections>
      <Card.Section>
        <Gauge
          fillColors={[{ min: 0, max: 1, color: colors.warning }]}
          fraction={value ? value / 100 : 0}
          iconName="boxes"
          className={cls.chart}
        />
      </Card.Section>
      <Card.Section>
        <Card.Title size="large" color="warning">
          {value}%
        </Card.Title>
        {t("completion_over_60")}
      </Card.Section>
    </Card>
  )
}

export { CompletionOver60Min }
