const orders = {
  tabs: {
    pending: "To receive",
    finished: "Received",
    failed: "Failed",
    invalid: "Invalid",

    orders: "Orders",
    details: "Details",
    payments: "Payments",
  },

  headers: {
    invalid_orders: "Invalid orders",
    order_date: "No. and creation date",
    provider: "Provider",
    delivery_method: "Delivery method",
    payment_method: "Payment method",
    status: "Status",
    invalid: "Invalid",
    orders_to_receive: "Orders to receive",
    order_uid: "No.",
    payment_status: "Payment status",
    amount: "Amount",
    payment_form: "Payment form",
    address: "Address",
    time: "Time",
    payment_refund_required: "Payment refund required",
    order_uid_and_address: "Address and order number",
    driver: "Courier",
    track_driver: "Track courier",
    created_at: "Created at",
    pickup_at: "Pickup at",
    phone: "Phone number",
    distance: "Distance",
  },

  payments_summary: {
    today_summary: "Today summary",
    paid_amount: "Paid",
    paid_by_cash_amount: "Cash",
    paid_by_card_amount: "Card",
    total: "Total",
  },

  delivery_method: {
    delivery_now: "Delivery - asap",
    delivery_later: "Delivery",
    pickup_now: "Self pickup",
    pickup_later: "Self pickup",
    own_delivery: "Own delivery",
  },
  package_type: {
    "big-pizza": "Big pizza",
    "standard-dish": "Standard dish | pizza up to 32 cm",
    "own-packaging": "Own packaging",
    "medium-pizza": "Medium pizza",
    dinner: "Dinner",
    "hebe-box-1": "Hebe Box 1",
    "hebe-box-2": "Hebe Box 2",
    "hebe-box-3": "Hebe Box 3",
    "hebe-box-4": "Hebe Box 4",
    "hebe-box-5": "Hebe Box 5",
    "ecommerce-size-a": "Gabaryt A",
    "ecommerce-size-b": "Gabaryt B",
    "ecommerce-size-c": "Gabaryt C",
    "inpost-size-a": "Gabaryt A",
    "inpost-size-b": "Gabaryt B",
    "inpost-size-c": "Gabaryt C",
  },
  unit_0: "unit",
  unit_1: "units",
  unit_2: "units",
  state: {
    // Order only
    draft: "Adding",
    unpaid: "Unpaid",
    waiting: "Waiting",
    // Common for Order and OrderPoint
    pending: "Processing",
    assigned: "In progress",
    published: "Published",
    started: "Started",
    finished: "Finished",
    canceled: "Canceled",
    failed: "Failed",
    expired: "Expired",
    // OrderPoint only
    in_progress: "In progress",
  },

  filters: {
    all: "All",
    pickup_only: "Pickup only",
    deliveries: "Deliveries",
    show: "Show:",
  },

  order_pickup_label: {
    published: "Published",
    started: "Started",
    in_progress: "In progress",
  },

  order_delivery_label: {
    published: "Published",
    started: "Started",
    in_progress: "In progress",
  },

  order_state_label: {
    pending: "Processing",
    assigned: "In progress",
    finished: "Finished",
    failed: "Failed",
    canceled: "Canceled",
    expired: "Expired",
  },

  orders_to_receive: {
    actions: {
      confirm: "Confirm",
      own_delivery: "Own delivery",
      continue: "Continue",
      reject: "Reject",
      edit_address: "Edit address",
    },
    headers: {
      customer_details: "Customer details",
      invoice: "Invoice",
      payment_form: "Payment form",
      company: "Company",
      address: "Address",
      nip: "NIP",
    },
    errors: {
      max_distance_error: "Max distance exceeded - own delivery only",
    },
  },

  invalid_orders_modal: {
    headers: {
      confirm_invalid_order: "Confirm",
      order_address_is_invalid: "Address is invalid",
      update_address: "Edit address",
      form: "Form",
      source_data: "Source data",
      pickup_data: "Pickup data",
      delivery_data: "Delivery data",
    },
    actions: {
      return: "Back",
      confirm: "Next",
    },
    toasts: {
      order_rejected: "Order rejected",
    },
  },

  receive_order_modal: {
    headers: {
      confirm_order_id: "Confirm order #{{orderId}}",
      order_id: "Order # {{orderId}}",
      distance: "Distance",
      delivery_method: "Delivery method",
      order_total_price: "Total price",
      order_delivery_price: "Delivery price",
      order_discount: "Discount",
      order_price_packaging: "Packaging price",
      order_total_amount: "Total",
    },
  },

  signature_modal: {
    customer_signature: "Signature",
    download: "Download",
  },

  timeline: {
    fetch_error: "Connection error",
    loading: "Loading...",
  },

  details_modal: {
    order_uid: "Order #{{orderUid}}",
    system_data: "Data",
    created_at: "Created at",
    estimated_time: "Estimated time",
    payment: "Payment form",
    count: "Count",
    weight: "Weight",
    size: "Size",
    drinks: "Drinks",
    yes: "Yes",
    no: "No",
    phone: "Phone number",
    address: "Address",
    distance: "Distance",
    order_comment: "Order comment",
    client_comment: "Restaurant comment",
    customer_comment: "Customer comment",
    none: "None",
    cancellation_reason: "Cancellation reason",
    invoice: "Invoice data",
    nip: "NIP",
    authorization_code: "Authorization code",
    signature: "Signature",
    show_signature: "Show signature",
    timeline: "Timeline",
  },

  update_order_payment_modal: {
    success: "Payment updated",
    order_amount: "Amount",
    delivery_price: "Delivery price",
  },

  reenter_order_modal: {
    title: "Are you sure you want to enter this order again?",
    desc: "If the order is active, selecting this option will cancel the current order",
    fetch_order_error: "The order data for re-entry could not be retrieved",
    create_order_error: "The re-entered order could not be created",
  },

  payment_refund_modal: {
    title: "Are you sure you want to refund this order?",
    payment_refunded: "The payment has been successfully refunded",
    cannot_be_refunded:
      "Order #{{orderId}} has been canceled by the administrator, please contact support to return the transaction.",
  },

  order_notes_modal: {
    title: "Customer notes",
    notes_content_placeholder: "The content of comments provided by the client",
    cannot_be_empty: "Please provide content",
    notes_updated: "The customer notes were successfully updated",
  },

  order_basket_modal: {
    order_content: "Content",
    return_to_order: "Back to order",
  },

  cancel_order_modal: {
    title: "Are you sure you want to cancel this order?",
    cancelation_reason: "Choose a reason for canceling",
    select_cancelation_reason_error:
      "You must select a reason for cancellation to proceed",
    order_canceled: "The order has been successfully canceled",
  },

  cancelation_reasons: {
    delivery_time_exceeded: "Too long waiting time for the courier",
    canceled_by_client: "The customer resigned from the order",
    other: "Other",
  },

  order_actions: {
    cancel: "Cancel",
    reenter: "Re-enter",
    update_payment: "Change payment form or amount (PLN)",
    refund: "Refund",
    content: "Content",
    notes: "Notes",
    download_label: "Download label .PDF",
    print: "Print order",
  },

  notify_driver: "Notify courier",
  driver_notified: "Courier notified",

  order_tracking: "Order tracking",
}

export default orders
