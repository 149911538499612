import { Button } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

import { useAppDispatch } from "@/App"
import { requestConfirmOrder, selectOrderById } from "@/store/orders"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { isTruthyOrZero } from "@/utils"

import cls from "../ReceiveOrderModal.module.scss"
import { ActionButtonProps } from "."

const ConfirmButton = ({
  orderId,
  loading,
  disabled,
  isParentMounted,
  handleModalClose,
  setIsActionInProgress,
}: ActionButtonProps) => {
  const dispatch = useAppDispatch()
  const order = useSelector(selectOrderById(orderId || 0))
  const distanceLimit = useSelector(
    selectCurrentRestaurantKey("deligoo_max_distance_limit")
  )

  const { t } = useTranslation("orders")

  function handleConfirmOrder() {
    if (!orderId) return
    if (
      disabled &&
      isTruthyOrZero(order?.distance) &&
      Number(order?.distance) > Number(distanceLimit)
    ) {
      toast.error(t("orders_to_receive.errors.max_distance_error"))
    }

    isParentMounted() && setIsActionInProgress(true)

    dispatch(requestConfirmOrder(orderId))
      .then(unwrapResult)
      .then(() => {
        handleModalClose()
      })
      .finally(() => {
        isParentMounted() && setIsActionInProgress(false)
      })
  }
  return (
    <Button
      className={cls.button}
      color={disabled ? "muted" : "success"}
      size="extra-large"
      fullWidth
      iconProps={{ name: disabled ? "close-circle" : "check", size: "small" }}
      iconSide="right"
      onClick={handleConfirmOrder}
      loading={loading}
    >
      {t("orders_to_receive.actions.confirm")}
    </Button>
  )
}

export { ConfirmButton }
