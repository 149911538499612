import "./Tooltip.scss"

import Tippy from "@tippyjs/react"

export type TooltipProps = React.ComponentProps<typeof Tippy> & {
  theme?: "light" | "dark" | "alert"
}

/**
If you want to use a component element as a child, ensure you forward the ref to the DOM node.
If you're using a library that doesn't forwardRef for you, and doesn't give access to the ref via innerRef or similar, you can use a wrapper <span> or <div> element as a workaround.
**/
export const Tooltip = ({ theme = "light", children, ...props }: TooltipProps) => {
  return (
    <Tippy theme={theme} {...props}>
      {children}
    </Tippy>
  )
}
