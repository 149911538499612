import clsx from "clsx"
import { ComponentPropsWithoutRef } from "react"

import { ThemeColor } from "../../types"
import { Icon } from "../Icon"
import cls from "./Avatar.module.scss"

export type AvatarProps = {
  iconProps?: ComponentPropsWithoutRef<typeof Icon>
  color?: AvatarColor
  dotColor?: ThemeColor
  indicator?: "break"
  className?: string
  children?: string
}

export const Avatar = ({
  iconProps,
  color,
  indicator,
  dotColor,
  className,
  children,
  ...props
}: AvatarProps & JSX.IntrinsicElements["div"]) => {
  return (
    <div className={clsx(cls.avatar, color && cls[color], className)} {...props}>
      {iconProps && <Icon {...iconProps} />}

      {indicator === "break" ? (
        <div className={clsx(cls.break)} />
      ) : dotColor ? (
        <div className={clsx(cls.dot, cls[`dot-${dotColor}`])} />
      ) : null}

      {children?.length && children[0]}
    </div>
  )
}

export enum AvatarColors {
  "blue-violet" = "blue-violet",
  "silver-tree" = "silver-tree",
  "malibu" = "malibu",
  "porsche" = "porsche",
  "cornflower-blue" = "cornflower-blue",
  "heliotrope" = "heliotrope",
  "turquoise-blue" = "turquoise-blue",
  "matrix" = "matrix",
  "santa-fe" = "santa-fe",
  "apple" = "apple",
  "zorba" = "zorba",
  "red-damask" = "red-damask",
}

export type AvatarColor = keyof typeof AvatarColors | ThemeColor
