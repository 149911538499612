import clsx from "clsx"
import { forwardRef, ReactNode } from "react"

import { ThemeColor } from "../../types"
import cls from "./Switch.module.scss"

type NativeInputPropsWithoutSize = Omit<JSX.IntrinsicElements["input"], "size">

export type SwitchProps = NativeInputPropsWithoutSize & {
  on?: boolean
  defaultOn?: boolean
  color?: ThemeColor
  bgColor?: ThemeColor | "white"
  size?: "small" | "medium" | "large"
  labelClassName?: string
  className?: string
  children?: ReactNode
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ on, defaultOn, color, bgColor, size = "medium", labelClassName, className, children, ...props }, ref) => {
    return (
      <label className={clsx(cls.label, size && cls[size], className)}>
        {children && <div className={clsx(cls.labelText, labelClassName)}>{children}</div>}

        <input type="checkbox" checked={on} defaultChecked={defaultOn} ref={ref} {...props} />

        <div className={clsx(cls.switch, color && cls[color], bgColor && cls[`bg-${bgColor}`])} />
      </label>
    )
  }
)
