import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import ReconnectingWebSocket from "reconnecting-websocket"

import i18n from "@/i18n"
import { selectIsAuthenticated } from "@/store/auth"
import { setIsDataRefreshNeeded } from "@/store/global"
import { selectCurrentRestaurantKey } from "@/store/restaurant"

import { Channel, Channels } from "./channels"
import { getWebSocketURI, requestDataRefresh } from "./utils"

const MS_TO_FORCED_RECONNECT = 15000

export function useSockets() {
  const socket = useRef<ReconnectingWebSocket | null>(null)
  const heartbeat = useRef<number | null>(null)
  const isInitialOpen = useRef(true)

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const restaurantUuid = useSelector(selectCurrentRestaurantKey("uuid"))
  const teamUuid = useSelector(selectCurrentRestaurantKey("team_uuid"))
  const isAutoReconnectEnabled = useSelector(
    selectCurrentRestaurantKey("auto_reconnect")
  )

  const dispatch = useDispatch()

  useEffect(() => {
    const lsSite = localStorage.getItem("site")

    if (!isAuthenticated || !restaurantUuid || !teamUuid || !lsSite) return

    const currentSite = JSON.parse(lsSite)

    socket.current = new ReconnectingWebSocket(getWebSocketURI(currentSite))

    socket.current.onopen = () => {
      Object.values(Channels).forEach((Channel) =>
        socket.current?.send(Channel.subscribe({ restaurantUuid, teamUuid }))
      )

      if (!isInitialOpen.current) {
        toast.dismiss("offline")
        if (isAutoReconnectEnabled) {
          // Wait 1s to ensure connection is fully restored before requests
          // Tests have shown that requests can be made too early
          window.setTimeout(() => {
            dispatch(requestDataRefresh())
            toast.success(i18n.t("common:alerts.reconnected"), {
              toastId: "reconnect",
              position: "bottom-right",
            })
          }, 1000)
        } else {
          dispatch(setIsDataRefreshNeeded(true))
        }
      } else {
        isInitialOpen.current = false
      }
    }

    socket.current.onmessage = (msg) => {
      const msgData = JSON.parse(msg.data)

      if (msgData.type === "ping") {
        heartbeat.current && window.clearTimeout(heartbeat.current)

        heartbeat.current = window.setTimeout(() => {
          socket.current?.reconnect()

          toast.error(i18n.t("common:alerts.connection_lost"), {
            toastId: "offline",
            autoClose: false,
            position: "bottom-right",
          })
        }, MS_TO_FORCED_RECONNECT)

        return
      }

      if (msgData.type !== "ping" && "message" in msgData) {
        const channel = JSON.parse(msgData.identifier).channel as keyof Channel

        Channels[channel].handleMessage(dispatch, msgData.message)
      }
    }

    socket.current.onerror = (err) => {
      console.error("WebSocket error observed:", err)
    }

    socket.current.onclose = (e) => {
      console.debug(e)

      // If token is null WS was closed because of logout action, so
      // don't show "Connection to server restored" toast or refresh data
      const lsToken = localStorage.getItem("token")
      if (!lsToken) isInitialOpen.current = true
    }

    return () => {
      heartbeat.current && window.clearTimeout(heartbeat.current)
      socket.current?.close()
    }
  }, [
    dispatch,
    isAuthenticated,
    restaurantUuid,
    teamUuid,
    isAutoReconnectEnabled,
  ])
}
