import { fetchFindPostalCode, Field, Modal, Spinner } from "@deligoo/ui"
import { useEffect, useRef, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Rifm } from "rifm"

import { DistanceBar } from "@/components/DistanceBar"
import { Price } from "@/components/Price"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import {
  checkIsDeliveryDetails,
  DataStatus,
  DeliveryDetails,
  FullAddress,
} from "@/types"
import {
  fetchDeliveryDetails,
  getFirstError,
  isTruthyOrZero,
  useIsMounted,
} from "@/utils"
import { formatPostalCode } from "@/utils/masks"
import { CityField } from "@/views/OrderForm/ContactDetails/partials/CityField"
import { StreetField } from "@/views/OrderForm/ContactDetails/partials/StreetField"

import cls from "./DeliveryDetailsModal.module.scss"

type DeliveryDetailsModalProps = {
  handleClose: () => void
}

const DeliveryDetailsModal = ({ handleClose }: DeliveryDetailsModalProps) => {
  const isMounted = useIsMounted()

  const cityRef = useRef<HTMLInputElement | null>(null)
  const streetRef = useRef<HTMLInputElement | null>(null)
  const houseNumberRef = useRef<HTMLInputElement | null>(null)

  const formMethods = useForm<FullAddress>()

  const { register, errors, clearErrors, getValues, setValue, watch } =
    formMethods

  const address = watch()

  const distanceLimit =
    useSelector(selectCurrentRestaurantKey("deligoo_max_distance_limit")) || 0

  const [details, setDetails] = useState<DeliveryDetails>()

  const [detailsStatus, setDetailsStatus] = useState<Omit<
    DataStatus,
    "updating"
  > | null>()

  const { t } = useTranslation()

  useEffect(() => {
    if (address.postal_code) isMounted() && updateDeliveryDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.postal_code])

  async function updatePostalCode() {
    const partialAddress = getValues([
      "city",
      "state",
      "county",
      "municipality",
      "street",
      "house_number",
    ])

    const isAddressValid = Object.values(partialAddress).every(Boolean)

    if (!isAddressValid) return

    const {
      json: { data },
    } = await fetchFindPostalCode(address)

    if (!isMounted()) return

    setValue("postal_code", data?.postal_code || "")
  }

  async function updateDeliveryDetails() {
    const addressValues = Object.values(address)
    const isAddressValid = addressValues.length && addressValues.every(Boolean)

    if (!isAddressValid) return

    setDetailsStatus("loading")

    const {
      json: { data, errors },
    } = await fetchDeliveryDetails(address)

    if (isMounted()) {
      if (errors) {
        toast.error(getFirstError(errors)?.text, {
          toastId: "deliveryDetailsError",
        })
      } else if (checkIsDeliveryDetails(data)) {
        setDetails(data)
      }

      setDetailsStatus("fetched")
    }
  }

  function handleAddressChange() {
    toast.dismiss("deliveryDetailsError")
    setDetails(undefined)
    clearErrors()
    updatePostalCode()
    updateDeliveryDetails()
  }

  return (
    <Modal handleClose={handleClose} className={cls.modal} light>
      <Modal.Title>{t("headers.check_distance")}</Modal.Title>
      <Modal.Content>
        <FormProvider {...formMethods}>
          <div className={cls.form}>
            <CityField
              name="city"
              label={t("labels.city")}
              ref={(el) => {
                register(el)
                cityRef.current = el
              }}
              error={errors?.city?.message}
              inputProps={{
                onBlur: () => {
                  handleAddressChange()
                  if (getValues("city") && !getValues("street")) {
                    streetRef.current?.focus()
                  }
                },
              }}
            />

            <StreetField
              name="street"
              label={t("labels.street")}
              ref={(el) => {
                register(el)
                streetRef.current = el
              }}
              error={errors?.street?.message}
              inputProps={{
                autoFocus: true,
                onBlur: () => {
                  handleAddressChange()
                  if (getValues("street") && !getValues("house_number")) {
                    houseNumberRef.current?.focus()
                  }
                },
              }}
            />

            <Field
              label={t("labels.house_number")}
              name="house_number"
              ref={(el) => {
                register(el)
                houseNumberRef.current = el
              }}
              error={errors?.house_number?.message}
              inputProps={{
                onBlur: handleAddressChange,
              }}
            />

            <Rifm
              value={address.postal_code || ""}
              onChange={(value) => setValue("postal_code", value)}
              format={formatPostalCode}
            >
              {({ value, onChange }) => (
                <Field
                  label={t("labels.postal_code")}
                  name="postal_code"
                  inputProps={{
                    tabIndex: -1,
                    onBlur: updateDeliveryDetails,
                    value,
                    onChange,
                  }}
                  error={errors?.postal_code?.message}
                  ref={register()}
                />
              )}
            </Rifm>
          </div>

          {detailsStatus === "loading" && (
            <div className={cls.loading}>
              <Spinner size="extra-large" />
            </div>
          )}

          {detailsStatus !== "loading" && (
            <div className={cls.results}>
              <div className={cls.item}>
                <div className="h300">{t("headers.distance")}</div>
                <div className={cls.itemContent}>
                  {isTruthyOrZero(details?.distance) ? (
                    <DistanceBar
                      distance={details!.distance}
                      limit={distanceLimit}
                    />
                  ) : (
                    <span className="h600 text-primary">–</span>
                  )}
                </div>
              </div>

              <div className={cls.item}>
                <div className="h300">{t("headers.delivery_cost")}</div>
                <div className={cls.itemContent}>
                  <span className="h600 text-primary">
                    {isTruthyOrZero(details?.delivery_price_subunit) ? (
                      <Price>{details!.delivery_price_subunit}</Price>
                    ) : (
                      "–"
                    )}
                  </span>
                </div>
              </div>

              <div className={cls.item}>
                <div className="h300">{t("headers.delivery_duration")}</div>
                <div className={cls.itemContent}>
                  <span className="h600 text-primary">
                    {isTruthyOrZero(details?.delivery_duration)
                      ? `${details!.delivery_duration} min.`
                      : "–"}
                  </span>
                </div>
              </div>
            </div>
          )}
        </FormProvider>
      </Modal.Content>
    </Modal>
  )
}

export { DeliveryDetailsModal }
