import { Button, Icon } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import dayjs, { Dayjs } from "dayjs"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { batch, useSelector } from "react-redux"

import { useAppDispatch } from "@/App"
import {
  selectCurrentOrderFormOrder,
  selectOrderFormCurrentStepStatus,
  selectOrderFormStepData,
  setOrderFormStep,
  setOrderFormStepData,
  submitOrderFormStep,
} from "@/store/orderForm"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { Order, ProcessingTime as TProcessingTime } from "@/types"
import { setFormErrors } from "@/utils"

import formCls from "../OrderForm.module.scss"
import { CalculateTimes } from "./partials/CalculateTimes"
import { ReadyToPickup } from "./partials/ReadyToPickup"

const stepKey = "processing_time"

export type ProcessingTimeReference = "pickup_in" | "pickup_at" | "delivery_at"
export type TimingDeliveryReference = "30" | "60" | null

type CalculateTimesParams = {
  timeReference: ProcessingTimeReference
  value: string
  distance: number
}

export function calculateTimes({
  timeReference,
  value,
  distance,
}: CalculateTimesParams) {
  const deliveryTime = Math.ceil(distance) * 4
  const avgDeliveryTime = deliveryTime >= 10 ? deliveryTime : 10
  const now = dayjs()

  let pickupIn: number, pickupAt: Dayjs, deliveryAt: Dayjs

  switch (timeReference) {
    case "pickup_in":
      pickupIn = Number(value)
      pickupAt = now.add(pickupIn, "m")
      deliveryAt = pickupAt.add(avgDeliveryTime, "m")
      break
    case "pickup_at":
      pickupAt = dayjs(value)
      deliveryAt = pickupAt.add(avgDeliveryTime, "m")
      pickupIn = pickupAt.diff(now, "m")
      break
    case "delivery_at":
      deliveryAt = dayjs(value)
      pickupAt = deliveryAt.subtract(avgDeliveryTime, "m")
      pickupIn = pickupAt.diff(now, "m")
      break
  }

  if (pickupIn < 10) {
    pickupIn = 10
    pickupAt = now.add(pickupIn, "m")
    deliveryAt = pickupAt.add(avgDeliveryTime, "m")
  }

  return {
    pickup_in: pickupIn,
    pickup_at: pickupAt.format(),
    delivery_at: deliveryAt.format(),
  }
}

function getDefaultValues(
  order?: Order | null,
  formStepData?: TProcessingTime
) {
  const delivery_method = order?.delivery_method || "delivery_now"

  const delivery_at =
    formStepData?.delivery_at ||
    (order?.delivery_at ? dayjs(order?.delivery_at).format() : null)

  const pickup_at =
    formStepData?.pickup_at ||
    (order?.pickup_at ? dayjs(order?.pickup_at).format() : null)

  const pickup_in = formStepData?.pickup_in || 0
  const distance = order?.distance || 0

  const isDelivery = [
    "delivery_now",
    "delivery_later",
    "own_delivery",
  ].includes(delivery_method)

  if ((isDelivery && delivery_at) || (!isDelivery && pickup_at)) {
    return calculateTimes({
      timeReference: isDelivery ? "delivery_at" : "pickup_at",
      value: isDelivery ? delivery_at! : pickup_at!,
      distance,
    })
  }

  if (pickup_at && !delivery_at) {
    return calculateTimes({
      timeReference: "pickup_at",
      value: pickup_at,
      distance,
    })
  }

  if (delivery_at && !pickup_at) {
    return calculateTimes({
      timeReference: "delivery_at",
      value: delivery_at,
      distance,
    })
  }

  return {
    pickup_in,
    pickup_at: pickup_at || null,
    delivery_at: delivery_at || null,
  }
}

const ProcessingTime = () => {
  const dispatch = useAppDispatch()

  const order = useSelector(selectCurrentOrderFormOrder)

  const currentStepStatus = useSelector(selectOrderFormCurrentStepStatus)

  const contactDetailData = useSelector(
    selectOrderFormStepData("contact_detail")
  )

  const interfaceType = useSelector(
    selectCurrentRestaurantKey("interface_type")
  )

  const timingDeliveryEnabled = useSelector(
    selectCurrentRestaurantKey("timing_delivery_enabled")
  )

  const formStepData =
    useSelector(selectOrderFormStepData("processing_time")) || undefined

  const defaultValues = getDefaultValues(order, formStepData)

  const formMethods = useForm({ defaultValues })

  const { register, getValues, handleSubmit, setError } = formMethods

  const distance = contactDetailData?.distance ?? order?.distance ?? 0

  const { t } = useTranslation("createOrder")

  function onSubmit(stepData: TProcessingTime) {
    dispatch(setOrderFormStepData({ stepKey, stepData }))

    dispatch(submitOrderFormStep({ stepKey, stepData, orderId: order?.id }))
      .then(unwrapResult)
      .then((res) => {
        if (!res) return

        if (res.ok) {
          dispatch(setOrderFormStep("notes"))
        } else if (res.errors) {
          setFormErrors(res.errors, setError)
        }
      })
  }

  return (
    <FormProvider {...formMethods}>
      <form className={formCls.step} onSubmit={handleSubmit(onSubmit)}>
        <div className={formCls.main}>
          {interfaceType === "deligoo" || interfaceType === "soliddelivery" ? (
            <CalculateTimes
              distance={distance}
              order={order}
              timing_delivery_enabled={timingDeliveryEnabled}
            />
          ) : (
            <ReadyToPickup distance={distance} />
          )}
        </div>

        <input
          type="hidden"
          name="pickup_in"
          ref={register({
            required: true,
            min: {
              value: 10,
              message: `${t("errors.invalid_pickup_time")}`,
            },
          })}
        />

        <input
          type="hidden"
          name="pickup_at"
          ref={register({ required: true })}
        />

        <input
          type="hidden"
          name="delivery_at"
          ref={register({ required: true })}
        />

        <input
          type="hidden"
          name="timing_delivery"
          ref={register({ required: false })}
        />

        <div className={formCls.actions}>
          <Button
            onClick={() => {
              batch(() => {
                dispatch(
                  setOrderFormStepData({ stepKey, stepData: getValues() })
                )

                dispatch(setOrderFormStep("payment_and_packages"))
              })
            }}
            color="primary"
            variation="accented"
            size="extra-large"
            type="button"
            fullWidth
          >
            <Icon name="short-arrow-tiny-left" />
            {t("buttons.return")}
          </Button>

          <Button
            loading={currentStepStatus === "loading"}
            color="primary"
            size="extra-large"
            type="submit"
            fullWidth
          >
            {t("buttons.continue")}
            <Icon name="short-arrow-tiny-right" />
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export { ProcessingTime }
