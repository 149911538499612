import { Button, Tooltip } from "@deligoo/ui"
import clsx from "clsx"
import { useState } from "react"
import { useSelector } from "react-redux"

import { Indicator } from "@/components/Indicator"
import { Notifications } from "@/components/Notifications"
import { selectCurrentHour } from "@/store/global"
import {
  selectIsAnyUnreadImportantMessage,
  selectUnreadMessagesCount,
} from "@/store/messages"
import {
  selectIsAnyUnreadImportantNotification,
  selectUnreadNotificationsCount,
} from "@/store/notifications"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { formatPhone } from "@/utils/masks"
import { Messages } from "@/views/Messages"

import { ActionButton, RestaurantButton, UpdateIndicator } from "./partials"
import cls from "./Topbar.module.scss"

type TopbarProps = {
  className: string
}

const Topbar = ({ className }: TopbarProps) => {
  const unreadMessagesCount = useSelector(selectUnreadMessagesCount)
  const unreadNotificationsCount = useSelector(selectUnreadNotificationsCount)
  const supportPhone = useSelector(selectCurrentRestaurantKey("support_phone"))

  const isAnyUnreadImportantMessage = useSelector(
    selectIsAnyUnreadImportantMessage
  )

  const isAnyUnreadImportantNotification = useSelector(
    selectIsAnyUnreadImportantNotification
  )

  const [areMessagesShown, showMessages] = useState(false)
  const [areNotificationsShown, showNotifications] = useState(false)

  return (
    <section className={clsx(cls.topbar, className)}>
      <ActionButton />
      <UpdateIndicator />

      <nav className={cls.nav}>
        <div className={cls.navItem}>
          {supportPhone && (
            <Button
              size="large"
              variation="text"
              color="dark"
              iconProps={{ name: "phone", color: "primary" }}
            >
              {supportPhone && formatPhone(supportPhone)}
            </Button>
          )}
        </div>

        <div className={cls.navItem}>
          <Button
            size="large"
            variation="text"
            color="dark"
            iconProps={{ name: "clock", color: "primary" }}
          >
            <CurrentHour />
          </Button>
        </div>

        <div className={cls.iconButtons}>
          <div className={cls.navItem}>
            <Indicator
              count={unreadNotificationsCount || 0}
              onClick={() => showNotifications(true)}
              color={isAnyUnreadImportantNotification ? "alert" : "primary"}
            >
              <Button
                variation="text"
                color={isAnyUnreadImportantNotification ? "alert" : "dark"}
                iconProps={{ name: "ringbell", size: "large" }}
                className={clsx(
                  cls.navBtn,
                  isAnyUnreadImportantNotification && cls.notificationAlert
                )}
              />
            </Indicator>
          </div>

          {areNotificationsShown && (
            <Notifications handleClose={() => showNotifications(false)} />
          )}

          <div className={cls.navItem}>
            <Tooltip
              visible={isAnyUnreadImportantMessage}
              content="PILNE"
              zIndex={1}
              theme="alert"
              placement="bottom"
              className={cls.tooltipBadge}
            >
              <span>
                <Indicator
                  count={unreadMessagesCount || 0}
                  onClick={() => showMessages(true)}
                >
                  <Button
                    className={cls.navBtn}
                    variation="text"
                    color="dark"
                    iconProps={{ name: "envelope", size: "large" }}
                  />
                </Indicator>
              </span>
            </Tooltip>
          </div>

          {areMessagesShown && (
            <Messages handleClose={() => showMessages(false)} />
          )}

          <div className={cls.navItem}>
            <Button
              className={cls.navBtn}
              variation="text"
              color="dark"
              iconProps={{ name: "life-buoy", size: "large" }}
            />
          </div>
        </div>
      </nav>

      <RestaurantButton />
    </section>
  )
}

const CurrentHour = () => {
  const currentHour = useSelector(selectCurrentHour)

  return <>{currentHour}</>
}

export { Topbar }
