const dashboard = {
  "Learn more": "Learn more",

  "Alert message": "Alert message",

  "Receiving orders": "Receiving orders",

  "Remaining deliveries": "Remaining deliveries",

  "Expected delivery time": "Expected delivery time",
  "Expected pickup time": "Expected pickup time",
  "Estimated pickup time": "Estimated pickup time",
  "Available soon": "Available soon",

  "Distance limit": "Distance limit",

  Integrations: "Integrations",

  Mode: "Mode",

  "Payments unsettled or awaiting confirmation":
    "Payments unsettled or awaiting confirmation",
  "No unsettled payments": "No unsettled payments",
  "{{count}} payments_0": "{{count}} payment",
  "{{count}} payments_1": "{{count}} payments",
  "{{count}} payments_2": "{{count}} payments",

  "Delivery pricing policy": "Delivery pricing policy",
  Active: "Active",
  Inactive: "Inactive",

  "You have {{count}} new orders to receive_0":
    "You have {{count}} new order to receive",
  "You have {{count}} new orders to receive_1":
    "You have {{count}} new orders to receive",
  "You have {{count}} new orders to receive_2":
    "You have {{count}} new orders to receive",

  "You have {{count}} new invalid orders_0":
    "You have {{count}} new invalid order",
  "You have {{count}} new invalid orders_1":
    "You have {{count}} new invalid orders",
  "You have {{count}} new invalid orders_2":
    "You have {{count}} new invalid orders",

  "See orders_0": "See order",
  "See orders_1": "See orders",
  "See orders_2": "See orders",

  "No new orders to receive": "No new orders to receive",

  "Pending deliveries": "Pending deliveries",
  "Deliveries in progress": "Deliveries in progress",
  "Orders received by clients": "Deliveries finished",
  "Total deliveries": "Total deliveries",

  "Traffic load": "Occupancy",

  "Traffic rate": "Traffic rate",

  "Restaurant status": "Restaurant status",

  remaining_deliveries_desc: "Number of deliveries remaining on the day.",
  remaining_deliveries_small:
    "* applies to contracted partners with a maximum delivery limit.",

  distance_limit_desc:
    "The maximum distance you can ship your order. The distance is measured along the shortest route that the driver must cover.",

  integrations_desc:
    "Activate or deactivate the activity of the premises on the website or on the website glodny.pl",
  integrations_count_0: "order acceptance inactive",
  integrations_count_1: "you are active on one of the two portals",
  integrations_count_2: "you are active on all connected portals",

  status_standard: "Standard",
  status_standard_desc:
    "We show the courier's arrival time up to 30 minutes in advance.",
  status_standby: "Emergency",
  status_standby_desc:
    "We will show you the pick-up time after the start of the order to your point.",
  status_desc:
    "* after adding an order, the system designates a driver who can pick up the order the fastest. If the order preparation time is less than 30 minutes, we search for it immediately. If the preparation time is longer, we will show you the exact time of receipt a little later.",

  estimated_pickup_time_desc:
    "Average pickup time, based on actual occupancy and traffic. After clicking and entering the address, you can check the estimated time for the total order fulfillment.",

  privacy_policy_desc:
    "After entering the address, the system will suggest the amount to be added to the customer's bill.",
  privacy_policy_small:
    "* information for restaurant staff only. You must first set a delivery price list on the website app.deligoo.pl/partner/",

  receiving_orders_desc:
    "Here you will see orders to be picked up from your website or glodny.pl website - click on the flashing message.",

  traffic_load_desc: "Percentage of the current order load.",
  traffic_load_green: "Green",
  traffic_load_green_desc:
    "high performance, a large number of available drivers.",
  traffic_load_orange: "Orange",
  traffic_load_orange_desc:
    "increase in the number of orders, slightly longer waiting time for collection.",
  traffic_load_red: "Red",
  traffic_load_red_desc:
    "a very large number of orders, extended waiting time.",

  traffic_rate: "Current road traffic (traffic jams)",
  traffic_rate_desc:
    "The system measures the current travel time on several main streets of the city in real time. An indicator above 50% means rush hour. Together with the traffic load indicator, we show our current bandwidth.",
  traffic_rate_small: "* high index means extended order fulfillment time.",

  working_hours_desc:
    "Deligoo couriers working hours. You can add an order earlier, but pickup will take place within the specified range.",
  working_hours_small:
    "* You can add the last order for a maximum of 30 minutes. before the end of our work",
}

export default dashboard
