import { Button, Dropdown, Icon } from "@deligoo/ui"
import clsx from "clsx"
import { useMediaQuery } from "react-responsive"
import { useHistory, useLocation } from "react-router-dom"

import i18n from "@/i18n"
import { IconName } from "@/types"

import cls from "./OrdersTabs.module.scss"

type Tab = {
  route: string
  label: string
  icon: IconName
}

const TABS: Tab[] = [
  {
    route: "/zlecenia",
    label: i18n.t("orders:tabs.orders"),
    icon: "tasks",
  },
  {
    route: "/zlecenia/szczegoly",
    label: i18n.t("orders:tabs.details"),
    icon: "notes",
  },
  {
    route: "/zlecenia/platnosci",
    label: i18n.t("orders:tabs.payments"),
    icon: "cash",
  },
]

// TODO: Make a generic component out of this and OrdersToReceiveTabs
const OrdersTabs = () => {
  const history = useHistory()
  const location = useLocation()

  const currentRoute = location.pathname
  const currentTab = TABS.find((tab) => tab.route === currentRoute)

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" })

  if (isMobile) {
    if (!currentTab) return null

    return (
      <nav>
        <Dropdown
          trigger={
            <span>
              <Button
                color="primary"
                variation="solid"
                iconProps={{ name: currentTab.icon }}
                size="extra-large"
              >
                {currentTab.label}
                <Icon name="chevron-tiny-down" size="small" gutter="left" />
              </Button>
            </span>
          }
        >
          {TABS.map((tab) => (
            <Dropdown.MenuItem
              key={tab.route}
              handleClick={() => history.push(tab.route)}
            >
              {tab.label}
            </Dropdown.MenuItem>
          ))}
        </Dropdown>
      </nav>
    )
  }

  return (
    <nav>
      {TABS.map((tab) => (
        <div
          key={tab.route}
          className={clsx(cls.tab, tab.route !== currentRoute && cls.inactive)}
        >
          <Button
            className={cls.button}
            onClick={() => history.push(tab.route)}
            color={tab.route === currentRoute ? "primary" : "muted"}
            variation={tab.route === currentRoute ? "solid" : "text"}
            iconProps={{ name: tab.icon }}
            size="extra-large"
          >
            {tab.label}
          </Button>
        </div>
      ))}
    </nav>
  )
}

export { OrdersTabs }
