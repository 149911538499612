import clsx from "clsx"
import { ComponentProps, forwardRef, HTMLAttributes } from "react"

import { Input } from "../Input"
import cls from "./Field.module.scss"

type FieldProps = {
  labelClassName?: string
  error?: string
  help?: string
  inline?: boolean
  inputProps?: ComponentProps<typeof Input>
  label?: string
  name?: string
  required?: boolean
} & HTMLAttributes<HTMLDivElement>

const Field = forwardRef<HTMLInputElement, FieldProps>(
  ({ className, labelClassName, error, help, inline, inputProps, label, name, required, children }, ref) => {
    return (
      <div
        className={clsx(cls.field, error && cls.hasError, inline && cls.inline, required && cls.required, className)}
      >
        {label && (
          <label className={clsx(labelClassName)} htmlFor={name}>
            {label}
          </label>
        )}

        <div className={cls.inputWrapper}>
          {children ? (
            children
          ) : (
            <Input
              id={name}
              name={name}
              required={required}
              autoComplete="off"
              size="large"
              ref={ref}
              selectOnFocus
              plain
              {...inputProps}
            />
          )}

          {help && !error && <span className={cls.help}>{help}</span>}
          {error && <span className={cls.error}>{error}</span>}
        </div>
      </div>
    )
  }
)

export { Field }
