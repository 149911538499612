import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit"
import deepMerge from "lodash.merge"

import { DataStatus, RootState } from "@/types"
import { InvalidOrder } from "@/types/InvalidOrder"
import {
  fetchAcceptInvalidOrder,
  fetchInvalidWaitingOrders,
  fetchRejectInvalidOrder,
  handleStandardError,
} from "@/utils"

import { updateOrder } from "./orders"

const invalidOrdersAdapter = createEntityAdapter<InvalidOrder>()

type InvalidOrdersState = EntityState<InvalidOrder> & {
  status: DataStatus | null
}

const initialState: InvalidOrdersState = invalidOrdersAdapter.getInitialState({
  status: null,
})

export const getInvalidOrders = createAsyncThunk(
  "invalidOrders/getInvalidOrders",
  async () => {
    const { response, json } = await fetchInvalidWaitingOrders()

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
    }
  }
)

export const acceptInvalidOrder = createAsyncThunk(
  "invalidOrders/acceptInvalidOrder",
  async (
    {
      id,
      body,
    }: {
      id: InvalidOrder["id"]
      body: Partial<InvalidOrder["data"]>
    },
    { dispatch }
  ) => {
    const { response, json } = await fetchAcceptInvalidOrder(id, body)
    const { data: order } = json

    if (!response.ok || !order) {
      handleStandardError(response, json)
      return { response }
    }

    dispatch(getInvalidOrders())
    dispatch(updateOrder(order))

    return {
      ok: response.ok,
      status: response.status,
      data: order,
    }
  }
)

export const rejectInvalidOrder = createAsyncThunk(
  "invalidOrders/rejectInvalidOrder",
  async (id: InvalidOrder["id"], { dispatch }) => {
    const { response, json } = await fetchRejectInvalidOrder(id)

    if (!response.ok) {
      handleStandardError(response, json)
      return { response }
    }

    dispatch(getInvalidOrders())

    return { response: { ok: true } }
  }
)

const invalidOrdersSlice = createSlice({
  name: "invalidOrders",
  initialState,
  reducers: {
    updateInvalidOrder(state, action: PayloadAction<InvalidOrder>) {
      const orderUpdate = action.payload
      const orderInState = state.entities[orderUpdate.id]

      if (orderInState) {
        invalidOrdersAdapter.upsertOne(
          state,
          deepMerge({}, orderInState, orderUpdate)
        )
      } else {
        invalidOrdersAdapter.addOne(state, orderUpdate)
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInvalidOrders.pending, (state) => {
      if (state.ids.length) {
        state.status = "updating"
      } else {
        state.status = "loading"
      }
    })

    builder.addCase(getInvalidOrders.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        invalidOrdersAdapter.setAll(state, payload.data)
        state.status = "fetched"
      } else {
        state.status = "error"
      }
    })
  },
})

const { selectAll, selectById } = invalidOrdersAdapter.getSelectors(
  (state: RootState) => state.invalidOrders
)

export const selectInvalidOrdersIds = () =>
  createSelector(selectAll, (orders) => orders.map((order) => order.id))

export const selectInvalidOrderById =
  (id: InvalidOrder["id"]) => (state: RootState) =>
    selectById(state, id)

export const selectInvalidOrdersProviders = () =>
  createSelector(selectAll, (orders) =>
    Array.from(new Set(orders.map((order) => order.data.provider)))
  )

export const selectInvalidOrdersStatus = (state: RootState) =>
  state.invalidOrders.status

export const { updateInvalidOrder } = invalidOrdersSlice.actions

export const invalidOrdersReducer = invalidOrdersSlice.reducer
