import { Button } from "@deligoo/ui"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"

import { setOrderFormStep } from "@/store/orderForm"
import {
  selectCanRestaurantCreateOrders,
  selectIsBlockedFromCreatingOrders,
  selectIsHolidayOrMaintenanceModeEnabled,
  setLockedAccountModalState,
} from "@/store/restaurant"

import cls from "../Topbar.module.scss"
import { HolidayOrMaintenanceModeModal } from "./HolidayOrMaintenanceModeModal"

export const ActionButton = () => {
  const canRestaurantCreateOrders = useSelector(selectCanRestaurantCreateOrders)

  const isBlockedFromCreatingOrders = useSelector(
    selectIsBlockedFromCreatingOrders
  )

  const isHolidayOrMaintenanceModeEnabled = useSelector(
    selectIsHolidayOrMaintenanceModeEnabled
  )

  const [holidayOrMaintenanceModeModal, setHolidayOrMaintenanceModeModal] =
    useState(false)

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const isDashboard = location.pathname === "/"

  function handleCreateOrderClick() {
    if (isHolidayOrMaintenanceModeEnabled) {
      setHolidayOrMaintenanceModeModal(true)
    } else if (isBlockedFromCreatingOrders) {
      dispatch(setLockedAccountModalState(true))
    } else {
      dispatch(setOrderFormStep("contact_detail"))
    }
  }

  if (isDashboard && canRestaurantCreateOrders) {
    return (
      <>
        <Button
          onClick={handleCreateOrderClick}
          className={cls.actionBtn}
          iconProps={{ name: "box-new" }}
          iconSide="right"
          size="extra-large"
          color="primary"
        >
          {t("buttons.create_order")}
        </Button>

        {holidayOrMaintenanceModeModal && (
          <HolidayOrMaintenanceModeModal
            handleClose={() => setHolidayOrMaintenanceModeModal(false)}
          />
        )}
      </>
    )
  }

  if (!isDashboard) {
    return (
      <Button
        onClick={() => history.push("/")}
        className={cls.actionBtn}
        iconProps={{ name: "dashboard", color: "primary" }}
        iconSide="right"
        size="extra-large"
        color="dark"
      >
        {t("buttons.dashboard")}
      </Button>
    )
  }

  return null
}
