const appUpdate = {
  "New version is available": "Dostępna jest nowa wersja",
  "Application will be restarted automatically":
    "Aplikacja zostanie zrestartowana automatycznie",
  "in {{count}} seconds_0": "za {{count}} sekundę",
  "in {{count}} seconds_1": "za {{count}} sekundy",
  "in {{count}} seconds_2": "za {{count}} sekund",
  "Update now": "Zaktualizuj teraz",
}

export default appUpdate
