import { Icon } from "@deligoo/ui"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

import { IconName, Order } from "@/types"
import { formatHour } from "@/utils"

import cls from "./DeliveryMethod.module.scss"

type DeliveryMethodProps = {
  deliveryMethod?: Order["delivery_method"]
  deliveryAt?: Order["delivery_at"]
  pickupAt?: Order["pickup_at"]
}

const DeliveryMethod = ({
  deliveryMethod,
  deliveryAt,
  pickupAt,
}: DeliveryMethodProps) => {
  const { t } = useTranslation("orders")

  const color = deliveryMethod === "delivery_now" ? "primary" : "secondary"

  return (
    <span className={clsx(cls.deliveryMethod, `text-${color}`)}>
      <Icon
        className={cls.icon}
        name={getIconName(deliveryMethod)}
        color={color}
      />
      {t(`delivery_method.${deliveryMethod}`)}
      {deliveryAt &&
        deliveryMethod === "delivery_later" &&
        ` na ${formatHour(deliveryAt)}`}
      {pickupAt &&
        deliveryMethod === "pickup_later" &&
        ` na ${formatHour(pickupAt)}`}
    </span>
  )
}

function getIconName(delivery_method?: Order["delivery_method"]): IconName {
  switch (delivery_method) {
    case "delivery_now":
      return "time-bomb"
    case "delivery_later":
      return "stoper"
    case "pickup_now":
    case "pickup_later":
      return "paper-bag"
    case "own_delivery":
      return "box-delivery" // TODO
    default:
      return "boxes"
  }
}

export { DeliveryMethod }
