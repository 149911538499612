import { Icon } from "@deligoo/ui"
import clsx from "clsx"
import dayjs, { Dayjs } from "dayjs"
import { useSelector } from "react-redux"

import { ProgressBar } from "@/components/ProgressBar"
import i18n from "@/i18n"
import { selectCurrentTime } from "@/store/global"
import { IconName, Order, OrderPoint } from "@/types"
import { formatHour } from "@/utils"
import {
  getCurrentPoint,
  getDelayColor,
  getOrderTime,
  getOrderTimeWithLate,
  getPointTime,
  hasFinished,
  isAfterPerformAt,
  isDelivery,
  isOrderProcessing,
  isPickup,
} from "@/utils/order"

import cls from "./OrderProgress.module.scss"

type OrderProgressProps = {
  order: Order
  lastOrderInLink?: Order
  className?: string
}

const OrderProgress = ({
  order,
  lastOrderInLink,
  className,
}: OrderProgressProps) => {
  const currentPoint = getCurrentPoint(order)

  const currentTime = useSelector(
    selectCurrentTime,
    // Do not trigger a rerender on finished orders
    () => !currentPoint || hasFinished(currentPoint.state)
  )

  const now = dayjs(currentTime)

  if (hasFinished(order.state)) {
    return (
      <span className={clsx(cls.state, cls[order.state], className)}>
        {getLabel(order)}

        {order.state === "finished" &&
          order.delivery?.state === "finished" &&
          order.delivery.arrived_at && // arrived_at sic!
          ` o ${formatHour(getPointTime(order.delivery)!)}`}

        <Icon
          className={cls.icon}
          name={order.state === "finished" ? "check-tiny" : "close-tiny"}
        />
      </span>
    )
  }

  if (isOrderProcessing(order.state)) {
    const pickupLate = order.pickup?.late || 0

    const late = pickupLate && pickupLate > 2 ? `(-${pickupLate} min.)` : null

    if (order.state === "pending" || order.state === "omitted") {
      const {
        pickup_range_start_time: rangeStart,
        pickup_range_end_time: rangeEnd,
      } = order

      const pickupRange =
        rangeStart &&
        rangeEnd &&
        `${formatHour(rangeStart)} - ${formatHour(rangeEnd)}`

      return (
        <ProgressBar
          className={className}
          iconName={getPointStateIcon(currentPoint)}
          label={getLabel(order)}
          value={50}
          valueRight={isAfterPerformAt(order) ? undefined : pickupRange}
          late={late}
        />
      )
    } else if (order.state === "assigned") {
      const estimatedPickupTime =
        order.pickup?.estimated_time &&
        dayjs(order.pickup.estimated_time).add(pickupLate, "minutes")

      const estimatedPickupTimeRange =
        estimatedPickupTime &&
        `${formatHour(estimatedPickupTime)} - ${formatHour(
          estimatedPickupTime.add(10, "minutes")
        )}`

      return (
        <ProgressBar
          className={className}
          iconName={getPointStateIcon(currentPoint)}
          label={getLabel(order)}
          value={50}
          valueRight={estimatedPickupTimeRange}
          late={late}
        />
      )
    }
  }

  const orderTime = getOrderTime(order)
  const orderTimeWithLate = getOrderTimeWithLate(order)

  const currentPointLate = currentPoint.late || 0

  const currentPointColor = isPickup(currentPoint) ? "success" : "primary"

  if (currentPoint.state === "in_progress") {
    return (
      <ProgressBar
        className={className}
        color={currentPointColor}
        iconName={getPointStateIcon(currentPoint)}
        value={100}
        valueRight={formatHour(orderTimeWithLate)}
        label={getLabel(order)}
      />
    )
  }

  const color =
    isPickup(currentPoint) && currentPointLate > 2
      ? getDelayColor(currentPointLate)
      : currentPointColor

  const lastOrderInLinkEstimatedTime =
    lastOrderInLink?.pickup &&
    getPointTime(lastOrderInLink.pickup) &&
    dayjs(getPointTime(lastOrderInLink.pickup)!).add(
      currentPointLate, // Yes, really
      "minute"
    )

  const valueRight = getValueRight(
    dayjs(orderTimeWithLate),
    lastOrderInLinkEstimatedTime
  )

  const shouldShowLate = isPickup(currentPoint) && currentPointLate > 2

  const late = shouldShowLate
    ? `${formatHour(orderTime)} (-${currentPointLate} min.)`
    : undefined

  const value = currentPointLate ? 80 : 50

  // - is for reversing the sign as design prefers minus sign for time delays
  const timeToEstimatedTime = -Math.floor(
    now.diff(orderTimeWithLate, "minutes", true)
  )

  const indicatorLabel =
    isPickup(currentPoint) && timeToEstimatedTime > 0
      ? `${timeToEstimatedTime} min.`
      : null

  return (
    <ProgressBar
      className={className}
      color={color}
      iconName={getPointStateIcon(currentPoint)}
      value={value}
      valueRight={valueRight}
      late={late}
      label={getLabel(order)}
      indicatorLabel={indicatorLabel}
    />
  )
}

function getValueRight(
  orderTime: Dayjs | null,
  lastOrderInLinkEstimatedTime?: Dayjs | string | null
) {
  if (orderTime) {
    if (lastOrderInLinkEstimatedTime) {
      return `${formatHour(orderTime)} - ${formatHour(
        lastOrderInLinkEstimatedTime
      )}`
    }

    return formatHour(orderTime)
  }
}

function getLabel(order: Order) {
  if (order.pickup) {
    switch (order.pickup.state) {
      case "published":
        return i18n.t("orders:order_pickup_label.published")
      case "started":
        return i18n.t("orders:order_pickup_label.started")
      case "in_progress":
        return i18n.t("orders:order_pickup_label.in_progress")
    }
  }

  if (order.delivery) {
    switch (order.delivery.state) {
      case "published":
        return i18n.t("orders:order_delivery_label.published")
      case "started":
        return i18n.t("orders:order_delivery_label.started")
      case "in_progress":
        return i18n.t("orders:order_delivery_label.in_progress")
    }
  }

  switch (order.state) {
    case "pending":
    case "omitted":
      return isAfterPerformAt(order)
        ? i18n.t("orders:order_state_label.pending")
        : i18n.t("orders:order_state_label.assigned")
    case "assigned":
      return i18n.t("orders:order_state_label.assigned")
    case "finished":
      return i18n.t("orders:order_state_label.finished")
    case "failed":
      return i18n.t("orders:order_state_label.failed")
    case "canceled":
      return i18n.t("orders:order_state_label.canceled")
    case "expired":
      return i18n.t("orders:order_state_label.expired")
  }
}

function getPointStateIcon(orderPoint: OrderPoint): IconName {
  if (orderPoint.state === "pending" || orderPoint.state === "assigned") {
    return "time-bomb"
  }
  if (orderPoint.state === "in_progress") {
    return "at-location"
  }
  if (isPickup(orderPoint)) {
    return "box-pickup"
  }
  if (isDelivery(orderPoint)) {
    return "box-delivery"
  }
  return "overdues"
}

export { OrderProgress }
