import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"

import { DataStatus, RootState, Statistics, TeamStats } from "@/types"
import { fetchStatistics } from "@/utils"

import { clearDataOnLogout } from "./auth"
import { setCurrentRestaurant } from "./restaurant"

type StatisticsState = {
  data: Statistics | null
  status: DataStatus | null
}

const initialState: StatisticsState = {
  status: null,
  data: null,
}

export const getStatistics = createAsyncThunk(
  "statistics/getStatistics",
  async () => {
    const today = dayjs()
    const { response, json } = await fetchStatistics(today, today)

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
    }
  }
)

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    updateStatistics(state, action: PayloadAction<Statistics>) {
      const updatedStatistics = action.payload
      state.data = updatedStatistics
    },
    updateTeamStats(state, action: PayloadAction<TeamStats>) {
      if (!state.data) return

      const { load_value, traffic_value } = action.payload
      state.data.load_value = load_value
      state.data.traffic_value = traffic_value
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatistics.pending, (state) => {
      if (state.data) {
        state.status = "updating"
      } else {
        state.status = "loading"
      }
    })

    builder.addCase(getStatistics.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        state.data = payload.data
        state.status = "fetched"
      } else {
        state.status = "error"
      }
    })

    builder.addCase(setCurrentRestaurant.pending, (state) => {
      state.data = null
      state.status = null
    })

    builder.addCase(clearDataOnLogout, () => initialState)
  },
})

export const selectStatisticsData = (state: RootState) => state.statistics.data

export const selectStatisticsStatus = (state: RootState) =>
  state.statistics.status

export const { updateStatistics, updateTeamStats } = statisticsSlice.actions
export const statisticsReducer = statisticsSlice.reducer
