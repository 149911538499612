const payments = {
  headers: {
    unsettled_payments: "Unsettled payments",
    order_uid: "Order number",
    pickup_date: "Pickup date",
    driver: "Courier",
    amount: "Amount",
    event: "Event",
    status: "Status",
    amount_to_settle: "Amount to settle",
  },

  summary: {
    unsettled_amount: "Unsettled amount",
    awaiting_confirmation_amount: "Awaiting confirmation",
    total: "Total",
  },

  settlement_type_label: {
    waiting_for_client: "Confirm",
    waiting_for_driver: "Waiting",
    waiting: "Settle",
    unsettled: "Settle",
  },

  refund_for: {
    for: "for",
    client: "Restaurant",
    driver: "Courier",
  },
  payment_form: {
    card: "Card",
    cash: "Cash",
    paid: "Paid",
  },
  payment_status: {
    completed: "Completed",
    in_progress: "In progress",
    failed: "Failed",
    chargeback: "Refund",
  },
  sales_document: {
    invoice: "Invoice",
    receipt: "Receipt",
  },
  modal: {
    header: {
      waiting_for_client: "Confirm payment #{{uid}}",
      waiting_for_driver: "Payment #{{uid}} is waiting",
      unsettled: "Settle payment #{{uid}}",
    },
    description: {
      waiting_for_client:
        "This transaction has been settled by the courier, if you confirm the event, confirm the settlement.",
      waiting_for_driver:
        "Payment will be settled upon confirmation by the courier.",
      unsettled:
        "If you confirm the settlement, this transaction will need to be confirmed by the courier.",
    },
    buttonText: {
      waiting_for_client: "Confirm",
      waiting_for_driver: "Confirm",
      unsettled: "Confirm",
    },
  },
}

export default payments
