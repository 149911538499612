import clsx from "clsx"
import { CSSProperties } from "react"

import cls from "./DistanceBar.module.scss"

type DistanceBarProps = {
  distance?: number
  limit?: number
}

const DistanceBar = ({ distance = 0, limit = 5 }: DistanceBarProps) => {
  const progress = Math.round((distance / limit) * 100)

  const progressStyle =
    progress >= 0 ? ({ "--progress": `${progress}%` } as CSSProperties) : {}

  const color = progress < 70 ? "success" : progress < 100 ? "warning" : "alert"

  return (
    <div className={clsx(cls.distanceBar, cls[color])}>
      <div className={cls.progress} style={progressStyle} />
      <div className={cls.label}>
        {distance.toString().replace(".", ",")} km
      </div>
    </div>
  )
}

export { DistanceBar }
