import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit"
import { toast } from "react-toastify"

import { DataStatus, RootState, UnsettledPayment } from "@/types"
import {
  fetchConfirmUnsettledPayment,
  fetchUnsettledPayments,
  handleStandardError,
  roundToTwoDecimals,
  sortByCreationTime,
  sumFullPrices,
} from "@/utils"

import { clearDataOnLogout } from "./auth"
import { setCurrentRestaurant } from "./restaurant"

const unsettledPaymentsAdapter = createEntityAdapter<UnsettledPayment>({
  sortComparer: sortByCreationTime,
})

type UnsettledPaymentsState = EntityState<UnsettledPayment> & {
  status: DataStatus | null
}

const initialState: UnsettledPaymentsState =
  unsettledPaymentsAdapter.getInitialState({ status: null })

export const getUnsettledPayments = createAsyncThunk(
  "unsettledPayments/getUnsettledPayments",
  async () => {
    const { response, json } = await fetchUnsettledPayments()

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
    }
  }
)

export const requestConfirmUnsettledPayment = createAsyncThunk(
  "unsettledPayments/confirm",
  async (paymentId: UnsettledPayment["id"], { dispatch }) => {
    const { response, json } = await fetchConfirmUnsettledPayment(paymentId)
    const { data: payment } = json

    if (!response.ok || !payment) {
      handleStandardError(response, json)
      return { ok: false }
    }

    dispatch(updateUnsettledPayment(payment))

    return {
      ok: response.ok,
      status: response.status,
    }
  }
)

const unsettledPaymentsSlice = createSlice({
  name: "unsettledPayments",
  initialState,
  reducers: {
    updateUnsettledPayment(state, action: PayloadAction<UnsettledPayment>) {
      const updatedUnsettledPayment = action.payload

      if (
        state.ids.some((id) => id === updatedUnsettledPayment.id) &&
        updatedUnsettledPayment.settlement_type === "settled"
      ) {
        unsettledPaymentsAdapter.removeOne(state, updatedUnsettledPayment.id)

        toast.success(
          `Płatność #${updatedUnsettledPayment.uid} została rozliczona`
        )
      } else {
        unsettledPaymentsAdapter.upsertOne(state, updatedUnsettledPayment)
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUnsettledPayments.pending, (state) => {
      if (state.ids.length) {
        state.status = "updating"
      } else {
        state.status = "loading"
      }
    })

    builder.addCase(getUnsettledPayments.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        unsettledPaymentsAdapter.setAll(state, payload.data)
        state.status = "fetched"
      } else {
        state.status = "error"
      }
    })

    builder.addCase(setCurrentRestaurant.pending, (state) => {
      unsettledPaymentsAdapter.removeAll(state)
      state.status = null
    })

    builder.addCase(clearDataOnLogout, () => initialState)
  },
})

const { selectAll, selectById, selectIds } =
  unsettledPaymentsAdapter.getSelectors(
    (state: RootState) => state.unsettledPayments
  )

export const selectUnsettledPaymentsIds = (state: RootState) =>
  selectIds(state) as number[] // TODO

export const selectUnsettledPaymentById =
  (id: UnsettledPayment["id"]) => (state: RootState) =>
    selectById(state, id)

const selectAmount = (predicate: (payment: UnsettledPayment) => boolean) =>
  createSelector(selectAll, (payments) =>
    roundToTwoDecimals(sumFullPrices(payments.filter(predicate)))
  )

const onlyWaitingForClient = (payment: UnsettledPayment) =>
  payment.settlement_type === "waiting_for_client"

export const selectUnsettledAmount = selectAmount(
  (payment) => !onlyWaitingForClient(payment)
)

export const selectAwaitingConfirmationAmount =
  selectAmount(onlyWaitingForClient)

export const selectUnsettledPaymentsStatus = (state: RootState) =>
  state.unsettledPayments.status

export const { updateUnsettledPayment } = unsettledPaymentsSlice.actions
export const unsettledPaymentsReducer = unsettledPaymentsSlice.reducer
