import { FullCity } from "@deligoo/shared"
import { Autocomplete, AutocompleteField, Field } from "@deligoo/ui"
import { debounce } from "lodash"
import { ComponentProps, useEffect, useRef, useState } from "react"

import { fetchCitySuggestions, useIsMounted } from "../../utils"

type CityFieldProps = {
  value: string
  setValue: (value: FullCity) => void
  onSuggestionsChange?: (suggestions: FullCity[]) => void
  translations?: Autocomplete["translations"]
} & ComponentProps<typeof Field>

export const CityField = ({ value, setValue, onSuggestionsChange, translations, ...props }: CityFieldProps) => {
  const [citySuggestions, setCitySuggestions] = useState<FullCity[]>([])
  const [suggestionStatus, setSuggestionStatus] = useState<Autocomplete["status"]>(null)

  const [currentCityValue, setCurrentCityValue] = useState(value)

  const isMounted = useIsMounted()

  const debouncedGetCities = useRef(
    debounce(async (currentCityValue) => {
      if (!currentCityValue || currentCityValue.length < 2) {
        setSuggestionStatus(null)
        setCitySuggestions([])
        return
      }

      setSuggestionStatus("loading")

      const { response, json } = await fetchCitySuggestions(currentCityValue)

      if (!isMounted()) return

      if (!response.ok) {
        setSuggestionStatus("error")
        return
      }

      setCitySuggestions(json.data || [])
      setSuggestionStatus("fetched")
    }, 300)
  ).current

  useEffect(() => {
    debouncedGetCities(currentCityValue)

    return () => {
      debouncedGetCities.cancel()
    }
  }, [currentCityValue, debouncedGetCities])

  useEffect(() => {
    if (onSuggestionsChange) onSuggestionsChange(citySuggestions)
  }, [citySuggestions, onSuggestionsChange])

  return (
    <AutocompleteField
      required
      label="Miasto"
      name="city"
      currentValue={currentCityValue}
      setCurrentValue={setCurrentCityValue}
      inputProps={{ id: "city", ...props.inputProps }}
      autocomplete={{
        status: suggestionStatus,
        values: citySuggestions,
        onValueSelect: (value) => {
          setValue(value as FullCity)
          setCurrentCityValue((value as FullCity).city)
        },
        valueToString: (value) => (value as FullCity).city,
        valueToSubtitle: (value) => `${(value as FullCity).county}, ${(value as FullCity).municipality}`,
        translations,
      }}
      {...props}
    />
  )
}
