import { Button, Modal } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { batch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import { useAppDispatch } from "@/App"
import {
  setOrderFormData,
  setOrderFormStep,
  setOrderId,
  submitOrderFormStep,
} from "@/store/orderForm"
import { requestCancelOrder, selectOrderById } from "@/store/orders"
import { fetchOrderSteps, useIsMounted } from "@/utils"
import { canBeCanceled } from "@/utils/order"

import { ActionProps } from "."
import cls from "./actions.module.scss"

type ReenterOrderActionProps = ActionProps & {
  handleModalClose: () => void
}

const ReenterOrder = ({
  orderId,
  handleClose,
  handleModalClose,
  isParentMounted,
  isActionInProgress,
  setIsActionInProgress,
}: ReenterOrderActionProps) => {
  const order = useSelector(selectOrderById(orderId))

  const dispatch = useAppDispatch()

  const isMounted = useIsMounted()

  const { t } = useTranslation(["orders", "common"])

  // This action was previously in Redux, but since it is a compound
  // action that dispatches other async actions it made error handling difficult
  // This is a stop-gap solution until we come up with a better idea
  async function handleReenterOrder() {
    if (!orderId || !order) return

    setIsActionInProgress(true)

    // Fetch order to reenter's steps
    const { response, json } = await fetchOrderSteps(orderId)

    if (!response.ok || !json.data || !json.data.steps) {
      toast.error(t("reenter_order_modal.fetch_order_error"))
      return { ok: false }
    }

    const { steps: orderFormData } = json.data

    async function cancelOrderToReenter() {
      const cancellationReason = "Zlecenie wprowadzone ponownie"
      const withRefund = order!.provider !== "shop"

      const { ok: hasOrderBeenCanceled } = await dispatch(
        requestCancelOrder({ orderId, cancellationReason, withRefund })
      ).then(unwrapResult)

      return { hasOrderBeenCanceled }
    }

    async function createReenteredOrder() {
      const { ok: hasOrderBeenCreated, orderId } = await dispatch(
        submitOrderFormStep({
          stepKey: "contact_detail",
          stepData: orderFormData.contact_detail,
        })
      ).then(unwrapResult)

      if (!hasOrderBeenCreated || !orderId) {
        toast.error(t("reenter_order_modal.create_order_error"))
        return { ok: false }
      }

      isMounted() && handleClose()
      isParentMounted() && handleModalClose()

      batch(() => {
        dispatch(setOrderFormData(orderFormData))
        dispatch(setOrderId(orderId))
        dispatch(setOrderFormStep("payment_and_packages"))
      })
    }

    if (canBeCanceled(order)) {
      const { hasOrderBeenCanceled } = await cancelOrderToReenter()
      if (!hasOrderBeenCanceled) {
        isParentMounted() && setIsActionInProgress(false)
        return
      }
    }

    await createReenteredOrder()

    isParentMounted() && setIsActionInProgress(false)
  }

  if (!orderId || !order) return null

  return (
    <Modal
      handleClose={handleClose}
      className={clsx(cls.actionModal, cls.confirm)}
      light
    >
      <Modal.Title>{t("reenter_order_modal.title")}</Modal.Title>

      <Modal.Content>
        <div className={cls.content}>{t("reenter_order_modal.desc")}</div>

        <div className={cls.actions}>
          <Button
            onClick={handleClose}
            className={cls.action}
            color="alert"
            size="extra-large"
          >
            {t("common:buttons.no")}
          </Button>

          <Button
            onClick={handleReenterOrder}
            className={cls.action}
            color="success"
            size="extra-large"
            loading={isActionInProgress}
          >
            {t("common:buttons.yes")}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export { ReenterOrder }
