import {
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit"

import {
  Address,
  checkIsContactDetails,
  DataStatus,
  Order,
  OrderFormData,
  OrderFormDataStatus,
  OrderFormStepKey,
  OrderStepKey,
  RootState,
  SubmitOrderStep,
} from "@/types"
import {
  fetchContactDetails,
  fetchCreateOrder,
  fetchUpdateOrderStep,
  handleStandardError,
} from "@/utils"

import { clearDataOnLogout } from "./auth"
import { updateOrder } from "./orders"

type OrderFormState = {
  orderId: Order["id"] | null
  addressList: Address[] | null
  addressListStatus: Omit<DataStatus, "updating"> | null
  currentStep: OrderFormStepKey | null
  formData: OrderFormData
  formDataStatus: OrderFormDataStatus
}

const initialState: OrderFormState = {
  orderId: null,
  addressList: null,
  addressListStatus: null,
  currentStep: null,
  formData: {
    contact_detail: null,
    payment_and_packages: null,
    processing_time: null,
    notes: null,
  },
  formDataStatus: {
    contact_detail: null,
    payment_and_packages: null,
    processing_time: null,
    notes: null,
  },
}

export const getAddressList = createAsyncThunk(
  "orderForm/getAddressList",
  async (phone: string) => {
    const { response, json } = await fetchContactDetails(phone)

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
    }
  }
)

export const submitOrderFormStep = createAsyncThunk(
  "orderForm/submitOrderStep",
  async ({ stepKey, stepData, orderId }: SubmitOrderStep, { dispatch }) => {
    // TODO: Use getState() to check for orderId
    const fetchResponse = orderId
      ? await fetchUpdateOrderStep(orderId, stepKey, stepData)
      : checkIsContactDetails(stepData) && (await fetchCreateOrder(stepData))

    if (!fetchResponse) return { ok: false, stepKey }

    const { response, json } = fetchResponse
    const { data: order, errors } = json

    if (!response.ok) {
      handleStandardError(response, json)
    }

    if (order) dispatch(updateOrder(order))

    return {
      ok: response.ok,
      status: response.status,
      orderId: order?.id || null,
      stepData,
      stepKey,
      errors,
    }
  }
)

const orderFormSlice = createSlice({
  name: "orderForm",
  initialState,
  reducers: {
    setOrderFormStep(state, action: PayloadAction<OrderFormStepKey | null>) {
      if (action.payload) {
        state.currentStep = action.payload
      } else {
        return initialState
      }
    },
    setOrderFormStepData<K extends OrderStepKey>(
      state: Draft<OrderFormState>,
      action: PayloadAction<{ stepKey: K; stepData: OrderFormData[K] }>
    ) {
      const step = action.payload.stepKey
      state.formData[step] = action.payload.stepData
    },
    setOrderFormData(state, action: PayloadAction<OrderFormData>) {
      state.formData = action.payload
    },
    setOrderId(state, action: PayloadAction<Order["id"]>) {
      state.orderId = action.payload
    },
    resetAddressList(state) {
      state.addressList = null
      state.addressListStatus = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAddressList.pending, (state) => {
      state.addressListStatus = "loading"
    })

    builder.addCase(getAddressList.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        state.addressList = payload.data
        state.addressListStatus = "fetched"
      } else {
        state.addressListStatus = "error"
      }
    })

    builder.addCase(submitOrderFormStep.pending, (state, action) => {
      const step = action.meta.arg.stepKey
      state.formDataStatus[step] = "loading"
    })

    builder.addCase(submitOrderFormStep.fulfilled, (state, { payload }) => {
      if (!payload) return

      const step = payload.stepKey

      if (payload.ok) {
        if (payload.orderId) state.orderId = payload.orderId
        state.formDataStatus[step] = "submitted"
      } else {
        state.formDataStatus[step] = "error"
      }
    })

    builder.addCase(clearDataOnLogout, () => initialState)
  },
})

export const selectCurrentOrderFormStep = (state: RootState) =>
  state.orderForm.currentStep

export const selectCurrentOrderFormOrderId = (state: RootState) =>
  state.orderForm.orderId

export const selectCurrentOrderFormOrder = (state: RootState) =>
  state.orderForm.orderId
    ? state.orders.entities[state.orderForm.orderId]
    : null

export const selectAddressList = (state: RootState) =>
  state.orderForm.addressList

export const selectAddressListStatus = (state: RootState) =>
  state.orderForm.addressListStatus

export const selectOrderFormStepData =
  <T extends keyof OrderFormData>(key: T) =>
  (state: RootState) =>
    state.orderForm.formData?.[key]

export const selectOrderFormCurrentStepStatus = (state: RootState) =>
  state.orderForm.currentStep
    ? state.orderForm.formDataStatus[state.orderForm.currentStep]
    : null

export const {
  setOrderFormStep,
  setOrderFormStepData,
  setOrderFormData,
  setOrderId,
  resetAddressList,
} = orderFormSlice.actions
export const orderFormReducer = orderFormSlice.reducer
