import Cookies from "js-cookie"
import { ReactNode, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { SITES } from "@/sites"
import { useSockets } from "@/sockets"
import { requestClient, selectIsAuthenticated } from "@/store/auth"
import { tick } from "@/store/global"
import { getInvalidOrders, selectInvalidOrdersIds } from "@/store/invalidOrders"
import {
  getMessages,
  selectIsAnyUnreadImportantMessage,
} from "@/store/messages"
import {
  getNotifications,
  selectIsAnyUnreadImportantNotification,
} from "@/store/notifications"
import {
  getOrders,
  getWaitingOrders,
  selectOrdersToReceiveIds,
  selectPostponedOrdersIds,
} from "@/store/orders"
import {
  selectCurrentRestaurantKey,
  selectCurrentRestaurantStatus,
  setIsAppAlertHidden,
} from "@/store/restaurant"
import { getDefaultSite, Sounds, useIsMounted } from "@/utils"

type GlobalStateProps = {
  children: ReactNode
}

const GlobalState = ({ children }: GlobalStateProps) => {
  const isMounted = useIsMounted()

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const currentRestaurantUuid = useSelector(selectCurrentRestaurantKey("uuid"))

  const ordersToReceiveIds = useSelector(selectOrdersToReceiveIds())
  const postponedOrdersIds = useSelector(selectPostponedOrdersIds)
  const invalidOrdersIds = useSelector(selectInvalidOrdersIds())

  const alert = useSelector(selectCurrentRestaurantKey("alert"))

  const restaurantStatus = useSelector(selectCurrentRestaurantStatus)

  const isAnyUnreadImportantMessage = useSelector(
    selectIsAnyUnreadImportantMessage
  )

  const isAnyUnreadImportantNotification = useSelector(
    selectIsAnyUnreadImportantNotification
  )

  const dispatch = useDispatch()

  useEffect(() => {
    const clockUpdater = setInterval(() => {
      isMounted() && dispatch(tick())
    }, 15000)

    return () => window.clearInterval(clockUpdater)
  }, [isMounted, dispatch])

  useEffect(() => {
    const lsSite = localStorage.getItem("site")
    const adminAuthHost = Cookies.get("pos_admin_auth_host")

    if (adminAuthHost) {
      const adminAuthSite = SITES.find((site) => site.appHost === adminAuthHost)
      localStorage.setItem("site", JSON.stringify(adminAuthSite))
      Cookies.remove("pos_admin_auth_host", { domain: ".deligoo.pl" })
    } else if (!lsSite) {
      localStorage.setItem("site", JSON.stringify(getDefaultSite()))
    }
  }, [])

  useEffect(() => {
    const lsToken = localStorage.getItem("token")
    const lsSite = localStorage.getItem("site")

    if (!isAuthenticated || !lsToken || !lsSite) return

    dispatch(requestClient())
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    if (!isAuthenticated || !currentRestaurantUuid) return

    dispatch(getMessages())
    dispatch(getNotifications())
    dispatch(getOrders())
    dispatch(getWaitingOrders())
    dispatch(getInvalidOrders())
  }, [dispatch, isAuthenticated, currentRestaurantUuid])

  useEffect(() => {
    let intervalId: number | undefined

    const hasPendingOrders =
      ordersToReceiveIds.filter(
        (orderId) => !postponedOrdersIds.some((id) => id === orderId)
      ).length || invalidOrdersIds.length

    if (hasPendingOrders && !intervalId) {
      Sounds.playReceiveOrderSound()
      intervalId = window.setInterval(
        () => Sounds.playReceiveOrderSound(),
        3000
      )
    } else {
      window.clearInterval(intervalId)
    }

    return () => {
      window.clearInterval(intervalId)
    }
  }, [invalidOrdersIds, ordersToReceiveIds, postponedOrdersIds])

  useEffect(() => {
    let intervalId: number | undefined

    if (isAnyUnreadImportantMessage && !intervalId) {
      intervalId = window.setInterval(() => Sounds.playMessageSound(), 10_000)
    } else {
      window.clearInterval(intervalId)
    }

    return () => {
      window.clearInterval(intervalId)
    }
  }, [isAnyUnreadImportantMessage])

  useEffect(() => {
    let intervalId: number | undefined

    if (isAnyUnreadImportantNotification && !intervalId) {
      intervalId = window.setInterval(
        () => Sounds.playNotificationSound("high"),
        10_000
      )
    } else {
      window.clearInterval(intervalId)
    }

    return () => {
      window.clearInterval(intervalId)
    }
  }, [isAnyUnreadImportantNotification])

  useEffect(() => {
    if (restaurantStatus !== "fetched") return
    if (!alert) localStorage.removeItem("hidden_alert_text")

    const hiddenAlert = localStorage.getItem("hidden_alert_text")
    const isRead = alert ? alert === hiddenAlert : true

    dispatch(setIsAppAlertHidden(isRead))
  }, [alert, restaurantStatus, dispatch])

  useSockets()

  return <>{children}</>
}

export { GlobalState }
