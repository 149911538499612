import { Button } from "@deligoo/ui"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { selectSettingsKey } from "@/store/client"
import { printOrderData } from "@/utils"

const TEST_PRINT_DATA =
  "e@e!\u0010ea\u0001DELIGOO.PL\ne@ed\u0003Restauracja Testowa\nDietla 52/5, 33-332 Krakow\n123456789\n\nDATA ZAMOWIENIA\n2020-03-03 o 08:43\n\nNUMER ZAMOWIENIA:#{order.uid}\n\nDANE KLIENTA\ntel: 123456789\n \nJozefa Wybickiego 10, 31-261 Krakow\n\nFORMA PLATNOSCI: GOTOWKA\n\nDostawa - Jak najszybciej\n\n\n\nKoszt dostawy: 5 zl\nLaczny koszt: 35 zl\n\ned\u0003"

export const PrintingTestButton = () => {
  const settings = useSelector(selectSettingsKey("settings_attributes"))

  const { automatic_receipt_printing = false } = settings || {}

  const [isPrinting, setIsPrinting] = useState(false)

  const { t } = useTranslation("settings")

  async function testPrinting() {
    setIsPrinting(true)
    await printOrderData(TEST_PRINT_DATA)
    setIsPrinting(false)
  }

  if (!automatic_receipt_printing) return null

  return (
    <>
      {!window.KBBluetooth ? (
        <span className="text-error">{t("errors.bt_interface")}</span>
      ) : (
        <Button onClick={testPrinting} loading={isPrinting}>
          {t("buttons.printing_test")}
        </Button>
      )}
    </>
  )
}
