import { LatLngExpression, LatLngTuple, Polyline } from "leaflet"
import { useEffect, useState } from "react"
import { useMap } from "react-leaflet"

export function useRoutePolyline(
  origin: LatLngTuple | null,
  destination: LatLngTuple | null
) {
  const map = useMap()

  const [routePolyline, setRoutePolyline] = useState<LatLngExpression[] | null>(
    null
  )

  useEffect(() => {
    if (!origin || !destination) return

    async function fetchRoutePolyline() {
      const response = await fetch(
        `https://vrp.deligoo.pl/ors/v2/directions/driving-car?start=${
          origin![1]
        },${origin![0]}&end=${destination![1]},${destination![0]}`
      )

      const json = await response.json()

      if (json?.features[0]) {
        const polyline = json?.features[0]?.geometry?.coordinates?.map(
          (item: LatLngExpression[]) => [item[1], item[0]]
        ) as LatLngExpression[]

        setRoutePolyline((current) => {
          // Fit map to polyline bounds on first polyline render
          if (!current) {
            map.fitBounds(new Polyline(polyline).getBounds(), {
              padding: [32, 32],
            })
          }

          return polyline
        })
      }
    }

    fetchRoutePolyline()
  }, [map, origin, destination])

  return routePolyline
}
