import { Backdrop, Button } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useAppDispatch } from "@/App"
import { DriverInfo } from "@/components/DriverInfo"
import { Price } from "@/components/Price"
import {
  requestConfirmUnsettledPayment,
  selectUnsettledPaymentById,
} from "@/store/unsettledPayments"
import { UnsettledPayment } from "@/types"
import { useIsMounted } from "@/utils"

import cls from "./PaymentConfirmModal.module.scss"
import { RefundEvent } from "./RefundEvent"

type PaymentConfirmModalProps = {
  paymentToConfirmId: UnsettledPayment["id"]
  handleClose: () => void
}

const PaymentConfirmModal = ({
  paymentToConfirmId,
  handleClose,
}: PaymentConfirmModalProps) => {
  const payment = useSelector(selectUnsettledPaymentById(paymentToConfirmId))
  const [isActionInProgress, setIsActionInProgress] = useState(false)
  const isMounted = useIsMounted()

  const dispatch = useAppDispatch()

  const { t } = useTranslation("payments")

  function handleConfirmPayment() {
    if (!paymentToConfirmId) return
    isMounted() && setIsActionInProgress(true)

    dispatch(requestConfirmUnsettledPayment(paymentToConfirmId))
      .then(unwrapResult)
      .then(() => {
        isMounted() && handleClose()
      })
      .finally(() => {
        isMounted() && setIsActionInProgress(false)
      })
  }

  if (!payment) return null

  return (
    <Backdrop>
      <div className={cls.confirmModal}>
        <Button
          className={cls.closeBtn}
          variation="text"
          iconProps={{
            className: cls.icon,
            name: "close-circle",
            color: "muted",
            size: "large",
          }}
          onClick={handleClose}
        />
        <div className={cls.contentWrapper}>
          <div className={cls.content}>
            <header className={clsx(cls.section)}>
              <div className="h600 mb-2">
                {t(`modal.header.${payment.settlement_type}`, {
                  uid: payment.uid,
                })}
              </div>
              <div className="text-large text-muted">
                {t(`modal.description.${payment.settlement_type}`)}
              </div>
            </header>
            <div className={clsx(cls.infoSection, cls.section)}>
              <Price className="h600" subunit>
                {payment.full_price_subunit}
              </Price>
              <div className="text-muted">{t("headers.amount_to_settle")}</div>
            </div>
            <div className={clsx(cls.infoSection, cls.section)}>
              <RefundEvent
                className={cls.refundEvent}
                inOrder={payment.payment_method_in_order}
                duringDelivery={payment.payment_method_during_delivery}
                labels
              />
            </div>
            <div className={clsx(cls.infoSection, cls.section)}>
              <div className={cls.driver}>
                {payment.driver ? <DriverInfo driver={payment.driver} /> : "–"}
              </div>
              <div className="text-muted">{t("headers.driver")}</div>
            </div>
          </div>
          <Button
            className={cls.button}
            color="success"
            size="extra-large"
            fullWidth
            iconProps={{ name: "check", size: "small" }}
            iconSide="right"
            loading={isActionInProgress}
            onClick={handleConfirmPayment}
            disabled={payment.settlement_type === "waiting_for_driver"}
          >
            {t(`modal.buttonText.${payment.settlement_type}`)}
          </Button>
        </div>

        <div className={cls.figure}>
          <img src={require("./credit-card-man.svg").default} alt="" />
        </div>
      </div>
    </Backdrop>
  )
}

function usePaymentConfirmModal(): [
  UnsettledPayment["id"] | null,
  (id: UnsettledPayment["id"] | null) => void
] {
  const [paymentToConfirmId, setPaymentToConfirmId] = useState<
    UnsettledPayment["id"] | null
  >(null)

  return [paymentToConfirmId, setPaymentToConfirmId]
}

export { PaymentConfirmModal, usePaymentConfirmModal }
