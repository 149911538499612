import { fetchCitySuggestions, Field } from "@deligoo/ui"
import {
  ComponentProps,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { AutocompleteField } from "@/components/AutocompleteField"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { City, DataStatus } from "@/types"
import { debounce, useIsMounted } from "@/utils"

const CityField = forwardRef<HTMLInputElement, ComponentProps<typeof Field>>(
  ({ inputProps, ...props }, ref) => {
    const defaultCity = useSelector(selectCurrentRestaurantKey("city"))
    const defaultState = useSelector(selectCurrentRestaurantKey("state"))
    const defaultCounty = useSelector(selectCurrentRestaurantKey("county"))
    const defaultMunicipality = useSelector(
      selectCurrentRestaurantKey("municipality")
    )

    const { clearErrors, setError, setValue, getValues, errors, register } =
      useFormContext()

    const [citySuggestions, setCitySuggestions] = useState<City[]>([])
    const [suggestionsStatus, setSuggestionStatus] = useState<DataStatus>()

    const isMounted = useIsMounted()

    const { t } = useTranslation()

    function handleBlur(e: FocusEvent<HTMLInputElement>) {
      validateSuggestions()
      inputProps?.onBlur?.(e)
    }

    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
      setValue("state", "")
      setValue("county", "")
      setValue("municipality", "")

      getCities()
      resetAutocomplete()
      inputProps?.onKeyDown?.(e)
    }

    function resetAutocomplete() {
      setSuggestionStatus(undefined)
      setCitySuggestions([])
    }

    const validateSuggestions = useCallback(() => {
      if (errors.city) return

      const currentCity = getValues("city")
      const isReady = suggestionsStatus === "fetched"

      const isValidCity = citySuggestions?.find(
        (address) => address?.city === currentCity
      )

      if (isReady && !isValidCity) {
        setError("city", { message: `${t("errors.select_city")}` })
      } else {
        clearErrors("city")
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      suggestionsStatus,
      citySuggestions,
      getValues,
      setError,
      clearErrors,
      errors,
    ])

    const getCities = useMemo(() => {
      async function getCitySuggestions() {
        const city = getValues("city")

        resetAutocomplete()

        if (!city || city?.length < 2) return

        setSuggestionStatus("loading")

        const {
          json: { data },
        } = await fetchCitySuggestions(city)

        if (!isMounted()) return

        if (data) {
          setCitySuggestions(data)
          setSuggestionStatus("fetched")
        } else {
          resetAutocomplete()
        }
      }

      return debounce(getCitySuggestions, 1000)
    }, [getValues, isMounted])

    useEffect(() => {
      validateSuggestions()
    }, [citySuggestions, suggestionsStatus, validateSuggestions])

    return (
      <>
        <AutocompleteField
          autocomplete={{
            values: citySuggestions,
            status: suggestionsStatus,
            onValueSelect: (value) => {
              setValue("city", value.city)
              setValue("state", value.state)
              setValue("county", value.county)
              setValue("municipality", value.municipality)
            },
            valueToString: (value) => value.city!,
            valueToSubtitle: (value) => `${value.state}, ${value.county}`,
          }}
          inputProps={{
            ...inputProps,
            onBlur: handleBlur,
            onKeyDown: handleKeyDown,
            defaultValue: defaultCity,
          }}
          ref={ref}
          {...props}
        />

        <input
          name="state"
          defaultValue={defaultState}
          style={{ display: "none" }}
          ref={register}
        />

        <input
          name="county"
          defaultValue={defaultCounty}
          style={{ display: "none" }}
          ref={register}
        />

        <input
          name="municipality"
          defaultValue={defaultMunicipality}
          style={{ display: "none" }}
          ref={register}
        />
      </>
    )
  }
)

export { CityField }
