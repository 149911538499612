import { Button, Icon, Modal } from "@deligoo/ui"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useAppDispatch } from "@/App"
import { requestDataRefresh } from "@/sockets"
import { setIsDataRefreshNeeded } from "@/store/global"
import { Sounds } from "@/utils"

export const RefreshNeededModal = () => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  useEffect(() => {
    Sounds.playNotificationSound("high")

    let intervalId: number | undefined
    if (!intervalId) {
      intervalId = window.setInterval(
        () => Sounds.playNotificationSound("high"),
        5_000
      )
    } else {
      window.clearInterval(intervalId)
    }

    return () => {
      window.clearInterval(intervalId)
    }
  }, [])

  return (
    <Modal handleClose={() => null}>
      <Modal.Title>
        <Icon name="warnings" color="alert" gutter="right" size="large" />
        {t("alerts.data_sync_required")}
      </Modal.Title>
      <Modal.Content>{t("alerts.connection_established")}</Modal.Content>
      <Modal.Footer className="pt-0">
        <Button
          fullWidth
          color="primary"
          onClick={() => {
            dispatch(setIsDataRefreshNeeded(false))
            dispatch(requestDataRefresh())
          }}
        >
          {t("buttons.sync")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
