import { ThemeColor } from "@deligoo/ui"
import { useTranslation } from "react-i18next"

import { Card } from "@/components/Card"
import { Gauge } from "@/components/Gauge"
import colors from "@/scss/colors.module.scss"
import { Statistics } from "@/types"
import { formatTimeDuration } from "@/utils"

import cls from "../Statistics.module.scss"

type AveragePreparationTimeProps = {
  loading: boolean
  value?: Statistics["average_preparation_time_duration"]
  className?: string
}

const AveragePreparationTime = ({
  loading,
  value,
  className,
}: AveragePreparationTimeProps) => {
  const { t } = useTranslation("statistics")

  return (
    <Card loading={loading} className={className} sections inline>
      <Card.Section>
        <Gauge
          fillColors={[
            { min: 0, max: 0.33, color: colors.success },
            { min: 0.33, max: 0.66, color: colors.warning },
            { min: 0.66, max: 1, color: colors.alert },
          ]}
          fraction={value ? getAvgPrepTimeFraction(value.interval) : 0}
          iconName="clock"
          className={cls.chart}
        />
      </Card.Section>
      <Card.Section>
        <Card.Title
          color={getLabelColor(
            value ? getAvgPrepTimeFraction(value.interval) : 0
          )}
        >
          {value && formatTimeDuration(value)}
        </Card.Title>
        {t("average_preparation_time_duration")}
      </Card.Section>
    </Card>
  )
}

function getAvgPrepTimeFraction(interval = 0) {
  const minutes = interval / 60
  return minutes <= 60 ? minutes / 60 : 1
}

function getLabelColor(fraction = 0): ThemeColor {
  return fraction > 0.66 ? "alert" : fraction > 0.33 ? "warning" : "success"
}

export { AveragePreparationTime }
