import { Button } from "@deligoo/ui"
import { useTranslation } from "react-i18next"

import { LOCALES } from "@/i18n"
import { usePersistLocale } from "@/utils"

export const LocaleChangeForm = () => {
  const { t, i18n } = useTranslation(["settings", "common"])

  usePersistLocale(i18n.language)

  return (
    <div className="d-flex">
      {LOCALES.map((locale) => (
        <Button
          onClick={() => i18n.changeLanguage(locale)}
          color={i18n.language === locale ? "primary" : undefined}
          key={locale}
          className="mr-5"
        >
          {t(`common:${locale}`)}
        </Button>
      ))}
    </div>
  )
}
