const getApps = {
  pos_app: "DeliGoo POS",

  step: "Step {{count}}",

  step_1_title: "Watch video",
  step_1_description:
    "Check step by step how to grant permissions and install the application.",
  step_1_cta: "Watch",

  step_2_title: "Download DeliGoo POS",
  step_2_description: "Order panel application for Android devices.",
  step_2_cta: "Download",

  step_3_title: "AnyDesk remote support",
  step_3_description:
    "Application for remote device management in case you need to contact support.",
  step_3_cta: "Visit",
}

export default getApps
