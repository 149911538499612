import "./Toast.scss"

import { Button, Icon } from "@deligoo/ui"
import clsx from "clsx"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

import cls from "./AppUpdateToast.module.scss"

const AppUpdateToast = ({ onButtonClick }: { onButtonClick: () => void }) => {
  const [now, setNow] = useState(dayjs().toISOString())
  const [forcedUpdateTimestamp] = useState(
    dayjs().add(5, "minutes").toISOString()
  )

  const timeToUpdate = Math.round(
    dayjs(forcedUpdateTimestamp).diff(dayjs(now)) / 1000
  )

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setNow(dayjs().toISOString())
    }, 1000)

    return () => window.clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (dayjs(forcedUpdateTimestamp).isBefore(dayjs(now))) onButtonClick()
  }, [now, forcedUpdateTimestamp, onButtonClick])

  const { t } = useTranslation("appUpdate")

  return (
    <div>
      <span className={cls.info}>
        <header>
          <Icon name="warnings" color="warning" gutter="right" />
          {`${t("New version is available")}.`}
        </header>
        <span className={clsx(timeToUpdate <= 15 && "text-alert")}>{`${t(
          "Application will be restarted automatically"
        )} ${t("in {{count}} seconds", {
          count: timeToUpdate,
        })}.`}</span>
      </span>
      <Button
        className={cls.updateButton}
        size="large"
        color="success"
        onClick={onButtonClick}
        fullWidth
      >
        {t("Update now")}
      </Button>
    </div>
  )
}

const showAppUpdateToast = (onButtonClick: () => void) => {
  const toastId = "appUpdate"

  return toast(<AppUpdateToast onButtonClick={onButtonClick} />, {
    toastId,
    className: cls.appUpdateToast,
    position: "bottom-right",
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
    draggable: false,
  })
}

export { AppUpdateToast, showAppUpdateToast }
