import { Message } from "@/types"

import cls from "../Messages.module.scss"
import { MessageItem } from "./MessageItem"

type MessagesListProps = {
  messages: Message[] | null
  selectedMessage: Message | null
  selectMessage: (message: Message) => void
}

const MessagesList = ({
  messages,
  selectedMessage,
  selectMessage,
}: MessagesListProps) => {
  return (
    <div className={cls.list}>
      <ul className={cls.messagesList}>
        {messages?.map((message) => (
          <MessageItem
            key={message.id}
            message={message}
            active={message.id === selectedMessage?.id}
            selectMessage={selectMessage}
          />
        ))}
      </ul>
    </div>
  )
}

export { MessagesList }
