import { Button } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import { useTranslation } from "react-i18next"

import { useAppDispatch } from "@/App"
import { requestCancelOrder } from "@/store/orders"

import cls from "../ReceiveOrderModal.module.scss"
import { ActionButtonProps } from "."

const RejectButton = ({
  orderId,
  loading,
  isParentMounted,
  handleModalClose,
  setIsActionInProgress,
}: ActionButtonProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation("orders")

  function handleCancelOrder() {
    if (!orderId) return
    isParentMounted() && setIsActionInProgress(true)

    const cancellationReason = "Zlecenie zostało odrzucone przez restaurację"

    dispatch(requestCancelOrder({ orderId, cancellationReason }))
      .then(unwrapResult)
      .then(() => {
        handleModalClose()
      })
      .finally(() => {
        isParentMounted() && setIsActionInProgress(false)
      })
  }

  return (
    <Button
      className={cls.button}
      color="alert"
      size="extra-large"
      fullWidth
      iconProps={{ name: "close", size: "small" }}
      iconSide="right"
      onClick={handleCancelOrder}
      loading={loading}
    >
      {t("orders_to_receive.actions.reject")}
    </Button>
  )
}

export { RejectButton }
