import clsx from "clsx"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Redirect, useHistory, useLocation } from "react-router-dom"

import { DatePicker } from "@/components/DatePicker"
import { selectInvalidOrdersIds } from "@/store/invalidOrders"
import {
  selectOrdersReceivedIds,
  selectOrdersToReceiveIds,
  selectOrdersUnsuccessfullyReceivedIds,
} from "@/store/orders"
import { selectCanRestaurantReceiveOrders } from "@/store/restaurant"

import cls from "./OrdersToReceive.module.scss"
import { OrdersToReceiveList, OrdersToReceiveTabs } from "./partials"
import { InvalidOrdersList } from "./partials/InvalidOrdersList"

const PATHS = [
  "/odbieranie-zlecen",
  "/odbieranie-zlecen/odebrane",
  "/odbieranie-zlecen/nieudane",
  "/odbieranie-zlecen/nieprawidlowe",
]

export const OrdersToReceive = () => {
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"))

  const canReceiveOrders = useSelector(selectCanRestaurantReceiveOrders)
  const ordersToReceiveIds = useSelector(selectOrdersToReceiveIds())
  const ordersReceivedIds = useSelector(selectOrdersReceivedIds(dayjs(date)))
  const ordersUnsuccessfullyReceivedIds = useSelector(
    selectOrdersUnsuccessfullyReceivedIds(dayjs(date))
  )
  const invalidOrdersIds = useSelector(selectInvalidOrdersIds())

  function handleDateChange(date: Date | null) {
    date && setDate(dayjs(date).format("YYYY-MM-DD"))
  }

  const location = useLocation()
  const history = useHistory()

  const ordersToReceiveCount = ordersToReceiveIds.length
  const ordersReceivedCount = ordersReceivedIds.length
  const ordersUnsuccessfullyReceivedCount =
    ordersUnsuccessfullyReceivedIds.length
  const invalidOrdersCount = invalidOrdersIds.length

  const counts = useMemo(
    () => [
      ordersToReceiveCount,
      ordersReceivedCount,
      ordersUnsuccessfullyReceivedCount,
      invalidOrdersCount,
    ],
    [
      ordersToReceiveCount,
      ordersReceivedCount,
      ordersUnsuccessfullyReceivedCount,
      invalidOrdersCount,
    ]
  )

  const shouldShowDatePicker = ![
    "/odbieranie-zlecen",
    "/odbieranie-zlecen/nieprawidlowe",
  ].includes(location.pathname)

  useEffect(() => {
    if (location.pathname !== "/odbieranie-zlecen") return

    const firstTabWithContent =
      PATHS[
        counts.reduceRight(
          (prev, curr, currIndex) => (curr > 0 ? currIndex : prev),
          0
        )
      ]

    if (!firstTabWithContent) return
    if (firstTabWithContent === location.pathname) return

    history.push(firstTabWithContent)
    // Only on first render
    // eslint-disable-next-line
  }, [])

  function renderContent(pathname: string) {
    switch (pathname) {
      case "/odbieranie-zlecen":
        return (
          <OrdersToReceiveList
            type="ordersToReceive"
            date={date}
            ordersIds={ordersToReceiveIds}
          />
        )
      case "/odbieranie-zlecen/odebrane":
        return (
          <OrdersToReceiveList
            type="ordersReceived"
            date={date}
            ordersIds={ordersReceivedIds}
          />
        )
      case "/odbieranie-zlecen/nieudane":
        return (
          <OrdersToReceiveList
            type="ordersUnsuccessfullyReceived"
            date={date}
            ordersIds={ordersUnsuccessfullyReceivedIds}
          />
        )
      case "/odbieranie-zlecen/nieprawidlowe":
        return <InvalidOrdersList ordersIds={invalidOrdersIds} />
    }
  }

  if (!canReceiveOrders) return <Redirect to={"/"} />

  return (
    <div className={clsx("page-content", cls.ordersToReceive)}>
      <header className={cls.header}>
        <OrdersToReceiveTabs counts={counts} />

        {shouldShowDatePicker && (
          <DatePicker
            date={dayjs(date).toDate()}
            handleDateChange={handleDateChange}
          />
        )}
      </header>

      {renderContent(location.pathname)}
    </div>
  )
}
