import { Button, Icon } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { useRef } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { batch, useSelector } from "react-redux"

import { useAppDispatch } from "@/App"
import {
  selectCurrentOrderFormOrderId,
  selectOrderFormCurrentStepStatus,
  selectOrderFormStepData,
  setOrderFormStep,
  setOrderFormStepData,
  submitOrderFormStep,
} from "@/store/orderForm"
import { Notes as TNotes } from "@/types"
import { setFormErrors } from "@/utils"

import formCls from "../OrderForm.module.scss"
import cls from "./Notes.module.scss"

const stepKey = "notes"

const TEMPLATES = [
  "contact_before_delivery",
  "no_intercom",
  "access_code",
  "non_contact_delivery",
  "contact_person",
  "company_name",
]

const Notes = () => {
  const dispatch = useAppDispatch()

  const orderId = useSelector(selectCurrentOrderFormOrderId)

  const currentStepStatus = useSelector(selectOrderFormCurrentStepStatus)

  const defaultValues =
    useSelector(selectOrderFormStepData("notes")) || undefined

  const { getValues, register, handleSubmit, setError } = useForm({
    defaultValues,
  })

  const noteRef = useRef<HTMLTextAreaElement | null>()

  const { t } = useTranslation("createOrder")

  function applyTemplate(template: typeof TEMPLATES[number]) {
    if (!noteRef.current) return

    noteRef.current.value = `${t(`client_notes_templates.${template}`, {
      lng: "pl",
    })} `
    noteRef.current.focus()
  }

  function onSubmit(stepData: TNotes) {
    dispatch(setOrderFormStepData({ stepKey, stepData }))

    dispatch(submitOrderFormStep({ stepKey, stepData, orderId }))
      .then(unwrapResult)
      .then((res) => {
        if (!res) return

        if (res.ok) {
          dispatch(setOrderFormStep("summary"))
        } else if (res.errors) {
          setFormErrors(res.errors, setError)
        }
      })
  }

  return (
    <form className={formCls.step} onSubmit={handleSubmit(onSubmit)}>
      <div className={clsx(formCls.main, cls.content)}>
        <h2 className="h400 mb-5">{t("headers.client_notes")}</h2>
        <div className="text-large">{t("descriptions.client_noted")}</div>

        <div className={cls.desc}>
          <span className="text-dark">{t("headers.notes_templates")}</span> (
          {t("headers.click_to_select")})
        </div>

        <div className={cls.templates}>
          {TEMPLATES.map((template, index) => (
            <Button
              className={cls.template}
              onClick={() => applyTemplate(template)}
              size="large"
              type="button"
              key={index}
            >
              {t(`client_notes_templates.${template}`)}
            </Button>
          ))}
        </div>

        <div className={cls.notes}>
          <textarea
            name="description"
            placeholder={t("headers.client_notes_content")}
            ref={(e) => {
              register(e)
              noteRef.current = e
            }}
          />
        </div>
      </div>

      <div className={formCls.actions}>
        <Button
          onClick={() => {
            batch(() => {
              dispatch(setOrderFormStepData({ stepKey, stepData: getValues() }))
              dispatch(setOrderFormStep("processing_time"))
            })
          }}
          color="primary"
          variation="accented"
          size="extra-large"
          type="button"
          fullWidth
        >
          <Icon name="short-arrow-tiny-left" />
          {t("buttons.return")}
        </Button>

        <Button
          loading={currentStepStatus === "loading"}
          color="primary"
          size="extra-large"
          type="submit"
          fullWidth
        >
          {t("buttons.continue")}
          <Icon name="short-arrow-tiny-right" />
        </Button>
      </div>
    </form>
  )
}

export { Notes }
