import { Icon, ThemeColor } from "@deligoo/ui"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

import { Order } from "@/types"

import cls from "./PaymentForm.module.scss"

type PaymentFormProps = {
  paymentForm?: Order["payment_form"]
  color?: ThemeColor
  iconSide?: "left" | "right"
}

const PaymentForm = ({ paymentForm, color, iconSide }: PaymentFormProps) => {
  const { t } = useTranslation("payments")

  const PaymentIcon = () => (
    <Icon
      className={clsx(cls.icon, iconSide && cls[iconSide])}
      name={getIconName(paymentForm)}
      color={color || getIconDefaultColor(paymentForm)}
    />
  )

  return (
    <span className={cls.paymentForm}>
      {iconSide === "left" && <PaymentIcon />}
      <span>{t(`payment_form.${paymentForm}`)}</span>
      {iconSide === "right" && <PaymentIcon />}
    </span>
  )
}

export function getIconName(paymentForm?: Order["payment_form"]) {
  switch (paymentForm) {
    case "card":
      return "credit-card"
    case "cash":
      return "cash"
    case "paid":
      return "check-circle"
    default:
      return "boxes"
  }
}

export function getIconDefaultColor(paymentForm?: Order["payment_form"]) {
  switch (paymentForm) {
    case "card":
      return "info"
    case "cash":
      return "success"
    case "paid":
      return "warning"
    default:
      return "muted"
  }
}

export { PaymentForm }
