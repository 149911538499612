const createOrder = {
  steps: {
    contact_detail: "Dane klienta",
    payment_form: "Rodzaj płatności",
    package_type: "Rodzaj opakowania",
    processing_time: "Czas realizacji",
    notes: "Uwagi do zamówienia",
    summary: "Rezultat",
  },

  buttons: {
    return: "Wstecz",
    continue: "Przejdź dalej",
    cancel: "Anuluj",
    cancel_order: "Anuluj zlecenie",
    download_label: "Pobierz etykietę .PDF",
    return_to_orders: "Powrót do listy zleceń",
    create_order: "Nowe zlecenie",
    search_for_address: "Wyszukaj inny adres",
    add_new: "Dodaj nowy",
  },

  errors: {
    select_package: "Przynajmniej jedno opakowanie jest wymagane",
    price_value_too_low:
      "Kwota dla wybranej formy płatności musi być większa od 0",
    price_value_required: "Należy podać kwotę",
    no_payment_methods:
      "Nie wskazano żadnej formy płatności. Skontaktuj się z supportem.",
    payment_value_required: "Należy podać formę i kwotę płatności",
    invalid_pickup_time: "Zbyt krótki czas na odbiór",
    invalid_time: "Podaj poprawną godzinę",
  },

  headers: {
    create_order: "Nowe zlecenie",
    saved_addresses: "Zapisane adresy klienta",
    payment_form: "Rodzaj płatności",
    order_kind: "Rodzaj zamówienia",
    select_package_and_weight: "Wybierz rodzaj opakowania i jego wagę",
    select_package_type: "Wybierz rodzaj opakowania",
    delivery_price: "Koszt dostawy",
    total: "Suma",
    drinks: "Napoje",
    client_notes: "Uwagi do zamówienia otrzymane od klienta",
    client_notes_content: "Treść uwag przekazanych przez klienta",
    notes_templates: "Szablony uwag",
    click_to_select: "kliknij, aby wybrać",
    today_orders: "Zlecenia dzisiaj",
    buffering: "Zlecenie w trakcie przypisywania",
    searching_for_driver: "Szukamy odpowiedniego kuriera",
    order_uid: "Numer zlecenia",
    processing: "Przetwarzanie...",
    driver_assigned: "Kurier przypisany",
    driver: "Kurier",
    pickup_in: "Przyjazd za",
    pickup_on: "o godz. {{hour}}",
    order_created: "Zlecenie <1>#{{orderUid}}<1> dodane",
    order_finished: "Zlecenie <1>#{{orderUid}}<1> zakończone",
    order_canceled: "Zlecenie <1>#{{orderUid}}<1> anulowane",
    order_rejected: "Zlecenie <1>#{{orderUid}}<1> odrzucone",
    order_expired: "Zlecenie <1>#{{orderUid}}<1> wygasło",
    is_from_future: "To zlecenie jest zaplanowane na dzień {{date}}",
    prep_time: "Czas przygotowania posiłku",
    proposed_time: "Proponowana godzina realizacji",
    timing_delivery: "Dostawa czasowa",
    timing_delivery_30: "30 min",
    timing_delivery_60: "60 min",
    self_pickup: "Odbiór osobisty",
    pickup: "Odbiór",
    delivery: "Doręczenie",
    ready_to_pickup: "Przesyłka gotowa do odbioru",
    time_range:
      "Preferowany przez klienta przedział czasowy na doręczenie przesyłki",
  },

  descriptions: {
    prep_time:
      "Ile czasu potrzebujesz na przygotowanie tego zamówienia, jaką ilość minut potrzebuje kuchnia, aby przygotować posiłek.",
    proposed_time:
      "Możesz wybrać jedną z dwóch opcji - zaproponować godzinę odbioru zamówienia z restauracji lub żądaną przez klienta godzinę doręczenia zamówienia w 30 minutowym przedziale.",
    timing_delivery:
      "Skorzystaj z tej opcji jeśli potrzebujesz zarezerwować dodatkowy czas na realizację zamówienia, np. gdy kurier powinien wrócić po zrealizowaniu dostawy. Płatne wg. cennika.",
    order_kind:
      "Wybierz wielkość Twojego zamówienia, dzięki czemu kurier będzie wiedział jakiego rodzaju pojemnik użyć do spakowania potraw. Jeśli pojemnik nie jest potrzebny, wybierz opcję opakowanie własne oraz dodaj odpowiednią ilość sztuk.",
    client_noted:
      "Jeśli klient przekazał jakiekolwiek uwagi dotyczące dostawy, takie jak wskazówki dojazdu, piętro, numer klatki itp.",
    buffering:
      "Zamówienie zostało poprawnie przesłane i jest w trakcie przypisywania do odpowiedniego kuriera w celu wyznaczenia dokładnej godziny odbioru.",
    estimated_time: "Szacowana godzina odbioru: {{pickupRange}}",
    next_steps:
      "Możesz teraz dodać kolejne zlecenie lub powrócić do listy, aby obserwować statusy kolejnych zleceń.",
  },

  package_types: {
    "big-pizza": "Duża pizza",
    "standard-dish": "Danie standard",
    "own-packaging": "Opakowanie własne",
    "hebe-box-1": "BOX 1",
    "hebe-box-2": "BOX 2",
    "hebe-box-3": "BOX 3",
    "hebe-box-4": "BOX 4",
    "hebe-box-5": "BOX 5",
    "ecommerce-size-a": "Gabaryt A",
    "ecommerce-size-b": "Gabaryt B",
    "ecommerce-size-c": "Gabaryt C",
    "inpost-size-a": "Gabaryt A",
    "inpost-size-b": "Gabaryt B",
    "inpost-size-c": "Gabaryt C",
  },

  package_types_desc: {
    "big-pizza": "32 cm+",
    "standard-dish": "pizza do 32 cm",
    "own-packaging": "sushi / zakupy",
    max: "maks.",
  },

  client_notes_templates: {
    contact_before_delivery: "Proszę o kontakt telefoniczny przed dostawą",
    no_intercom: "Nie używać domofonu",
    access_code: "Kod do wejścia to",
    non_contact_delivery: "Dostawa bezkontaktowa",
    contact_person: "Osoba kontaktowa",
    company_name: "Nazwa firmy",
  },
}

export default createOrder
