import { LatLngTuple } from "leaflet"
import { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import ReconnectingWebSocket from "reconnecting-websocket"

import { getWebSocketURI } from "@/sockets"
import { selectOrderById } from "@/store/orders"
import { Order } from "@/types"
import { fetchOrderTracking, OrderTrackingResponse } from "@/utils"

export function useDriverLocation(orderId: Order["id"]) {
  const socket = useRef<ReconnectingWebSocket | null>(null)

  const order = useSelector(selectOrderById(orderId))
  const orderUuid = order?.uuid

  const [driverLocation, setDriverLocation] = useState<
    OrderTrackingResponse["driver"] | null
  >(null)

  const driverPosition: LatLngTuple | null = useMemo(() => {
    if (driverLocation?.lat && driverLocation?.lng) {
      return [driverLocation.lat, driverLocation.lng]
    }

    if (order?.driver?.lat && order?.driver?.lng) {
      return [order.driver.lat, order.driver.lng]
    }

    return null
    // Only recalculate when latlngs are updated by websocket
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverLocation?.lat, driverLocation?.lng])

  useEffect(() => {
    async function fetchInitialDriverLocation() {
      const { response, json } = await fetchOrderTracking(orderId)

      if (response.ok && json.driver) {
        setDriverLocation(json.driver)
      }
    }

    fetchInitialDriverLocation()
  }, [orderId])

  useEffect(() => {
    const lsSite = localStorage.getItem("site")

    if (!lsSite || !orderUuid) return

    const currentSite = JSON.parse(lsSite)

    socket.current = new ReconnectingWebSocket(getWebSocketURI(currentSite))

    socket.current.onopen = () => {
      socket.current?.send(
        JSON.stringify({
          command: "subscribe",
          identifier: JSON.stringify({
            channel: "Orders::TrackingChannel",
            uuid: orderUuid,
          }),
        })
      )
    }

    socket.current.onmessage = (msg) => {
      const msgData = JSON.parse(msg.data)

      if (msgData.type === "ping") return

      if (msgData.message?.data?.driver) {
        setDriverLocation(msgData.message.data.driver)
      }
    }

    return () => {
      socket.current?.close()
    }
  }, [orderUuid])

  return { driverPosition, driverHeading: driverLocation?.heading || 0 }
}
