import { Statistics } from "@/types"

import {
  AverageDailyOrders,
  AverageDeliveryTime,
  AveragePreparationTime,
  CompletionOver60Min,
  CompletionUnder60Min,
  DeliveriesFailed,
  TotalOrders,
} from "./partials"
import cls from "./Statistics.module.scss"

type StatisticsCardsProps = {
  statistics: Statistics | null
  isLoading: boolean
}

const StatisticsCards = ({ isLoading, statistics }: StatisticsCardsProps) => {
  return (
    <>
      <CompletionUnder60Min
        className={cls.tall}
        loading={isLoading}
        value={statistics?.completion_under_60_minutes_in_percent}
      />

      <CompletionOver60Min
        className={cls.tall}
        loading={isLoading}
        value={statistics?.completion_over_60_minutes_in_percent}
      />

      <AverageDeliveryTime
        className={cls.wide}
        loading={isLoading}
        value={statistics?.average_estimated_delivery_time_duration}
      />

      <AverageDailyOrders
        className={cls.wide}
        loading={isLoading}
        value={statistics?.average_daily_orders_count}
      />

      <TotalOrders loading={isLoading} value={statistics?.total_orders_count} />

      <DeliveriesFailed
        loading={isLoading}
        value={statistics?.orders_with_delivery_failed_count}
      />

      <AveragePreparationTime
        loading={isLoading}
        className={cls.wide}
        value={statistics?.average_preparation_time_duration}
      />
    </>
  )
}

export { StatisticsCards }
