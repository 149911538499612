import { ComponentProps } from "react"

import { AppLayout, BasicLayout, PlainLayout } from "@/layouts"
// import { Complaints } from "@/views/Complaints"
import { Dashboard } from "@/views/Dashboard"
import { GetApps } from "@/views/GetApps"
import { Integrations } from "@/views/Integrations"
import { Orders } from "@/views/Orders"
import { OrdersToReceive } from "@/views/OrdersToReceive"
import { Payments } from "@/views/Payments"
import { Settings } from "@/views/Settings"
import { SignIn } from "@/views/SignIn"
import { Statistics } from "@/views/Statistics"

type Route = {
  Layout: (props: ComponentProps<typeof BasicLayout>) => JSX.Element
  subroutes: Subroute[]
  restricted?: boolean
}

type Subroute = {
  path: string | string[]
  exact?: boolean
  Content: () => JSX.Element
}

const routes: Route[] = [
  {
    Layout: PlainLayout,
    subroutes: [
      {
        path: "/pobierz",
        Content: GetApps,
      },
    ],
  },
  {
    Layout: BasicLayout,
    subroutes: [
      {
        path: "/zaloguj",
        Content: SignIn,
      },
    ],
  },
  {
    Layout: AppLayout,
    restricted: true,
    subroutes: [
      {
        path: "/integracje",
        Content: Integrations,
      },
      {
        path: "/platnosci",
        Content: Payments,
      },
      {
        path: "/statystyki",
        Content: Statistics,
      },
      {
        path: "/zlecenia*",
        Content: Orders,
      },
      {
        path: "/odbieranie-zlecen*",
        Content: OrdersToReceive,
      },
      {
        path: "/ustawienia",
        Content: Settings,
      },
      // {
      //   path: "/reklamacje",
      //   Content: Complaints,
      // },
      {
        path: "/",
        Content: Dashboard,
        exact: true,
      },
    ],
  },
]

export default routes
