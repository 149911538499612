import Deli1Default from "../../assets/providers/deli1.svg"
import Deli1Light from "../../assets/providers/deli1-light.svg"
import DeligooDefault from "../../assets/providers/deligoo.svg"
import DeligooLight from "../../assets/providers/deligoo-light.svg"
import DeligooSmall from "../../assets/providers/deligoo-sm.svg"
import DeligooSmallLight from "../../assets/providers/deligoo-sm-light.svg"
import FastcatDefault from "../../assets/providers/fastcat.png"
import FurgonetkaDefault from "../../assets/providers/furgonetka.svg"
import FurgonetkaLight from "../../assets/providers/furgonetka-light.svg"
import FurgonetkaSmall from "../../assets/providers/furgonetka-sm.svg"
import FurgonetkaSmallLight from "../../assets/providers/furgonetka-sm-light.svg"
import GlodnyDefault from "../../assets/providers/glodny.svg"
import GlodnyLight from "../../assets/providers/glodny-light.svg"
import GlodnySmall from "../../assets/providers/glodny-sm.svg"
import GlodnySmallLight from "../../assets/providers/glodny-sm-light.svg"
import GlovoDefault from "../../assets/providers/glovo.svg"
import GlovoLight from "../../assets/providers/glovo-light.svg"
import GlovoSmall from "../../assets/providers/glovo-sm.svg"
import GlovoSmallLight from "../../assets/providers/glovo-sm-light.svg"
import JedzCoChceszDefault from "../../assets/providers/jedzcochcesz.svg"
import JedzCoChceszLight from "../../assets/providers/jedzcochcesz-light.svg"
import MakroDefault from "../../assets/providers/makro.svg"
import MakroLight from "../../assets/providers/makro-light.svg"
import MenuplDefault from "../../assets/providers/menupl.svg"
import MenuplLight from "../../assets/providers/menupl-light.svg"
import MenuplSmall from "../../assets/providers/menupl-sm.svg"
import MenuplSmallLight from "../../assets/providers/menupl-sm-light.svg"
import PyszneDefault from "../../assets/providers/pyszne.svg"
import PyszneLight from "../../assets/providers/pyszne-light.svg"
import PyszneSmall from "../../assets/providers/pyszne-sm.svg"
import PyszneSmallLight from "../../assets/providers/pyszne-sm-light.svg"
import RestaumaticDefault from "../../assets/providers/restaumatic.svg"
import RestaumaticLight from "../../assets/providers/restaumatic-light.svg"
import RestaumaticSmall from "../../assets/providers/restaumatic-sm.svg"
import RestaumaticSmallLight from "../../assets/providers/restaumatic-sm-light.svg"
import UberEatsDefault from "../../assets/providers/ubereats.svg"
import UberEatsLight from "../../assets/providers/ubereats-light.svg"
import UberEatsSmall from "../../assets/providers/ubereats-sm.svg"
import UberEatsSmallLight from "../../assets/providers/ubereats-sm-light.svg"
import UpmenuDefault from "../../assets/providers/upmenu.svg"
import UpmenuLight from "../../assets/providers/upmenu-light.svg"
import UpmenuSmall from "../../assets/providers/upmenu-sm.svg"
import UpmenuSmallLight from "../../assets/providers/upmenu-sm-light.svg"
import ZioappDefault from "../../assets/providers/zioapp.png"

export type ProviderLogotypes = {
  default: string
  light?: string
  small?: string
  smallLight?: string
} | null

export const deligoo: ProviderLogotypes = {
  default: DeligooDefault,
  light: DeligooLight,
  small: DeligooSmall,
  smallLight: DeligooSmallLight,
} as const

export const deli1: ProviderLogotypes = {
  default: Deli1Default,
  light: Deli1Light,
  small: DeligooSmall,
  smallLight: DeligooSmallLight,
} as const

export const jedzcochcesz: ProviderLogotypes = {
  default: JedzCoChceszDefault,
  light: JedzCoChceszLight,
} as const

export const glodny: ProviderLogotypes = {
  default: GlodnyDefault,
  light: GlodnyLight,
  small: GlodnySmall,
  smallLight: GlodnySmallLight,
} as const

export const pyszne: ProviderLogotypes = {
  default: PyszneDefault,
  light: PyszneLight,
  small: PyszneSmall,
  smallLight: PyszneSmallLight,
} as const

export const ubereats: ProviderLogotypes = {
  default: UberEatsDefault,
  light: UberEatsLight,
  small: UberEatsSmall,
  smallLight: UberEatsSmallLight,
} as const

export const glovo: ProviderLogotypes = {
  default: GlovoDefault,
  light: GlovoLight,
  small: GlovoSmall,
  smallLight: GlovoSmallLight,
} as const

export const makro: ProviderLogotypes = {
  default: MakroDefault,
  light: MakroLight,
}

export const upmenu: ProviderLogotypes = {
  default: UpmenuDefault,
  light: UpmenuLight,
  small: UpmenuSmall,
  smallLight: UpmenuSmallLight,
} as const

export const menupl: ProviderLogotypes = {
  default: MenuplDefault,
  light: MenuplLight,
  small: MenuplSmall,
  smallLight: MenuplSmallLight,
} as const

export const fastcat: ProviderLogotypes = {
  default: FastcatDefault,
} as const

export const zioapp: ProviderLogotypes = {
  default: ZioappDefault,
} as const

export const furgonetka: ProviderLogotypes = {
  default: FurgonetkaDefault,
  light: FurgonetkaLight,
  small: FurgonetkaSmall,
  smallLight: FurgonetkaSmallLight,
} as const

export const restaumatic: ProviderLogotypes = {
  default: RestaumaticDefault,
  light: RestaumaticLight,
  small: RestaumaticSmall,
  smallLight: RestaumaticSmallLight,
} as const
