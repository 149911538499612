import { Icon, ThemeColor } from "@deligoo/ui"
import clsx from "clsx"
import { scaleLinear } from "d3-scale"
import { arc } from "d3-shape"
import { ReactNode } from "react"

import colors from "@/scss/colors.module.scss"
import { IconName } from "@/types"

import cls from "./Gauge.module.scss"

type GaugeProps = {
  /** @param fraction: number between 0 and 1 */
  fraction: number
  fillColors?: FillColor[]
  color?: ThemeColor
  backgroundColor?: string
  iconName?: IconName
  className?: string
  children?: ReactNode
}

type FillColor = {
  /** @param min: number between 0 and 1 */
  min: number
  /** @param max: number between 0 and 1 */
  max: number
  /** @param color: valid CSS color attribute */
  color: string
}

const defaultFillColors = [
  { min: 0, max: 0.33, color: colors.alert },
  { min: 0.34, max: 0.66, color: colors.warning },
  { min: 0.67, max: 1, color: colors.success },
]

const arcGenerator = arc().cornerRadius(1)

const angleScale = scaleLinear()
  .domain([0, 1])
  .range([-Math.PI / 1.5, Math.PI / 1.5])
  .clamp(true)

const arcCommonOptions = {
  innerRadius: 0.75,
  outerRadius: 1,
  startAngle: -Math.PI / 1.5,
}

const Gauge = ({
  fraction,
  fillColors = defaultFillColors,
  color,
  backgroundColor,
  iconName,
  className,
  children,
  ...props
}: GaugeProps) => {
  const backgroundArcPath = arcGenerator({
    ...arcCommonOptions,
    endAngle: Math.PI / 1.5,
  })

  const angle = angleScale(fraction)

  const fillArcPath = arcGenerator({
    ...arcCommonOptions,
    endAngle: angle || 0,
  })

  const fillColor = color
    ? colors[color]
    : fillColors.find((color) => fraction >= color.min && fraction <= color.max)
        ?.color || "currentColor"

  return (
    <div className={clsx(cls.gauge, className)} {...props}>
      <svg className={cls.viewbox} viewBox="-1 -1 2 1.45">
        <path
          d={backgroundArcPath || undefined}
          fill={backgroundColor || cls.defaultBackgroundColor}
        />
        <path d={fillArcPath || undefined} fill={fillColor} />
      </svg>
      {iconName && (
        <Icon
          className={cls.icon}
          name={iconName}
          style={{ color: fillColor }}
        />
      )}
    </div>
  )
}

export { Gauge }
