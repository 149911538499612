import { Avatar } from "@deligoo/ui"
import clsx from "clsx"

import { Order } from "@/types"
import { formatPhone } from "@/utils"

import cls from "./DriverInfo.module.scss"

type DriverInfoProps = {
  driver: Order["driver"]
}

const DriverInfo = ({ driver }: DriverInfoProps) => {
  if (!driver) return null

  const { display_name, phone } = driver

  return (
    <div className={cls.driver}>
      <Avatar color="primary">{display_name}</Avatar>
      <div className={clsx("h200", cls.info)}>
        {display_name}
        <div className="text-primary mt-2">{formatPhone(phone)}</div>
      </div>
    </div>
  )
}

export { DriverInfo }
