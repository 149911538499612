import { Input } from "@deligoo/ui"
import { FocusEvent } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Rifm } from "rifm"

import cls from "../PaymentAndPackage.module.scss"

const WeightInput = () => {
  const { register, errors, setValue, control } = useFormContext()

  const { t } = useTranslation()

  const weight = useWatch({
    control,
    name: "weight",
    defaultValue: 0,
  })

  function formatWeight(e: FocusEvent<HTMLInputElement>) {
    const value = e.currentTarget.value || 0
    setValue("weight", Number(value))
  }

  return (
    <div className={cls.weight}>
      <div className={cls.weightField}>
        <Rifm
          value={weight.toString()}
          onChange={(value) => setValue("weight", value)}
          format={(value) => (value.match(/\d+[.,]?\d{0,3}/) || []).join("")}
          replace={(value) => value.replace(",", ".")}
          accept={/[\d.,]/g}
        >
          {({ value, onChange }) => (
            <Input
              value={value}
              onChange={onChange}
              onBlur={formatWeight}
              name="weight"
              placeholder="0.000"
              inputMode="decimal"
              maxLength={7}
              ref={register({ required: `${t("errors.is_required")}` })}
              className={cls.weightInput}
              size="extra-large"
              selectOnFocus
            />
          )}
        </Rifm>
        kg
      </div>

      {errors?.weight && (
        <div className="text-error">{errors.weight.message}</div>
      )}
    </div>
  )
}

export { WeightInput }
