import { Backdrop, ProviderLogo } from "@deligoo/ui"
import { Button } from "@deligoo/ui"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { Basket } from "@/components/Basket"
import { CloseButton } from "@/components/CloseButton"
import { DeliveryMethod } from "@/components/DeliveryMethod"
import { DistanceBar } from "@/components/DistanceBar"
import { Price } from "@/components/Price"
import { postponeOrder, selectOrderById } from "@/store/orders"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { Order } from "@/types"
import { printOrderDetails } from "@/utils"
import { isTruthyOrZero, useIsMounted, useRemoveToastOnMount } from "@/utils"
import {
  canBeConfirmed,
  canBeConfirmedWithOwnDelivery,
  canBeRejected,
  canContinueReceive,
  isDeliveryMethodPickup,
  isToReceive,
} from "@/utils/order"

import {
  ConfirmButton,
  ConfirmWithOwnDeliveryButton,
  ContinueButton,
  RejectButton,
} from "./actions"
import cls from "./ReceiveOrderModal.module.scss"
import { CustomerContactDetailsSection } from "./sections/CustomerContactDetailsSection"
import { PaymentSection } from "./sections/PaymentSection"

type ReceiveOrderModalProps = {
  orderId: Order["id"]
  handleClose: () => void
}

const ReceiveOrderModal = ({
  orderId,
  handleClose,
}: ReceiveOrderModalProps) => {
  const order = useSelector(selectOrderById(orderId))
  const distanceLimit = useSelector(
    selectCurrentRestaurantKey("deligoo_max_distance_limit")
  )

  const [isActionInProgress, setIsActionInProgress] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)

  const isMounted = useIsMounted()

  const dispatch = useDispatch()

  const { t } = useTranslation("orders")

  useRemoveToastOnMount("newOrderToReceive")

  useEffect(() => {
    dispatch(postponeOrder(orderId))
  }, [orderId, dispatch])

  if (!order) return null

  return (
    <Backdrop>
      <div className={cls.receiveOrder}>
        <header className={cls.header}>
          <div className={cls.left}>
            <h2 className={cls.title}>
              {isToReceive(order)
                ? t("receive_order_modal.headers.confirm_order_id", {
                    orderId: order.uid,
                  })
                : t("receive_order_modal.headers.order_id", {
                    orderId: order.uid,
                  })}
            </h2>
            <span className={cls.address}>{order.delivery?.address}</span>
          </div>

          <div className={cls.right}>
            <div>
              {order.provider && (
                <ProviderLogo
                  provider={order.provider}
                  marketplace={order.marketplace_kind}
                />
              )}
              <br />
              #{order.external_id && order.external_id.slice(-8)}
            </div>

            <CloseButton
              onClick={handleClose}
              size="extra-large"
              className="ml-8"
            />
          </div>
        </header>
        <div className={cls.content}>
          <div className={cls.left}>
            {!isDeliveryMethodPickup(order) && (
              <div className={cls.distance}>
                <h3 className="h300">
                  {t("receive_order_modal.headers.distance")}
                </h3>
                {isTruthyOrZero(order.distance) ? (
                  <>
                    <DistanceBar
                      distance={order.distance as number}
                      limit={distanceLimit}
                    />
                    {Number(order.distance) > Number(distanceLimit) && (
                      <div className="text-error">
                        {t("common:errors.distance_limit", { distanceLimit })}
                      </div>
                    )}
                  </>
                ) : (
                  <span className="h600 text-primary">–</span>
                )}
              </div>
            )}

            <Basket details={order.details} />
            <br /><br />
            {window.KBBluetooth && (
              <Button
                className={cls.actionBtn}
                color="muted"
                iconProps={{ name: "bills" }}
                onClick={async () => {
                  if (isPrinting) return
                  setIsPrinting(true)

                  await printOrderDetails(order.id)
                  setIsPrinting(false)
                }}
                loading={isPrinting}
              >
                {t("order_actions.print")}
              </Button>
            )}
          </div>
          <div className={cls.right}>
            <div className={cls.summary}>
              <CustomerContactDetailsSection
                details={order.customer_contact_detail}
              />

              <div className={clsx(cls.section, cls.deliveryInfo)}>
                <div className={cls.deliveryMethod}>
                  <DeliveryMethod
                    deliveryMethod={order.delivery_method}
                    deliveryAt={order.delivery_at}
                    pickupAt={order.pickup_at}
                  />
                  {t("receive_order_modal.headers.delivery_method")}
                </div>

                {order.order_comment && (
                  <div className={cls.orderComment}>{order.order_comment}</div>
                )}

                {order.customer_comment && (
                  <div className={cls.customerComment}>
                    {order.customer_comment}
                  </div>
                )}
              </div>

              <PaymentSection
                provider={order.provider}
                marketplace={order.marketplace_kind}
                paymentForm={order.payment_form}
                invoice={order.invoice}
              />

              <div className={clsx(cls.section, cls.pricing)}>
                <div className={cls.item}>
                  <span className={cls.label}>
                    {t("receive_order_modal.headers.order_total_price")}:
                  </span>
                  {order.price_subunit !== null ? (
                    <Price className={cls.value} subunit>
                      {order.price_subunit}
                    </Price>
                  ) : (
                    "-"
                  )}
                </div>
                <div className={cls.item}>
                  <span className={cls.label}>
                    {t("receive_order_modal.headers.order_delivery_price")}:
                  </span>
                  {order.price_delivery_subunit !== null ? (
                    <Price className={cls.value} subunit>
                      {order.price_delivery_subunit}
                    </Price>
                  ) : (
                    "-"
                  )}
                </div>
                {order.price_packaging_subunit && order.price_packaging_subunit > 0 && (
                    <div className={cls.item}>
                      <span className={cls.label}>
                        {t("receive_order_modal.headers.order_price_packaging")}:
                      </span>
                      <Price className={cls.value} subunit>
                        {order.price_packaging_subunit}
                      </Price>
                    </div>
                  )}
                {order.discount_code_value_subunit !== null &&
                  order.discount_code_value_subunit > 0 && (
                    <div className={cls.item}>
                      <span className={cls.label}>
                        {t("receive_order_modal.headers.order_discount")}:
                      </span>
                      <Price className={cls.value} subunit>
                        {order.discount_code_value_subunit}
                      </Price>
                    </div>
                  )}
                <div className={cls.item}>
                  <span className={cls.label}>
                    {t("receive_order_modal.headers.order_total_amount")}:
                  </span>
                  <Price subunit>{order.full_price_subunit}</Price>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className={cls.actions}>
          {canBeRejected(order) && (
            <RejectButton
              orderId={order.id}
              loading={isActionInProgress}
              isParentMounted={isMounted}
              setIsActionInProgress={setIsActionInProgress}
              handleModalClose={handleClose}
            />
          )}
          <ConfirmButton
            orderId={order.id}
            loading={isActionInProgress}
            disabled={!canBeConfirmed(order)}
            isParentMounted={isMounted}
            setIsActionInProgress={setIsActionInProgress}
            handleModalClose={handleClose}
          />
          {canContinueReceive(order) && (
            <ContinueButton
              orderId={order.id}
              loading={isActionInProgress}
              isParentMounted={isMounted}
              setIsActionInProgress={setIsActionInProgress}
              handleModalClose={handleClose}
            />
          )}
          {canBeConfirmedWithOwnDelivery(order) && (
            <ConfirmWithOwnDeliveryButton
              orderId={order.id}
              loading={isActionInProgress}
              isParentMounted={isMounted}
              setIsActionInProgress={setIsActionInProgress}
              handleModalClose={handleClose}
            />
          )}
        </footer>
      </div>
    </Backdrop>
  )
}

function useReceiveOrderModal(): [
  Order["id"] | null,
  (id: Order["id"] | null) => void
] {
  const [orderToReceiveId, setOrderToReceiveId] = useState<Order["id"] | null>(
    null
  )

  return [orderToReceiveId, setOrderToReceiveId]
}

export { ReceiveOrderModal, useReceiveOrderModal }
