import { Spinner } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useAppDispatch } from "@/App"
import {
  selectAddressListStatus,
  selectCurrentOrderFormOrderId,
  selectOrderFormCurrentStepStatus,
  selectOrderFormStepData,
  setOrderFormStep,
  setOrderFormStepData,
  submitOrderFormStep,
} from "@/store/orderForm"
import { ContactDetail } from "@/types"
import { setFormErrors, useIsMounted } from "@/utils"

import formCls from "../OrderForm.module.scss"
import { AddressForm } from "./partials/AddressForm"
import { AddressList } from "./partials/AddressList"
import { PhoneField } from "./partials/PhoneField"

const stepKey = "contact_detail"

const ContactDetails = () => {
  const defaultValues =
    useSelector(selectOrderFormStepData(stepKey)) || undefined

  const orderId = useSelector(selectCurrentOrderFormOrderId)
  const addressListStatus = useSelector(selectAddressListStatus)
  const currentStepStatus = useSelector(selectOrderFormCurrentStepStatus)

  const formMethods = useForm({ defaultValues, mode: "onChange" })
  const { handleSubmit, errors, register, setError } = formMethods

  const [view, _setView] = useState<"form" | "list">("form")

  const isMounted = useIsMounted()

  const setView = (view: "form" | "list") => {
    return isMounted() ? _setView(view) : null
  }

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  function onSubmit(stepData: ContactDetail) {
    dispatch(setOrderFormStepData({ stepKey, stepData }))

    dispatch(submitOrderFormStep({ stepKey, stepData, orderId }))
      .then(unwrapResult)
      .then((res) => {
        if (!res) return

        if (res.ok) {
          dispatch(setOrderFormStep("payment_and_packages"))
        } else if (res.errors) {
          setFormErrors(res.errors, setError)
        }
      })
  }

  return (
    <FormProvider {...formMethods}>
      <form className={formCls.step} onSubmit={handleSubmit(onSubmit)}>
        <PhoneField
          setView={setView}
          error={errors?.phone?.message}
          ref={register({
            required: `${t("errors.is_required")}`,
            pattern: {
              value: /[0-9]{3} [0-9]{3} [0-9]{3}/,
              message: `${t("errors.phone_number_format")}`,
            },
          })}
        />

        {addressListStatus === "loading" ? (
          <Spinner size="extra-large" className={formCls.spinner} />
        ) : view === "form" ? (
          <AddressForm
            currentStepStatus={currentStepStatus}
            defaultValues={defaultValues}
            setView={setView}
          />
        ) : (
          <AddressList setView={setView} />
        )}
      </form>
    </FormProvider>
  )
}

export { ContactDetails }
