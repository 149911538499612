import { Field } from "@deligoo/ui"
import clsx from "clsx"
import {
  ComponentProps,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  useState,
} from "react"
import { useTranslation } from "react-i18next"

import { ReactComponent as LoadingPlaceholder } from "@/assets/text-placeholder.svg"
import { Address, DataStatus } from "@/types"
import { escapeRegex } from "@/utils"

import cls from "./AutocompleteField.module.scss"

type Autocomplete = {
  status?: DataStatus
  values: Array<Partial<Address>>
  onValueSelect: (value: Partial<Address>) => void
  valueToString: (value: Partial<Address>) => string
  valueToSubtitle?: (value: Partial<Address>) => string
}

type AutocompleteFieldProps = ComponentProps<typeof Field> & {
  autocomplete: Autocomplete
  className?: string
}

const AutocompleteField = forwardRef<HTMLInputElement, AutocompleteFieldProps>(
  ({ autocomplete, inputProps, className, ...props }, ref) => {
    const [areSuggestionsShown, showSuggestions] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [currentValue, setCurrentValue] = useState("")

    const { t } = useTranslation()

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
      const value = autocomplete.values[0]

      setCurrentValue(e.currentTarget.value)
      setSelectedIndex(0)

      if (value) autocomplete.onValueSelect(value)
      if (inputProps?.onChange) inputProps.onChange(e)
    }

    function handleInputFocus(e: FocusEvent<HTMLInputElement>) {
      showSuggestions(true)

      if (inputProps?.onFocus) inputProps.onFocus(e)
    }

    function handleInputBlur(e: FocusEvent<HTMLInputElement>) {
      const value = autocomplete.values[selectedIndex]

      if (value) autocomplete.onValueSelect(value)

      setCurrentValue(e.currentTarget.value)
      showSuggestions(false)

      if (inputProps?.onBlur) inputProps.onBlur(e)
    }

    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
      const suggestionsCount = autocomplete.values.length

      const nextIndex =
        selectedIndex + 1 < suggestionsCount ? selectedIndex + 1 : selectedIndex

      const prevIndex = selectedIndex > 0 ? selectedIndex - 1 : selectedIndex

      switch (e.key) {
        case "ArrowDown":
          e.preventDefault()
          setSelectedIndex(nextIndex)
          return
        case "ArrowUp":
          e.preventDefault()
          setSelectedIndex(prevIndex)
          return
        case "Enter":
          e.preventDefault()
          e.currentTarget.blur()
      }

      if (inputProps?.onKeyDown) inputProps.onKeyDown(e)
    }

    function highlightSuggestion(suggestion: string) {
      const re = new RegExp(escapeRegex(currentValue), "ig")
      const match = suggestion.match(re)
      const highlight = match?.length ? match[0] : ""

      return suggestion.replace(highlight, `<span>${highlight}</span>`)
    }

    return (
      <div className={clsx(cls.autocomplete, className)}>
        <Field
          ref={ref}
          inputProps={{
            ...inputProps,
            onFocus: handleInputFocus,
            onBlur: handleInputBlur,
            onKeyDown: handleKeyDown,
            onChange: handleInputChange,
          }}
          {...props}
        />

        {areSuggestionsShown && autocomplete.status && (
          <div className={cls.suggestions}>
            <ul className={cls.list}>
              {autocomplete.values?.map((value, index) => {
                const title = highlightSuggestion(
                  autocomplete.valueToString(value)
                )

                const subtitle = autocomplete.valueToSubtitle?.(value)

                return (
                  <li key={index}>
                    <button
                      type="button"
                      onClick={() => autocomplete.onValueSelect(value)}
                      onMouseEnter={() => setSelectedIndex(index)}
                      className={clsx(
                        cls.suggestion,
                        selectedIndex === index && cls.selected
                      )}
                      dangerouslySetInnerHTML={{
                        __html: `
                          <div>${title}</div>
                          ${subtitle ? `<div>${subtitle}</div>` : ""}
                        `,
                      }}
                    />
                  </li>
                )
              })}
            </ul>

            {["loading", "updating"].includes(autocomplete.status) && (
              <LoadingPlaceholder className={cls.loading} />
            )}

            {autocomplete.status === "fetched" &&
              !autocomplete.values.length && (
                <div className="text-small text-muted">
                  {t("autocomplete.no_results")}
                </div>
              )}

            {autocomplete.status === "error" && (
              <div className="text-small text-alert">
                {t("autocomplete.fetch_error")}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
)

export { AutocompleteField }
