import { fetchStreetSuggestions, Field } from "@deligoo/ui"
import {
  ComponentProps,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { AutocompleteField } from "@/components/AutocompleteField"
import { Address, DataStatus } from "@/types"
import { debounce, useIsMounted } from "@/utils"

const StreetField = forwardRef<HTMLInputElement, ComponentProps<typeof Field>>(
  ({ inputProps, ...props }, ref) => {
    const { errors, clearErrors, setError, setValue, getValues, trigger } =
      useFormContext()

    const [streetSuggestions, setStreetSuggestions] = useState<
      Array<Pick<Address, "street">>
    >([])

    const [suggestionsStatus, setSuggestionStatus] = useState<DataStatus>()

    const isMounted = useIsMounted()

    const { t } = useTranslation()

    function handleFocus(e: FocusEvent<HTMLInputElement>) {
      _getPlacematicStreetSuggestions()
      inputProps?.onFocus?.(e)
    }

    function handleBlur(e: FocusEvent<HTMLInputElement>) {
      validateSuggestions()
      inputProps?.onBlur?.(e)
    }

    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
      getStreets()
      resetAutocomplete()
      inputProps?.onKeyDown?.(e)
    }

    function resetAutocomplete() {
      setSuggestionStatus(undefined)
      setStreetSuggestions([])
    }

    function filterDuplicatedSuggestions(
      suggestions: Array<Pick<Address, "street">>
    ) {
      return suggestions.filter(
        (suggestionsAddress, index, self) =>
          self.findIndex(
            (address) => address.street === suggestionsAddress.street
          ) === index
      )
    }

    const validateSuggestions = useCallback(async () => {
      const currentStreet = getValues("street")
      const isReady = suggestionsStatus === "updating"

      if (currentStreet.length < 2) return

      const isValidStreet = streetSuggestions?.find(
        (address) => address.street === currentStreet
      )

      if (isReady && !isValidStreet) {
        setError("street", {
          message: `${t("errors.db_street_not_found")}`,
        })
      } else {
        clearErrors("street")

        try {
          await trigger("street")
        } catch (error) {
          console.log(error)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      suggestionsStatus,
      streetSuggestions,
      getValues,
      setError,
      clearErrors,
      errors,
    ])

    const _getDatabaseStreetSuggestions = useMemo(() => {
      async function getDatabaseStreetSuggestions() {
        const partialAddress = getValues([
          "city",
          "state",
          "county",
          "municipality",
          "street",
        ])

        const hasRequiredValues = Object.values(partialAddress).every(Boolean)

        if (!hasRequiredValues || partialAddress.street.length < 2) {
          return
        }

        setSuggestionStatus("loading")

        const {
          json: { data },
        } = await fetchStreetSuggestions(partialAddress, true)

        if (!isMounted()) return

        if (data) {
          setStreetSuggestions(filterDuplicatedSuggestions(data))
          setSuggestionStatus("updating")
        } else {
          resetAutocomplete()
        }
      }

      return debounce(getDatabaseStreetSuggestions, 1000)
    }, [isMounted, getValues])

    const _getPlacematicStreetSuggestions = useMemo(() => {
      async function getPlacematicStreetSuggestions() {
        const partialAddress = getValues([
          "city",
          "state",
          "county",
          "municipality",
          "street",
        ])

        const hasRequiredValues = Object.values(partialAddress).every(Boolean)

        if (!hasRequiredValues || partialAddress.street.length < 2) {
          return
        }

        // Avoid unnecessary request if autocomplete is not active
        // TODO: Check if updated suggestionsStatus !== "loading"
        // instead of relaying on DOM elements
        if (document.activeElement?.id !== "street") return

        const {
          json: { data },
        } = await fetchStreetSuggestions(partialAddress)

        if (!isMounted()) return

        if (data?.length) {
          setStreetSuggestions((currentSuggestions) =>
            filterDuplicatedSuggestions([...currentSuggestions, ...data])
          )
        }

        setSuggestionStatus("fetched")
      }

      return debounce(getPlacematicStreetSuggestions, 3000)
    }, [isMounted, getValues])

    function getStreets() {
      resetAutocomplete()
      _getDatabaseStreetSuggestions()
      _getPlacematicStreetSuggestions()
    }

    useEffect(() => {
      validateSuggestions()
    }, [streetSuggestions, validateSuggestions])

    return (
      <AutocompleteField
        autocomplete={{
          values: streetSuggestions,
          status: suggestionsStatus,
          onValueSelect: (value) => setValue("street", value.street),
          valueToString: (value) => value.street!,
        }}
        inputProps={{
          ...inputProps,
          onFocus: handleFocus,
          onBlur: handleBlur,
          onKeyDown: handleKeyDown,
        }}
        ref={ref}
        {...props}
      />
    )
  }
)

export { StreetField }
