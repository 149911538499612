import clsx from "clsx"

import { ThemeColor } from "../../types"
import cls from "./Spinner.module.scss"

export type SpinnerProps = {
  color?: ThemeColor
  size?: "small" | "medium" | "large" | "extra-large"
  inline?: boolean
  className?: string
}

export const Spinner = ({
  color = "primary",
  size = "extra-large",
  inline,
  className,
  ...props
}: SpinnerProps & JSX.IntrinsicElements["div"]) => (
  <div className={clsx(cls.spinner, cls.inline, size && cls[size], color && cls[color], className)} {...props} />
)
