import L, { LatLngTuple } from "leaflet"
import { Marker } from "react-leaflet"

type DriverMarkerProps = {
  position: LatLngTuple
  heading: number | null
}

const DriverMarker = ({ position, heading }: DriverMarkerProps) => {
  if (!position) return null

  return (
    <Marker
      icon={icon}
      position={position}
      rotationOrigin="center center"
      rotationAngle={heading || 0}
    />
  )
}

const icon = L.divIcon({
  className: `DriverMarker info`,
  iconAnchor: [8, 8],
  iconSize: [24, 24],
})

export { DriverMarker }
