import clsx from "clsx"

import { formatPrice } from "@/utils"

import cls from "./price.module.scss"

type PriceProps = {
  children: number | string | null
  className?: string
  currency?: string
  subunit?: boolean
}

const Price = ({
  children,
  className,
  currency = "PLN",
  subunit = false,
}: PriceProps) => {
  return (
    <span className={clsx(cls.price, className)}>
      {formatPrice(children, { subunit })}&nbsp;
      <span>{currency}</span>
    </span>
  )
}

export { Price }
