import { Button, Icon } from "@deligoo/ui"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Table } from "@/components/Table"
import { selectAddressList } from "@/store/orderForm"
import { Address } from "@/types"

import formCls from "../../OrderForm.module.scss"
import cls from "../ContactDetails.module.scss"

type AddressListProps = {
  setView: (view: "form" | "list") => void
}

const AddressList = ({ setView }: AddressListProps) => {
  const { reset, getValues } = useFormContext()

  const addressList = useSelector(selectAddressList)

  const { t } = useTranslation(["common", "createOrder"])

  function selectAddress(address: Address) {
    const { phone, ...data } = address

    reset({
      phone: getValues("phone"),
      ...data,
    })

    setView("form")
  }

  function addNewAddress() {
    reset({ phone: getValues("phone") })
    setView("form")
  }

  return (
    <div className={formCls.step}>
      <div className={cls.addressList}>
        <div className={cls.title}>
          {t("createOrder:headers.saved_addresses")}
        </div>

        {!addressList ? (
          <div className={formCls.main}>{t("errors.phone_number_format")}</div>
        ) : (
          <Table
            columns="minmax(200px, 1fr) 150px 110px 110px"
            className={cls.list}
            minWidth="500px"
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{t("labels.street_with_house_number")}</Table.Th>
                <Table.Th>{t("labels.flat")}</Table.Th>
                <Table.Th>{t("labels.staircase")}</Table.Th>
                <Table.Th>{t("labels.floor")}</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {addressList.map((address) => (
                <Table.Tr
                  onClick={() => selectAddress(address)}
                  key={address.humanized_full_address}
                >
                  <Table.Td>{address.humanized_full_address}</Table.Td>
                  <Table.Td>{address.flat_number || "–"}</Table.Td>
                  <Table.Td>{address.staircase || "–"}</Table.Td>
                  <Table.Td>{address.floor || "–"}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        )}
      </div>

      <div className={formCls.actions}>
        <Button
          onClick={addNewAddress}
          type="button"
          color="primary"
          size="extra-large"
          fullWidth
        >
          {t("createOrder:buttons.add_new")}
          <Icon name="short-arrow-tiny-right" />
        </Button>
      </div>
    </div>
  )
}
export { AddressList }
