import { Button, Field, Modal } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { useState } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Rifm } from "rifm"

import { useAppDispatch } from "@/App"
import { getIconName } from "@/components/PaymentForm/PaymentForm"
import { requestChangeOrderPayment, selectOrderById } from "@/store/orders"
import { ChangeOrderPaymentData } from "@/types"
import {
  formatPrice,
  handlePriceInputBlur,
  priceMaskProps,
  priceToSubunit,
  useIsMounted,
} from "@/utils"

import { ActionProps } from "."
import cls from "./actions.module.scss"

const CASH_AND_CARD: ("cash" | "card")[] = ["cash", "card"]

const UpdateOrderPayment = ({
  orderId,
  handleClose,
  isParentMounted,
  isActionInProgress,
  setIsActionInProgress,
}: ActionProps) => {
  const order = useSelector(selectOrderById(orderId))

  const formMethods = useForm({
    defaultValues: {
      payment_form: order?.payment_form || undefined,
      price_subunit: order?.price_subunit || 0,
      price_delivery_subunit: order?.price_delivery_subunit || 0,
    },
  })

  const { register, setValue, watch, handleSubmit } = formMethods

  const currentPrice = watch("price_subunit")
  const currentDeliveryPrice = watch("price_delivery_subunit")
  const currentPaymentForm = watch("payment_form")

  const dispatch = useAppDispatch()
  const isMounted = useIsMounted()

  const { t } = useTranslation(["payments", "orders", "common"])

  function handleChangePayment(formData: ChangeOrderPaymentData["payment"]) {
    setIsActionInProgress(true)

    dispatch(
      requestChangeOrderPayment({ orderId, data: { payment: formData } })
    )
      .then(unwrapResult)
      .then(({ ok }) => {
        if (!ok) return
        toast.success(t("orders:update_order_payment_modal.success"))
        isMounted() && handleClose()
      })
      .finally(() => {
        isParentMounted() && setIsActionInProgress(false)
      })
  }

  if (!order) return null

  return (
    <Modal handleClose={handleClose} className={cls.actionModal} light>
      <Modal.Content>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleChangePayment)}>
            <h1 className="h400 mb-8">
              {t("orders:update_order_payment_modal.order_amount")}
            </h1>
            <AmountInput
              inputName="price_subunit"
              defaultValue={currentPrice}
            />
            <h1 className="h400 my-8">
              {t("orders:update_order_payment_modal.delivery_price")}
            </h1>
            <AmountInput
              inputName="price_delivery_subunit"
              defaultValue={currentDeliveryPrice}
            />

            <input
              type="hidden"
              name="payment_form"
              ref={register({ required: true })}
            />

            <div className={clsx(cls.content, cls.paymentForms)}>
              {CASH_AND_CARD.map(
                (paymentType) =>
                  (!order.driver?.payment_forms ||
                    order.driver?.payment_forms.includes(paymentType)) && (
                    <Button
                      onClick={() => setValue("payment_form", paymentType)}
                      variation={
                        currentPaymentForm === paymentType
                          ? "solid"
                          : "accented"
                      }
                      iconProps={{
                        name: getIconName(paymentType),
                      }}
                      color="primary"
                      size="extra-large"
                      className={cls.button}
                      key={paymentType}
                    >
                      {t(`payment_form.${paymentType}`)}
                    </Button>
                  )
              )}

              <Button
                onClick={() => setValue("payment_form", "paid")}
                variation={currentPaymentForm === "paid" ? "solid" : "accented"}
                iconProps={{ name: getIconName("paid") }}
                color="primary"
                size="extra-large"
                className={cls.button}
              >
                {t(`payment_form.paid`)}
              </Button>
            </div>

            <div className={cls.actions}>
              <Button
                onClick={handleClose}
                className={cls.action}
                color="alert"
                size="extra-large"
              >
                {t("common:buttons.cancel")}
              </Button>

              <Button
                loading={isActionInProgress}
                className={cls.action}
                color="success"
                size="extra-large"
                type="submit"
              >
                {t("common:buttons.confirm")}
              </Button>
            </div>
          </form>
        </FormProvider>
      </Modal.Content>
    </Modal>
  )
}

type AmountInputProps = {
  inputName: string
  defaultValue?: number
}

const AmountInput = ({ inputName, defaultValue }: AmountInputProps) => {
  const { register, setValue, errors } = useFormContext()

  const { t } = useTranslation()

  const [maskedValue, setMaskedValue] = useState(
    formatPrice(defaultValue, { subunit: true })
  )

  return (
    <div className={clsx(cls.content, cls.paymentValue)}>
      <input
        name={inputName}
        ref={register({ required: `${t("errors.is_required")}` })}
        type="hidden"
      />

      <div className={cls.inputWrapperWrapper}>
        <Rifm
          value={maskedValue}
          onChange={(value) => {
            setMaskedValue(value)
            setValue(inputName, priceToSubunit(value))
          }}
          {...priceMaskProps}
        >
          {({ value, onChange }) => (
            <Field
              error={errors[inputName]?.message}
              inputProps={{
                value,
                onChange,
                onBlur: handlePriceInputBlur,
                inputMode: "decimal",
                placeholder: "0.00",
                size: "extra-large",
                className: cls.input,
                maxLength: 10,
                plain: false,
              }}
            />
          )}
        </Rifm>
      </div>
      <span className={cls.currency}>PLN</span>
    </div>
  )
}

export { UpdateOrderPayment }
