import { Button, Input } from "@deligoo/ui"
import clsx from "clsx"
import { FocusEvent, useEffect, useRef, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Rifm } from "rifm"

import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { IconName, PaymentAndPackage, PaymentForm } from "@/types"
import {
  formatPrice,
  isTruthyOrZero,
  priceMaskProps,
  priceToSubunit,
} from "@/utils"

import cls from "../PaymentAndPackage.module.scss"

type PaymentOptionsProps = {
  readOnly: boolean
}

const PaymentOptions = ({ readOnly }: PaymentOptionsProps) => {
  const acceptedPaymentMethods = useSelector(
    selectCurrentRestaurantKey("accepted_payment_forms")
  )?.filter((form) => form.length) // TODO: Remove filter after API fix

  const { register, errors, watch } = useFormContext()

  const selectedPaymentForm = watch("payment_form")

  const { t } = useTranslation("createOrder")

  return (
    <div className={cls.paymentOptions}>
      {readOnly ? (
        <PaymentOption paymentOption={selectedPaymentForm} readOnly />
      ) : acceptedPaymentMethods?.length ? (
        acceptedPaymentMethods.map((paymentOption) => (
          <PaymentOption paymentOption={paymentOption} key={paymentOption} />
        ))
      ) : (
        t("errors.no_payment_methods")
      )}

      <input
        type="hidden"
        name="payment_form"
        ref={register({ required: `${t("errors.payment_value_required")}` })}
      />

      {errors?.payment_form ? (
        <div className={clsx("text-error", cls.error)}>
          {errors.payment_form.message}
        </div>
      ) : (
        errors?.price_subunit && (
          <div className={clsx("text-error", cls.error)}>
            {errors.price_subunit.message}
          </div>
        )
      )}
    </div>
  )
}

type PaymentOptionProps = {
  paymentOption: NonNullable<PaymentForm>
  readOnly?: boolean
}

const PaymentOption = ({
  paymentOption,
  readOnly = false,
}: PaymentOptionProps) => {
  const { setValue } = useFormContext()

  const { payment_form, price_subunit } = useWatch<PaymentAndPackage>({})

  const [maskedValue, setMaskedValue] = useState(
    formatPrice(price_subunit, {
      subunit: true,
    })
  )

  const inputRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation("payments")

  function setPaymentForm(paymentOption: NonNullable<PaymentForm>) {
    setValue("payment_form", paymentOption)
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    const value = formatPrice(e.currentTarget.value)

    if (value.length) {
      setMaskedValue(value)
    } else if (isTruthyOrZero(price_subunit)) {
      setMaskedValue(formatPrice(price_subunit))
    } else if (paymentOption === "paid") {
      setMaskedValue(formatPrice(0))
    }
  }

  useEffect(() => {
    setValue(
      "price_subunit",
      maskedValue ? priceToSubunit(maskedValue) : undefined
    )
  }, [maskedValue, setValue])

  return (
    <Button
      type="button"
      color="primary"
      variation={payment_form === paymentOption ? "solid" : "accented"}
      size={payment_form === paymentOption ? "extra-large" : "large"}
      iconProps={{ name: getIconName(paymentOption) }}
      onClick={() => {
        setPaymentForm(paymentOption)
        inputRef.current?.focus()
      }}
      disabled={readOnly}
    >
      <span className={cls.buttonLabel}>
        {t(`payment_form.${paymentOption}`)}
      </span>

      {payment_form === paymentOption && (
        <span className={cls.amount}>
          <Rifm
            value={maskedValue}
            onChange={(value) => setMaskedValue(value)}
            {...priceMaskProps}
          >
            {({ value, onChange }) => (
              <Input
                value={value}
                onClick={(e) => e.stopPropagation()}
                onChange={onChange}
                onBlur={handleBlur}
                readOnly={readOnly}
                placeholder="0.00"
                maxLength={10}
                inputMode="decimal"
                size="extra-large"
                className={cls.input}
                autoComplete="off"
                autoFocus={!isTruthyOrZero(maskedValue)}
                ref={inputRef}
                selectOnFocus
                dark
              />
            )}
          </Rifm>
          <span className={cls.currency}>PLN</span>
        </span>
      )}
    </Button>
  )
}

function getIconName(paymentType: NonNullable<PaymentForm>): IconName {
  switch (paymentType) {
    case "cash":
      return "cash"
    case "card":
      return "credit-card"
    case "paid":
      return "check-circle"
  }
}

export { PaymentOptions }
