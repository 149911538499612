import bigPizza from "@/assets/packages/big-pizza.png"
import ecommerceSizeA from "@/assets/packages/ecommerce-size-a.png"
import ecommerceSizeB from "@/assets/packages/ecommerce-size-b.png"
import ecommerceSizeC from "@/assets/packages/ecommerce-size-c.png"
import hebe from "@/assets/packages/hebe.png"
import inpostSizeA from "@/assets/packages/inpost-size-a.png"
import inpostSizeB from "@/assets/packages/inpost-size-b.png"
import inpostSizeC from "@/assets/packages/inpost-size-c.png"
import ownPackaging from "@/assets/packages/own-packaging.png"
import standardDish from "@/assets/packages/standard-dish.png"

export const PACKAGES_ASSETS = {
  "big-pizza": bigPizza,
  "standard-dish": standardDish,
  "own-packaging": ownPackaging,
  "medium-pizza": "",
  dinner: "",

  "hebe-box-1": hebe,
  "hebe-box-2": hebe,
  "hebe-box-3": hebe,
  "hebe-box-4": hebe,
  "hebe-box-5": hebe,

  "ecommerce-size-a": ecommerceSizeA,
  "ecommerce-size-b": ecommerceSizeB,
  "ecommerce-size-c": ecommerceSizeC,

  "inpost-size-a": inpostSizeA,
  "inpost-size-b": inpostSizeB,
  "inpost-size-c": inpostSizeC,
} as const
