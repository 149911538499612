import { Field } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import { forwardRef, KeyboardEvent } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Rifm } from "rifm"

import { useAppDispatch } from "@/App"
import { getAddressList, resetAddressList } from "@/store/orderForm"
import { useIsMounted } from "@/utils"
import { formatPhone } from "@/utils/masks"

import cls from "../ContactDetails.module.scss"

type PhoneFieldProps = {
  error?: string
  setView: (view: "form" | "list") => void
}

const PhoneField = forwardRef<HTMLInputElement, PhoneFieldProps>(
  ({ error, setView }, ref) => {
    const dispatch = useAppDispatch()

    const { setValue, watch, reset } = useFormContext()

    const maskedPhone = watch("phone")

    const isMounted = useIsMounted()

    const { t } = useTranslation()

    function handlePhoneInput(event: KeyboardEvent<HTMLInputElement>) {
      if (!Number(event.key)) return // Avoid fetch if phone real value didn't changed

      if (maskedPhone.length === 11) {
        event.currentTarget.blur()

        dispatch(getAddressList(maskedPhone))
          .then(unwrapResult)
          .then((result) => {
            if (result.data?.length && isMounted()) setView("list")
          })
      }
    }

    function clearForm() {
      setValue("distance", null)
      setValue("delivery_price_subunit", null)
      dispatch(resetAddressList())

      reset({})
    }

    return (
      <Rifm
        value={maskedPhone || ""}
        onChange={(value) => setValue("phone", value)}
        format={formatPhone}
      >
        {({ value, onChange }) => (
          <Field
            required
            name="phone"
            label={t("labels.phone")}
            className={cls.phone}
            error={error}
            ref={ref}
            inputProps={{
              onKeyUp: handlePhoneInput,
              onFocus: clearForm,
              maxLength: 11,
              iconRightProps: { name: "search" },
              autoFocus: value ? false : true,
              size: "extra-large",
              type: "tel",
              value,
              onChange,
            }}
          />
        )}
      </Rifm>
    )
  }
)

export { PhoneField }
