import { Marketplace, Provider } from "@deligoo/shared"
import { Icon, Modal, ThemeColor, Tooltip } from "@deligoo/ui"
import clsx from "clsx"
import { HTMLAttributes, ReactNode, SyntheticEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import cls from "./Card.module.scss"

type HelpModal = {
  title: string
  content: ReactNode
}

type CardProps = {
  children?: ReactNode
  className?: string
  color?: ThemeColor | Provider | Marketplace
  disabled?: string | boolean
  help?: string
  inline?: boolean
  loading?: boolean
  sections?: boolean
  link?: boolean
  helpModal?: HelpModal
}

const Card = ({
  children,
  className,
  color,
  disabled,
  help,
  inline,
  loading,
  sections,
  link,
  helpModal,
}: CardProps) => {
  if (loading) {
    return (
      <div className={clsx(cls.card, className, loading && cls.loading)}>
        <img src={require("./placeholder.svg").default} alt={""} />
      </div>
    )
  }

  return (
    <div
      className={clsx(
        cls.card,
        className,
        color && cls[color],
        disabled && cls.disabled,
        inline && cls.inline,
        sections && cls.sections,
        link && cls.link
      )}
    >
      {help && (
        <Card.Help text={help} modal={helpModal} className={cls.floating} />
      )}

      {children}
    </div>
  )
}

type HelpProps = HTMLAttributes<HTMLDivElement> & {
  text: string
  modal?: HelpModal
}

const Help = ({ text, modal, className, ...props }: HelpProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const { t } = useTranslation("dashboard")

  function handleClick(e: SyntheticEvent) {
    e.stopPropagation()
    e.preventDefault()
    if (modal) setModalOpen(true)
  }

  return (
    <>
      <Tooltip content={text} zIndex={1}>
        <div
          className={clsx(cls.help, className)}
          onClick={handleClick}
          {...props}
        >
          <Icon name="help" size="small" />
        </div>
      </Tooltip>

      {modal && isModalOpen && (
        <Modal
          handleClose={() => setModalOpen(false)}
          className={cls.helpModal}
        >
          <Modal.Title>{t(modal.title)}</Modal.Title>
          <Modal.Content>{modal.content}</Modal.Content>
        </Modal>
      )}
    </>
  )
}

const Header = ({ className, children }: HTMLAttributes<HTMLDivElement>) => {
  return <header className={clsx(cls.header, className)}>{children}</header>
}

const Footer = ({ className, children }: HTMLAttributes<HTMLDivElement>) => {
  return <footer className={clsx(cls.footer, className)}>{children}</footer>
}

const Section = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={clsx(cls.section, className)} {...props}>
      {children}
    </div>
  )
}

type CardTitleProps = HTMLAttributes<HTMLDivElement> & {
  as?: "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  color?: ThemeColor
  size?: "small" | "medium" | "large"
}

const Title = ({
  as = "div",
  color,
  size,
  className,
  children,
  ...props
}: CardTitleProps) => {
  const Wrapper = as

  return (
    <Wrapper
      className={clsx(
        cls.title,
        color && cls[color],
        size && cls[size],
        className
      )}
      {...props}
    >
      {children}
    </Wrapper>
  )
}

type CardActionProps = HTMLAttributes<HTMLButtonElement> & {
  iconOnly?: boolean
}

const Action = ({
  iconOnly,
  className,
  children,
  ...props
}: CardActionProps) => {
  return (
    <button
      className={clsx(cls.actionBtn, iconOnly && cls.iconOnly, className)}
      type="button"
      {...props}
    >
      {children}
    </button>
  )
}

Card.Header = Header
Card.Footer = Footer
Card.Section = Section
Card.Title = Title
Card.Action = Action
Card.Help = Help

export { Card }
