import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import {
  Client,
  DataStatus,
  RootState,
  Settings,
  SettingsToUpdate,
} from "@/types"
import {
  fetchSettings,
  fetchUpdateSettings,
  handleStandardError,
} from "@/utils"

import { clearDataOnLogout, requestClient } from "./auth"
import { setCurrentRestaurant } from "./restaurant"

type ClientState = {
  data: Client | null
  settings: {
    data: Settings | null
    status: DataStatus | null
  }
}

const initialState: ClientState = {
  data: null,
  settings: {
    data: null,
    status: null,
  },
}

export const requestSettings = createAsyncThunk(
  "client/requestSettings",
  async () => {
    const { response, json } = await fetchSettings()

    return { ok: response.ok, status: response.status, data: json.data }
  }
)

export const requestUpdateSettings = createAsyncThunk(
  "client/requestUpdateSettings",
  async (settingsToUpdate: SettingsToUpdate) => {
    const { response, json } = await fetchUpdateSettings(settingsToUpdate)

    if (!response.ok) {
      handleStandardError(response, json)
    }

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
      errors: json.errors,
    }
  }
)

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestClient.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) state.data = payload.data
    })

    builder.addCase(setCurrentRestaurant.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) state.data = payload.data
    })

    builder.addCase(requestSettings.pending, (state) => {
      if (state.settings.data) {
        state.settings.status = "updating"
      } else {
        state.settings.status = "loading"
      }
    })

    builder.addCase(requestSettings.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        state.settings.data = payload.data
        state.settings.status = "fetched"
        if (state.data) {
          state.data = { ...state.data, ...payload.data }
        }
      } else {
        state.settings.status = "error"
      }
    })

    builder.addCase(requestUpdateSettings.pending, (state) => {
      if (state.settings.data) {
        state.settings.status = "updating"
      } else {
        state.settings.status = "loading"
      }
    })

    builder.addCase(requestUpdateSettings.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data && state.data) {
        state.settings.data = payload.data
        state.data = { ...state.data, ...payload.data }
      }
      state.settings.status = "fetched" // !!
    })

    builder.addCase(clearDataOnLogout, () => initialState)
  },
})

export const selectClient = (state: RootState) => state.client.data

export const selectClientKey =
  <T extends keyof Client>(key: T) =>
  (state: RootState) =>
    state.client.data?.[key]

export const selectSettings = (state: RootState) => state.client.settings.data

export const selectSettingsStatus = (state: RootState) =>
  state.client.settings.status

export const selectSettingsKey =
  <T extends keyof Settings>(key: T) =>
  (state: RootState) =>
    state.client.settings.data?.[key]

export const clientReducer = clientSlice.reducer
