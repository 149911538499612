import { AppDispatch } from "@/App"
import { subscribe } from "@/sockets/utils"
import { updateInvalidOrder } from "@/store/invalidOrders"
import { updateMessage } from "@/store/messages"
import { updateNotification } from "@/store/notifications"
import { handleOrderUpdate, updateConnectedOrdersIds } from "@/store/orders"
import { updateRestaurant } from "@/store/restaurant"
import { updateStatistics, updateTeamStats } from "@/store/statistics"
import { updateUnsettledPayment } from "@/store/unsettledPayments"
import {
  ConnectedOrdersIds,
  Message,
  Notification,
  Order,
  Restaurant,
  Statistics,
  TeamStats,
  UnsettledPayment,
} from "@/types"
import { InvalidOrder } from "@/types/InvalidOrder"
import { Sounds } from "@/utils"

type Recipients = {
  restaurantUuid: string
  teamUuid: string
}

// TODO: Stricter typing
export type Channel = {
  subscribe: (recipients: Recipients) => ReturnType<typeof subscribe>
  handleMessage: (
    dispatch: AppDispatch,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    message: { data?: unknown } | any
  ) => void
}

// "Message" can refer either to WS message (as in handleMessage) or
// to POS message (as in updateMessage)
export const Channels: { [channelName: string]: Channel } = {
  MessageChannel: {
    subscribe: ({ restaurantUuid }) =>
      subscribe("MessageChannel", restaurantUuid),
    handleMessage: (dispatch: AppDispatch, message: { data: Message }) => {
      Sounds.playMessageSound()
      dispatch(updateMessage(message.data))
    },
  },
  NotificationChannel: {
    subscribe: ({ restaurantUuid }) =>
      subscribe("NotificationChannel", restaurantUuid),
    handleMessage: (dispatch: AppDispatch, message: { data: Notification }) => {
      Sounds.playNotificationSound(message.data.priority)
      dispatch(updateNotification(message.data))
    },
  },
  ClientChannel: {
    // currentRestaurant in POS vocabulary
    subscribe: ({ restaurantUuid }) =>
      subscribe("ClientChannel", restaurantUuid),
    handleMessage: (dispatch: AppDispatch, message: { data: Restaurant }) => {
      dispatch(updateRestaurant(message.data))
    },
  },
  "Clients::StatisticsChannel": {
    subscribe: ({ restaurantUuid }) =>
      subscribe("Clients::StatisticsChannel", restaurantUuid),
    handleMessage: (dispatch: AppDispatch, message: { data: Statistics }) => {
      dispatch(updateStatistics(message.data))
    },
  },
  OrderChannel: {
    subscribe: ({ restaurantUuid }) =>
      subscribe("OrderChannel", restaurantUuid),
    handleMessage: (dispatch: AppDispatch, message: { data: Order }) => {
      dispatch(handleOrderUpdate(message.data))
    },
  },
  InvalidOrderChannel: {
    subscribe: ({ restaurantUuid }) =>
      subscribe("InvalidOrderChannel", restaurantUuid),
    handleMessage: (dispatch: AppDispatch, message: { data: InvalidOrder }) => {
      dispatch(updateInvalidOrder(message.data))
    },
  },
  "Orders::Payments::UnsettledChannel": {
    subscribe: ({ restaurantUuid }) =>
      subscribe("Orders::Payments::UnsettledChannel", restaurantUuid),
    handleMessage: (
      dispatch: AppDispatch,
      message: { data: UnsettledPayment }
    ) => {
      dispatch(updateUnsettledPayment(message.data))
    },
  },
  TeamChannel: {
    subscribe: ({ teamUuid }) => subscribe("TeamChannel", teamUuid),
    handleMessage: (dispatch: AppDispatch, message: { data: TeamStats }) => {
      dispatch(updateTeamStats(message.data))
    },
  },
  RefreshChannel: {
    subscribe: ({ restaurantUuid }) =>
      subscribe("RefreshChannel", restaurantUuid),
    handleMessage: (
      _dispatch: AppDispatch,
      message: { data: { refresh: true } }
    ) => {
      if (message.data.refresh) window.location.reload()
    },
  },
  "Clients::ConnectedOrdersChannel": {
    subscribe: ({ restaurantUuid }) =>
      subscribe("Clients::ConnectedOrdersChannel", restaurantUuid),
    handleMessage: (
      dispatch: AppDispatch,
      message: { data: { connected_orders_ids: ConnectedOrdersIds } }
    ) => {
      dispatch(updateConnectedOrdersIds(message.data.connected_orders_ids))
    },
  },
}
