import "leaflet/dist/leaflet.css"
import "./leaflet.scss"

import { Backdrop, Button } from "@deligoo/ui"
import { LatLngTuple } from "leaflet"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MapContainer, TileLayer } from "react-leaflet"
import { useSelector } from "react-redux"

import { DriverInfo } from "@/components/DriverInfo"
import { OrderProgress } from "@/components/OrderProgress"
import { selectOrderById } from "@/store/orders"
import { selectCurrentRestaurant } from "@/store/restaurant"
import { Order } from "@/types"
import { canBeTracked } from "@/utils"

import { DriverMarker } from "./DriverMarker"
import { DriverRoute } from "./DriverRoute"
import cls from "./OrderTracking.module.scss"
import { RestaurantMarker } from "./RestaurantMarker"
import { useDriverLocation } from "./useDriverLocation"

// const MAPTILER_API_KEY = process.env.REACT_APP_MAPTILER_API_KEY
const TILE_SUBDOMAINS = ["1", "2", "3", "4"]
// const TILE_URL = `https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}.png?key=${MAPTILER_API_KEY}`
const HERE_API_KEY = process.env.REACT_APP_HERE_API_KEY
const TILE_URL = `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png?size=256&apiKey=${HERE_API_KEY}`

const defaultZoom = 15
const defaultCenter: LatLngTuple = [52, 19]

type OrderTrackingProps = {
  orderId: Order["id"]
  handleClose: () => void
}

const OrderTracking = ({ orderId, handleClose }: OrderTrackingProps) => {
  const order = useSelector(selectOrderById(orderId))
  const currentRestaurant = useSelector(selectCurrentRestaurant)

  const { t } = useTranslation("orders")

  const restaurantPosition: LatLngTuple | null = useMemo(() => {
    return currentRestaurant
      ? [currentRestaurant.lat, currentRestaurant.lng]
      : null
  }, [currentRestaurant])

  const { driverPosition, driverHeading } = useDriverLocation(orderId)

  if (!order || !currentRestaurant || !restaurantPosition) return null
  if (!canBeTracked(order)) handleClose()

  return (
    <Backdrop>
      <div className={cls.orderTracking}>
        <header className={cls.header}>
          <h1 className={cls.title}>
            {t("order_tracking")} <span className={cls.uid}>#{order.uid}</span>
          </h1>
          <Button
            className={cls.closeBtn}
            variation="text"
            iconProps={{
              className: cls.icon,
              name: "close",
              color: "muted",
              size: "large",
            }}
            onClick={handleClose}
          />
        </header>

        <MapContainer
          center={defaultCenter}
          zoom={defaultZoom}
          minZoom={5}
          maxZoom={18}
          zoomControl={false}
          attributionControl={false}
          whenCreated={(map) => map.fitBounds([restaurantPosition])}
        >
          <TileLayer url={TILE_URL} subdomains={TILE_SUBDOMAINS} />
          {driverPosition && (
            <DriverRoute
              driverPosition={driverPosition}
              restaurantPosition={restaurantPosition}
            />
          )}
          <RestaurantMarker position={restaurantPosition} />
          {driverPosition && (
            <DriverMarker position={driverPosition} heading={driverHeading} />
          )}
        </MapContainer>

        <footer className={cls.footer}>
          <div className={cls.address}>{order.pickup?.address}</div>
          <OrderProgress className={cls.orderProgress} order={order} />
          <div className={cls.driver}>
            <DriverInfo driver={order.driver} />
          </div>
        </footer>
      </div>
    </Backdrop>
  )
}

export { OrderTracking }
