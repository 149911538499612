import { Icon } from "@deligoo/ui"
import clsx from "clsx"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { Order } from "@/types"
import { InvalidOrder } from "@/types/InvalidOrder"

import cls from "../ReceiveOrderModal.module.scss"

type Details =
  | Order["customer_contact_detail"]
  | InvalidOrder["data"]["customer_contact_detail_attributes"]

export const CustomerContactDetailsSection = ({
  details,
}: {
  details?: Details
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { t } = useTranslation(["orders", "common"])

  if (!details) return null

  return (
    <div className={clsx(cls.section, cls.client)}>
      <button
        className={cls.top}
        onClick={() => setIsExpanded((state) => !state)}
        type="button"
      >
        <div className={cls.title}>
          <Icon name="business-client" color="primary" gutter="right" />
          {t("orders_to_receive.headers.customer_details")}
        </div>

        <div className={cls.expander}>
          <Icon
            name={isExpanded ? "chevron-up" : "chevron-down"}
            size="tiny"
            color="muted"
          />
        </div>
      </button>

      {isExpanded && (
        <div className={cls.bottom}>
          {customerDetailsFields.map((field) => {
            const fieldValue = details[field]

            if (!fieldValue) return null

            return (
              <div key={field} className={cls.item}>
                <span>{t(`common:labels.${field}`)}</span>
                <span>{fieldValue}</span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const customerDetailsFields: Array<keyof NonNullable<Details>> = [
  "first_name",
  "last_name",
  "company_name",
  "phone",
  "email",
  "humanized_full_address",
  "house_number",
  "flat_number",
  "floor",
  "staircase",
  "access_code",
  "postal_code",
  "language",
]
