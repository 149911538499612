import { Provider } from "@deligoo/shared"
import dayjs, { Dayjs } from "dayjs"
import { toast } from "react-toastify"

import { globalStore } from "@/App"
import i18n from "@/i18n"
import { Site } from "@/sites"
import { clearDataOnLogout } from "@/store/auth"
import {
  Address,
  ChangeOrderPaymentData,
  Client,
  ConnectedOrdersIds,
  ContactDetail,
  DeliveryDetails,
  Errors,
  FullAddress,
  Message,
  NonNullableDict,
  Notification,
  Order,
  OrderFormData,
  OrderFull,
  OrderStepKey,
  PackageType,
  ProvidersIntegration,
  Settings,
  SettingsToUpdate,
  Statistics,
  TransportType,
  UnsettledPayment,
} from "@/types"
import { InvalidOrder } from "@/types/InvalidOrder"
import { isProductionEnvironment } from "@/utils"
import { ComplaintsFormData } from "@/views/Complaints"

const networkErrorJson = {
  errors: {
    network: [
      {
        code: "network_error",
        text: "Błąd połączenia z serwerem lub brak dostępu do Internetu",
      },
    ],
  },
}

const JSONHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

// Endpoints are to be switched to 'appHost' and 'appPort' eventually
export function getApiURL({ appHost, appPort, protocol }: Site) {
  return `${protocol}://${appHost}${
    appPort ? `:${appPort}` : ""
  }/api/clients_app/v1`
}

async function fetchFromApi<T>(endpoint: string, options: RequestInit = {}) {
  try {
    const apiUrl = getApiURL(JSON.parse(localStorage.getItem("site")!))

    const userLocale = i18n.language

    const response = await fetch(`${apiUrl}${endpoint}`, {
      ...options,
      headers: {
        Authorization: localStorage.getItem("token")!,
        "Accept-Language": userLocale,
        ...JSONHeaders,
        ...options.headers,
      },
    })

    if (response.status === 401) {
      const isAuthenticated = globalStore.getState().auth.isAuthenticated

      if (isAuthenticated) {
        globalStore.dispatch(clearDataOnLogout())
        localStorage.removeItem("token")

        toast.error(i18n.t("common:errors.session_expired"))
      }
    }

    const json: Partial<T> & { errors?: Errors } = await response.json()

    return { response, json }
  } catch (error) {
    return {
      response: { ok: false, status: null },
      json: networkErrorJson as unknown as Partial<T> & { errors: Errors },
    }
  }
}

// GET /clients_app/v1/info
export function fetchInfo() {
  return fetchFromApi<{ contact_number: string | null }>("/info")
}

// POST /clients_app/v1/sign_in
export function fetchSignIn(username: string, password: string) {
  return fetchFromApi<{ success?: boolean; errors?: Errors }>("/sign_in", {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
    }),
  })
}

// DELETE /clients_app/v1/sign_out
export function fetchSignOut() {
  return fetchFromApi("/sign_out", { method: "DELETE" })
}

// GET /clients_app/v1/me
export function fetchClient() {
  return fetchFromApi<{ data: Client }>("/me")
}

// PUT /clients_app/v1/set_current_restaurant
export function fetchSetCurrentRestaurant(restaurantId: number) {
  return fetchFromApi<{ data: Client }>(
    `/set_current_restaurant?restaurant_id=${restaurantId}`,
    {
      method: "PUT",
    }
  )
}

// GET /clients_app/v1/statistics
export function fetchStatistics(startDate: Dayjs, endDate: Dayjs) {
  return fetchFromApi<{ data: Statistics }>(
    `/statistics?start_date=${startDate.format(
      "YYYY-MM-DD"
    )}&end_date=${endDate.format("YYYY-MM-DD")}`
  )
}

// GET /clients_app/v1/orders
export function fetchOrders(date: Dayjs) {
  return fetchFromApi<{
    data: Order[]
    meta: { connected_orders_ids: ConnectedOrdersIds }
  }>(`/orders?per=1000&date=${date.format("YYYY-MM-DD")}`)
}

// GET /clients_app/v1/orders/${id}
export function fetchOrder(id: OrderFull["id"]) {
  return fetchFromApi<{ data: OrderFull }>(`/orders/${id}`)
}

// GET /clients_app/v1/orders/waiting/${id}
export function fetchWaitingOrders() {
  return fetchFromApi<{ data: Order[] }>(`/orders/waiting`)
}

// GET /clients_app/v1/invalid_orders/waiting
export function fetchInvalidWaitingOrders() {
  return fetchFromApi<{ data: InvalidOrder[] }>(`/invalid_orders/waiting`)
}

// PUT clients_app/v1/invalid_orders/${id}/accept
export function fetchAcceptInvalidOrder(
  id: InvalidOrder["id"],
  body: Partial<InvalidOrder["data"]>
) {
  return fetchFromApi<{ data?: Order; errors?: Errors }>(
    `/invalid_orders/${id}/accept`,
    {
      method: "PUT",
      body: JSON.stringify({
        form_data: { ...body },
      }),
    }
  )
}

// PUT clients_app/v1/invalid_orders/${id}/reject
export function fetchRejectInvalidOrder(id: InvalidOrder["id"]) {
  return fetchFromApi<{ errors?: Errors }>(`/invalid_orders/${id}/reject`, {
    method: "PUT",
  })
}

// GET /clients_app/v1/payments/unsettled
export function fetchUnsettledPayments() {
  // Always fetch unsettled payments for current day
  const todayDate = dayjs().format("YYYY-MM-DD")

  return fetchFromApi<{
    data: UnsettledPayment[]
  }>(
    `/payments/unsettled?start_date=${todayDate}&end_date=${todayDate}&per=1000`
  )
}

// GET /clients_app/v1/integrations
export function fetchIntegrations() {
  return fetchFromApi<{ data: ProvidersIntegration[] }>("/integrations")
}

// PUT /clients_app/v1/integrations/{provider}/activity
export function fetchSetProviderActivity(
  provider: Provider,
  body: { name: string; active: boolean }
) {
  return fetchFromApi<{ data: ProvidersIntegration[] }>(
    `/integrations/${provider}/activity`,
    {
      method: "PUT",
      body: JSON.stringify(body),
    }
  )
}

// GET /clients_app/v1/messages
export function fetchMessages() {
  return fetchFromApi<{ data: Message[] }>("/messages?per=1000")
}

// PUT /clients_app/v1/messages/read_all
export function fetchReadAllUnreadMessages() {
  return fetchFromApi<{ data: Message[] }>("/messages/read_all", {
    method: "PUT",
  })
}

// PUT /clients_app/v1/messages/{id}/read
export function fetchReadMessage(messageId: Message["id"]) {
  return fetchFromApi<{ data: Message }>(`/messages/${messageId}/read`, {
    method: "PUT",
  })
}

// GET /clients_app/v1/settings
export function fetchSettings() {
  return fetchFromApi<{ data: Settings }>("/settings")
}

// PUT /clients_app/v1/update_settings
export function fetchUpdateSettings(settingsToUpdate: SettingsToUpdate) {
  return fetchFromApi<{ data: Settings }>("/settings", {
    method: "PUT",
    body: JSON.stringify({
      ...settingsToUpdate,
    }),
  })
}

// GET /clients_app/v1/notifications
export function fetchNotifications() {
  return fetchFromApi<{ data: Notification[] }>("/notifications?per=1000")
}

// PUT /clients_app/v1/notifications/read_all
export function fetchReadAllNotifications() {
  return fetchFromApi<{ data: Notification[] }>("/notifications/read_all", {
    method: "PUT",
  })
}

// PUT /clients_app/v1/notifications/hide_all
export function fetchHideAllNotifications() {
  return fetchFromApi<{ data: Notification[] }>("/notifications/hide_all", {
    method: "PUT",
  })
}

// GET /clients_app/v1/addresses/get_delivery_details
export function fetchDeliveryDetails({
  city,
  street,
  house_number,
  postal_code,
}: FullAddress) {
  return fetchFromApi<{ data: DeliveryDetails }>(
    `/addresses/get_delivery_details?city=${city}&street=${street}&house_number=${house_number}&postal_code=${postal_code}`
  )
}

// GET /clients_app/v1/customers/contact_details
export function fetchContactDetails(phone: string) {
  return fetchFromApi<{ data: Address[] }>(
    `/customers/contact_details?phone=${phone}`
  )
}

// POST /clients_app/v1/orders
export function fetchCreateOrder(contactDetails: ContactDetail) {
  return fetchFromApi<{ data: Order }>("/orders", {
    method: "POST",
    body: JSON.stringify({
      form_data: {
        contact_detail: {
          ...contactDetails,
        },
      },
    }),
  })
}

// PUT /clients_app/v1/orders/{id}/steps/{step_key}
export function fetchUpdateOrderStep(
  orderId: Order["id"],
  stepKey: OrderStepKey,
  data: OrderFormData[keyof OrderFormData]
) {
  return fetchFromApi<{ data: Order }>(`/orders/${orderId}/steps/${stepKey}`, {
    method: "PUT",
    body: JSON.stringify({
      form_data: {
        [stepKey]: {
          ...data,
        },
      },
    }),
  })
}

export function fetchOrderSteps(orderId: Order["id"]) {
  return fetchFromApi<{
    data: { steps: NonNullableDict<OrderFormData> }
  }>(`/orders/${orderId}/steps`)
}

// GET /clients_app/v1/orders/{id}/available_transport_types
export function fetchAvailableTransportTypes(
  orderId: Order["id"],
  packages: Partial<Record<PackageType, string | number>>
) {
  const params = new URLSearchParams()

  Object.entries(packages).forEach(([packageType, amount]) => {
    params.append(`packages[${packageType}]`, String(amount))
  })

  return fetchFromApi<{
    data: { available_transport_types: TransportType[] }
  }>(`/orders/${orderId}/available_transport_types?${params.toString()}`)
}

// PUT /clients_app/v1/orders/{id}/cancel
export function fetchCancelOrder(
  orderId: Order["id"],
  cancellation_reason: string,
  with_refund?: boolean
) {
  return fetchFromApi<{
    data: Order
  }>(`/orders/${orderId}/cancel`, {
    method: "PUT",
    body: JSON.stringify({ cancellation_reason, with_refund }),
  })
}

// PUT /clients_app/v1/orders/{id}/confirm
export function fetchConfirmOrder(orderId: Order["id"]) {
  return fetchFromApi<{ data: Order }>(`/orders/${orderId}/confirm`, {
    method: "PUT",
  })
}

// PUT /clients_app/v1/orders/{id}/confirm__with_own_delivery
export function fetchConfirmWithOwnDelivery(orderId: Order["id"]) {
  return fetchFromApi<{ data: Order }>(
    `/orders/${orderId}/confirm_with_own_delivery`,
    {
      method: "PUT",
    }
  )
}

// POST /clients_app/v1/orders/{id}/order_is_ready
export function fetchConfirmOrderIsReady(orderId: Order["id"]) {
  return fetchFromApi<{ data: Order }>(
    // | { [key: string]: Errors }
    `/orders/${orderId}/order_is_ready`,
    {
      method: "POST",
    }
  )
}

// PUT /clients_app/v1/orders/{id}/payment
export function fetchChangeOrderPayment(
  orderId: Order["id"],
  data: ChangeOrderPaymentData
) {
  return fetchFromApi<{
    data: Order
  }>(`/orders/${orderId}/payment`, {
    method: "PUT",
    body: JSON.stringify({
      form_data: data,
    }),
  })
}

// POST /clients_app/v1/orders/{id}/payment_refund
export function fetchPaymentRefund(orderId: Order["id"]) {
  return fetchFromApi<{ data: Order }>(`/orders/${orderId}/payment_refund`, {
    method: "POST",
  })
}

// PUT /clients_app/v1/payments/{id}/confirm
export function fetchConfirmUnsettledPayment(
  paymentId: UnsettledPayment["id"]
) {
  return fetchFromApi<{ data: UnsettledPayment }>(
    `/payments/${paymentId}/confirm`,
    { method: "PUT" }
  )
}

// PUT /clients_app/v1/orders/{id}/update_client_comment
export function fetchUpdateClientComment(
  orderId: Order["id"],
  comment: string
) {
  return fetchFromApi<{ data: Order }>(
    `/orders/${orderId}/update_client_comment`,
    {
      method: "PUT",
      body: JSON.stringify({ description: comment }),
    }
  )
}

export type OrderTrackingResponse = {
  driver: {
    heading: number | null
    lat: number | null
    lng: number | null
  }
  uuid: string
}

// GET /clients_app/v1/{id}/tracking
export function fetchOrderTracking(orderId: Order["id"]) {
  return fetchFromApi<OrderTrackingResponse>(`/${orderId}/tracking`)
}

// GET /clients_app/v1/orders/{id}/download_label
export function fetchDownloadOrderLabel(orderId: Order["id"]) {
  return fetchFromApi<{ data: string; filename: string; content_type: string }>(
    `/orders/${orderId}/download_label`
  )
}

// GET /clients_app/v1/orders/{id}/print
export function fetchPrintOrderDetails(orderId: Order["id"]) {
  return fetchFromApi<{ data: { id: Order["id"]; print_data: string } }>(
    `/orders/${orderId}/print`
  )
}

// Submit complaint - via deligoo-home API
// POST $home_api_url/api/customer_complaint
const DELIGOO_HOME_URL = isProductionEnvironment()
  ? "https://deligoo.pl"
  : "https://preproduction-home.deligoo.pl"

export function fetchSubmitComplaint(complaintsFormData: ComplaintsFormData) {
  return fetch(`${DELIGOO_HOME_URL}/api/customer_complaint`, {
    method: "POST",
    headers: JSONHeaders,
    body: JSON.stringify(complaintsFormData),
  })
}
