import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Cookies from "js-cookie"

import { Errors, RootState } from "@/types"
import { fetchClient, fetchSignIn, fetchSignOut, getFirstError } from "@/utils"

const adminAuthToken = Cookies.get("pos_admin_auth_token")

if (adminAuthToken) {
  localStorage.setItem("token", `Bearer ${adminAuthToken}`)
  Cookies.remove("pos_admin_auth_token", { domain: ".deligoo.pl" })
}

type AuthState = {
  isAuthenticating: boolean
  isAuthenticated: boolean
  errors: Errors | null
}

const initialState: AuthState = {
  isAuthenticating: false,
  isAuthenticated: Boolean(localStorage.getItem("token")) || false,
  errors: null,
}

type LoginCredentials = {
  username: string
  password: string
}

export const requestClientLogin = createAsyncThunk(
  "auth/requestLogin",
  async ({ username, password }: LoginCredentials) => {
    const { response, json } = await fetchSignIn(username, password)

    if ("headers" in response) {
      const token = response.headers.get("authorization")
      if (token) localStorage.setItem("token", token)
    }

    return { ok: response.ok, status: response.status, errors: json.errors }
  }
)

export const requestClientLogout = createAsyncThunk(
  "auth/requestLogout",
  async (_, { dispatch }) => {
    fetchSignOut()
    dispatch(clearDataOnLogout())
    localStorage.removeItem("token")
  }
)

export const requestClient = createAsyncThunk(
  "auth/requestClient",
  async () => {
    const { response, json } = await fetchClient()

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
      errors: json.errors,
    }
  }
)

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearDataOnLogout(state) {
      state.isAuthenticated = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestClientLogin.pending, (state) => {
      state.isAuthenticating = true
    })

    builder.addCase(requestClientLogin.fulfilled, (state, { payload }) => {
      state.isAuthenticating = false
      if (payload.ok) {
        state.isAuthenticated = true
        state.errors = null
      } else if (payload.errors) {
        state.errors = payload.errors
      } else {
        state.errors = {
          auth: [{ code: "auth", text: "Nieprawidłowe dane logowania" }],
        }
      }
    })

    builder.addCase(requestClientLogin.rejected, (state) => {
      state.isAuthenticating = false
      state.errors = {
        auth: [{ code: "auth", text: "Wystąpił błąd serwera" }],
      }
    })

    builder.addCase(requestClient.fulfilled, (state, { payload }) => {
      if (payload.ok) {
        state.isAuthenticated = true
      } else {
        state.isAuthenticated = false
      }
    })
  },
})

export const selectIsAuthenticating = (state: RootState) =>
  state.auth.isAuthenticating

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated

export const selectAuthError = (state: RootState) =>
  state.auth.errors ? getFirstError(state.auth.errors) : null

export const { clearDataOnLogout } = authSlice.actions
export const authReducer = authSlice.reducer
