import { Button, Dropdown } from "@deligoo/ui"
import { useDispatch, useSelector } from "react-redux"

import { RestaurantStatus } from "@/components/RestaurantStatus"
import { selectClientKey } from "@/store/client"
import {
  selectCurrentRestaurantKey,
  setCurrentRestaurant,
} from "@/store/restaurant"

import cls from "../Topbar.module.scss"

const RestaurantButton = () => {
  const clientName = useSelector(selectCurrentRestaurantKey("name"))
  const clientStatus = useSelector(selectCurrentRestaurantKey("status"))
  const allowedRestaurants = useSelector(selectClientKey("allowed_restaurants"))

  const dispatch = useDispatch()

  if (allowedRestaurants?.length) {
    return (
      <Dropdown
        trigger={
          <span className={cls.restaurantBtn}>
            <Button
              className={cls.btn}
              variation="contrast"
              color="light"
              iconProps={{ name: "chevron-tiny-down", size: "tiny" }}
              iconSide="right"
              size="extra-large"
              fullWidth
            >
              <span className={cls.content}>
                <span className={cls.label}>{clientName}</span>
                <RestaurantStatus status={clientStatus} />
              </span>
            </Button>
          </span>
        }
      >
        {allowedRestaurants.map((restaurant) => (
          <Dropdown.MenuItem
            key={restaurant.id}
            handleClick={() => dispatch(setCurrentRestaurant(restaurant.id))}
          >
            {restaurant.name}
          </Dropdown.MenuItem>
        ))}
      </Dropdown>
    )
  }

  return (
    <span className={cls.restaurantBtn}>
      <Button variation="contrast" color="light" size="extra-large" fullWidth>
        <span className={cls.content}>
          <span className={cls.label}>{clientName}</span>
          <RestaurantStatus status={clientStatus} />
        </span>
      </Button>
    </span>
  )
}

export { RestaurantButton }
