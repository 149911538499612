import { Icon, ThemeColor } from "@deligoo/ui"
import clsx from "clsx"
import { HTMLAttributes } from "react"

import { IconName } from "@/types"

import cls from "./Alert.module.scss"

type AlertProps = {
  color?: ThemeColor
  iconName?: IconName
} & HTMLAttributes<HTMLDivElement>

export const Alert = ({
  color = "muted",
  iconName,
  children,
  className,
  ...props
}: AlertProps) => {
  return (
    <div className={clsx(cls.alert, cls[color], className)} {...props}>
      {iconName && (
        <div className={cls.iconWrapper}>
          <Icon name={iconName} size="small" />
        </div>
      )}

      {children}
    </div>
  )
}
