import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Card } from "@/components/Card"
import { Gauge } from "@/components/Gauge"
import {
  selectCurrentRestaurantKey,
  selectCurrentRestaurantStatus,
} from "@/store/restaurant"

import cls from "../Dashboard.module.scss"

function getColor(fraction: number) {
  if (fraction < 0.35) return "alert"
  if (fraction < 0.5) return "warning"
  return "success"
}

const DeliveriesValue = () => {
  const restaurantStatus = useSelector(selectCurrentRestaurantStatus)

  const availableOrders = useSelector(
    selectCurrentRestaurantKey("today_available_orders")
  )

  const dailyOrdersLimit = useSelector(
    selectCurrentRestaurantKey("daily_orders_limit")
  )

  const isUnlimited = availableOrders === null || dailyOrdersLimit === null

  const fraction =
    availableOrders && dailyOrdersLimit ? availableOrders / dailyOrdersLimit : 0

  const availableOrdersFraction = isUnlimited ? 1 : fraction

  const { t } = useTranslation("dashboard")

  const helpModal = {
    title: "Remaining deliveries",
    content: (
      <>
        {t("remaining_deliveries_desc")}
        <div className="text-small mt-2">{t("remaining_deliveries_small")}</div>
      </>
    ),
  }

  return (
    <Card
      sections
      help={t("Learn more")}
      helpModal={helpModal}
      className={cls.tall}
      loading={restaurantStatus === "loading"}
    >
      <Card.Section>
        <Gauge
          color={getColor(availableOrdersFraction)}
          fraction={availableOrdersFraction}
          iconName="paper-bag"
          className={cls.chart}
        />
      </Card.Section>
      <Card.Section>
        <Card.Title size="large" color={getColor(availableOrdersFraction)}>
          {isUnlimited ? "∞" : availableOrders}
        </Card.Title>
        {t("Remaining deliveries")}
      </Card.Section>
    </Card>
  )
}

export { DeliveriesValue }
