const common = {
  pl: "Polski",
  en: "English",

  notifications: {
    header: "Komunikaty",
    clear: "Wyczyść wszystko",
  },

  headers: {
    orders_panel: "Panel zleceń",
    check_distance: "Sprawdź odległość",
    distance: "Odległość",
    delivery_cost: "Cena dostawy",
    delivery_duration: "Przewidywany czas dostawy",
  },

  buttons: {
    create_order: "Dodaj zlecenie",
    dashboard: "Dashboard",
    save: "Zapisz",
    change_language: "Zmień język",
    close: "Zamknij",
    ok: "Ok",
    sync: "Synchronizuj",
    go_to_payments: "Przejdź do płatności",
    confirm: "Potwierdź",
    cancel: "Anuluj",
    yes: "Tak",
    no: "Nie",
  },

  alerts: {
    holiday_mode_enabled: "Tryb świąteczny jest aktywny",
    maintenance_mode_enabled: "Tryb awaryjny jest aktywny",
    creating_orders_disabled: "Dodawanie zleceń zablokowane",
    empty: "Brak danych do wyświetlenia",
    data_sync_required: "Wymagana synchronizacja danych",
    connection_established:
      "Połączenie z serwerem zostało odzyskane. Synchronizuj dane aplikacji, aby kontynuować.",
    reconnected: "Odzyskano połączenie z serwerem",
    connection_lost: "Utracono połączenie z serwerem",
  },

  errors: {
    is_required: "Pole jest wymagane",
    min_length_0: "Wpisz co najmniej {{count}} znak",
    min_length_1: "Wpisz co najmniej {{count}} znaki",
    min_length_2: "Wpisz co najmniej {{count}} znaków",
    select_city: "Wybierz miasto z listy",
    select_street: "Wybierz ulicę z listy",
    db_street_not_found: "Nie znaleziono ulicy w bazie danych",
    phone_number_format: "Podaj poprawny numer telefonu",
    postal_code_format: "Podaj poprawny kod pocztowy",
    distance_limit: "Przekroczony limit – {{distanceLimit}} km",
    session_expired: "Nastąpiło wylogowanie z powodu wygasłej sesji",
    network_error: "Błąd połączenia z serwerem lub brak dostępu do Internetu",
  },

  labels: {
    city: "Miasto",
    street: "Ulica (bez numeru budynku)",
    street_with_house_number: "Ulica i numer budynku",
    first_name: "Imię",
    last_name: "Nazwisko",
    company_name: "Nazwa firmy",
    company_name_or_person: "Imię i nazwisko / Nazwa firmy",
    phone: "Numer telefonu",
    email: "Adres e-mail",
    humanized_full_address: "Adres",
    house_number: "Numer domu",
    flat_number: "Numer mieszkania",
    flat: "Mieszkanie",
    floor: "Piętro",
    staircase: "Klatka",
    access_code: "Kod do domofonu",
    postal_code: "Kod pocztowy",
    language: "Język",
  },

  nav: {
    dashboard: "Dashboard",
    orders: "Zlecenia",
    payments: "Płatności",
    orders_receiveing: "Odbieranie zleceń",
    statistics: "Statystyki",
    settings: "Ustawienia",
  },

  autocomplete: {
    no_results: "Brak wyników",
    fetch_error: "Nie udało się pobrać listy",
  },

  dish: {
    packaging: "Opakowanie",
  },

  today: "Dzisiaj",
}

export default common
