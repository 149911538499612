import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Card } from "@/components/Card"
import { RestaurantStatus } from "@/components/RestaurantStatus"
import {
  selectCurrentRestaurant,
  selectCurrentRestaurantStatus,
} from "@/store/restaurant"

import cls from "../Dashboard.module.scss"

const WorkingHours = () => {
  const restaurant = useSelector(selectCurrentRestaurant)
  const restaurantDataStatus = useSelector(selectCurrentRestaurantStatus)

  const { t } = useTranslation("dashboard")

  const start = restaurant?.start_working_hours || "00:00"
  const end =
    restaurant?.temporary_end_working_hours ||
    restaurant?.end_working_hours ||
    "00:00"

  const [startHours, startMinutes] = start.split(":")
  const [endHours, endMinutes] = end.split(":")

  const restaurantStatus = restaurant?.status

  const helpModal = {
    title: "Restaurant status",
    content: (
      <>
        {t("working_hours_desc")}
        <div className="text-small mt-2">{t("working_hours_small")}</div>
      </>
    ),
  }

  return (
    <Card
      help={t("Learn more")}
      helpModal={helpModal}
      className={cls.wide}
      loading={restaurantDataStatus === "loading"}
    >
      <Card.Header>
        <Card.Title
          color={restaurantStatus ? "success" : "warning"}
          className="no-wrap"
        >
          {startHours}
          <sup>{startMinutes}</sup> – {endHours}
          <sup>{endMinutes}</sup>
        </Card.Title>
      </Card.Header>
      <div className={cls.workingHoursStatus}>
        {t("Restaurant status")}
        <RestaurantStatus status={restaurantStatus} />
      </div>
    </Card>
  )
}

export { WorkingHours }
