import "./Toast.scss"

import { ProviderLogo } from "@deligoo/ui"
import clsx from "clsx"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import { useAppDispatch } from "@/App"
import { postponeOrder } from "@/store/orders"
import { Order } from "@/types"

import cls from "./NewOrderToReceiveToast.module.scss"

type NewOrderToReceiveToastProps = {
  provider: Order["provider"]
  marketplace: Order["marketplace_kind"]
  orderId: Order["id"]
  toastId: string
}

const NewOrderToReceiveToast = ({
  orderId,
  provider,
  marketplace,
  toastId,
}: NewOrderToReceiveToastProps) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  return (
    <>
      <div className={cls.logo}>
        <ProviderLogo provider={provider} marketplace={marketplace} light />
      </div>

      <div className={cls.content}>
        <div className={cls.text}>Pojawiło się nowe zlecenie do odebrania</div>

        <div className={cls.actions}>
          <button
            className={clsx(cls.button, cls.leaveForLater)}
            onClick={() => {
              dispatch(postponeOrder(orderId))
              toast.dismiss(toastId)
            }}
          >
            Odłóż na później
          </button>

          <button
            className={clsx(cls.button, cls[provider])}
            onClick={() => {
              history.push("/odbieranie-zlecen")
              toast.dismiss(toastId)
            }}
          >
            Zobacz teraz
          </button>
        </div>
      </div>
    </>
  )
}

const showNewOrderToReceiveToast = ({
  provider,
  marketplace,
  orderId,
}: Omit<NewOrderToReceiveToastProps, "toastId">) => {
  const toastId = "newOrderToReceive" // TODO: Maybe link toast to a specific order ID

  return toast(
    <NewOrderToReceiveToast
      provider={provider}
      marketplace={marketplace}
      orderId={orderId}
      toastId={toastId}
    />,
    {
      toastId,
      className: clsx(cls.toast, cls[`bg-${marketplace || provider}`]),
      bodyClassName: cls.body,
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
    }
  )
}

export { NewOrderToReceiveToast, showNewOrderToReceiveToast }
