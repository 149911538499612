import { Icon, ThemeColor } from "@deligoo/ui"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

import { IconName, UnsettledPayment } from "@/types"

import cls from "./RefundEvent.module.scss"

type RefundEventProps = {
  inOrder: UnsettledPayment["payment_method_in_order"]
  duringDelivery: UnsettledPayment["payment_method_during_delivery"]
  refundFor?: UnsettledPayment["refund_for"]
  labels?: boolean
  className?: string
}

const RefundEvent = ({
  inOrder,
  duringDelivery,
  refundFor,
  labels,
  className,
}: RefundEventProps) => {
  const { t } = useTranslation("payments")

  return (
    <div className={clsx(cls.refundEvent, className)}>
      <div className={clsx(cls.icons, "font-weight-bold")}>
        <div className={`text-${getIconColor(inOrder)}`}>
          <Icon
            name={getIconName(inOrder)}
            color={getIconColor(inOrder)}
            size="large"
            gutter={labels ? "right" : undefined}
          />
          {labels && t(`payment_form.${inOrder}`)}
        </div>
        <Icon name="short-arrow-tiny-right" color="muted" size="small" />
        <div className={`text-${getIconColor(duringDelivery)}`}>
          {labels && t(`payment_form.${duringDelivery}`)}
          <Icon
            name={getIconName(duringDelivery)}
            color={getIconColor(duringDelivery)}
            size="large"
            gutter={labels ? "left" : undefined}
          />
        </div>
      </div>
      {refundFor && (
        <div className={cls.refundFor}>
          <span className="text-muted">{t("refund_for.for")} </span>
          <strong>{t(`refund_for.${refundFor}`)}</strong>
        </div>
      )}
    </div>
  )
}

function getIconName(
  paymentMethod:
    | UnsettledPayment["payment_method_in_order"]
    | UnsettledPayment["payment_method_during_delivery"]
): IconName {
  switch (paymentMethod) {
    case "card":
      return "credit-card"
    case "cash":
      return "cash"
    default:
      return "boxes"
  }
}

function getIconColor(
  paymentMethod:
    | UnsettledPayment["payment_method_in_order"]
    | UnsettledPayment["payment_method_during_delivery"]
): ThemeColor {
  switch (paymentMethod) {
    case "card":
      return "secondary"
    case "cash":
      return "success"
    default:
      return "muted"
  }
}

export { RefundEvent }
