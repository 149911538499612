import clsx from "clsx"

import { ControlSize, ThemeColor } from "../../types"
import { Icon } from "../Icon"
import cls from "./Badge.module.scss"

export type BadgeProps = {
  color?: ThemeColor
  size?: ControlSize
  variation?: "solid" | "outlined" | "accented"
  iconProps?: React.ComponentProps<typeof Icon>
  removable?: boolean
  onRemove?: (e: React.MouseEvent<HTMLButtonElement>) => void
} & JSX.IntrinsicElements["div"]

export const Badge = ({
  color = "light",
  size = "medium",
  variation = "accented",
  iconProps,
  removable,
  className,
  children,
  onClick,
  onRemove,
  ...props
}: BadgeProps) => {
  return (
    <div
      onClick={onClick}
      className={clsx(cls.badge, cls[color], cls[size], cls[variation], onClick && cls.action, className)}
      role={onClick ? "button" : undefined}
      {...props}
    >
      {iconProps && (
        <span className={cls.icon}>
          <Icon {...iconProps} />
        </span>
      )}

      <span className={cls.text}>{children}</span>

      {removable && (
        <button onClick={onRemove} className={cls.remove} type="button">
          <Icon name="close" className={cls.removeIcon} />
        </button>
      )}
    </div>
  )
}
