import { Icon, Spinner, ThemeColor } from "@deligoo/ui"
import clsx from "clsx"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { DriverInfo } from "@/components/DriverInfo"
import { Price } from "@/components/Price"
import { SummaryList } from "@/components/SummaryList"
import { Table } from "@/components/Table"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import {
  getUnsettledPayments,
  selectAwaitingConfirmationAmount,
  selectUnsettledAmount,
  selectUnsettledPaymentById,
  selectUnsettledPaymentsIds,
  selectUnsettledPaymentsStatus,
} from "@/store/unsettledPayments"
import { IconName, UnsettledPayment } from "@/types"

import {
  PaymentConfirmModal,
  RefundEvent,
  usePaymentConfirmModal,
} from "./partials"
import cls from "./Payments.module.scss"

const Payments = () => {
  const [paymentToConfirmId, setPaymentToConfirmId] = usePaymentConfirmModal()

  const unsettledPaymentsIds = useSelector(selectUnsettledPaymentsIds)

  const unsettledAmount = useSelector(selectUnsettledAmount)
  const awaitingConfirmationAmount = useSelector(
    selectAwaitingConfirmationAmount
  )

  const unsettledStatus = useSelector(selectUnsettledPaymentsStatus)
  const currentRestaurantId = useSelector(selectCurrentRestaurantKey("id"))

  const dispatch = useDispatch()

  const { t } = useTranslation("payments")

  useEffect(() => {
    if (currentRestaurantId) dispatch(getUnsettledPayments())
  }, [dispatch, currentRestaurantId])

  return (
    <div className={clsx("page-content", cls.payments)}>
      <header className={cls.header}>
        <h1 className={cls.title}>{t("headers.unsettled_payments")}</h1>

        <SummaryList>
          <SummaryList.Item
            title={t("summary.unsettled_amount")}
            desc={
              unsettledStatus === "fetched" ? (
                <Price subunit>{unsettledAmount}</Price>
              ) : (
                <Spinner size="small" />
              )
            }
            color="warning"
            iconName="cash"
          />
          <SummaryList.Item
            title={t("summary.awaiting_confirmation_amount")}
            desc={
              unsettledStatus === "fetched" ? (
                <Price subunit>{awaitingConfirmationAmount}</Price>
              ) : (
                <Spinner size="small" />
              )
            }
            color="secondary"
            iconName="tasks"
          />
          <SummaryList.Item
            title={t("summary.total")}
            desc={
              unsettledStatus === "fetched" ? (
                <Price subunit>
                  {unsettledAmount + awaitingConfirmationAmount}
                </Price>
              ) : (
                <Spinner size="small" />
              )
            }
            color="primary"
            iconName="money"
          />
        </SummaryList>
      </header>

      {paymentToConfirmId && (
        <PaymentConfirmModal
          paymentToConfirmId={paymentToConfirmId}
          handleClose={() => setPaymentToConfirmId(null)}
        />
      )}

      <Table columns={"3fr 160px 250px 170px 150px 100px"}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t("headers.order_uid")}</Table.Th>
            <Table.Th>{t("headers.pickup_date")}</Table.Th>
            <Table.Th>{t("headers.driver")}</Table.Th>
            <Table.Th className="align-right">{t("headers.amount")}</Table.Th>
            <Table.Th>{t("headers.event")}</Table.Th>
            <Table.Th>{t("headers.status")}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody loading={unsettledStatus === "loading"}>
          {unsettledStatus === "fetched" &&
            unsettledPaymentsIds &&
            unsettledPaymentsIds.map((paymentId) => (
              <PaymentRow
                key={paymentId}
                paymentId={paymentId}
                setPaymentToConfirmId={setPaymentToConfirmId}
              />
            ))}
        </Table.Tbody>
      </Table>
    </div>
  )
}

type PaymentRowProps = {
  paymentId: UnsettledPayment["id"]
  setPaymentToConfirmId: (id: UnsettledPayment["id"]) => void
}

const PaymentRow = ({ paymentId, setPaymentToConfirmId }: PaymentRowProps) => {
  const payment = useSelector(selectUnsettledPaymentById(paymentId))

  const { t } = useTranslation("payments")

  if (!payment) return null

  return (
    <Table.Tr onClick={() => setPaymentToConfirmId(paymentId)}>
      <Table.Td
        className={clsx(payment.unsettled_order_after_72_hours && "text-alert")}
      >
        <div className={cls.address}>
          {payment.address}
          {payment.unsettled_order_after_72_hours && (
            <Icon className={cls.warningIcon} name="warnings" color="alert" />
          )}
        </div>
        <div
          className={clsx(
            "text-medium mt-2",
            payment.unsettled_order_after_72_hours
              ? "text-alert"
              : "text-primary"
          )}
        >
          #{payment.uid}
        </div>
      </Table.Td>
      <Table.Td>
        {payment.pickup_at.date}
        <div className="text-medium text-muted mt-2">
          {payment.pickup_at.time}
        </div>
      </Table.Td>
      <Table.Td className={cls.driver}>
        {payment.driver && <DriverInfo driver={payment.driver} />}
      </Table.Td>
      <Table.Td className="h400 align-right">
        <Price subunit>{payment.full_price_subunit}</Price>
      </Table.Td>
      <Table.Td>
        <RefundEvent
          inOrder={payment.payment_method_in_order}
          duringDelivery={payment.payment_method_during_delivery}
          refundFor={payment.refund_for}
        />
      </Table.Td>
      <Table.Td
        className={clsx(
          cls.status,
          payment.unsettled_order_after_72_hours
            ? "text-alert"
            : `text-${getColor(payment.settlement_type)}`
        )}
      >
        <Icon name={getIcon(payment.settlement_type)} />
        <div className={cls.label}>
          {t(`settlement_type_label.${payment.settlement_type}`)}
        </div>
      </Table.Td>
    </Table.Tr>
  )
}

function getIcon(
  settlementType: UnsettledPayment["settlement_type"]
): IconName {
  switch (settlementType) {
    case "waiting_for_client":
      return "cash"
    case "waiting_for_driver":
      return "overdues"
    case "waiting":
    case "unsettled":
    default:
      return "tasks-done"
  }
}

function getColor(
  settlementType: UnsettledPayment["settlement_type"]
): ThemeColor {
  switch (settlementType) {
    case "waiting_for_client":
      return "info"
    case "waiting_for_driver":
      return "success"
    case "waiting":
    case "unsettled":
      return "warning"
    default:
      return "muted"
  }
}

export { Payments }
