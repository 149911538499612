import clsx from "clsx"

import { useAppSelector } from "@/App"
import { AppAlert } from "@/components/AppAlert"
import { LockedAccountModal } from "@/components/LockedAccountModal"
import { Navbar } from "@/components/Navbar"
import { RefreshNeededModal } from "@/components/RefreshNeededModal/RefreshNeededModal"
import { Topbar } from "@/components/Topbar"
import { LayoutProps } from "@/layouts"
import { selectCurrentOrderFormStep } from "@/store/orderForm"
import { selectLockedAccountModalState } from "@/store/restaurant"
import { OrderForm } from "@/views/OrderForm"

import cls from "./AppLayout.module.scss"

const AppLayout = ({ children, className }: LayoutProps) => {
  const orderFormStep = useAppSelector(selectCurrentOrderFormStep)
  const isLockedAccountModalActive = useAppSelector(
    selectLockedAccountModalState
  )
  const isDataRefreshNeeded = useAppSelector(
    (state) => state.global.isDataRefreshNeeded
  )

  return (
    <div className={clsx(cls.appLayout, className)}>
      <Navbar className={cls.navbar} />
      <Topbar className={cls.topbar} />

      <main className={cls.main}>
        <AppAlert />
        {children}
      </main>

      {orderFormStep && <OrderForm />}
      {isLockedAccountModalActive && <LockedAccountModal />}
      {isDataRefreshNeeded && <RefreshNeededModal />}
    </div>
  )
}

export { AppLayout }
