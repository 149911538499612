import "./index.scss" // Should be imported before any component
import "./i18n"
import "dayjs/locale/pl"

import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import dayjsisToday from "dayjs/plugin/isToday"
import relativeTime from "dayjs/plugin/relativeTime"
import { StrictMode } from "react"
import ReactDOM from "react-dom"

import { showAppUpdateToast } from "@/components/Toast"
import { initSentry, SentryErrorBoundary } from "@/sentry"
import { getDefaultSite, isSiteValid } from "@/utils"

import App from "./App"
import { register as registerServiceWorker } from "./registerServiceWorker"

dayjs.extend(dayjsisToday)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.locale("pl")

initSentry()

const lsSite = localStorage.getItem("site")
if (!lsSite || !isSiteValid(lsSite)) {
  localStorage.setItem("site", JSON.stringify(getDefaultSite()))
}

ReactDOM.render(
  <StrictMode>
    <SentryErrorBoundary>
      <App />
    </SentryErrorBoundary>
  </StrictMode>,
  document.getElementById("root")
)

export function invokeSWUpdateFlow(registration: ServiceWorkerRegistration) {
  const waitingServiceWorker = registration.waiting
  if (!waitingServiceWorker) return

  const skipWaiting = () => {
    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
  }

  showAppUpdateToast(skipWaiting)
}

function checkForWaitingSW() {
  navigator.serviceWorker.getRegistration().then((registration) => {
    if (registration?.waiting) invokeSWUpdateFlow(registration)
  })
}

if ("serviceWorker" in navigator) {
  let isRefreshing = false

  navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (!isRefreshing) {
      window.location.reload()
      isRefreshing = true
    }
  })

  checkForWaitingSW()

  const WAITING_CHECK_INTERVAL = 15 * 60 * 1000
  setInterval(checkForWaitingSW, WAITING_CHECK_INTERVAL)
}

registerServiceWorker({
  onUpdate: (registration) => {
    if (registration.waiting) invokeSWUpdateFlow(registration)
  },
})
