import { useTranslation } from "react-i18next"

import { Card } from "@/components/Card"
import { Statistics } from "@/types"

type DeliveriesFailedProps = {
  loading: boolean
  value?: Statistics["orders_with_delivery_failed_count"]
  className?: string
}

const DeliveriesFailed = ({
  loading,
  value,
  className,
}: DeliveriesFailedProps) => {
  const { t } = useTranslation("statistics")

  return (
    <Card loading={loading} className={className}>
      <Card.Header>
        <Card.Title color="warning">{value}</Card.Title>
      </Card.Header>
      {t("orders_with_delivery_failed")}
    </Card>
  )
}

export { DeliveriesFailed }
