import { useTranslation } from "react-i18next"

import { Card } from "@/components/Card"
import { Gauge } from "@/components/Gauge"
import colors from "@/scss/colors.module.scss"
import { Statistics } from "@/types"
import { formatTimeDuration } from "@/utils"

import cls from "../Statistics.module.scss"

type AverageDeliveryTimeProps = {
  loading: boolean
  value?: Statistics["average_estimated_delivery_time_duration"]
  className?: string
}

const AverageDeliveryTime = ({
  loading,
  value,
  className,
}: AverageDeliveryTimeProps) => {
  const { t } = useTranslation("statistics")

  return (
    <Card loading={loading} className={className} sections inline>
      <Card.Section>
        <Gauge
          fillColors={[{ min: 0, max: 1, color: colors.success }]}
          fraction={0.9}
          iconName="delivery-time"
          className={cls.chart}
        />
      </Card.Section>
      <Card.Section>
        <Card.Title color="success">
          {value && formatTimeDuration(value)}
        </Card.Title>
        {t("average_estimated_delivery_time_duration")}
      </Card.Section>
    </Card>
  )
}

export { AverageDeliveryTime }
