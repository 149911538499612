import { Icon } from "@deligoo/ui"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

import cls from "./SalesDocument.module.scss"

type SalesDocumentProps = {
  invoice: boolean
  iconSide?: "left" | "right"
}

const SalesDocument = ({ invoice, iconSide }: SalesDocumentProps) => {
  const { t } = useTranslation("payments")

  const DocumentIcon = () => (
    <Icon
      className={clsx(cls.icon, iconSide && cls[iconSide])}
      name={invoice ? "invoice" : "bills"}
      color={invoice ? "warning" : "primary"}
    />
  )

  return (
    <span className={cls.salesDocument}>
      {iconSide === "left" && <DocumentIcon />}
      <span>{t(`sales_document.${invoice ? "invoice" : "receipt"}`)}</span>
      {iconSide === "right" && <DocumentIcon />}
    </span>
  )
}

export { SalesDocument }
