import { Spinner } from "@deligoo/ui"
import { useSelector } from "react-redux"

import { selectSettingsStatus } from "@/store/client"
import { selectNotificationsStatus } from "@/store/notifications"
import { selectOrdersStatus } from "@/store/orders"
import { selectCurrentRestaurantStatus } from "@/store/restaurant"
import { selectStatisticsStatus } from "@/store/statistics"
import { selectUnsettledPaymentsStatus } from "@/store/unsettledPayments"

import cls from "../Topbar.module.scss"

const UpdateIndicator = () => {
  const ordersStatus = useSelector(selectOrdersStatus)
  const unsettledPaymentsStatus = useSelector(selectUnsettledPaymentsStatus)
  const settingsStatus = useSelector(selectSettingsStatus)
  const restaurantStatus = useSelector(selectCurrentRestaurantStatus)
  const statisticsStatus = useSelector(selectStatisticsStatus)
  const notificationsStatus = useSelector(selectNotificationsStatus)

  const statuses = [
    ordersStatus,
    unsettledPaymentsStatus,
    settingsStatus,
    restaurantStatus,
    statisticsStatus,
    notificationsStatus,
  ]

  const isLoading = statuses.some(
    (status) => status === "loading" || status === "updating"
  )

  if (!isLoading) return null

  return (
    <Spinner
      color="muted"
      className={cls.updatingIndicator}
      size="extra-large"
      inline
    />
  )
}

export { UpdateIndicator }
