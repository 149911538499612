const orders = {
  tabs: {
    pending: "Do odebrania",
    finished: "Odebrane",
    failed: "Nieudane",
    invalid: "Nieprawidłowe",

    orders: "Zlecenia",
    details: "Szczegóły",
    payments: "Płatności",
  },

  headers: {
    invalid_orders: "Nieprawidłowe zamówienia",
    order_date: "Nr zam. i godz. dodania",
    provider: "Źródło",
    delivery_method: "Sposób realizacji",
    payment_method: "Płatność",
    status: "Status",
    invalid: "Nieprawidłowe",
    orders_to_receive: "Zamówienia do odebrania",
    order_uid: "Nr. zam.",
    payment_status: "Status płatności",
    amount: "Kwota",
    payment_form: "Forma płatności",
    address: "Adres",
    time: "Godzina",
    payment_refund_required: "Wymagany zwrot",
    order_uid_and_address: "Adres i numer zamówienia",
    driver: "Kurier",
    track_driver: "Śledź kuriera",
    created_at: "Godzina dodania",
    pickup_at: "Godzina odbioru",
    phone: "Numer telefonu",
    distance: "Odległość",
  },

  payments_summary: {
    today_summary: "Podsumowanie dnia",
    paid_amount: "Zapłacone",
    paid_by_cash_amount: "Gotówka",
    paid_by_card_amount: "Karta",
    total: "Suma",
  },

  delivery_method: {
    delivery_now: "Dostawa - jak najszybciej",
    delivery_later: "Dostawa",
    pickup_now: "Odbiór osobisty",
    pickup_later: "Odbiór osobisty",
    own_delivery: "Dostawa własna",
  },
  package_type: {
    "big-pizza": "Duża pizza",
    "standard-dish": "Danie standard | pizza do 32 cm",
    "own-packaging": "Opakowanie własne",
    "medium-pizza": "Średnia pizza",
    dinner: "Obiad",
    "hebe-box-1": "Hebe Box 1",
    "hebe-box-2": "Hebe Box 2",
    "hebe-box-3": "Hebe Box 3",
    "hebe-box-4": "Hebe Box 4",
    "hebe-box-5": "Hebe Box 5",
    "ecommerce-size-a": "Gabaryt A",
    "ecommerce-size-b": "Gabaryt B",
    "ecommerce-size-c": "Gabaryt C",
    "inpost-size-a": "Gabaryt A",
    "inpost-size-b": "Gabaryt B",
    "inpost-size-c": "Gabaryt C",
  },
  unit_0: "sztuka",
  unit_1: "sztuki",
  unit_2: "sztuk",
  state: {
    // Order only
    draft: "Dodawanie",
    unpaid: "Nieopłacone",
    waiting: "Oczekuje",
    // Common for Order and OrderPoint
    pending: "Przetwarzanie",
    assigned: "Przypisane",
    published: "Opublikowane",
    started: "W realizacji",
    finished: "Zakończone",
    canceled: "Anulowane",
    failed: "Odrzucone",
    expired: "Wygasłe",
    // OrderPoint only
    in_progress: "Na miejscu",
  },

  filters: {
    all: "Wszystkie",
    pickup_only: "Odbiory osobiste",
    deliveries: "Dostawy",
    show: "Pokaż:",
  },

  order_pickup_label: {
    published: "Odbiór zaplanowany",
    started: "Odbiór rozpoczęty",
    in_progress: "Na miejscu odbioru",
  },

  order_delivery_label: {
    published: "Doręczenie zaplanowane",
    started: "W doręczeniu",
    in_progress: "Na miejscu doręczenia",
  },

  order_state_label: {
    pending: "W trakcie przetwarzania",
    assigned: "Szacowany odbiór",
    finished: "Zlecenie doręczone",
    failed: "Zlecenie odrzucone",
    canceled: "Anulowano zlecenie",
    expired: "Zlecenie wygasło",
  },

  orders_to_receive: {
    actions: {
      confirm: "Potwierdź",
      own_delivery: "Dostawa własna",
      continue: "Kontynuuj",
      reject: "Odrzuć",
      edit_address: "Edytuj adres zamówienia",
    },
    headers: {
      customer_details: "Dane zamawiającego",
      invoice: "Dokument sprzedaży",
      payment_form: "Forma płatności",
      company: "Nazwa firmy",
      address: "Adres",
      nip: "NIP",
    },
    errors: {
      max_distance_error: "Przekroczony limit km - użyj dostawy własnej",
    },
  },

  invalid_orders_modal: {
    headers: {
      confirm_invalid_order: "Potwierdź odbiór zamówienia",
      order_address_is_invalid: "Adres zamówienia jest nieprawidłowy",
      update_address: "Edytuj adres",
      form: "Formularz",
      source_data: "Dane źródłowe",
      pickup_data: "Dane odbioru",
      delivery_data: "Dane dostawy",
    },
    actions: {
      return: "Wstecz",
      confirm: "Przejdź dalej",
    },
    toasts: {
      order_rejected: "Zlecenie zostało odrzucone",
    },
  },

  receive_order_modal: {
    headers: {
      confirm_order_id: "Potwierdź odbiór zamówienia #{{orderId}}",
      order_id: "Zamówienie # {{orderId}}",
      distance: "Odległość",
      delivery_method: "Sposób dostawy",
      order_total_price: "Suma zamówienia",
      order_delivery_price: "Koszt dostawy",
      order_price_packaging: "Kosz opakowań",
      order_discount: "Rabat",
      order_total_amount: "Łącznie",
    },
  },

  signature_modal: {
    customer_signature: "Podpis odbiorcy",
    download: "Pobierz",
  },

  timeline: {
    fetch_error: "Błąd podczas pobierania osi czasu",
    loading: "Pobieranie osi czasu...",
  },

  details_modal: {
    order_uid: "Zamówienie #{{orderUid}}",
    system_data: "Dane wprowadzone do systemu",
    created_at: "Data i godzina dodania",
    estimated_time: "Proponowana godzina",
    payment: "Forma płatności i kwota",
    count: "Ilość",
    weight: "Waga",
    size: "Wielkość zamówienia",
    drinks: "Napoje",
    yes: "Tak",
    no: "Nie",
    phone: "Numer telefonu",
    address: "Adres",
    distance: "Dystans",
    order_comment: "Uwagi do dostawy",
    client_comment: "Uwagi od lokalizacji",
    customer_comment: "Uwagi od zamawiającego",
    none: "Brak",
    cancellation_reason: "Powód anulacji",
    invoice: "Dane do faktury",
    nip: "NIP",
    authorization_code: "Kod odbioru",
    signature: "Podpis",
    show_signature: "Zobacz potwierdzenie",
    timeline: "Oś czasu",
  },

  update_order_payment_modal: {
    success: "Płatność została pomyślnie zaktualizowana",
    order_amount: "Wartość zamówienia",
    delivery_price: "Koszty dostawy",
  },

  reenter_order_modal: {
    title: "Czy na pewno chcesz wprowadzić to zlecenie ponownie?",
    desc: "Jeżeli zlecenie jest aktywne wybranie tej opcji spowoduje anulowanie obecnego zlecenia",
    fetch_order_error:
      "Nie udało się pobrać danych zlecenia do ponownego wprowadzenia",
    create_order_error:
      "Nie udało się stworzyć ponownie wprowadzanego zlecenia",
  },

  payment_refund_modal: {
    title: "Czy na pewno chcesz zwrócić płatność za to zlecenie?",
    payment_refunded: "Płatność została pomyślnie zwrócona",
    cannot_be_refunded:
      "Zamówienie #{{orderId}} zostało anulowane przez administratora, aby dokonać zwrotu transakcji skontaktuj się z supportem.",
  },

  order_notes_modal: {
    title: "Uwagi dla klienta",
    notes_content_placeholder: "Treść uwag przekazanych przez klienta",
    cannot_be_empty: "Należy podać treść",
    notes_updated: "Uwagi dla klienta zostały pomyślnie zaktualizowane",
  },

  order_basket_modal: {
    order_content: "Treść zamówienia",
    return_to_order: "Wróć do zamówienia",
  },

  cancel_order_modal: {
    title: "Czy na pewno chcesz anulować to zlecenie?",
    cancelation_reason: "Wybierz powód anulowania",
    select_cancelation_reason_error:
      "Aby przejść dalej, musisz wybrać powód anulowania",
    order_canceled: "Zlecenie zostało pomyślnie anulowane",
  },

  cancelation_reasons: {
    delivery_time_exceeded: "Zbyt długi czas oczekiwania na kuriera",
    canceled_by_client: "Klient zrezygnował z zamówienia",
    other: "Inny",
  },

  order_actions: {
    cancel: "Anuluj odbiór",
    reenter: "Wprowadź ponownie",
    update_payment: "Zmień formę lub kwotę (PLN)",
    refund: "Zwrot transakcji",
    content: "Treść zamówienia",
    notes: "Uwagi",
    download_label: "Pobierz etykietę .PDF",
    print: "Wydrukuj zamówienie",
  },

  notify_driver: "Powiadom kuriera",
  driver_notified: "Kurier powiadomiony",

  order_tracking: "Śledzenie kuriera",
}

export default orders
