import { Backdrop, Button } from "@deligoo/ui"
import { useCallback, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { CloseButton } from "@/components/CloseButton"
import {
  requestReadAllUnreadMessages,
  requestReadMessage,
  selectMessages,
} from "@/store/messages"
import { Message } from "@/types"

import cls from "./Messages.module.scss"
import { MessageContent, MessagesList } from "./partials"

const Messages = ({ handleClose }: { handleClose: () => void }) => {
  const messages = useSelector(selectMessages)

  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null)

  const dispatch = useDispatch()

  const { t } = useTranslation("messages")

  const selectMessage = useCallback(
    // TODO: Explicit typing of Message can be removed after we upgrade to
    // @types/react version where useCallback accepting ...args: any[] is fixed
    (message: Message) => {
      setSelectedMessage(message)

      if (message && !message.read_at) {
        dispatch(requestReadMessage(message.id))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (!selectedMessage && messages && messages.length) {
      selectMessage(messages[0])
    }
  }, [selectMessage, selectedMessage, messages])

  return createPortal(
    <Backdrop>
      <div className={cls.messages}>
        <MessagesList
          messages={messages}
          selectedMessage={selectedMessage}
          selectMessage={selectMessage}
        />
        <div className={cls.header}>
          <h2 className="h500">{t("messages")}</h2>

          <Button
            color="primary"
            variation="accented"
            size="extra-large"
            className="flex-grow-1 mx-8"
            onClick={() => dispatch(requestReadAllUnreadMessages())}
          >
            {t("mark_all_as_read")}
          </Button>

          <CloseButton onClick={handleClose} size="extra-large" />
        </div>
        <MessageContent message={selectedMessage} />
      </div>
    </Backdrop>,
    document.body
  )
}

export { Messages }
