import { Marketplace, Provider } from "@deligoo/shared"
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit"
import dayjs, { Dayjs } from "dayjs"
import deepMerge from "lodash.merge"

import { showNewOrderToReceiveToast } from "@/components/Toast"
import {
  ChangeOrderPaymentData,
  ConnectedOrdersIds,
  DataStatus,
  Order,
  PostponedOrdersIds,
  RootState,
} from "@/types"
import {
  fetchCancelOrder,
  fetchChangeOrderPayment,
  fetchConfirmOrder,
  fetchConfirmOrderIsReady,
  fetchConfirmWithOwnDelivery,
  fetchOrders,
  fetchOrderSteps,
  fetchPaymentRefund,
  fetchUpdateClientComment,
  fetchWaitingOrders,
  handleStandardError,
  printOrderDetails,
  roundToTwoDecimals,
  sumFullPrices,
} from "@/utils"
import {
  isFromDate,
  isReceivable,
  isToReceive,
  sortByPriority,
  wasUnsuccessfullyReceived,
} from "@/utils/order"

import { clearDataOnLogout } from "./auth"
import { setOrderFormData, setOrderFormStep, setOrderId } from "./orderForm"
import { setCurrentRestaurant } from "./restaurant"

const ordersAdapter = createEntityAdapter<Order>({
  sortComparer: sortByPriority,
})

type OrdersState = EntityState<Order> & {
  status: DataStatus | null
  connectedOrdersIds: ConnectedOrdersIds | null
  postponedOrdersIds: PostponedOrdersIds
}

const initialState: OrdersState = ordersAdapter.getInitialState({
  status: null,
  connectedOrdersIds: null,
  postponedOrdersIds: [],
})

export const getOrders = createAsyncThunk(
  "orders/getOrders",
  async (date: Dayjs = dayjs()) => {
    const { response, json } = await fetchOrders(date)

    const isRequestForToday = date?.isToday()

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
      connectedOrdersIds:
        isRequestForToday && json.meta?.connected_orders_ids
          ? json.meta.connected_orders_ids
          : null,
    }
  }
)

export const getWaitingOrders = createAsyncThunk(
  "orders/getWaitingOrders",
  async () => {
    const { response, json } = await fetchWaitingOrders()

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
    }
  }
)

export const requestCancelOrder = createAsyncThunk(
  "orders/requestCancel",
  async (
    {
      orderId,
      cancellationReason,
      withRefund,
    }: {
      orderId: Order["id"]
      cancellationReason: string
      withRefund?: boolean
    },
    { dispatch }
  ) => {
    const { response, json } = await fetchCancelOrder(
      orderId,
      cancellationReason,
      withRefund
    )

    const { data: order } = json

    if (!response.ok || !order) {
      handleStandardError(response, json)

      return {
        ok: response.ok,
        status: response.status,
      }
    }

    dispatch(updateOrder(order))

    return {
      ok: response.ok,
      status: response.status,
      data: order,
    }
  }
)

export const requestConfirmOrder = createAsyncThunk(
  "orders/requestConfirm",
  async (orderId: Order["id"], { dispatch }) => {
    const { response: confirmOrderResponse, json: confirmOrderJson } =
      await fetchConfirmOrder(orderId)
    const { data: order } = confirmOrderJson

    if (!confirmOrderResponse.ok || !order) {
      handleStandardError(confirmOrderResponse, confirmOrderJson)
      return { ok: false }
    }

    dispatch(updateOrder(order))
    dispatch(setOrderId(order.id))

    const { response: fetchStepsResponse, json: fetchStepsJson } =
      await fetchOrderSteps(orderId)

    if (!fetchStepsResponse.ok || !fetchStepsJson.data) {
      handleStandardError(fetchStepsResponse, fetchStepsJson)
      return { ok: false }
    }

    const {
      data: { steps: orderFormData },
    } = fetchStepsJson

    dispatch(setOrderFormData(orderFormData))
    dispatch(setOrderFormStep(order.step))

    return { ok: true }
  }
)

export const requestConfirmWithOwnDelivery = createAsyncThunk(
  "orders/requestConfirmWithOwnDelivery",
  async (orderId: Order["id"], { dispatch }) => {
    const { response: confirmOrderResponse, json: confirmOrderJson } =
      await fetchConfirmWithOwnDelivery(orderId)
    const { data: order } = confirmOrderJson

    if (!confirmOrderResponse.ok || !order) {
      handleStandardError(confirmOrderResponse, confirmOrderJson)
      return { ok: false }
    }

    dispatch(updateOrder(order))
    dispatch(setOrderId(order.id))

    const { response: fetchStepsResponse, json: fetchStepsJson } =
      await fetchOrderSteps(orderId)

    if (!fetchStepsResponse.ok || !fetchStepsJson.data) {
      handleStandardError(fetchStepsResponse, fetchStepsJson)
      return { ok: false }
    }

    const {
      data: { steps: orderFormData },
    } = fetchStepsJson

    dispatch(setOrderFormData(orderFormData))
    dispatch(setOrderFormStep(order.step))

    return { ok: true }
  }
)

export const requestContinueOrderReceive = createAsyncThunk(
  "orders/requestContinueReceive",
  async (order: Order, { dispatch }) => {
    dispatch(setOrderId(order.id))

    const { response, json } = await fetchOrderSteps(order.id)

    if (!response.ok || !json.data) {
      handleStandardError(response, json)
      return { ok: false }
    }

    const {
      data: { steps: orderFormData },
    } = json

    dispatch(setOrderFormData(orderFormData))
    dispatch(setOrderFormStep(order.step))

    return { ok: true }
  }
)

export const requestConfirmOrderIsReady = createAsyncThunk(
  "orders/confirmIsReady",
  async (orderId: Order["id"], { dispatch }) => {
    const { response, json } = await fetchConfirmOrderIsReady(orderId)

    if (!response.ok || !json.data) {
      handleStandardError(response, json)
      return { ok: false }
    }

    dispatch(updateOrder(json.data))

    return { ok: true }
  }
)

export const requestChangeOrderPayment = createAsyncThunk(
  "orders/requestChangePayment",
  async (
    { orderId, data }: { orderId: Order["id"]; data: ChangeOrderPaymentData },
    { dispatch }
  ) => {
    const { response, json } = await fetchChangeOrderPayment(orderId, data)
    const { data: order } = json

    if (!response.ok || !order) {
      handleStandardError(response, json)
      return { ok: false }
    }

    dispatch(updateOrder(order))

    return { ok: true }
  }
)

export const requestUpdateClientComment = createAsyncThunk(
  "orders/requestUpdateClientComment",
  async (
    { orderId, comment }: { orderId: Order["id"]; comment: string },
    { dispatch }
  ) => {
    const { response, json } = await fetchUpdateClientComment(orderId, comment)
    const { data: order } = json

    if (!response.ok || !order) {
      handleStandardError(response, json)
      return { ok: false }
    }

    dispatch(updateOrder(order))

    return { ok: true }
  }
)

export const requestPaymentRefund = createAsyncThunk(
  "orders/requestPaymentRefund",
  async (orderId: Order["id"], { dispatch }) => {
    const { response, json } = await fetchPaymentRefund(orderId)
    const { data: order } = json

    if (!response.ok || !order) {
      handleStandardError(response, json)
      return { response }
    }

    dispatch(updateOrder(order))

    return { ok: true }
  }
)

export const handleOrderUpdate = createAsyncThunk(
  "orders/handleOrderUpdate",
  async (orderUpdate: Order, { getState, dispatch }) => {
    const state = getState() as RootState
    const orderInState = state.orders.entities[orderUpdate.id]

    // If an update arrives where order.state is waiting and it's state in store
    // isn't "waiting", it means a new order to receive and toast has to be shown
    // Reducer is probably not the best place for this
    if (
      isReceivable(orderUpdate) &&
      orderUpdate.state === "waiting" &&
      // As of 2021-06-08 there is always "waiting" before "pending", so double check is necessary
      (!orderInState || !["waiting", "draft"].includes(orderInState.state))
    ) {
      // No toast when in dashboard view or orders to pickup view
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/odbieranie-zlecen"
      ) {
        showNewOrderToReceiveToast({
          provider: orderUpdate.provider,
          marketplace: orderUpdate.marketplace_kind,
          orderId: orderUpdate.id,
        })
      }

      const isPrintingEnabled =
        state.client.data?.settings_attributes.automatic_receipt_printing

      if (isPrintingEnabled && window.KBBluetooth) {
        printOrderDetails(orderUpdate.id)
      }
    }

    dispatch(updateOrder(orderUpdate))
  }
)

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    updateOrder(state, action: PayloadAction<Order>) {
      const orderUpdate = action.payload
      const orderInState = state.entities[orderUpdate.id]

      if (orderInState) {
        ordersAdapter.upsertOne(state, deepMerge({}, orderInState, orderUpdate))
      } else {
        const isFullEntity = Boolean(orderUpdate.team_id)
        if (isFullEntity) ordersAdapter.addOne(state, orderUpdate)
      }
    },
    updateConnectedOrdersIds(state, action: PayloadAction<ConnectedOrdersIds>) {
      state.connectedOrdersIds = action.payload
    },
    postponeOrder(state, action: PayloadAction<Order["id"]>) {
      state.postponedOrdersIds.push(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      if (state.ids.length) {
        state.status = "updating"
      } else {
        state.status = "loading"
      }
    })

    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        ordersAdapter.upsertMany(state, payload.data)
        if (payload.connectedOrdersIds) {
          state.connectedOrdersIds = payload.connectedOrdersIds
        }
        state.status = "fetched"
      } else {
        state.status = "error"
      }
    })

    builder.addCase(getWaitingOrders.pending, (state) => {
      if (state.ids.length) {
        state.status = "updating"
      } else {
        state.status = "loading"
      }
    })

    builder.addCase(getWaitingOrders.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        ordersAdapter.upsertMany(state, payload.data)
        state.status = "fetched"
      } else {
        state.status = "error"
      }
    })

    builder.addCase(setCurrentRestaurant.pending, (state) => {
      ordersAdapter.removeAll(state)
      state.status = null
    })

    builder.addCase(clearDataOnLogout, () => initialState)
  },
})

const { selectAll, selectEntities, selectById } = ordersAdapter.getSelectors(
  (state: RootState) => state.orders
)

const ORDER_LIST_HIDDEN_STATES = ["waiting", "draft", "unpaid"]

export const selectLastOrderInLink = (firstOrderId: Order["id"]) =>
  createSelector(
    (state: RootState) => state.orders.connectedOrdersIds,
    selectEntities,
    (connectedOrdersIds, ordersEntities) => {
      const foundConnection = connectedOrdersIds?.find((connection) =>
        connection.some((id) => id === firstOrderId)
      )

      if (foundConnection) {
        return ordersEntities[foundConnection[foundConnection.length - 1]]
      }
    }
  )

export const selectOrdersToReceiveIds = () =>
  createSelector(selectAll, (orders) =>
    orders.filter((order) => isToReceive(order)).map((order) => order.id)
  )

export const selectOrdersToReceiveProviders = () =>
  createSelector(
    selectOrdersToReceiveIds(),
    selectEntities,
    (ordersToReceiveIds, ordersEntities) => {
      type Providers = Array<{
        provider: Provider
        marketplace: Marketplace | null
      }>

      const providers: Providers = ordersToReceiveIds.map((orderId) => ({
        provider: ordersEntities[orderId]!.provider,
        marketplace: ordersEntities[orderId]!.marketplace_kind,
      }))

      const uniqueProviderWithMarketplace: Providers = Array.from(
        new Set(providers.map((item) => JSON.stringify(item)))
      ).map((item) => JSON.parse(item))

      return uniqueProviderWithMarketplace
    }
  )

export const selectOrdersUnsuccessfullyReceivedIds = (date: Dayjs) =>
  createSelector(selectAll, (orders) =>
    orders
      .filter(
        (order) => isFromDate(date)(order) && wasUnsuccessfullyReceived(order)
      )
      .map((order) => order.id)
  )

export const selectOrdersReceivedIds = (date: Dayjs) =>
  createSelector(selectAll, (orders) =>
    orders
      .filter(
        (order) =>
          isFromDate(date)(order) &&
          isReceivable(order) &&
          !isToReceive(order) &&
          !wasUnsuccessfullyReceived(order) &&
          !ORDER_LIST_HIDDEN_STATES.includes(order.state)
      )
      .map((order) => order.id)
  )

export const selectOrderById = (id: Order["id"]) => (state: RootState) =>
  selectById(state, id)

export const selectOrdersStatus = (state: RootState) => state.orders.status

export const selectFilteredPaidAmount = (
  ...filters: Array<(order: Order) => boolean>
) =>
  createSelector(selectAll, (orders) =>
    roundToTwoDecimals(
      sumFullPrices(
        orders.filter((order) => filters.every((filter) => filter(order)))
      )
    )
  )

export const selectOrders = selectAll

export const selectFilteredOrders = (
  ...filters: Array<(order: Order) => boolean>
) =>
  createSelector(selectOrders, (orders) =>
    orders.filter((order) => filters.every((filter) => filter(order)))
  )

export const selectFilteredOrdersIds = (
  ...filters: Array<(order: Order) => boolean>
) =>
  createSelector(selectFilteredOrders(...filters), (orders) =>
    orders.map((order) => order.id)
  )

export const selectFilteredOrdersIdsWithLinking = (
  ...filters: Array<(order: Order) => boolean>
) =>
  createSelector(
    selectFilteredOrdersIds(...filters),
    (state: RootState) => state.orders.connectedOrdersIds,
    (ordersIds, connectedOrdersIds) => {
      const ordersIdsWithLinking: Array<Order["id"] | Array<Order["id"]>> = [
        ...ordersIds,
      ]

      connectedOrdersIds?.forEach((connection: Array<Order["id"]>) => {
        const [firstId, ...restIds] = connection
        const indexAt = ordersIdsWithLinking.indexOf(firstId)

        if (indexAt === -1) return

        // insert connected ids array at index
        ordersIdsWithLinking.splice(indexAt, 1, connection)

        // delete rest of ids
        restIds.forEach((id) =>
          ordersIdsWithLinking.splice(ordersIdsWithLinking.indexOf(id), 1)
        )
      })

      // map single ids to one-element arrays
      return ordersIdsWithLinking.map((element) =>
        typeof element === "number" ? [element] : element
      )
    }
  )

export const selectPostponedOrdersIds = (state: RootState) =>
  state.orders.postponedOrdersIds

export const { updateOrder, updateConnectedOrdersIds, postponeOrder } =
  ordersSlice.actions
export const ordersReducer = ordersSlice.reducer
