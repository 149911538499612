import { Avatar } from "@deligoo/ui"
import clsx from "clsx"
import { ComponentProps } from "react"

import { Notification } from "@/types"
import { formatHour } from "@/utils"

import cls from "./Notifications.module.scss"

type NotificationItemProps = {
  notification: Notification
  wasPreviouslyUnread: boolean
  className?: string
}

const NotificationItem = ({
  notification,
  wasPreviouslyUnread,
  className,
}: NotificationItemProps) => {
  return (
    <div className={clsx(cls.notification, className)}>
      <Avatar
        className={cls.icon}
        color={getAvatarColor(notification.priority)}
        dotColor={wasPreviouslyUnread ? "info" : undefined}
        iconProps={{ name: "overdues" }}
      />
      <div className={cls.content}>
        <div className={cls.time}>{formatHour(notification.created_at)}</div>
        <div className={cls.message}>
          <span
            dangerouslySetInnerHTML={{
              __html: notification.text,
            }}
          />
        </div>
      </div>
    </div>
  )
}

function getAvatarColor(
  priority: Notification["priority"]
): ComponentProps<typeof Avatar>["color"] {
  switch (priority) {
    case "high":
      return "alert"
    case "average":
      return "warning"
    case "low":
      return "success"
  }
}

export { NotificationItem }
