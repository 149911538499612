import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Card } from "@/components/Card"
import {
  selectStatisticsData,
  selectStatisticsStatus,
} from "@/store/statistics"

const TotalOrders = () => {
  const statistics = useSelector(selectStatisticsData)
  const statisticsStatus = useSelector(selectStatisticsStatus)

  const { t } = useTranslation("dashboard")

  return (
    <Card loading={!statisticsStatus || statisticsStatus === "loading"}>
      <Card.Header>
        <Card.Title color="primary">
          {statistics?.total_orders_count}
        </Card.Title>
      </Card.Header>
      {t("Total deliveries")}
    </Card>
  )
}

export { TotalOrders }
