const createOrder = {
  steps: {
    contact_detail: "Contact detail",
    payment_form: "Payment form",
    package_type: "Package type",
    processing_time: "Processing time",
    notes: "Notes",
    summary: "Summary",
  },

  buttons: {
    return: "Back",
    continue: "Next",
    cancel: "Cancel",
    cancel_order: "Cancel order",
    download_label: "Download label .PDF",
    return_to_orders: "Back to orders list",
    create_order: "New order",
    search_for_address: "Search for an address",
    add_new: "Add new",
  },

  errors: {
    select_package: "At least one package is required",
    price_value_too_low:
      "The amount for the selected payment method must be greater than 0",
    price_value_required: "Price required",
    no_payment_methods: "No payment form selected. Contact support.",
    payment_value_required: "Please provide the form and amount of payment",
    invalid_pickup_time: "Pickup time too short",
    invalid_time: "Enter the correct time",
  },

  headers: {
    create_order: "New order",
    saved_addresses: "Saved addresses",
    payment_form: "Payment form",
    order_kind: "Order kind",
    select_package_and_weight: "Select package and weight",
    select_package_type: "Select package type",
    delivery_price: "Delivery price",
    total: "Total",
    drinks: "Drinks",
    client_notes: "Order comments received from the customer",
    client_notes_content: "The content of comments provided by the client",
    notes_templates: "Notes templated",
    click_to_select: "click to select",
    today_orders: "Today orders",
    buffering: "The order is being assigned",
    searching_for_driver: "We are looking for the best courier",
    order_uid: "Order number",
    processing: "Processing...",
    driver_assigned: "Courier assigned",
    driver: "Courier",
    pickup_in: "Pickup in",
    pickup_on: "at. {{hour}}",
    order_created: "Order <1>#{{orderUid}}<1> added",
    order_finished: "Order <1>#{{orderUid}}<1> finished",
    order_canceled: "Order <1>#{{orderUid}}<1> canceled",
    order_rejected: "Order <1>#{{orderUid}}<1> failed",
    order_expired: "Order <1>#{{orderUid}}<1> expired",
    is_from_future: "This assignment is scheduled for {{date}}",
    prep_time: "Preparation time",
    proposed_time: "Proposed time",
    self_pickup: "Self pickup",
    pickup: "Pickup",
    delivery: "Delivery",
    ready_to_pickup: "Ready to pickup",
    time_range:
      "Time interval preferred by the customer for the delivery of the parcel",
  },

  descriptions: {
    prep_time:
      "How much time do you need to prepare this order, how many minutes does the kitchen need to prepare a meal.",
    proposed_time:
      "You can choose one of two options - propose the time of picking up the order from the restaurant or the time of delivery requested by the customer within a 30-minute interval.",
    order_kind:
      "Select the size of your order, thanks to which the courier will know what type of container to use to pack the dishes. If the container is not needed, select the own packaging option and add the appropriate number of pieces.",
    client_noted:
      "If the customer provided any comments on the delivery such as directions, floor, staircase etc.",
    buffering:
      "The order has been correctly sent and is being assigned to the appropriate courier in order to determine the exact time of collection.",
    estimated_time: "Estimated pickup time: {{pickupRange}}",
    next_steps:
      "You can now add another order or return to the list to observe the status of subsequent orders.",
  },

  package_types: {
    "big-pizza": "Big pizza",
    "standard-dish": "Standard dish",
    "own-packaging": "Own packaging",
    "hebe-box-1": "BOX 1",
    "hebe-box-2": "BOX 2",
    "hebe-box-3": "BOX 3",
    "hebe-box-4": "BOX 4",
    "hebe-box-5": "BOX 5",
    "ecommerce-size-a": "Gabaryt A",
    "ecommerce-size-b": "Gabaryt B",
    "ecommerce-size-c": "Gabaryt C",
    "inpost-size-a": "Gabaryt A",
    "inpost-size-b": "Gabaryt B",
    "inpost-size-c": "Gabaryt C",
  },

  package_types_desc: {
    "big-pizza": "32 cm+",
    "standard-dish": "pizza up to 32 cm",
    "own-packaging": "sushi / shopping",
    max: "max.",
  },

  client_notes_templates: {
    contact_before_delivery: "Please call me before delivery",
    no_intercom: "Do not use the intercom",
    access_code: "Access code is",
    non_contact_delivery: "Non contact delivery",
    contact_person: "Contact person",
    company_name: "Company name",
  },
}

export default createOrder
