import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"

import { RootState } from "@/store"
import { formatHour } from "@/utils"

const initialNow = dayjs()

const initialState = {
  currentTimestamp: initialNow.toISOString(),
  currentHour: formatHour(initialNow),
  isDataRefreshNeeded: false,
}

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    tick(state) {
      const now = dayjs()

      state.currentTimestamp = now.toISOString()
      state.currentHour = formatHour(now)
    },
    setIsDataRefreshNeeded(state, action: PayloadAction<boolean>) {
      state.isDataRefreshNeeded = action.payload
    },
  },
})

export const selectCurrentTime = (state: RootState) =>
  state.global.currentTimestamp
export const selectCurrentHour = (state: RootState) =>
  formatHour(state.global.currentTimestamp)

export const { tick, setIsDataRefreshNeeded } = globalSlice.actions

export const globalReducer = globalSlice.reducer
