import { Button, Icon } from "@deligoo/ui"

import { IconName } from "@/types"

import cls from "./GetApps.module.scss"

type GetAppProps = {
  step: string
  title: string
  description: string
  ctaButtonText: string
  appImage: string
  target?: string
  ctaButtonLink: string
  ctaButtonIcon: IconName
}

export const GetApp = ({
  step,
  title,
  description,
  appImage,
  target,
  ctaButtonText,
  ctaButtonLink,
  ctaButtonIcon,
}: GetAppProps) => {
  return (
    <section className={cls.section}>
      <img
        src={require(`./assets/${appImage}.svg`).default}
        alt={title}
        className={cls.icon}
      />
      <div className={cls.info}>
        <div className={cls.step}>{step}</div>
        <h2 className={cls.title}>{title}</h2>
        <div className={cls.description}>{description}</div>
      </div>
      <Button
        as="a"
        href={ctaButtonLink}
        iconSide="left"
        size="large"
        color="primary"
        variation="outlined"
        className={cls.button}
        target={target ? target : "_blank"}
      >
        {ctaButtonText}
        <Icon name={ctaButtonIcon} color="primary" className="ml-4" />
      </Button>
    </section>
  )
}
