import { useSelector } from "react-redux"
import {
  Redirect,
  Route as NativeRoute,
  RouteProps as NativeRouteProps,
} from "react-router-dom"

import { selectIsAuthenticated } from "@/store/auth"

type RouteProps = NativeRouteProps & {
  restricted?: boolean
}

const Route = ({ restricted, ...props }: RouteProps) => {
  const isAuthenticated = useSelector(selectIsAuthenticated)

  if (!restricted) return <NativeRoute {...props} />

  return isAuthenticated ? (
    <NativeRoute {...props} />
  ) : (
    <Redirect to="/zaloguj" />
  )
}

export default Route
