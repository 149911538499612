import { LatLngTuple } from "leaflet"
import { Polyline } from "react-leaflet"

import { useRoutePolyline } from "./useRoutePolyline"

type DriverRouteProps = {
  driverPosition: LatLngTuple
  restaurantPosition: LatLngTuple
}

const DriverRoute = ({
  driverPosition,
  restaurantPosition,
}: DriverRouteProps) => {
  const driverRoutePolyline = useRoutePolyline(
    driverPosition,
    restaurantPosition
  )

  if (!driverRoutePolyline) return null

  return <Polyline positions={driverRoutePolyline} className="route" />
}

export { DriverRoute }
