import { StoreEnhancer } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import preval from "preval.macro"

const gitRevision: string = preval`
  const { execSync } = require("child_process")

  function getGitCommitHash() {
    return execSync("git rev-parse HEAD").toString().trim()
  }

  module.exports = getGitCommitHash()
`

export function initSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    enabled: process.env.NODE_ENV === "production", // every deployed server
    release: gitRevision,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  })
}

export const sentryReduxEnhancer: StoreEnhancer = Sentry.createReduxEnhancer()

export const SentryErrorBoundary = Sentry.ErrorBoundary
