import { useTranslation } from "react-i18next"

import { GetApp } from "./GetApp"
import cls from "./GetApps.module.scss"

const VIDEO_LINK = "https://youtu.be/mq3U81kkaMs"
const DELIGOO_APP_LINK = "/app-orders/deligoo_pos.apk"
const ANYDESK_LINK = "https://play.google.com/store/search?q=anydesk"

export const GetApps = () => {
  const { t } = useTranslation("getApps")

  return (
    <>
      <header className={cls.header}>
        <div className={cls.wrapper}>
          <a href="/">
            <img
              src={require("@/assets/logotypes/deligoo-slogan.svg").default}
              alt="DeliGoo"
              className={cls.logo}
            />
          </a>
          <h1 className={cls.title}>{t("pos_app")}</h1>
        </div>
      </header>

      <main className={cls.sections}>
        <GetApp
          step={t("step", { count: 1 })}
          title={t("step_1_title")}
          description={t("step_1_description")}
          ctaButtonText={t("step_1_cta")}
          appImage="play"
          ctaButtonLink={VIDEO_LINK}
          ctaButtonIcon="play"
        />

        <GetApp
          step={t("step", { count: 2 })}
          title={t("step_2_title")}
          description={t("step_2_description")}
          ctaButtonText={t("step_2_cta")}
          appImage="app"
          ctaButtonLink={DELIGOO_APP_LINK}
          ctaButtonIcon="download"
          target="_self"
        />

        <GetApp
          step={t("step", { count: 3 })}
          title={t("step_3_title")}
          description={t("step_3_description")}
          ctaButtonText={t("step_3_cta")}
          appImage="anydesk"
          ctaButtonLink={ANYDESK_LINK}
          ctaButtonIcon="short-arrow-tiny-right"
        />
      </main>
    </>
  )
}
