import clsx from "clsx"
import { Children, cloneElement,ComponentProps, isValidElement, useState } from "react"
import { Instance as TippyInstance } from "tippy.js"

import { Icon } from "../Icon"
import { Tooltip } from "../Tooltip"
import cls from "./Dropdown.module.scss"

export type DropdownProps = {
  trigger: JSX.Element
  children: React.ReactNode
  className?: string
  text?: boolean
}

/**
 * If you want the dropdown to close automatically when an option is clicked,
 * remember: 1) Dropdown.MenuItems have to be direct children of Dropdown component
 * 2) use handleClick handler instead of onClick (which overrides hiding behaviour)
 */
export const Dropdown = ({
  trigger,
  children,
  className,
  text,
  ...props
}: DropdownProps & Omit<ComponentProps<typeof Tooltip>, "trigger" | "children">) => {
  const [tippyInstance, setTippyInstance] = useState<TippyInstance | null>(null)

  return (
    <Tooltip
      className={clsx(cls.dropdown, className, text && cls.text)}
      placement="bottom"
      content={
        tippyInstance
          ? Children.map(children, (child) => {
              if (isValidElement(child)) {
                return cloneElement(child, {
                  hideDropdown: tippyInstance.hide,
                })
              }
            })
          : null
      }
      trigger="click"
      interactive
      onCreate={(instance) => setTippyInstance(instance)}
      {...props}
    >
      {trigger}
    </Tooltip>
  )
}

export type MenuItemProps = {
  children: React.ReactNode
  handleClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void
  hideDropdown?: TippyInstance["hide"]
  className?: string
}

const MenuItem = ({
  children,
  handleClick,
  hideDropdown,
  className,
  ...props
}: MenuItemProps & JSX.IntrinsicElements["button"]) => {
  return (
    <button
      className={clsx(cls.menuItem, className)}
      type="button"
      onClick={(event) => {
        handleClick?.(event)
        hideDropdown?.()
      }}
      {...props}
    >
      <div className={cls.content}>
        {children}
        <Icon name="chevron-tiny-right" size="small" className={cls.arrow} />
      </div>
    </button>
  )
}

Dropdown.MenuItem = MenuItem
