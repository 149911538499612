import { Icon, ThemeColor } from "@deligoo/ui"
import clsx from "clsx"
import { CSSProperties } from "react"

import { IconName } from "@/types"

import cls from "./ProgressBar.module.scss"

type ProgressBarProps = {
  iconName: IconName
  className?: string
  color?: ThemeColor
  inProgress?: boolean
  label?: string | null
  late?: string | null
  valueLeft?: string | null
  valueRight?: string | null
  indicatorLabel?: string | null
  /** @param value: number between 0 and 100 */
  value?: number
}

const ProgressBar = ({
  className,
  color = "muted",
  iconName,
  inProgress,
  label,
  late,
  valueLeft,
  valueRight,
  indicatorLabel,
  value = 50,
}: ProgressBarProps) => {
  const progressStyle =
    value >= 0 ? ({ "--progress": `${value}%` } as CSSProperties) : {}

  return (
    <div className={clsx(cls.wrapper, cls[color], className)}>
      <div className={cls.content}>
        {valueLeft && <span>{valueLeft}</span>}

        <div
          className={clsx(
            cls.progressBar,
            inProgress && cls.inProgress,
            value === 100 && cls.isFinished
          )}
          style={progressStyle}
        >
          <div
            className={clsx(
              cls.indicator,
              value > 30 && cls.alignCenter,
              value > 70 && cls.alignEnd
            )}
          >
            <Icon name={iconName} />

            {indicatorLabel && (
              <span className={cls.indicatorLabel}>{indicatorLabel}</span>
            )}
          </div>
        </div>

        {valueRight && <span>{valueRight}</span>}
      </div>

      <div className={cls.meta}>
        {label && <div className={cls.label}>{label}</div>}
        {late && <div className={cls.late}>{late}</div>}
      </div>
    </div>
  )
}

export { ProgressBar }
