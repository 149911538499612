import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { Card } from "@/components/Card"
import {
  selectCurrentRestaurant,
  selectCurrentRestaurantStatus,
} from "@/store/restaurant"

import cls from "../Dashboard.module.scss"

const Integrations = () => {
  const restaurant = useSelector(selectCurrentRestaurant)
  const restaurantStatus = useSelector(selectCurrentRestaurantStatus)

  const { t } = useTranslation("dashboard")

  const helpModal = {
    title: "Integrations",
    content: (
      <>
        {t("integrations_desc")}
        <dl className="mt-2">
          <dt className="h300 mt-3">0 / 2</dt>
          <dd>{t("integrations_count_0")}</dd>

          <dt className="h300 mt-3">1 / 2</dt>
          <dd>{t("integrations_count_1")}</dd>

          <dt className="h300 mt-3">2 / 2</dt>
          <dd>{t("integrations_count_2")}</dd>
        </dl>
      </>
    ),
  }

  return (
    <Link to="/integracje">
      <Card
        help={t("Learn more")}
        helpModal={helpModal}
        loading={restaurantStatus === "loading"}
        link
      >
        <Card.Header>
          <Card.Title color="success" className={cls.activity}>
            <span>{restaurant?.active_portals_count}</span> <span>/</span>{" "}
            <span>{restaurant?.integrated_portals_count}</span>
          </Card.Title>
        </Card.Header>
        {t("Integrations")}
      </Card>
    </Link>
  )
}

export { Integrations }
