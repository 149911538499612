import "react-nice-dates/build/style.css"
import "./nice-dates.scss"

import { Modal } from "@deligoo/ui"
import { enUS, pl } from "date-fns/locale"
import { useTranslation } from "react-i18next"
import { DatePickerCalendar, DatePickerCalendarProps } from "react-nice-dates"

import cls from "./DatePicker.module.scss"

type DatePickerModalProps = {
  handleClose: () => void
  datePickerCalendarProps: Omit<DatePickerCalendarProps, "locale">
}

const DatePickerModal = ({
  handleClose,
  datePickerCalendarProps,
}: DatePickerModalProps) => {
  const { i18n } = useTranslation()

  return (
    <Modal className={cls.modal} handleClose={handleClose}>
      <Modal.Content>
        <DatePickerCalendar
          {...datePickerCalendarProps}
          locale={i18n.language === "pl" ? pl : enUS}
        />
      </Modal.Content>
    </Modal>
  )
}

export { DatePickerModal }
