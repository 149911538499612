import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { DataStatus, Notification, RootState } from "@/types"
import {
  fetchHideAllNotifications,
  fetchNotifications,
  fetchReadAllNotifications,
  handleStandardError,
  updateCollectionItemById,
} from "@/utils"

import { clearDataOnLogout } from "./auth"
import { setCurrentRestaurant } from "./restaurant"

type NotificationsState = {
  data: Notification[] | null
  status: DataStatus | null
}

const initialState: NotificationsState = {
  data: null,
  status: null,
}

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async () => {
    const { response, json } = await fetchNotifications()

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
    }
  }
)

export const requestReadAllNotifications = createAsyncThunk(
  "notifications/readAll",
  async () => {
    const { response, json } = await fetchReadAllNotifications()

    if (!response.ok) {
      handleStandardError(response, json)
    }

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
    }
  }
)

export const requestHideAllNotifications = createAsyncThunk(
  "notifications/hideAll",
  async () => {
    const { response, json } = await fetchHideAllNotifications()

    if (!response.ok) {
      handleStandardError(response, json)
    }

    return {
      ok: response.ok,
      status: response.status,
      data: json.data,
    }
  }
)

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    updateNotification(state, action: PayloadAction<Notification>) {
      const updatedNotification = action.payload
      if (
        state.data?.some(
          (notification) => notification.id === updatedNotification.id
        )
      ) {
        updateCollectionItemById(state.data, updatedNotification)
      } else {
        state.data?.unshift(updatedNotification)
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      if (state.data) {
        state.status = "updating"
      } else {
        state.status = "loading"
      }
    })

    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        state.data = payload.data
        state.status = "fetched"
      } else {
        state.status = "error"
      }
    })

    builder.addCase(
      requestReadAllNotifications.fulfilled,
      (state, { payload }) => {
        if (payload.ok && payload.data) {
          payload.data.forEach((updatedNotification) => {
            if (!state.data) return
            updateCollectionItemById(state.data, updatedNotification)
          })
        }
      }
    )

    builder.addCase(
      requestHideAllNotifications.fulfilled,
      (state, { payload }) => {
        if (payload.ok) state.data = []
      }
    )

    builder.addCase(setCurrentRestaurant.pending, (state) => {
      state.data = null
      state.status = null
    })

    builder.addCase(clearDataOnLogout, () => initialState)
  },
})

export const selectNotifications = (state: RootState) =>
  state.notifications.data

export const selectNotificationsStatus = (state: RootState) =>
  state.notifications.status

export const selectUnreadNotifications = (state: RootState) =>
  state.notifications.data?.filter((notification) => !notification.read_at)

export const selectUnreadNotificationsCount = (state: RootState) =>
  state.notifications.data?.filter((notification) => !notification.read_at)
    .length

export const selectIsAnyUnreadImportantNotification = (state: RootState) =>
  state.notifications.data?.some(
    (notification) => !notification.read_at && notification.priority === "high"
  )

export const { updateNotification } = notificationsSlice.actions
export const notificationsReducer = notificationsSlice.reducer
