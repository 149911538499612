import { Button, fetchFindPostalCode, Field, Icon } from "@deligoo/ui"
import { useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Rifm } from "rifm"

import { useAppDispatch } from "@/App"
import { setOrderFormStep } from "@/store/orderForm"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { ContactDetail, OrderFormStepStatus } from "@/types"
import { fetchDeliveryDetails, useIsMounted } from "@/utils"
import { formatPhone, formatPostalCode } from "@/utils/masks"

import formCls from "../../OrderForm.module.scss"
import cls from "../ContactDetails.module.scss"
import { CityField } from "./CityField"
import { StreetField } from "./StreetField"
import { Summary } from "./Summary"

type AddressFormProps = {
  currentStepStatus?: OrderFormStepStatus | null
  defaultValues?: ContactDetail
  setView: (view: "form" | "list") => void
}

const AddressForm = ({
  currentStepStatus,
  defaultValues,
  setView,
}: AddressFormProps) => {
  const [isAddressRefetching, setIsAddressRefetching] = useState(false)

  const interfaceType = useSelector(
    selectCurrentRestaurantKey("interface_type")
  )

  const cityRef = useRef<HTMLInputElement | null>(null)
  const streetRef = useRef<HTMLInputElement | null>(null)
  const houseNumberRef = useRef<HTMLInputElement | null>(null)

  const {
    getValues,
    register,
    reset,
    errors,
    watch,
    setValue,
    setError,
    trigger,
    clearErrors,
  } = useFormContext()

  const phone = watch("phone")

  const hasValidPhone = phone?.length === 11 && !errors?.phone

  const address = watch(
    [
      "city",
      "state",
      "county",
      "municipality",
      "street",
      "house_number",
      "postal_code",
    ],
    defaultValues
  )

  const maskedPostalCode = watch("postal_code")

  const dispatch = useAppDispatch()

  const isMounted = useIsMounted()

  const { t } = useTranslation(["common", "createOrder"])

  function focusNextInput() {
    const inputs = getValues(["phone", "city", "street", "house_number"])

    if (!inputs.phone) return

    if (!inputs.city) cityRef.current?.focus()
    else if (!inputs.street) streetRef.current?.focus()
    else if (!inputs.house_number) houseNumberRef.current?.focus()
  }

  async function updatePostalCode() {
    const partialAddress = getValues([
      "city",
      "state",
      "county",
      "municipality",
      "street",
      "house_number",
    ])

    const isAddressValid = Object.values(partialAddress).every(Boolean)

    if (!isAddressValid) return

    const {
      json: { data },
    } = await fetchFindPostalCode(partialAddress)

    if (!isMounted()) return

    if (data?.postal_code) {
      setValue("postal_code", data.postal_code)
    } else {
      setValue("postal_code", "")
    }
  }

  async function updateDeliveryDetails() {
    const address = getValues([
      "city",
      "state",
      "county",
      "municipality",
      "street",
      "house_number",
      "postal_code",
    ])

    const isAddressValid = Object.values(address).every(Boolean)

    if (!isAddressValid) {
      setValue("distance", null)
      setValue("delivery_price_subunit", null)
      return
    }

    const {
      json: { data, errors: fetchedErrors },
    } = await fetchDeliveryDetails(address)

    if (!isMounted()) return

    if (fetchedErrors) {
      Object.entries(fetchedErrors).forEach(([field, errors]) =>
        errors.forEach((error) => {
          setError(field, { message: error.text })

          toast.error(error.text, {
            toastId: "fallbackError",
          })
        })
      )
    } else {
      clearErrors()
      toast.dismiss("fallbackError")
    }

    setValue("distance", data?.distance)
    setValue("delivery_price_subunit", data?.delivery_price_subunit)

    try {
      await trigger("distance")
    } catch (error) {
      console.log(error)
    }
  }

  async function handleAddressChange() {
    setIsAddressRefetching(true)
    await updatePostalCode()
    await updateDeliveryDetails()
    setIsAddressRefetching(false)
  }

  useEffect(() => {
    if (defaultValues) {
      reset({
        ...defaultValues,
        phone: formatPhone(defaultValues.phone || ""),
        postal_code: formatPostalCode(defaultValues.postal_code || ""),
      })

      updateDeliveryDetails()
    }

    focusNextInput()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, reset, setValue])

  useEffect(() => {
    if (address.postal_code) isMounted() && updateDeliveryDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.postal_code])

  return (
    <>
      <div className={formCls.main}>
        <div className={cls.addressForm}>
          <CityField
            name="city"
            label={t("labels.city")}
            ref={(el) => {
              register(el, {
                required: `${t("errors.is_required")}`,
                minLength: { value: 2, message: `${t("errors.select_city")}` },
              })
              cityRef.current = el
            }}
            error={errors?.city?.message}
            inputProps={{
              disabled: !hasValidPhone,
              onBlur: () => {
                handleAddressChange()
                if (getValues("city") && !getValues("street")) {
                  streetRef.current?.focus()
                }
              },
            }}
            className={cls.col4}
            required
          />

          <StreetField
            name="street"
            label={t("labels.street")}
            ref={(el) => {
              register(el, {
                required: `${t("errors.is_required")}`,
                minLength: {
                  value: 2,
                  message: `${t("errors.select_street")}`,
                },
              })
              streetRef.current = el
            }}
            error={errors?.street?.message}
            inputProps={{
              disabled: !hasValidPhone,
              onBlur: () => {
                handleAddressChange()
                if (getValues("street") && !getValues("house_number")) {
                  houseNumberRef.current?.focus()
                }
              },
            }}
            className={interfaceType === "inpost" ? cls.col4 : cls.col8}
            required
          />

          {interfaceType === "inpost" && (
            <Field
              label={t("labels.company_name_or_person")}
              name="company_name"
              ref={register()}
              className={cls.col4}
              inputProps={{ disabled: !hasValidPhone }}
            />
          )}

          <Field
            label={t("labels.floor")}
            name="floor"
            ref={register()}
            error={errors?.floor?.message}
            inputProps={{ type: "number", disabled: !hasValidPhone }}
            className={cls.col2}
          />

          <Field
            label={t("labels.staircase")}
            name="staircase"
            ref={register()}
            error={errors?.staircase?.message}
            className={cls.col2}
            inputProps={{ disabled: !hasValidPhone }}
          />

          <Field
            label={t("labels.house_number")}
            name="house_number"
            ref={(el) => {
              register(el, { required: `${t("errors.is_required")}` })
              houseNumberRef.current = el
            }}
            error={errors?.house_number?.message}
            inputProps={{
              disabled: !hasValidPhone,
              onChange: handleAddressChange,
            }}
            className={cls.col2}
            required
          />

          <Field
            label={t("labels.flat_number")}
            name="flat_number"
            ref={register()}
            error={errors?.flat_number?.message}
            className={cls.col2}
            inputProps={{ disabled: !hasValidPhone }}
          />

          <Field
            label={t("labels.access_code")}
            name="access_code"
            ref={register()}
            error={errors?.access_code?.message}
            className={cls.col2}
            inputProps={{ disabled: !hasValidPhone }}
          />

          <Rifm
            value={maskedPostalCode || ""}
            onChange={(value) => setValue("postal_code", value)}
            format={formatPostalCode}
          >
            {({ value, onChange }) => (
              <Field
                label={t("labels.postal_code")}
                name="postal_code"
                inputProps={{
                  tabIndex: -1,
                  readOnly: true,
                  onBlur: updateDeliveryDetails,
                  value,
                  onChange,
                }}
                error={errors?.postal_code?.message}
                className={cls.col2}
                required
                ref={register({
                  required: `${t("errors.is_required")}`,
                  pattern: {
                    value: /[0-9]{2}-[0-9]{3}/,
                    message: `${t("errors.postal_code_format")}`,
                  },
                })}
              />
            )}
          </Rifm>

          <input
            defaultValue={address.city}
            name="city_suggestion"
            type="hidden"
            ref={register({ required: `${t("errors.is_required")}` })}
          />

          <input
            defaultValue={address.street}
            name="street_suggestion"
            type="hidden"
            ref={register({ required: `${t("errors.is_required")}` })}
          />
        </div>

        <Button
          color="primary"
          variation="text"
          iconProps={{ name: "short-arrow-tiny-left" }}
          onClick={() => setView("list")}
          className={cls.addressListBtn}
        >
          {t("createOrder:buttons.search_for_address")}
        </Button>

        <Summary />
      </div>

      <div className={formCls.actions}>
        <Button
          onClick={() => dispatch(setOrderFormStep(null))}
          color="primary"
          variation="accented"
          size="extra-large"
          type="button"
          fullWidth
        >
          <Icon name="short-arrow-tiny-left" />

          {t("buttons.cancel")}
        </Button>

        <Button
          loading={currentStepStatus === "loading" || isAddressRefetching}
          color="primary"
          size="extra-large"
          type="submit"
          fullWidth
        >
          {t("createOrder:buttons.continue")}
          <Icon name="short-arrow-tiny-right" />
        </Button>
      </div>
    </>
  )
}

export { AddressForm }
