import { Icon, ProviderLogo } from "@deligoo/ui"
import clsx from "clsx"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { PaymentForm } from "@/components/PaymentForm"
import { SalesDocument } from "@/components/SalesDocument"
import { Order } from "@/types"

import cls from "../ReceiveOrderModal.module.scss"

export const PaymentSection = ({
  provider,
  marketplace,
  paymentForm,
  invoice,
}: {
  provider: Order["provider"]
  marketplace: Order["marketplace_kind"]
  paymentForm: Order["payment_form"]
  invoice: Order["invoice"]
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const canShowInvoice = Boolean(invoice && provider !== "marketplace")

  const { t } = useTranslation("orders")

  return (
    <div className={clsx(cls.section, cls.payment)}>
      <button
        className={cls.top}
        onClick={() => setIsExpanded((state) => !state)}
        disabled={!canShowInvoice}
      >
        <div className={clsx(cls.form, cls.document)}>
          {provider === "marketplace" ? (
            <ProviderLogo provider={provider} marketplace={marketplace} />
          ) : (
            <div className={cls.document}>
              <div className={cls.label}>
                {t("orders_to_receive.headers.invoice")}
                {Boolean(invoice) && (
                  <Icon name="warnings" color="alert" gutter="left" />
                )}
              </div>

              <SalesDocument invoice={Boolean(invoice)} iconSide="left" />
            </div>
          )}
          <div className={cls.form}>
            <div className={cls.label}>
              {t("orders_to_receive.headers.payment_form")}
            </div>
            <PaymentForm paymentForm={paymentForm} iconSide="left" />
          </div>
        </div>

        {canShowInvoice && (
          <div className={cls.expander}>
            {isExpanded ? (
              <Icon name="chevron-up" size="tiny" color="muted" />
            ) : (
              <Icon name="chevron-down" size="tiny" color="muted" />
            )}
          </div>
        )}
      </button>

      {invoice && isExpanded && (
        <div className={cls.bottom}>
          <div className={cls.item}>
            <span>{t("orders_to_receive.headers.company")}</span>
            <span>{invoice.company}</span>
          </div>
          <div className={cls.item}>
            <span>{t("orders_to_receive.headers.address")}</span>
            <span>{`${invoice.street}, ${invoice.postal_code} ${invoice.city}`}</span>
          </div>
          <div className={cls.item}>
            <span>{t("orders_to_receive.headers.nip")}</span>
            <span>{invoice.nip}</span>
          </div>
        </div>
      )}
    </div>
  )
}
