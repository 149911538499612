export const humanizedProviderName = {
  deligoo: "DeliGoo",
  deli1: "Deli1",
  shop: "DeliGoo",
  api: "API",
  marketplace: "Marketplace",
  glodny: "Glodny.pl",
  pyszne: "Pyszne.pl",
  ubereats: "UberEats.pl",
  glovo: "Glovo.pl",
  makro: "Makro",
  upmenu: "Upmenu.pl",
  menupl: "Menu.pl",
  fastcat: "Fastcat",
  zioapp: "ZiO app",
  furgonetka: "Furgonetka.pl",
  restaumatic: "Restaumatic",
}

export const humanizedMarketplaceName = {
  jedzcochcesz: "JedzCoChcesz.pl",
  deli1: "Deli1",
}
