import { createAsyncThunk } from "@reduxjs/toolkit"
import { batch } from "react-redux"

import { AppDispatch } from "@/App"
import { Site } from "@/sites"
import { requestClient } from "@/store/auth"
import { getIntegrations } from "@/store/integrations"
import { getMessages } from "@/store/messages"
import { getNotifications } from "@/store/notifications"
import { getOrders } from "@/store/orders"
import { getStatistics } from "@/store/statistics"
import { getUnsettledPayments } from "@/store/unsettledPayments"
import { RootState } from "@/types"

export function getWebSocketURI({ wsHost, protocol, wsPort }: Site) {
  const wsProtocol = protocol === "https" ? "wss" : "ws"

  return `${wsProtocol}://${wsHost}${wsPort ? `:${wsPort}` : ""}/cable`
}

export function subscribe(toChannel: string, asRecipient: string) {
  return JSON.stringify({
    command: "subscribe",
    identifier: JSON.stringify({
      channel: toChannel,
      uuid: asRecipient,
    }),
  })
}

export const requestDataRefresh = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("refreshData", (_, { getState, dispatch }) => {
  const { unsettledPayments, statistics, integrations } = getState()

  batch(() => {
    dispatch(requestClient())
    dispatch(getMessages())
    dispatch(getNotifications())
    dispatch(getOrders())
    // TODO?: Remove everything from order form data?
    if (unsettledPayments.ids.length) dispatch(getUnsettledPayments())
    if (statistics.data) dispatch(getStatistics())
    if (integrations.data) dispatch(getIntegrations())
  })
})
