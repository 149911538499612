import { Button } from "@deligoo/ui"
import clsx from "clsx"
import preval from "preval.macro"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { requestClientLogout } from "@/store/auth"
import { requestSettings } from "@/store/client"

import {
  AutomaticPrintingSwitch,
  PasswordChangeForm,
  PhoneChangeForm,
} from "./partials"
import { LocaleChangeForm } from "./partials/LocaleChangeForm"
import { PrintingTestButton } from "./partials/PrintingTestButton"
import cls from "./Settings.module.scss"

const Settings = () => {
  const { t } = useTranslation("settings")

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestSettings())
  }, [dispatch])

  const buildTimestamp: string = preval`module.exports = Date.now()`

  return (
    <div className={clsx("page-content", cls.settings)}>
      <div className={cls.section}>
        <div className="d-flex">
          <Button
            onClick={() => dispatch(requestClientLogout())}
            className={cls.logoutButton}
            size="extra-large"
          >
            {t("buttons.sign_out")}
          </Button>
        </div>
      </div>

      <div className={cls.section}>
        <h2 className={cls.header}>{t("headers.language")}</h2>
        <LocaleChangeForm />
      </div>

      <div className={cls.section}>
        <h2 className={cls.header}>{t("headers.update_pin")}</h2>
        <PasswordChangeForm />
      </div>

      <div className={cls.section}>
        <h2 className={cls.header}>{t("headers.update_client_phone")}</h2>
        <PhoneChangeForm />
      </div>

      <div className={clsx(cls.section, cls.row)}>
        <h2 className={cls.header}>{t("headers.printing")}</h2>

        <AutomaticPrintingSwitch />
        <PrintingTestButton />
      </div>

      <div className={cls.section}>
        {t("app_version", { version: buildTimestamp })}
      </div>
    </div>
  )
}

export { Settings }
