import { Notification } from "@/types"

export const Sounds = {
  receiveOrderSound: new Audio(
    require("@/assets/sounds/receiveOrder.mp3").default
  ),

  messageSound: new Audio(require("@/assets/sounds/message.mp3").default),

  lowPriorityNotificationSound: new Audio(
    require("@/assets/sounds/notification-1.mp3").default
  ),

  averagePriorityNotificationSound: new Audio(
    require("@/assets/sounds/notification-2.mp3").default
  ),

  highPriorityNotificationSound: new Audio(
    require("@/assets/sounds/notification-3.mp3").default
  ),

  async playReceiveOrderSound() {
    try {
      await this.receiveOrderSound.play()
    } catch (error) {
      console.log(error)
    }
  },

  async playMessageSound() {
    try {
      await this.messageSound.play()
    } catch (error) {
      console.log(error)
    }
  },

  async playNotificationSound(priority: Notification["priority"]) {
    try {
      switch (priority) {
        case "low":
          await this.lowPriorityNotificationSound.play()
          break
        case "average":
          await this.averagePriorityNotificationSound.play()
          break
        case "high":
          await this.highPriorityNotificationSound.play()
          break
      }
    } catch (error) {
      console.log(error)
    }
  },
}
