import { Provider } from "@deligoo/shared"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { DataStatus, ProvidersIntegration, RootState } from "@/types"
import {
  fetchIntegrations,
  fetchSetProviderActivity,
  handleStandardError,
} from "@/utils"

import { clearDataOnLogout } from "./auth"

type IntegrationsState = {
  data: ProvidersIntegration[] | null
  status: DataStatus | null
}

const initialState: IntegrationsState = {
  data: null,
  status: null,
}

export const getIntegrations = createAsyncThunk(
  "integrations/getIntegrations",
  async () => {
    const { response, json } = await fetchIntegrations()

    return { ok: response.ok, status: response.status, data: json.data }
  }
)

export const setProviderActivity = createAsyncThunk(
  "integrations/setProviderActivity",
  async ({
    provider,
    body,
  }: {
    provider: Provider
    body: { name: string; active: boolean }
  }) => {
    const { response, json } = await fetchSetProviderActivity(provider, body)

    if (!response.ok) {
      handleStandardError(response, json)
    }

    return { ok: response.ok, status: response.status, data: json.data }
  }
)

const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIntegrations.pending, (state) => {
      state.status = "loading"
    })

    builder.addCase(getIntegrations.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        state.data = payload.data
        state.status = "fetched"
      } else {
        state.status = "error"
      }
    })

    builder.addCase(setProviderActivity.fulfilled, (state, { payload }) => {
      if (payload.ok && payload.data) {
        state.data = payload.data
      }
    })

    builder.addCase(clearDataOnLogout, () => initialState)
  },
})

export const selectIntegrations = (state: RootState) => state.integrations.data

export const selectIntegrationsStatus = (state: RootState) =>
  state.integrations.status

export const integrationsReducer = integrationsSlice.reducer
