import { Button, Dropdown, Icon } from "@deligoo/ui"
import clsx from "clsx"
import { useMediaQuery } from "react-responsive"
import { useHistory, useLocation } from "react-router-dom"

import i18n from "@/i18n"
import { IconName } from "@/types"

import cls from "./OrdersToReceiveTabs.module.scss"

type Tab = {
  route: string
  label: string
  icon: IconName
}

const TABS: Tab[] = [
  {
    route: "/odbieranie-zlecen",
    label: i18n.t("orders:tabs.pending"),
    icon: "box-pickup",
  },
  {
    route: "/odbieranie-zlecen/odebrane",
    label: i18n.t("orders:tabs.finished"),
    icon: "check-circle",
  },
  {
    route: "/odbieranie-zlecen/nieudane",
    label: i18n.t("orders:tabs.failed"),
    icon: "close-circle",
  },
  {
    route: "/odbieranie-zlecen/nieprawidlowe",
    label: i18n.t("orders:tabs.invalid"),
    icon: "block",
  },
]

type OrdersToReceiveTabsProps = {
  counts: number[]
}

// TODO: Make a generic component out of this and OrdersTabs
const OrdersToReceiveTabs = ({ counts }: OrdersToReceiveTabsProps) => {
  const history = useHistory()
  const location = useLocation()

  const currentRoute = location.pathname
  const currentTabIndex = TABS.findIndex((tab) => tab.route === currentRoute)
  const currentTab =
    currentTabIndex || currentTabIndex === 0 ? TABS[currentTabIndex] : null

  const isMobile = useMediaQuery({ query: "(max-width: 900px)" })

  if (isMobile) {
    if (!currentTab) return null

    return (
      <nav>
        <Dropdown
          trigger={
            <span>
              <Button
                color="primary"
                variation="solid"
                iconProps={{ name: currentTab.icon }}
                size="extra-large"
              >
                {currentTab.label} (${counts[currentTabIndex]})
                <Icon name="chevron-tiny-down" size="small" gutter="left" />
              </Button>
            </span>
          }
        >
          {TABS.map((tab, index) => (
            <Dropdown.MenuItem
              key={tab.route}
              handleClick={() => history.push(tab.route)}
            >
              {tab.label} ({counts[index]})
            </Dropdown.MenuItem>
          ))}
        </Dropdown>
      </nav>
    )
  }

  return (
    <nav>
      {TABS.map((tab, index) => (
        <div
          key={tab.route}
          className={clsx(cls.tab, tab.route !== currentRoute && cls.inactive)}
        >
          <Button
            className={cls.button}
            onClick={() => history.push(tab.route)}
            color={tab.route === currentRoute ? "primary" : "muted"}
            variation={tab.route === currentRoute ? "solid" : "text"}
            iconProps={{ name: tab.icon }}
            size="extra-large"
          >
            {tab.label} ({counts[index]})
          </Button>
        </div>
      ))}
    </nav>
  )
}

export { OrdersToReceiveTabs }
