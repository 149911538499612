const settings = {
  headers: {
    language: "Język",
    update_pin: "Zmiana PIN",
    update_client_phone: "Zmiana numeru telefonu lokalu",
    printing: "Drukowanie",
  },

  buttons: {
    sign_out: "Wyloguj się",
    printing_test: "Test wydruku",
  },

  errors: {
    bt_interface: "Interfejs bluetooth drukarki jest niedostępny",
    passwords_match: "Podane hasła muszą się zgadzać",
    valid_phone_number: "Podaj poprawny numer telefonu",
  },

  labels: {
    current_pin: "Aktualny PIN",
    new_pin: "Nowy PIN",
    repeat_new_pin: "Powtórz nowy PIN",
    phone_number: "Numer telefonu",
  },

  toasts: {
    pin_updated: "Pomyślnie zaktualizowano PIN",
    phone_updated: "Pomyślnie zaktualizowano numeru telefonu lokalu",
  },

  app_version: "Wersja aplikacji: {{version}}",
}

export default settings
