import { Button } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { AnimationEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useAppDispatch } from "@/App"
import { requestConfirmOrderIsReady, selectOrderById } from "@/store/orders"
import { canOrderBeConfirmedReady } from "@/utils"

import cls from "../OrderDetailsModal.module.scss"
import { ActionProps } from "."
import actionsCls from "./actions.module.scss"

type NotifyDriverProps = Pick<ActionProps, "orderId" | "isParentMounted">

const NotifyDriver = ({ orderId, isParentMounted }: NotifyDriverProps) => {
  const order = useSelector(selectOrderById(orderId))

  const isReady = order?.order_is_ready

  const [isLoading, setLoading] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)

  const dispatch = useAppDispatch()

  const { t } = useTranslation("orders")

  function handleNotifyDriver(e: AnimationEvent<HTMLButtonElement>) {
    if (!order || isReady) return

    setIsInProgress(true)

    dispatch(requestConfirmOrderIsReady(order.id))
      .then(unwrapResult)
      .finally(() => {
        isParentMounted() && setIsInProgress(false)
      })
  }

  if (!orderId || !order) return null

  return (
    <Button
      className={clsx(
        cls.actionBtn,
        actionsCls.notifyDriver,
        isLoading && actionsCls.loading,
        (isInProgress || isReady) && actionsCls.ready
      )}
      color="muted"
      iconProps={{
        name: isReady ? "check-circle" : "courier",
      }}
      onPointerDown={() => !isReady && setLoading(true)}
      onPointerUp={() => setLoading(false)}
      onAnimationEnd={handleNotifyDriver}
      disabled={isInProgress || !canOrderBeConfirmedReady(order)}
      loading={isInProgress}
    >
      {isReady ? t("driver_notified") : t("notify_driver")}
    </Button>
  )
}

export { NotifyDriver }
