import { Button } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useAppDispatch } from "@/App"
import { requestContinueOrderReceive, selectOrderById } from "@/store/orders"

import cls from "../ReceiveOrderModal.module.scss"
import { ActionButtonProps } from "."

const ContinueButton = ({
  orderId,
  loading,
  isParentMounted,
  handleModalClose,
  setIsActionInProgress,
}: ActionButtonProps) => {
  const order = useSelector(selectOrderById(orderId!))

  const dispatch = useAppDispatch()

  const { t } = useTranslation("orders")

  if (!order || !orderId) return null

  function handleConfirmOrder() {
    if (!order) return
    isParentMounted() && setIsActionInProgress(true)

    dispatch(requestContinueOrderReceive(order))
      .then(unwrapResult)
      .then(() => {
        handleModalClose()
      })
      .finally(() => {
        isParentMounted() && setIsActionInProgress(false)
      })
  }
  return (
    <Button
      className={cls.button}
      color="success"
      size="extra-large"
      fullWidth
      iconProps={{ name: "check", size: "small" }}
      iconSide="right"
      onClick={handleConfirmOrder}
      loading={loading}
    >
      {t("orders_to_receive.actions.continue")}
    </Button>
  )
}

export { ContinueButton }
