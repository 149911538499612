import { Button, Field, Icon } from "@deligoo/ui"
import { unwrapResult } from "@reduxjs/toolkit"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Rifm } from "rifm"

import { useAppDispatch } from "@/App"
import { requestUpdateSettings, selectSettingsKey } from "@/store/client"
import { Settings } from "@/types"
import { formatPhone } from "@/utils"

import cls from "../Settings.module.scss"

const PhoneChangeForm = () => {
  const currentPhone = useSelector(selectSettingsKey("phone"))
  const formattedCurrentPhone = formatPhone(currentPhone || "")

  const { register, handleSubmit, errors, formState, setValue, watch } =
    useForm<Settings>()

  const maskedPhone = watch("phone", formattedCurrentPhone)

  const dispatch = useAppDispatch()

  const { t } = useTranslation(["settings", "common"])

  async function onSubmit({ phone }: Settings) {
    dispatch(requestUpdateSettings({ phone: phone.replaceAll(" ", "") }))
      .then(unwrapResult)
      .then((res) => {
        if (res.ok) toast.success(t("toasts.phone_updated"))
      })
  }

  useEffect(() => {
    setValue("phone", formattedCurrentPhone)
  }, [formattedCurrentPhone, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cls.inputGroup}>
        <Rifm
          value={maskedPhone}
          onChange={(value) => setValue("phone", value)}
          format={formatPhone}
        >
          {({ value, onChange }) => (
            <Field
              label={t("phone_number")}
              name="phone"
              inputProps={{
                value,
                onChange,
                type: "tel",
              }}
              ref={register({
                required: `${t("common:errors.is_required")}`,
                pattern: {
                  value: /[0-9]{3} [0-9]{3} [0-9]{3}/,
                  message: `${t("errors.valid_phone_number")}`,
                },
              })}
              error={errors?.phone?.message}
            />
          )}
        </Rifm>
      </div>

      <Button
        type="submit"
        color="success"
        iconSide="right"
        iconProps={{ name: "check-tiny" }}
        size="extra-large"
        className={cls.saveButton}
        disabled={formState.isSubmitting}
      >
        {t("common:buttons.save")}
      </Button>

      {formState.isSubmitSuccessful && !errors.phone && (
        <Icon name="check-circle" color="success" />
      )}
    </form>
  )
}

export { PhoneChangeForm }
