import { Address, Errors, FullCity } from "@deligoo/shared"
import localForage from "localforage"

import { Distances, FetchDistanceBetweens } from "./fetchFromAddressTypes"

type Site = {
  id: number
  name: string
  protocol: "http" | "https"
  appHost: string
  appPort: number | null
  apiHost: string
  apiPort: number | null
  wsHost: string
  wsPort: number | null
}

export function getAddressesApiURL({ appHost, appPort, protocol }: Site) {
  return `${protocol}://${appHost}${appPort ? `:${appPort}` : ""}/addresses`
}

async function fetchFromAddressesApi<T>(endpoint: string) {
  const currentSite = (await localForage.getItem<Site>("current_site")) || JSON.parse(localStorage.getItem("site")!)

  const apiUrl = getAddressesApiURL(currentSite!)

  const response = await fetch(`${apiUrl}${endpoint}`, {
    headers: {
      Authorization: process.env.REACT_APP_ADDRESSES_API_TOKEN || "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })

  const json: T = await response.json()

  return { response, json }
}

// GET /addresses/cities/suggestions
export function fetchCitySuggestions(city: string) {
  return fetchFromAddressesApi<{
    data?: FullCity[]
  }>(`/cities/suggestions?city=${city}`)
}

// GET /addresses/streets/suggestions
export function fetchStreetSuggestions(address: FullCity & { street: string }, fromDatabase = false) {
  const { city, state, county, municipality, street } = address

  return fetchFromAddressesApi<{ data?: Array<FullCity & { street: string }> }>(
    `/streets/suggestions?city=${city}&state=${state}&county=${county}&municipality=${municipality}&street=${street}&from_database=${fromDatabase}`
  )
}

// GET /addresses/addresses/find_without_postal_code
export function fetchFindPostalCode({
  city,
  street,
  state,
  county,
  municipality,
  house_number,
}: Omit<Address, "postal_code">) {
  return fetchFromAddressesApi<{ data?: Pick<Address, "postal_code">; errors?: Errors }>(
    `/addresses/find_without_postal_code?city=${city}&state=${state}&county=${county}&municipality=${municipality}&street=${street}&house_number=${house_number}`
  )
}

// GET /addresses/distance_betweens/find
export function fetchFindDitanceBetweens({ teamId, pickup, delivery }: FetchDistanceBetweens) {
  const pickup_attributes = Object.entries(pickup).map(([key, value]) => [`pickup_attributes[${key}]`, value])
  const delivery_attributes = Object.entries(delivery).map(([key, value]) => [`delivery_attributes[${key}]`, value])

  const params = new URLSearchParams([...pickup_attributes, ...delivery_attributes, ["team_id", String(teamId)]])

  return fetchFromAddressesApi<{ data?: Distances; errors?: Errors }>(`/distance_betweens/find?${params.toString()}`)
}
