import { Icon, ThemeColor } from "@deligoo/ui"
import clsx from "clsx"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { DriverInfo } from "@/components/DriverInfo"
import { SvgProgress } from "@/components/SvgProgress"
import { selectCurrentTime } from "@/store/global"
import { IconName, Order, OrderPoint } from "@/types"
import { formatHour } from "@/utils"

import cls from "./Summary.module.scss"

const AVG_PROCESSING_SECONDS = 30

type SummaryStateProps = {
  order: Order
  totalOrders?: number
}

const SummaryState = ({ order, totalOrders }: SummaryStateProps) => {
  const { adding_state, state, uid, driver, pickup_at } = order

  if (state === "canceled") return <CanceledState orderUid={uid} />

  if (state === "failed") return <FailedState orderUid={uid} />

  if (state === "finished") return <CompletedState orderUid={uid} />

  if (state === "expired") return <ExpiredState orderUid={uid} />

  if (adding_state === "buffering") {
    return (
      <BufferingState
        pickupStart={order.pickup_range_start_time}
        pickupEnd={order.pickup_range_end_time}
        state={state}
        estimatedTime={order.pickup?.estimated_time}
        orderUid={uid}
        totalOrders={totalOrders}
      />
    )
  }

  if (state === "omitted" || adding_state === "processing") {
    return <ProcessingState orderUid={uid} totalOrders={totalOrders} />
  }

  if (adding_state === "assigned" && driver && pickup_at) {
    return (
      <AssignedState
        driver={driver}
        orderUid={uid}
        estimatedTime={order.pickup?.estimated_time}
        late={order.pickup?.late}
        totalOrders={totalOrders}
      />
    )
  }

  if (adding_state === "added") {
    return <AddedState orderUid={uid} totalOrders={totalOrders} />
  }

  return null
}

type SummaryStateHeaderProps = {
  icon: IconName
  title: string
  orderUid: Order["uid"]
  color?: ThemeColor
  totalOrders?: number
}

const SummaryStateHeader = ({
  color,
  icon,
  title,
  orderUid,
  totalOrders,
}: SummaryStateHeaderProps) => {
  const { t } = useTranslation("createOrder")

  return (
    <div className={cls.header}>
      <div className={clsx(cls.statusIcon, color && cls[color])}>
        <Icon name={icon} className={cls.icon} />
      </div>

      <h2 className={cls.title}>{title}</h2>
      <div className={cls.subtitle}>#{orderUid}</div>

      {Boolean(totalOrders) && (
        <div className={cls.ordersCount}>
          {t("headers.today_orders")}
          <div className="h400">{totalOrders}</div>
        </div>
      )}
    </div>
  )
}

type BufferingStateProps = {
  pickupStart: string | null
  pickupEnd: string | null
  orderUid: Order["uid"]
  state: Order["state"]
  estimatedTime?: string | null
  late?: OrderPoint["late"]
  totalOrders?: number
}

const BufferingState = ({
  pickupStart,
  pickupEnd,
  state,
  estimatedTime,
  late,
  orderUid,
  totalOrders,
}: BufferingStateProps) => {
  let pickupRange

  if (state === "pending" && pickupStart && pickupEnd) {
    pickupRange = `${formatHour(pickupStart)} - ${formatHour(pickupEnd)}`
  } else if (state === "assigned" && estimatedTime) {
    const rangeStart = dayjs(estimatedTime).add(late || 0, "minutes")
    const rangeEnd = rangeStart.add(10, "minutes")

    pickupRange = `${formatHour(rangeStart)} - ${formatHour(rangeEnd)}`
  }

  const { t } = useTranslation("createOrder")

  return (
    <>
      <SummaryStateHeader
        icon="time-bomb"
        title={t("headers.buffering")}
        orderUid={orderUid}
        totalOrders={totalOrders}
      />

      <div className={cls.buffering}>
        <h3 className={cls.status}>{t("headers.buffering")}</h3>
        <div className={cls.desc}>
          <p className="text-primary">{t("descriptions.buffering")}</p>

          {pickupRange && <p>{t("descriptions.buffering", { pickupRange })}</p>}

          <p className="text-muted">{t("descriptions.next_steps")}</p>
        </div>
      </div>
    </>
  )
}

type ProcessingStateProps = {
  orderUid: Order["uid"]
  totalOrders?: number
}

const ProcessingState = ({ orderUid, totalOrders }: ProcessingStateProps) => {
  const [progress, setProgress] = useState(0)

  const { t } = useTranslation("createOrder")

  useEffect(() => {
    let intervalId: number | undefined

    if (!intervalId && progress < 100) {
      intervalId = window.setInterval(
        () => setProgress((currentProgress) => currentProgress + 1),
        (AVG_PROCESSING_SECONDS / 100) * 1000
      )
    } else {
      window.clearInterval(intervalId)
    }

    return () => {
      window.clearInterval(intervalId)
    }
  })

  return (
    <>
      <SummaryStateHeader
        icon="courier"
        color="primary"
        title={t("headers.searching_for_driver")}
        orderUid={orderUid}
        totalOrders={totalOrders}
      />

      <div className={cls.pending}>
        <div className={cls.about}>
          {t("headers.order_uid")}
          <div className={clsx("h600", cls.id)}>{orderUid}</div>
          <div className={cls.pendingSpinner}>
            <SvgProgress progress={progress < 100 ? progress : undefined} />
          </div>
          {progress < 100 ? `${progress}%` : t("headers.processing")}
        </div>
      </div>
    </>
  )
}

type AssignedStateProps = {
  driver: Order["driver"]
  orderUid: Order["uid"]
  estimatedTime?: OrderPoint["estimated_time"]
  late?: OrderPoint["late"]
  totalOrders?: number
}

const AssignedState = ({
  orderUid,
  driver,
  estimatedTime,
  late,
  totalOrders,
}: AssignedStateProps) => {
  const currentTime = useSelector(selectCurrentTime)
  const now = dayjs(currentTime)

  const estimatedPickupTime =
    estimatedTime && dayjs(estimatedTime).add(late || 0, "minutes")

  const pickupIn = estimatedPickupTime && now.to(estimatedPickupTime)

  const { t } = useTranslation("createOrder")

  return (
    <>
      <SummaryStateHeader
        icon="courier"
        color="success"
        title={t("headers.driver_assigned")}
        orderUid={orderUid}
        totalOrders={totalOrders}
      />

      <div className={cls.assigned}>
        <section>
          <h3 className={cls.sectionTitle}>{t("headers.driver")}</h3>

          <div className={cls.courier}>
            <DriverInfo driver={driver} />
          </div>
        </section>

        <div className={cls.transportType}>
          <img
            className={cls.landscape}
            src={require("@/assets/landscape.svg").default}
            alt=""
          />

          {driver && (
            <img
              className={cls.courier}
              src={require(`@/assets/${driver.transport_type}.svg`).default}
              alt={driver.transport_type}
            />
          )}
        </div>

        <section className="align-right">
          <h3 className={cls.sectionTitle}>{t("headers.pickup_in")}</h3>

          {estimatedTime && (
            <div className={cls.time}>
              <div className={cls.title}>{pickupIn}</div>
              <div className={cls.subtitle}>
                {t("headers.pickup_on", { hour: formatHour(estimatedTime) })}
              </div>
              <Icon name="clock-alt" color="primary" className={cls.icon} />
            </div>
          )}
        </section>
      </div>
    </>
  )
}

type AddedStateProps = {
  orderUid: Order["uid"]
  totalOrders?: number
}

const AddedState = ({ orderUid, totalOrders }: AddedStateProps) => {
  return (
    <>
      <SummaryStateHeader
        icon="courier"
        color="success"
        title="Zlecenie dodane"
        orderUid={orderUid}
        totalOrders={totalOrders}
      />

      <div className={cls.completed}>
        <div className={cls.checkCircle}>
          <Icon name="check" color="success" />
        </div>
        <span>
          <Trans i18nKey="createOrder:headers.order_created">
            Order <strong>#{{ orderUid }}</strong> created
          </Trans>
        </span>
      </div>
    </>
  )
}

type CompletedStateProps = {
  orderUid: Order["uid"]
}

const CompletedState = ({ orderUid }: CompletedStateProps) => {
  return (
    <>
      <div className={cls.completed}>
        <div className={cls.checkCircle}>
          <Icon name="check" color="success" />
        </div>
        <span>
          <Trans i18nKey="createOrder:headers.order_finished">
            Order <strong>#{{ orderUid }}</strong> finished
          </Trans>
        </span>
      </div>
    </>
  )
}

type CanceledStateProps = {
  orderUid: Order["uid"]
}

const CanceledState = ({ orderUid }: CanceledStateProps) => {
  return (
    <>
      <div className={cls.canceled}>
        <div className={cls.checkCircle}>
          <Icon name="block" color="alert" />
        </div>
        <span>
          <Trans i18nKey="createOrder:headers.order_canceled">
            Order <strong>#{{ orderUid }}</strong> canceled
          </Trans>
        </span>
      </div>
    </>
  )
}

type FailedStateProps = {
  orderUid: Order["uid"]
}

const FailedState = ({ orderUid }: FailedStateProps) => {
  return (
    <>
      <div className={cls.canceled}>
        <div className={cls.checkCircle}>
          <Icon name="block" color="alert" />
        </div>
        <span>
          <Trans i18nKey="createOrder:headers.order_rejected">
            Order <strong>#{{ orderUid }}</strong> rejected
          </Trans>
        </span>
      </div>
    </>
  )
}

type ExpiredStateProps = {
  orderUid: Order["uid"]
}

const ExpiredState = ({ orderUid }: ExpiredStateProps) => {
  return (
    <>
      <div className={cls.canceled}>
        <div className={cls.checkCircle}>
          <Icon name="block" color="alert" />
        </div>
        <span>
          <Trans i18nKey="createOrder:headers.order_expired">
            Order <strong>#{{ orderUid }}</strong> expired
          </Trans>
        </span>
      </div>
    </>
  )
}

export {
  AddedState,
  AssignedState,
  BufferingState,
  CanceledState,
  CompletedState,
  ExpiredState,
  FailedState,
  ProcessingState,
  SummaryState,
}
