import { Icon, ThemeColor } from "@deligoo/ui"
import { IconName } from "@deligoo/ui/src/icons/build"
import clsx from "clsx"
import dayjs from "dayjs"

import { TimelineEvent } from "../../types"
import cls from "./TimelineStep.module.scss"

type TimelineStepProps = {
  event: TimelineEvent
  textKey: (event: TimelineEvent) => string
  parameters: (event: TimelineEvent, paramCls: string) => React.ReactNode
  rootSize?: string
}

export const TimelineStep = ({ event, textKey, parameters, rootSize }: TimelineStepProps) => {
  const color = getColorFor(event.key)

  return (
    <div className={cls.step} style={rootSize ? ({ "--rootsize": rootSize } as React.CSSProperties) : undefined}>
      <div className={cls.datetime}>{dayjs(event.created_at).locale("pl").format("D MMMM YYYY, HH:mm")}</div>

      <div className={cls.event}>
        <div className={cls.iconWrapper}>
          <Icon name={getIconFor(event.key)} color={color} className={cls.icon} />
        </div>

        <div className={cls.content}>
          <div className={clsx(cls.name, `text-${color}`)}>{textKey(event)}</div>

          {parameters(event, cls.param)}
        </div>
      </div>
    </div>
  )
}

function getIconFor(key: TimelineEvent["key"]): IconName {
  switch (key) {
    case "orders.created":
      return "tasks-done"

    case "orders.is_buffered":
      return "custom-date"

    case "orders.set_as_published":
      return "check-circle"

    case "orders.set_as_assigned":
    case "orders.pickups.set_as_assigned":
    case "orders.deliveries.set_as_assigned":
      return "courier"

    case "orders.pickups.set_as_started":
      return "box-pickup"

    case "orders.deliveries.set_as_started":
      return "box-delivery"

    case "orders.pickups.set_as_in_progress":
    case "orders.deliveries.set_as_in_progress":
    case "drivers.order_pickup_in_progress":
    case "drivers.order_delivery_in_progress":
      return "at-location"

    case "orders.set_as_finished":
    case "orders.pickups.set_as_finished":
    case "orders.deliveries.set_as_finished":
    case "drivers.order_assigned":
      return "check-circle"

    case "orders.reassigned":
    case "orders.force_reassigned":
    case "orders.pickups.time_changed":
    case "orders.deliveries.time_changed":
      return "edit"

    case "orders.set_as_failed":
    case "orders.pickups.set_as_failed":
    case "orders.deliveries.set_as_failed":
    case "orders.set_as_canceled":
    case "orders.pickups.set_as_canceled":
    case "orders.deliveries.set_as_canceled":
    case "drivers.order_canceled":
    case "drivers.order_pickup_finished":
    case "drivers.order_delivery_finished":
      return "close-circle"

    case "drivers.turn_on":
      return "login"

    case "drivers.turn_off":
      return "logout"

    case "drivers.reassigned_to":
      return "edit"

    case "drivers.break_created":
      return "break"

    case "drivers.order_pickup_failed":
    case "drivers.order_delivery_failed":
      return "tasks-closed"

    case "drivers.order_pickup_started":
    case "drivers.order_delivery_started":
    case "drivers.reassigned_from":
      return "started"

    default:
      return "status"
  }
}

function getColorFor(key: TimelineEvent["key"]): ThemeColor {
  switch (key) {
    case "orders.set_as_assigned":
    case "orders.pickups.set_as_assigned":
    case "orders.deliveries.set_as_assigned":
    case "orders.pickups.set_as_started":
    case "orders.deliveries.set_as_started":
    case "drivers.order_assigned":
    case "drivers.order_pickup_started":
    case "drivers.order_delivery_started":
      return "primary"

    case "orders.pickups.set_as_in_progress":
    case "orders.deliveries.set_as_in_progress":
    case "drivers.order_pickup_in_progress":
    case "drivers.order_delivery_in_progress":
      return "accent"

    case "orders.created":
    case "orders.set_as_published":
    case "orders.set_as_finished":
    case "orders.pickups.set_as_finished":
    case "orders.deliveries.set_as_finished":
    case "drivers.turn_on":
    case "drivers.order_pickup_finished":
    case "drivers.order_delivery_finished":
      return "success"

    case "orders.set_as_failed":
    case "orders.pickups.set_as_failed":
    case "orders.deliveries.set_as_failed":
    case "orders.set_as_canceled":
    case "orders.pickups.set_as_canceled":
    case "orders.deliveries.set_as_canceled":
    case "drivers.order_canceled":
    case "drivers.order_pickup_failed":
    case "drivers.order_delivery_failed":
    case "drivers.turn_off":
      return "alert"

    case "orders.reassigned":
    case "orders.force_reassigned":
    case "orders.pickups.time_changed":
    case "orders.deliveries.time_changed":
    case "drivers.reassigned_to":
    case "drivers.reassigned_from":
      return "warning"

    case "drivers.break_created":
      return "secondary"

    default:
      return "info"
  }
}
