export const matchInteger = (input: string) => {
  return (input.match(/\d+/g) || []).join("")
}

export const formatPhone = (input: string) => {
  const parsedValue = matchInteger(input)
  const chars = parsedValue.split("")

  return chars
    .reduce(
      (r, v, index) => (index === 3 || index === 6 ? `${r} ${v}` : `${r}${v}`),
      ""
    )
    .substr(0, 11)
}

export const formatPostalCode = (input: string) => {
  const parsedValue = matchInteger(input)
  const chars = parsedValue.split("")

  return chars
    .reduce((r, v, index) => (index === 2 ? `${r}-${v}` : `${r}${v}`), "")
    .substr(0, 6)
}

export const priceMaskProps = {
  format: (value: string) => (value.match(/\d+[.,]?\d{0,2}/) || []).join(""),
  replace: (value: string) => value.replace(",", "."),
  accept: /[\d.,]/g,
}
