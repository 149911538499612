import { Icon } from "@deligoo/ui"
import clsx from "clsx"
import { HTMLAttributes, ReactNode } from "react"

import { IconName } from "@/types"

import cls from "./SummaryList.module.scss"

const SummaryList = ({
  children,
  className,
}: HTMLAttributes<HTMLDivElement>) => {
  return <dl className={clsx(cls.summaryList, className)}>{children}</dl>
}

type ItemProps = HTMLAttributes<HTMLDivElement> & {
  title: string
  desc: ReactNode
  color?: string
  iconName?: IconName
}

const Item = ({ title, desc, color, iconName, ...props }: ItemProps) => {
  return (
    <div className={clsx(cls.item, color && `text-${color}`)} {...props}>
      {iconName && <Icon name={iconName} className={cls.icon} />}
      <dt className={cls.title}>{title}</dt>
      <dd className={cls.desc}>{desc}</dd>
    </div>
  )
}

SummaryList.Item = Item

export { SummaryList }
