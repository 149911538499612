import { Icon } from "@deligoo/ui"
import { ComponentProps } from "react"
import { useTranslation } from "react-i18next"

import cls from "./CloseButton.module.scss"

type CloseButtonProps = {
  onClick: () => void
  size?: ComponentProps<typeof Icon>["size"]
  title?: string
  className?: string
}

const CloseButton = ({
  size = "medium",
  className,
  ...props
}: CloseButtonProps) => {
  const { t } = useTranslation()

  return (
    <button
      className={className}
      title={t("buttons.close")}
      type="button"
      {...props}
    >
      <Icon
        name="close-circle"
        color="muted"
        size={size}
        className={cls.icon}
      />
    </button>
  )
}

export { CloseButton }
