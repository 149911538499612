import { Icon } from "@deligoo/ui"
import dayjs from "dayjs"
import { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { selectOrderFormStepData } from "@/store/orderForm"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { getDateFromTime } from "@/utils"

import { calculateTimes } from ".."
import cls from "../ProcessingTime.module.scss"

type ReadyToPickupProps = {
  distance: number
}

const ReadyToPickup = ({ distance }: ReadyToPickupProps) => {
  const { reset, errors } = useFormContext()

  const packagesData = useSelector(
    selectOrderFormStepData("payment_and_packages")
  )

  const startWorkingHours = useSelector(
    selectCurrentRestaurantKey("start_working_hours")
  )

  const interfaceType = useSelector(
    selectCurrentRestaurantKey("interface_type")
  )

  const packages = packagesData?.packages

  const { t } = useTranslation("createOrder")

  useEffect(() => {
    if (!packages || !startWorkingHours) return

    const startAt = getDateFromTime(startWorkingHours)
    const pickupIn = interfaceType === "hebe" ? 10 : 30

    const value = dayjs().isBefore(startAt)
      ? startAt
      : dayjs().add(pickupIn, "m")

    const values = calculateTimes({
      timeReference: "pickup_at",
      value: value.format(),
      distance,
    })

    reset(values)
  }, [distance, packages, reset, startWorkingHours, interfaceType])

  return (
    <div className={cls.ready}>
      <div className={cls.readyIcon}>
        <Icon name="check" size="large" />
      </div>

      <div className="h500">{t("headers.ready_to_pickup")}</div>
      {Object.values(errors).map((error) => (
        <div className="text-error">{error.message}</div>
      ))}
    </div>
  )
}

export { ReadyToPickup }
