import { Field } from "@deligoo/ui"
import { FocusEvent, useEffect, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Rifm } from "rifm"

import { selectOrderFormStepData } from "@/store/orderForm"
import { Order, PaymentAndPackage } from "@/types"
import {
  formatPrice,
  handlePriceInputBlur,
  priceMaskProps,
  priceToSubunit,
} from "@/utils"

import cls from "../PaymentAndPackage.module.scss"

export type DeliveryCostProps = {
  provider?: Order["provider"]
}

const DeliveryCost = ({ provider }: DeliveryCostProps) => {
  const contactDetailData = useSelector(
    selectOrderFormStepData("contact_detail")
  )

  const defaultDeliveryPrice = formatPrice(
    contactDetailData?.delivery_price_subunit || 0,
    { subunit: true }
  )

  const { register, errors, setValue } = useFormContext()

  const { price_delivery_subunit } = useWatch<PaymentAndPackage>({})

  const [maskedValue, setMaskedValue] = useState(
    formatPrice(price_delivery_subunit, { subunit: true })
  )

  const isPriceInputDisabled = provider
    ? ["shop", "glodny"].includes(provider)
    : false

  const { t } = useTranslation()

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    handlePriceInputBlur(e)

    if (!e.currentTarget.value.length) {
      setMaskedValue(defaultDeliveryPrice)
    }
  }

  useEffect(() => {
    setValue("price_delivery_subunit", priceToSubunit(maskedValue))
  }, [maskedValue, setValue])

  return (
    <div className={cls.inputWrapper}>
      <input
        name="price_delivery_subunit"
        ref={register({ required: `${t("errors.is_required")}` })}
        type="hidden"
      />

      <Rifm
        value={maskedValue}
        onChange={(value) => setMaskedValue(value)}
        {...priceMaskProps}
      >
        {({ value, onChange }) => (
          <Field
            error={errors?.price_delivery_subunit?.message}
            inputProps={{
              value,
              onChange,
              onBlur: handleBlur,
              inputMode: "decimal",
              placeholder: "0.00",
              maxLength: 10,
              className: cls.input,
              readOnly: isPriceInputDisabled,
            }}
          />
        )}
      </Rifm>
      <span className={cls.currency}>PLN</span>
    </div>
  )
}

export { DeliveryCost }
