import { ThemeColor } from "@deligoo/ui"
import clsx from "clsx"
import { ReactNode } from "react"

import cls from "./Indicator.module.scss"

type IndicatorProps = {
  count: number
  color?: ThemeColor
  children: ReactNode
  className?: string
  onClick?: () => void
}

const Indicator = ({
  count,
  color = "primary",
  children,
  className,
  onClick,
}: IndicatorProps) => {
  return (
    <div
      className={clsx(cls.indicator, color && cls[color], className)}
      onClick={onClick}
    >
      {children}
      {count > 0 && (
        <span className={clsx(cls.count, count > 9 && "text-desc")}>
          {count < 10 ? count : "9+"}
        </span>
      )}
    </div>
  )
}

export { Indicator }
