import clsx from "clsx"
import { forwardRef, HTMLProps } from "react"

import { ControlSize } from "../../types"
import { Icon } from "../Icon"
import cls from "./Input.module.scss"

export type InputWithoutNativeSize = Omit<HTMLProps<HTMLInputElement>, "size">

export type InputProps = InputWithoutNativeSize & {
  plain?: boolean
  dark?: boolean
  size?: ControlSize
  iconLeftProps?: React.ComponentProps<typeof Icon>
  iconRightProps?: React.ComponentProps<typeof Icon>
  htmlSize?: number
  clearOnFocus?: boolean
  selectOnFocus?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      plain,
      dark,
      size = "medium",
      iconLeftProps,
      iconRightProps,
      clearOnFocus,
      selectOnFocus,
      htmlSize,
      className,
      onFocus,
      ...props
    },
    ref
  ) => {
    function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
      if (clearOnFocus && !props.readOnly) e.currentTarget.value = ""
      if (selectOnFocus && !props.readOnly) e.currentTarget.select()
      if (onFocus) onFocus(e)
    }

    return (
      <div className={clsx(cls.inputWrapper, cls[size])}>
        {iconLeftProps && (
          <div className={cls.iconLeft}>
            <Icon {...iconLeftProps} />
          </div>
        )}

        <input
          className={clsx(
            cls.input,
            plain && cls.plain,
            dark && cls.dark,
            iconLeftProps && cls.hasIconLeft,
            iconRightProps && cls.hasIconRight,
            className
          )}
          onFocus={handleFocus}
          size={htmlSize}
          ref={ref}
          {...props}
        />

        {iconRightProps && (
          <div className={cls.iconRight}>
            <Icon {...iconRightProps} />
          </div>
        )}
      </div>
    )
  }
)
