import { Provider } from "@deligoo/shared"
import { ProviderLogo, Switch } from "@deligoo/ui"
import { ChangeEvent, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { Card } from "@/components/Card"
import {
  getIntegrations,
  selectIntegrations,
  selectIntegrationsStatus,
  setProviderActivity,
} from "@/store/integrations"
import { selectCurrentRestaurantKey } from "@/store/restaurant"
import { ProvidersIntegration } from "@/types"

import cls from "./Integrations.module.scss"

const Integrations = () => {
  const integrations = useSelector(selectIntegrations)
  const providersStatus = useSelector(selectIntegrationsStatus)
  const currentRestaurantId = useSelector(selectCurrentRestaurantKey("id"))
  const lockReason = useSelector(selectCurrentRestaurantKey("lock_reason"))

  const isRestaurantLocked = Boolean(lockReason)

  const dispatch = useDispatch()

  const { t } = useTranslation("integrations")

  function toggleActivity(name: string, provider: Provider, active: boolean) {
    dispatch(setProviderActivity({ provider, body: { name, active } }))
  }

  useEffect(() => {
    dispatch(getIntegrations())
  }, [dispatch, currentRestaurantId])

  if (providersStatus !== "fetched") {
    return (
      <div className={cls.integrations}>
        {[0, 1].map((card) => (
          <Card className={cls.loading} key={card} loading>
            ...
          </Card>
        ))}
      </div>
    )
  }

  return (
    <div className={cls.integrations}>
      {integrations?.map((integration: ProvidersIntegration) => (
        <Card
          className={cls.integration}
          color={integration.marketplace_kind || integration.provider}
          key={integration.name}
        >
          <Card.Header className={cls.header}>
            <ProviderLogo
              provider={integration.provider}
              marketplace={integration.marketplace_kind}
              light
            />
          </Card.Header>

          <div className="h300 text-light">{integration.name}</div>

          <Card.Title className={cls.status}>
            {t("online_status")}

            <Switch
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                toggleActivity(
                  integration.name,
                  integration.provider,
                  event.target.checked
                )
              }
              disabled={isRestaurantLocked}
              checked={integration.activity === "active"}
              bgColor="white"
            />
          </Card.Title>

          <Card.Footer className={cls.workingHours}>
            {t("delivery_hours")}
            <span>
              {integration.opening_time} – {integration.closing_time}
            </span>
          </Card.Footer>
        </Card>
      ))}
    </div>
  )
}

export { Integrations }
