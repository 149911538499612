import { Button, Field, Icon } from "@deligoo/ui"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

import { PasswordUpdate } from "@/types"
import { fetchUpdateSettings, getFirstError } from "@/utils"

import cls from "../Settings.module.scss"

const PasswordChangeForm = () => {
  const { register, handleSubmit, watch, errors, reset, formState, setError } =
    useForm<PasswordUpdate>()

  const { t } = useTranslation(["settings", "common"])

  async function requestPasswordChange({
    current_password,
    password,
    password_confirmation,
  }: PasswordUpdate) {
    const { response, json } = await fetchUpdateSettings({
      current_password,
      password,
      password_confirmation,
    })

    if (!response.ok && json.errors) {
      setError("current_password", {
        type: "server",
        message: getFirstError(json.errors)?.text,
      })
    } else {
      toast.success(t("toasts.pin_updated"))
      reset()
    }
  }

  return (
    <form onSubmit={handleSubmit(requestPasswordChange)}>
      <div className={cls.inputGroup}>
        <Field
          label={t("labels.current_pin")}
          name="current_password"
          ref={register({ required: `${t("common:errors.is_required")}` })}
          error={errors?.current_password?.message}
          inputProps={{
            inputMode: "numeric",
            type: "password",
          }}
        />

        <Field
          label={t("labels.new_pin")}
          name="password"
          ref={register({
            required: {
              value: true,
              message: `${t("common:errors.is_required")}`,
            },
            minLength: {
              value: 4,
              message: `${t("common:errors.min_length", { count: 4 })}`,
            },
          })}
          error={errors?.password?.message}
          inputProps={{
            inputMode: "numeric",
            type: "password",
          }}
        />

        <Field
          label={t("labels.repeat_new_pin")}
          name="password_confirmation"
          ref={register({
            required: {
              value: true,
              message: `${t("common:errors.is_required")}`,
            },
            minLength: {
              value: 4,
              message: `${t("common:errors.min_length", { count: 4 })}`,
            },
            validate: (value) =>
              value === watch("password") || `${t("errors.passwords_match")}`,
          })}
          error={errors?.password_confirmation?.message}
          inputProps={{
            inputMode: "numeric",
            type: "password",
          }}
        />
      </div>
      <Button
        type="submit"
        color="success"
        iconSide="right"
        iconProps={{ name: "check-tiny" }}
        size="extra-large"
        className={cls.saveButton}
        disabled={formState.isSubmitting}
      >
        {t("common:buttons.save")}
      </Button>
      {errors.password && errors.password.type === "server" && (
        <span className="text-alert">{errors.password.message}</span>
      )}
      {formState.isSubmitSuccessful && (
        <Icon name="check-circle" color="success" />
      )}
    </form>
  )
}

export { PasswordChangeForm }
