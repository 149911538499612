import { Button, Modal } from "@deligoo/ui"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Basket } from "@/components/Basket"
import { selectOrderById } from "@/store/orders"

import { ActionProps } from "."
import cls from "./actions.module.scss"

const OrderBasket = ({
  orderId,
  handleClose,
}: Pick<ActionProps, "orderId" | "handleClose">) => {
  const order = useSelector(selectOrderById(orderId))

  const { t } = useTranslation("orders")

  if (!orderId || !order) return null

  return (
    <Modal
      handleClose={handleClose}
      className={clsx(cls.actionModal, cls.notes)}
      light
    >
      <Modal.Title>{t("order_basket_modal.order_content")}</Modal.Title>
      <Modal.Content>
        <Basket details={order.details} />

        <div className={cls.actions}>
          <Button
            onClick={handleClose}
            className={cls.action}
            color="primary"
            size="extra-large"
          >
            {t("order_basket_modal.return_to_order")}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export { OrderBasket }
