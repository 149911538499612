import { Button, Input } from "@deligoo/ui"
import clsx from "clsx"
import dayjs from "dayjs"
import { FormEvent, useEffect, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Alert } from "@/components/Alert"
import { Order } from "@/types"
import { formatHour, getDateFromTime, isOrderFromFuture } from "@/utils"

import {
  calculateTimes,
  ProcessingTimeReference,
  TimingDeliveryReference,
} from ".."
import cls from "../ProcessingTime.module.scss"

const AVAILABLE_MINUTES = [10, 15, 20, 25, 30, 35, 40, 45]

function getDefaultSelectedTimeReference(order: Order) {
  const { delivery_method, delivery_at } = order

  if (delivery_method === "delivery_later" && delivery_at) return "delivery_at"
  if (delivery_method === "own_delivery" && delivery_at) return "delivery_at"

  return "pickup_at"
}

function getDefaultSelectedTimingDelivery(order: Order) {
  return null
}

function getInputDefaultValue(
  selectedTimeReference: ProcessingTimeReference,
  pickupAt: string,
  deliveryAt: string
) {
  const value = selectedTimeReference === "pickup_at" ? pickupAt : deliveryAt

  return dayjs(value).format("HH:mm")
}

type CalculateTimesProps = {
  distance: number
  order?: Order | null
  timing_delivery_enabled?: boolean | null
}

const CalculateTimes = ({
  distance,
  order,
  timing_delivery_enabled,
}: CalculateTimesProps) => {
  const { errors, reset, setValue } = useFormContext()

  const { t } = useTranslation("createOrder")

  const calculatedValues = useWatch({})

  const [selectedTime, setSelectedTime] = useState<number | null>(null)

  const [selectedTimeReference, setSelectedTimeReference] =
    useState<ProcessingTimeReference>(
      order ? getDefaultSelectedTimeReference(order) : "pickup_at"
    )

  const [selectedTimingDelivery, setSelectedTimingDelivery] =
    useState<TimingDeliveryReference>(
      order ? getDefaultSelectedTimingDelivery(order) : null
    )

  const [inputDefaultValue, setInputDefaultValue] = useState(
    getInputDefaultValue(
      selectedTimeReference,
      calculatedValues.pickup_at,
      calculatedValues.delivery_at
    )
  )

  const isFromFuture = isOrderFromFuture(order)

  const isSelfPickup = Boolean(order?.delivery_method.includes("pickup"))
  const isAsap = Boolean(order?.delivery_method.includes("now"))

  const shouldBlockEarlierPickup =
    isSelfPickup &&
    !isAsap &&
    ["shop", "marketplace", "glodny"].includes(order?.provider as string)

  const inputMinValue =
    (!isFromFuture &&
      shouldBlockEarlierPickup &&
      order?.pickup_at &&
      dayjs(order.pickup_at).format("HH:mm")) ||
    undefined

  function handleInputBlur(event: FormEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.value

    if (!inputValue) {
      setSelectedTime(null)
      setValue("pickup_at", null)
      setValue("delivery_at", null)
      setValue("pickup_in", null)
      return
    }

    const currentDate: string | undefined =
      calculatedValues.pickup_at || calculatedValues.delivery_at || undefined

    const values = calculateTimes({
      timeReference: selectedTimeReference,
      value: getDateFromTime(inputValue, currentDate).format(),
      distance,
    })

    setSelectedTime(null)
    reset(values)
  }

  useEffect(() => {
    if (selectedTime) {
      const values = calculateTimes({
        timeReference: "pickup_in",
        value: selectedTime.toString(),
        distance,
      })

      reset(values)
    }
  }, [selectedTime, distance, reset])

  useEffect(() => {
    setInputDefaultValue(
      getInputDefaultValue(
        selectedTimeReference,
        calculatedValues.pickup_at,
        calculatedValues.delivery_at
      )
    )
  }, [inputDefaultValue, selectedTimeReference, calculatedValues])

  return (
    <>
      <div className={cls.header}>
        {order?.pickup_at && isFromFuture && (
          <Alert color="alert" iconName="last-week" className={cls.alert}>
            {t("headers.is_from_future", {
              date: dayjs(order.pickup_at).format("DD.MM.YYYY"),
            })}
          </Alert>
        )}

        <h2 className="h400 mb-5">{t("headers.prep_time")}</h2>
      </div>

      <div className="text-large">{t("descriptions.prep_time")}</div>

      <div className={cls.timeBoxes}>
        {AVAILABLE_MINUTES.map((minutes) => (
          <TimeBox
            key={minutes}
            minutes={minutes}
            selected={selectedTime === minutes}
            disabled={
              selectedTimeReference !== "pickup_at" ||
              shouldBlockEarlierPickup ||
              isFromFuture
            }
            setSelectedTime={setSelectedTime}
          />
        ))}
      </div>

      <div className={cls.options}>
        <div>
          <h2 className="h400 my-5">{t("headers.proposed_time")}</h2>

          {!isSelfPickup && (
            <div className="text-large">{t("descriptions.proposed_time")}</div>
          )}
        </div>
        {timing_delivery_enabled && (
          <div>
            <h2 className="h400 my-5">{t("headers.timing_delivery")}</h2>
            <div className="text-large">
              {t("descriptions.timing_delivery")}
            </div>
          </div>
        )}
      </div>

      <div className={cls.options}>
        <div className={cls.deliveryTime}>
          <div className={cls.deliveryMethodSwitch}>
            <Button
              onClick={() => setSelectedTimeReference("pickup_at")}
              color={
                selectedTimeReference === "pickup_at" ? "primary" : "muted"
              }
              variation={
                selectedTimeReference === "pickup_at" ? "solid" : "contrast"
              }
              size="extra-large"
            >
              {isSelfPickup ? t("headers.self_pickup") : t("headers.pickup")}
            </Button>

            {!isSelfPickup && (
              <Button
                color={
                  selectedTimeReference === "delivery_at" ? "primary" : "muted"
                }
                variation={
                  selectedTimeReference === "delivery_at" ? "solid" : "contrast"
                }
                size="extra-large"
                onClick={() => {
                  setSelectedTimeReference("delivery_at")
                  setSelectedTime(null)
                }}
              >
                {t("headers.delivery")}
              </Button>
            )}
          </div>

          <div className={cls.inputs}>
            <div className={clsx(cls.wrapper)}>
              <Input
                onBlur={handleInputBlur}
                defaultValue={inputDefaultValue}
                min={inputMinValue}
                className={cls.input}
                size="extra-large"
                type="time"
                required
              />

              {selectedTimeReference === "delivery_at" &&
                calculatedValues.delivery_at && (
                  <span className={cls.upToRange}>
                    <span className={cls.separator}>–</span>
                    {formatHour(
                      dayjs(calculatedValues.delivery_at).add(30, "m")
                    )}
                  </span>
                )}
            </div>

            {errors?.pickup_in?.message && (
              <div className="text-error">{errors.pickup_in.message}</div>
            )}

            {(errors?.pickup_at || errors?.delivery_at) && (
              <div className="text-error">{t("errors.invalid_time")}</div>
            )}
          </div>
        </div>

        {timing_delivery_enabled && (
          <div className={cls.timingMethodSwitch}>
            <Button
              color={selectedTimingDelivery === "30" ? "primary" : "muted"}
              variation={selectedTimingDelivery === "30" ? "solid" : "contrast"}
              size="extra-large"
              onClick={() => {
                setSelectedTimingDelivery("30")
                setValue("timing_delivery", 30)
              }}
            >
              {t("headers.timing_delivery_30")}
            </Button>

            {!isSelfPickup && (
              <Button
                color={selectedTimingDelivery === "60" ? "primary" : "muted"}
                variation={
                  selectedTimingDelivery === "60" ? "solid" : "contrast"
                }
                size="extra-large"
                onClick={() => {
                  setSelectedTimingDelivery("60")
                  setValue("timing_delivery", 60)
                }}
              >
                {t("headers.timing_delivery_60")}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  )
}

type TimeBoxProps = {
  minutes: number
  selected: boolean
  disabled: boolean
  setSelectedTime: (minutes: number) => void
}

const TimeBox = ({
  minutes,
  selected,
  disabled,
  setSelectedTime,
}: TimeBoxProps) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={() => setSelectedTime(minutes)}
      className={clsx(
        cls.timeBox,
        selected && cls.selected,
        disabled && cls.disabled
      )}
    >
      <div className={clsx("h600", cls.minutes)}>{minutes}</div>
      <div className={cls.label}>min</div>
    </button>
  )
}

export { CalculateTimes }
