import { DataStatus, Order, OrderStepKey } from "@/types"

import { NullableDict } from "./utils"

export type OrderFormStepKey = OrderStepKey | "summary"

export type OrderFormStepStatus = "submitted" | DataStatus

export type OrderFormDataStatus = NullableDict<{
  contact_detail?: OrderFormStepStatus
  payment_and_packages?: OrderFormStepStatus
  processing_time?: OrderFormStepStatus
  notes?: OrderFormStepStatus
  summary?: OrderFormStepStatus
}>

export type City = {
  state: string
  county: string
  city: string
  municipality: string
}

export type ContactDetail = City & {
  phone: string
  house_number: string
  flat_number?: string
  floor?: string
  street: string
  staircase?: string
  postal_code: string
  access_code?: string
  company_name?: string
  distance?: number
  delivery_price_subunit?: number
}

export function checkIsContactDetails<T extends keyof OrderFormData>(
  data: OrderFormData[T]
): data is ContactDetail & OrderFormData[T] {
  return (
    typeof data === "object" &&
    data !== null &&
    "house_number" in data &&
    "city" in data &&
    "street" in data
  )
}

export type PaymentAndPackage = {
  price_subunit: number
  price_delivery_subunit: number
  payment_form: Order["payment_form"]
  packages: Order["packages"]
  transport_types: Order["transport_types"]
  weight?: Order["weight"] | string
}

export type ProcessingTime = {
  pickup_in: number
  pickup_at: Order["pickup_at"]
  delivery_at: Order["delivery_at"]
}

export type Notes = {
  description?: string
}

export type OrderFormData = NullableDict<{
  contact_detail?: ContactDetail
  payment_and_packages?: PaymentAndPackage
  processing_time?: ProcessingTime
  notes?: Notes
}>

export type OrderFormStepData =
  | ContactDetail
  | PaymentAndPackage
  | ProcessingTime
  | Notes

export type SubmitOrderStep = {
  stepData: OrderFormStepData
  stepKey: OrderStepKey
  orderId?: Order["id"] | null
}

export type Address = ContactDetail & {
  humanized_full_address: string
}

export type FullAddress = City &
  Pick<Address, "street" | "house_number" | "postal_code">

export type PartialAddress = City & Pick<Address, "street" | "house_number">

export type DeliveryDetails = {
  delivery_price_subunit: number
  distance: number
  delivery_duration: number
}

export function checkIsDeliveryDetails(data: unknown): data is DeliveryDetails {
  return (
    typeof data === "object" &&
    data !== null &&
    "delivery_price_subunit" in data &&
    "distance" in data &&
    "delivery_duration" in data
  )
}
