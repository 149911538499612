import { ReactComponent as DeligooIconsSprite } from "@deligoo/ui/src/icons/build/deligoo-icons.svg"
import { configureStore } from "@reduxjs/toolkit"
import {
  Provider,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from "react-redux"
import { BrowserRouter, Redirect, Switch } from "react-router-dom"

import { ToastContainer } from "@/components/Toast"
import { GlobalState } from "@/containers/GlobalState"
import routes, { Route } from "@/routes"
import { sentryReduxEnhancer } from "@/sentry"
import { rootReducer, RootState } from "@/store"

export const globalStore = configureStore({
  reducer: rootReducer,
  enhancers: (defaultEnhancers) => [...defaultEnhancers, sentryReduxEnhancer],
  devTools: { actionsBlacklist: "global/tick" },
})

export type AppDispatch = typeof globalStore.dispatch

export function useAppDispatch() {
  return useDispatch<AppDispatch>()
}

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const App = () => {
  return (
    <Provider store={globalStore}>
      <GlobalState>
        <BrowserRouter>
          <Switch>
            {routes.map(({ subroutes, restricted, Layout }, i) => (
              <Route
                key={i}
                exact={subroutes.some(({ exact }) => exact)}
                path={subroutes.flatMap(({ path }) => path)}
                restricted={restricted}
              >
                <Layout>
                  <Switch>
                    {subroutes.map(({ Content, ...props }, i) => (
                      <Route key={i} {...props}>
                        <Content />
                      </Route>
                    ))}
                  </Switch>
                </Layout>
              </Route>
            ))}
            <Redirect from="*" to="/" />
          </Switch>
          <ToastContainer position="top-center" />
        </BrowserRouter>
      </GlobalState>

      <DeligooIconsSprite style={{ display: "none" }} />
    </Provider>
  )
}

export default App
