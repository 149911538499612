import { Button, Modal } from "@deligoo/ui"
import clsx from "clsx"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import {
  selectCurrentRestaurantKey,
  selectIsAppAlertHidden,
  setIsAppAlertHidden,
} from "@/store/restaurant"

import cls from "./AppAlert.module.scss"

const AppAlert = () => {
  const alert = useSelector(selectCurrentRestaurantKey("alert"))
  const alertType = useSelector(selectCurrentRestaurantKey("alert_type"))

  const isAlertRead = useSelector(selectIsAppAlertHidden)

  const [isAlertModalActive, setAlertModalActive] = useState(false)

  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation(["dashboard", "common"])

  const isDashboard = location.pathname === "/"
  let klass = cls.danger

  if (alertType === "danger") {
    klass = cls.danger
  } else if (alertType === "warning") {
    klass = cls.warning
  } else if (alertType === "success") {
    klass = cls.success
  }

  function markAlertAsRead(e: React.SyntheticEvent) {
    e.stopPropagation()

    if (alert) {
      localStorage.setItem("hidden_alert_text", alert)
      dispatch(setIsAppAlertHidden(true))
    }
  }

  return (
    <>
      {isDashboard && alert && (
        <button
          onClick={() => setAlertModalActive(true)}
          className={clsx(cls.alert, klass)}
        >
          <div className={cls.content}>{alert}</div>
        </button>
      )}

      {!isDashboard && !isAlertRead && alert && (
        <button
          onClick={() => setAlertModalActive(true)}
          className={clsx(cls.alert, cls.disposable)}
        >
          <div className={cls.content}>{alert}</div>

          <Button
            onClick={markAlertAsRead}
            as="div"
            variation="text"
            className={cls.close}
            iconProps={{ name: "close-circle", color: "light", size: "large" }}
            title={t("common:buttons.close")}
          />
        </button>
      )}

      {isAlertModalActive && (
        <Modal
          handleClose={() => setAlertModalActive(false)}
          className={cls.modal}
        >
          <Modal.Title>{t("Alert message")}</Modal.Title>
          <Modal.Content>{alert}</Modal.Content>
        </Modal>
      )}
    </>
  )
}

export { AppAlert }
