const settings = {
  headers: {
    language: "Language",
    update_pin: "Update PIN",
    update_client_phone: "Update phone number",
    printing: "Printing",
  },

  buttons: {
    sign_out: "Sign out",
    printing_test: "Test print",
  },

  errors: {
    bt_interface: "The printer's bluetooth interface is not available",
    passwords_match: "The passwords entered must match",
    valid_phone_number: "Please provide valid phone number",
  },

  labels: {
    current_pin: "Current PIN",
    new_pin: "New PIN",
    repeat_new_pin: "Repeat new PIN",
    phone_number: "Phone number",
  },

  toasts: {
    pin_updated: "PIN updated",
    phone_updated: "Phone number updated",
  },

  app_version: "App version: {{version}}",
}

export default settings
